import React, { useState, useEffect } from 'react';
import NvLabel from 'components/NvLabel'
import NumberToKorean from 'utils/NumberToKorean';
import ConvertComma from 'utils/ConvertComma'
function StockInfo({stockTotal, faceValue, capital, commonStock, preferredStock, date}) {

  return (
    <div className='info-right-merge-box info_content_right_card'>
      <div className='info_content_title' style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <div className='info_content_card_title'>주식 현황</div>
        {(date) &&
          <div className='info-basic-date'>기준일 <NvLabel value={date} unit='년월일'/></div>
        }    
      </div>
      <div className='info_content_text_box'>
        {(stockTotal) &&
          <div className='info_content_text_item'>
            <p className='title'>총 발행주식</p>
            <p className='content'><NvLabel value={stockTotal} unit='주'/></p>
          </div>
        }
        {(commonStock) &&
          <div className='info_content_text_item'>
            <p className='title'>보통주</p>
            <p className='content'><NvLabel value={commonStock} unit='주'/></p>
          </div>
        }
        {(preferredStock) && 
          <div className='info_content_text_item'>
            <p className='title'>우선주</p>
            <p className='content'><NvLabel value={preferredStock} unit='주'/></p>
          </div>
        }
        {(capital) &&
          <div className='info_content_text_item'>
            <p className='title'>납입자본금</p>
            <p className='content'>{ConvertComma.comma(NumberToKorean(capital* 100000000, '원'))}</p>
          </div>
        }
        {(faceValue) &&
          <div className='info_content_text_item'>
            <p className='title'>액면가</p>
            <p className='content'><NvLabel value={faceValue} unit='원'/></p>
          </div>
        }
      </div>
    </div>
  );
}

export default StockInfo;