import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'
import search_icon from 'assets/images/search_icon.png'
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Reply from './Reply'
import axios from 'axios';
import url from 'utils/backend';
import Swal from 'sweetalert2'
import Auth from 'utils/auth';
import {ViewIcon, LikeIcon, ChatIcon} from 'components/discussion/Icon'

const DeleteButton = styled(Button)`
    height: 30px;
    margin-right: 15px !important;
`;
const DiscussCard = ({item, type, index,getBoardList}) => {
    const navigate = useNavigate()
    const [isVisibleReply, setIsVisibleReply] = useState(false);
    const [isLike, setIsLike] = useState(item.like);

    const handleLike = async() => {
        if(isLike){
            item.likes -= 1;
            await axios.delete(url + '/forum/' + item.type + '/' + item._id + '/unlike');
            setIsLike(false);
        }
        else{
          item.likes += 1;
            await axios.post(url + '/forum/' + item.type + '/' + item._id + '/like');
            setIsLike(true);
        }
    }
    const deleteBoard = async() => {
        try{
            Swal.fire({
                title: '글을 삭제하시겠습니까?',
                showCancelButton: true,
                confirmButtonText: '확인',
                cancelButtonText: '닫기'
              }).then(async(result) => {
                if(result.isConfirmed) {
                    const res = await axios.delete(url + '/forum/'+item.type+'/' + item._id);
                    getBoardList();
                } else if (result.isDenied) {
                }
            })

        }
        catch(e){
            console.log(e)
        }
    }
    return(
        <div className='discussion-card' onClick={() => navigate('/discussion/view/' +item.type + '/' +  item._id)} style={{cursor: 'pointer'}} >
            {/* <div className='rc-sb' style={{marginBottom: 10}}>
                <p></p>
                <div className='discussion-sub-title'>작성자</div>
            </div> */}
                <span className='discuss-category'>
                    <a className='a'>
                        {index==0 && <i style={{backgroundColor:'#222'}} className='i'>NOW</i>}
                        {index==1 && <i style={{backgroundColor:'#da3238'}} className='i'>HOT</i>}
                        {Auth.isMy(item.user_id) && <div className='discussion-sub-title' style={{color: 'red'}}>내글</div>}

                    </a>
                    {/* <p className='discussion-left-score small'>9.0<span className='discussion-right-score'> / 10</span></p>                 */}
                </span>
            <div className='discuss-tit'>
            {/* <p className='discussion-left-score'>9.0<span className='discussion-right-score'> / 10</span></p>                 */}
                <h3 className='h3'>
                    <a className='h3a'>
                        {(item.type == 'review') ? item.investor_name : item.title}
                    </a>
                <p className='discussion-category'>{item.category}</p>
                </h3>
                <p className='p'>
                    <a className='pa'>
                    {(item.deleted_at == null ) ? item.content : '삭제된 게시글 입니다.'}
                    </a>
                </p>
            </div>
            <div className='discuss-sub'>
                    <p className='author'>
                        <a>
                            {item.author}
                        </a>
                    </p>

                <div className='icon-wrap'>
                    <span style={{display:'flex'}}>
                    <a className='svg'>
                        <ViewIcon  cnt={item.views}/>
                    </a>
                    <a className='svg'>
                        <LikeIcon  cnt={item.likes} isLike={isLike} onClick={() => {if(!Auth.isMy(item.user_id)) handleLike()}}/>
                    </a>
                    <a className='svg'>
                        {(item.type == 'post') && <ChatIcon  cnt={item.replies}/>}
                    </a>
                    </span>
                    <p className='discussion-category small'>{item.next_step && "Proceeding to the next step"}</p>

                </div>
                {/* <div className='rc-sb'>
                    {Auth.isMy(item.user_id) && <DeleteButton variant="contained" color="error" onClick={() => deleteBoard()}>삭제</DeleteButton>}
                    {(true)
                    ?
                        <img className='discussion-bookmark-img' src={require('assets/images/bookmark_icon.png')}/>
                    :
                        <img className='discussion-bookmark-img' src={require('assets/images/bookmark_line.png')}/>
                    }
                </div> */}
            </div>
        </div>
    )
}
export default DiscussCard