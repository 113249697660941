import React,{useState, useCallback} from 'react';
import styled from 'styled-components';
import NvNumberInput from 'components/NvNumberInput';
import NvDatePicker from 'components/NvDatePicker'
import InputBox from 'components/filter/InputBox';
import _ from 'lodash'

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items:center;
    .wave-text{
        font-size:16px;
        font-weight: 600;
    }
    .nv-datepicker div input{
        font-size:12px !important;
    }
`;
const InputRangeBox = ({state, setState, obj, unit}) => {
    const [value, setValue] = useState(_.cloneDeep(state))
    const handleChange = (v, level1) => {
        value[obj][level1] = v;
        setValue(value);
        setState(_.cloneDeep(value))
    }
    const debouncedHandleChange = useCallback(_.debounce((v, level1) => handleChange(v, level1), 300), [state])
    return(
        <Container>
            {(unit != '년월' && unit != '년') 
            ?
                <>
                <InputBox style={{width: '45%', height: 25}}>
                    <NvNumberInput defaultValue={value[obj].min} onChange={(v) => debouncedHandleChange(v, 'min')} onBlur={() => setState(_.cloneDeep(value))} unit={unit}/>
                </InputBox>
                <p className='wave-text'>~</p>
                <InputBox style={{width: '45%', height: 25}}>
                    <NvNumberInput defaultValue={value[obj].max} onChange={(v) => debouncedHandleChange(v, 'max')} onBlur={() => setState(_.cloneDeep(value))}  unit={unit}/>
                </InputBox>
                </>
            :   
                <>
                <InputBox style={{width: '45%', height: 25}}>
                    <NvDatePicker defaultValue={value[obj].min} onAccept={(v) => {value[obj].min = v; setState(_.cloneDeep(value)) }}  unit={unit}/>
                </InputBox>
                <p className='wave-text'>~</p>
                <InputBox style={{width: '45%', height: 25}}>
                    <NvDatePicker defaultValue={value[obj].max} onAccept={(v) =>  {value[obj].max = v;setState(_.cloneDeep(value))}}  unit={unit}/>
                </InputBox>

                </>
            }
        </Container>
    )
}
export default InputRangeBox