import React, {useEffect, useState} from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Header from 'components/admin/Header'
import LeftSideBar from 'components/admin/LeftSideBar'
import RightSideBar from 'components/admin/RightSideBar'
import '../admin.scss'
import axios from 'axios'
import url from 'utils/backend'
import _ from 'lodash'
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import PageModule from 'components/admin/PageModule';
import PanelFilterSelect from 'components/admin/PanelFilterSelect';
import Item from './component/Item'
import ConvertComma from 'utils/ConvertComma';



const filterList = [
    {
        value: 'keyword',
        width: 35,
        child: [
            // { label: "아이디", value: "id" },
            { label: "이름", value: "name" },
            // { label: "필명", value: "nickname" },
            { label: "이메일", value: "email" },
            // { label: "주소", value: "address" },
        ]
    },
    {
        value: 'manage',
        width: 45,
        child: [
            { label: "관리등급", value: "default" },
            { label: "일반회원", value: "basic" },
            { label: "스타트업", value: "startup" },
            { label: "구독자", value: "subscriber" },
            { label: "관리자", value: "admin" },
        ]
    },
    {
        value: 'type',
        width: 45,
        child: [
            { label: "회원등급", value: "default" },
            { label: "일반회원", value: "basic" },
            { label: "시민기자", value: "citizen_reporter" },
            { label: "사진기자", value: "photojournalist" },
            { label: "기자", value: "reporter" },
            { label: "데스크", value: "desk" },
        ]
    },
    {
        value: 'status',
        width: 73,
        child: [
            { label: "전체상태", value: "default" },
            { label: "승인", value: "approve" },
            { label: "미승인", value: "not_approve" },
        ]
    }
]
const Main = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [filter, setFilter] = useState({
        keyword: 'name',
        manage: 'default',
        type: 'default',
        status:'default' 
    })
    const [search, setSearch] = useState('')
    const [pageIndex, setPageIndex] = useState(1)
    const [list, setList] = useState([])    
    const [total, setTotal] = useState(0)
    const size = 10
    const getUserList = async() => {
        try{
            const page = searchParams.get('page');
            let uri = url + '/user/all?page=' + page + '&size=' + size;
            if(search){
                uri += `&search_type=${filter.keyword}&search=${search}`
            }
            if(filter.manage != 'default'){
                uri += `&manage=${filter.manage}`
            }
            if(filter.type != 'default'){
                uri += `&type=${filter.type}`
            }
            if(filter.status != 'default'){
                uri += `&status=${filter.status}`
            }
            const res = await axios.get(uri);
            setTotal(res.data.total)
            setList(res.data.list)
        }
        catch(e){
            console.log(e)
        }
    }
    const handleKeyDown = (e) => {
        if (e.keyCode == 13) getUserList();
    };
    useEffect(()=>{
        const page = searchParams.get('page')
        page && setPageIndex(page)
        if(!page){
            navigate('/admin/member?page=1', {replace: true})
        }
        getUserList()
    },[location])
    return(
        <div className='admin-main-wrap mg'>
            <Header title={'회원'}/>
            <div className='admin-main'>
                <section className='admin-body mg' >
                    <LeftSideBar/>
                    <div className='admin-body-middle'>
                        <section className='panel-container'>
                            <header className='panel-header'>
                                <div className='panel-title'>
                                    <strong>회원목록</strong>
                                    <small>회원수 : {ConvertComma.comma(total)}명</small>
                                </div>
                            </header>
                            <article className='panel-block'>
                                <div className='panel-filter'>
                                    <div className='div'>
                                        {filterList.map((data, i)=>{
                                            return(
                                                <article className='item'>
                                                    <PanelFilterSelect 
                                                        width={data.width} 
                                                        defaultValue={filter[data.value]}
                                                        list={data.child}
                                                        onChange={(v) => {
                                                            setFilter(prev => {
                                                                let tmp = _.cloneDeep(prev);
                                                                tmp[data.value] = v;
                                                                return tmp;
                                                            })
                                                        }}
                                                    />
                                                </article>
                                            )
                                        })}
                                        <article className='item searchitem'>
                                            <Paper >
                                                <InputBase
                                                    placeholder="검색어를 입력하세요."
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <IconButton className='btns' aria-label="search"
                                                    onClick={getUserList}
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </Paper>
                                        </article>
                                        {/* <article className='item'>
                                            <div
                                                className='btn'
                                            >
                                                초기화
                                            </div>
                                        </article> */}
                                    </div>
                                </div>
                                <table className='panel-table'>
                                    <thead className='thead'>
                                        <tr>
                                            <th className='hideTh'>이름</th>
                                            <th className='hideTh'>이메일</th>
                                            <th className='hideTh'>관리등급</th>
                                            <th className='hideTh'>회원등급</th>
                                            <th className=''>상태</th>
                                            <th className=''>관리</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {list && list.map((data,i)=>{
                                            return <Item key={data._id} item={data} getUserList={getUserList} filterList={filterList}/>
                                        })}
                                    </tbody>
                                </table>
                                <footer className='panel-footer'>
                                    <div className='footer-group'>
                                    <PageModule
                                        isMember={true}
                                        pageCount={Math.ceil(total / 10)}
                                        onPageChange={(v) => {
                                            let num = v + 1;
                                            navigate('/admin/memberList?page=' + num );
                                        }}
                                    />
                                    </div>
                                </footer>
                            </article>
                        </section>
                    </div> 
                    <RightSideBar/>
                </section>
            </div>
        </div>
    )
}

export default Main