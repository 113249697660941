import react, { useRef, useState, useMemo, useEffect } from "react";
// import DateFormat_Year_Month from 'utils/DateFormat_Year_Month';
// import DateFormat_Year_Month_Date from 'utils/DateFormat_Year_Month_Date';
const DateFormat_Year_Month_Date = (date) => {
  if(!date) return ;
  let fullDate = new Date(date);
  const year = fullDate.getFullYear().toString();
  const month = fullDate.getMonth();
  const day = fullDate.getDate();
  return year.slice(0,4) + '년 ' + ('0' + (month + 1)).slice(-2) + '월 ' + ('0' + (day)).slice(-2) + '일';

}
const DateFormat_Year_Month = (date, short) => {
  if(!date) return ;
  let fullDate = new Date(date);
  const year = fullDate.getFullYear().toString();
  const month = fullDate.getMonth();
  if (short){
      return year.slice(2, 4) + '.' + ('0' + (month + 1)).slice(-2);
  }
  return year.slice(0, 4) + '년 ' + ('0' + (month + 1)).slice(-2) + '월 ';

}

function NvLabel(props) {
  const _display = () => {
    let v = props.value

    if (props.type == 'int') {
        v = Math.round(v)
    }

    if (props.unit == '억') {
      if (props.signed == true) {
        v = parseFloat((v * 1).toFixed(2)).toLocaleString('ko-KR');
        v = v + ' 억'
      } else {
        if (v > 0) {
          v = parseFloat((v * 1).toFixed(2)).toLocaleString('ko-KR');
          v = v + ' 억'
        } else {
          v = ''
        }
      }
    } else if(props.unit == '조억') {
      if (v > 0) {
        let jo = 0
        if(v >= 10000) {
          jo = parseInt(v / 10000)
          jo = parseFloat((jo * 1).toFixed(2))
        }
        let uk = Math.round(v % 10000)
        uk = parseFloat((uk * 1).toFixed(2))
        v = ''
        if(jo > 0) {
          v += jo.toLocaleString('ko-KR') + '조 ';
        }
        if(uk > 0) {
          v += uk.toLocaleString('ko-KR') + ' 억';
        }
      } else {
        v = ''
      }
    } else if (props.unit == '원') {
      if (v > 0) {
        v = parseFloat((v * 1).toFixed(2)).toLocaleString('ko-KR');
        v = v + ' 원'
      } else {
        v = ''
      }
    } else if (props.unit == '회') {
      if (v > 0) {
        v = parseFloat((v * 1).toFixed(2)).toLocaleString('ko-KR');
        v = v + ' 회'
      } else {
        v = ''
      }
    } else if (props.unit == '개') {
      if (v > 0) {
        v = parseFloat((v * 1).toFixed(2)).toLocaleString('ko-KR');
        v = v + ' 개'
      } else {
        v = ''
      }
    } else if (props.unit == '명') {
      if (v > 0) {
        v = parseFloat((v * 1).toFixed(2)).toLocaleString('ko-KR');
        v = v + ' 명'
      } else {
        v = ''
      }
    } else if (props.unit == '주') {
      if (v > 0) {
        v = parseFloat((v * 1).toFixed(2)).toLocaleString('ko-KR');
        v = v + ' 주'
      } else {
        v = ''
      }
    } else if (props.unit == '년월') {
      v = DateFormat_Year_Month(v)
    } else if (props.unit == '년월일') {
      v = DateFormat_Year_Month_Date(v)
    } else if (props.unit == '년월일시') {
      v = DateFormat_Year_Month_Date_Hour(v)
    } else {
      v = props.value
    }
    return v
  }

  const DateFormat_Year_Month_Date_Hour = (date) => {
    if(!date) return ;
    let fullDate = new Date(date);
    const year = fullDate.getFullYear().toString();
    const month = fullDate.getMonth();
    const day = fullDate.getDate();
    const hour = fullDate.getHours();
    return year.slice(0,4) + '년 ' + ('0' + (month + 1)).slice(-2) + '월 ' + ('0' + (day)).slice(-2) + '일 ' + ('0' + (hour)).slice(-2) + '시';

  }

  const _className = () => {
    const platform = window.navigator.platform;
    let class_names = 'nv-label' 
    if(props.className) class_names += ' ' + props.className
    return class_names     
  }

  const _style = () => {
    let style = {}
    if(props.style) style = props.style
    if(props.tal) style.textAlign = 'left'
    if(props.tar) style.textAlign = 'right'

    return style 
  }


  return (
    <span className={_className()} style={_style()}>
      {_display()}
    </span>
  );
}

export default NvLabel;
