import { createSlice } from '@reduxjs/toolkit'

const secondaryFilter = {
  sovereignty: [],
  exposure: []
}
export const secondaryFilterSlice = createSlice({
  name: 'secondaryFilter',
  initialState: secondaryFilter,
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return secondaryFilter
    }
  },
})

export const { update, reset } = secondaryFilterSlice.actions

export default secondaryFilterSlice.reducer