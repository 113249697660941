const DateFormat_Year_Month = (date, short) => {
    if(!date) return ;
    let fullDate = new Date(date);
    const year = fullDate.getFullYear().toString();
    const month = fullDate.getMonth();
    if (short){
        return year.slice(2, 4) + '. ' + ('0' + (month + 1)).slice(-2);
    }
    return year.slice(0, 4) + '. ' + ('0' + (month + 1)).slice(-2) + '';

}
export default DateFormat_Year_Month;