import React from 'react'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import TableArrow from 'components/TableArrow'
import { useNavigate } from 'react-router-dom'
import NvLabel from 'components/NvLabel'
import moment from 'moment'
function Table({ columns, data, handleNavigate, renderTd, style }) {
  const navigation = useNavigate();
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy
    )
  
    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    const firstPageRows = rows.slice(0, 2000)

    return (
      <>
        <table {...getTableProps()}  className='investor-table' style={{...style, tableLayout: 'fixed'}}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} >
                {headerGroup.headers.map((column, i) => (
                  <th 
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={(i == 0) && 'investor-table-first-cell'}
                    style={{
                      color: '#444444',
                      width: column.width * 30,
                      boxShadow: '0px 0.5px 0px #d4cec6', 
                      paddingLeft: column.paddingLeft}}
                  >
                    <span className='investor-table-th' style={{justifyContent: column.textAlign, marginRight: 8}}>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <TableArrow sort={'desc'}/>
                          : <TableArrow sort={'asc'}/>
                        : <TableArrow/>}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {firstPageRows.map(
              (row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={(i % 2 == 0) ? 'investor-table-tr-even' : null} style={(handleNavigate) ? {cursor: 'pointer'}: null} onClick={() => {if(handleNavigate) handleNavigate(i)}}>
                    {row.cells.map((cell, index) => {
                      let textAlign = headerGroups[0].headers[index].textAlign;
                      let marginRight = 0;
                      if(textAlign == 'flex-end') {
                        textAlign = 'right'; 
                        marginRight= 16;
                      }
                      else if(textAlign == 'flex-start') {
                        textAlign = 'left'
                      };
                      return (
                        <td 
                          {...cell.getCellProps({
                            style: {
                              width: cell.column.width * 30,
                              textAlign: textAlign,
                              paddingRight: 10,
                              paddingLeft: cell.column.paddingLeft
                            },
                          })} 
                          className={'investor-table-td ' + ((index == 0) && ' investor-table-first-cell')}
                        >
                          {renderTd(cell, data)}
                        </td>
                      )
                    })}
                  </tr>
                )}
            )}
          </tbody>
        </table>
      </>
    )
}
export default Table;  