import React,{useState, useEffect} from 'react'
import {debounce} from 'lodash'
import Header from '../component/Header'
import LeftSideBar from '../component/LeftSideBar'
import './news-reply.scss'
import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/List';
import PageModule from '../component/PageModule'
import NavBarBtm from "components/navbar/NavBarBtm";
import RightSideBar from '../component/RightSideBar'
import { useDidMountEffect } from 'hooks/useDidMountEffect'
const NewsReply = () =>{
    const [reactableSide, setReactableSide] = useState(true)
    const [reactableOpacity, setReactableOpacity] = useState(false)
    const [btnState, setBtnState] = useState(true)
    const [listState, setListState] = useState(true)
    const [pageState, setPageState] = useState(false)
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const handleResize = debounce(()=>{
        setWindowSize({
            width:window.innerWidth,
            height:window.innerHeight
        })
    }, 300)
    useEffect(()=>{
        window.addEventListener('resize', handleResize);
        return()=>{
            window.removeEventListener('resize',handleResize)
        }
    },[])
    useEffect(()=>{
        if(windowSize.width>1023){
            setReactableSide(true)
            setReactableOpacity(false)
            setPageState(false)
        }
        else{
            setReactableSide(false)
            setPageState(true)
        }
    },[windowSize])
    return(
        <div className='mynews-main-wrap mg'>                
        <Header title={'기사댓글'} reactableSide={reactableSide} setReactableSide={setReactableSide} reactableOpacity={reactableOpacity} setReactableOpacity={setReactableOpacity}/>

            <div className='mynews-main'>
                <section className='mynews-body mg' >
                    {/* <LeftSideBar reactableSide={reactableSide} setReactableSide={setReactableSide}/> */}
                    <div 
                    className='mynews-list' 
                    style={{opacity:(reactableOpacity?0.7:1)}}
                    onClick={()=>{
                        setReactableOpacity(false); 
                        if(pageState){
                            setReactableSide(false)
                        }
                        }}
                    >
                        <section className='section'>
                            <header className='header'>
                                <div className='title'>
                                    <strong className='strong'>내 기사댓글&nbsp;
                                    <small className='small'> (전체 3건)</small>
                                    </strong>
                                    <small className='small small-display'>
                                        <span className='beware'>댓글삭제시 </span>
                                        해당댓글의 답글까지 같이 삭제되니 참고바랍니다.
                                    </small>
                                </div>
                            </header>
                            <article className='article'>
                                <table className='rep-table'>
                                    <caption className='rep-cap'>기사댓글목록</caption> 
                                    <thead className='rep-thead'>
                                        <tr className='tr'>
                                            <th className='th width-150'>아이디</th>
                                            <th className='th'>내용</th>
                                            <th className='th width-180'>관리</th>
                                        </tr>
                                    </thead>
                                    <tbody className='rep-tbody'>
                                        <tr className='tr'>
                                            <td className='td'>
                                                <strong className='str'>시카고학파</strong>
                                                <small className='sm'>(비회원)</small>
                                            </td>
                                            <td className='td'>
                                                <div className='reply-date'>
                                                    2022-08-08 23:52:53 / 39.115.172.109
                                                </div>
                                                <p className='p'>
                                                    <a>
                                                    경제적인 측면에서 생산수단으로 활용되지 않는 유휴자산을 그대로 방치하는 것은 국가경제의 어마어마한 손실이지요 마땅히 국민들이 경제활동의 수단으로 활용할 수 있도록 장려해야 합니다
                                                    </a>
                                                </p>
                                            </td>
                                            <td className='td txt-center'>
                                                <div className='btn-news-href'>
                                                    <a className='btn-news'>해당기사보기</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='tr'>
                                            <td className='td'>
                                                <strong className='str'>시카고학파</strong>
                                                <small className='sm'>(비회원)</small>
                                            </td>
                                            <td className='td'>
                                                <div className='reply-date'>
                                                    2022-08-08 23:52:53 / 39.115.172.109
                                                </div>
                                                <p className='p'>
                                                    <a>
                                                    경제적인 측면에서 생산수단으로 활용되지 않는 유휴자산을 그대로 방치하는 것은 국가경제의 어마어마한 손실이지요 마땅히 국민들이 경제활동의 수단으로 활용할 수 있도록 장려해야 합니다
                                                    </a>
                                                </p>
                                            </td>
                                            <td className='td txt-center'>
                                                <div className='btn-news-href'>
                                                    <a className='btn-news'>해당기사보기</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='tr'>
                                            <td className='td'>
                                                <strong className='str'>시카고학파</strong>
                                                <small className='sm'>(비회원)</small>
                                            </td>
                                            <td className='td'>
                                                <div className='reply-date'>
                                                    2022-08-08 23:52:53 / 39.115.172.109
                                                </div>
                                                <p className='p'>
                                                    <a>
                                                    경제적인 측면에서 생산수단으로 활용되지 않는 유휴자산을 그대로 방치하는 것은 국가경제의 어마어마한 손실이지요 마땅히 국민들이 경제활동의 수단으로 활용할 수 있도록 장려해야 합니다
                                                    </a>
                                                </p>
                                            </td>
                                            <td className='td txt-center'>
                                                <div className='btn-news-href'>
                                                    <a className='btn-news'>해당기사보기</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='tr'>
                                            <td className='td'>
                                                <strong className='str'>시카고학파</strong>
                                                <small className='sm'>(비회원)</small>
                                            </td>
                                            <td className='td'>
                                                <div className='reply-date'>
                                                    2022-08-08 23:52:53 / 39.115.172.109
                                                </div>
                                                <p className='p'>
                                                    <a>
                                                    경제적인 측면에서 생산수단으로 활용되지 않는 유휴자산을 그대로 방치하는 것은 국가경제의 어마어마한 손실이지요 마땅히 국민들이 경제활동의 수단으로 활용할 수 있도록 장려해야 합니다
                                                    </a>
                                                </p>
                                            </td>
                                            <td className='td txt-center'>
                                                <div className='btn-news-href'>
                                                    <a className='btn-news'>해당기사보기</a>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                   </tbody>
                                </table>
                            </article>
                            <PageModule/>
                        </section>
                    </div>
                    <RightSideBar/>                  
                </section>
            </div>
            <NavBarBtm type={"Main"} />
        </div>
    )
}

export default NewsReply