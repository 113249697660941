import React from 'react';
import NvNumberInput2 from 'components/NvNumberInput2';
import styled from 'styled-components';
const Box = styled.div`
    width: 45%;
    .range{
        width: 70px;
        font-size: 14px;
        font-weight: 600;
        color: #444;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const InputPrice2 = ({state, setState}) => {
    return(
        <div className='rc' style={{marginTop: 15}}>
            <Box className='rc ' style={{marginRight: 70}}>
                <p className='range'>Min</p>
                <NvNumberInput2 defaultValue={state.min} onChange={(v) => setState({...state, min: v})} className='text_input' unit='억'/>
            </Box>
            <Box className='rc'>
                <p className='range'>Max</p>
                <NvNumberInput2 defaultValue={state.max} onChange={(v) => setState({...state, max: v})} className='text_input' unit='억'/>
            </Box>
        </div>
    )
}
export default InputPrice2;