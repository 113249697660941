import React, {useEffect, useState} from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {ReactComponent as Dashboard} from 'assets/images/admin_menu_dashboard.svg'
import {ReactComponent as Member} from 'assets/images/admin_menu_member.svg'
import {ReactComponent as News} from 'assets/images/admin_menu_news.svg'
const menuList = [
    {
        label: '대시보드',
        value: 'dashboard',
        icon: <Dashboard/>,
        path: '/admin',
        child: []
    },
    {
        label: '기사',
        value: 'news',
        icon: <News/>,
        path: '/admin/newsWrite',
        child: [
            {
                label: '기사등록',
                value: 'newsWrite',
                path: '/admin/newsWrite'
            },
            {
                label: '기사검색',
                value: 'newsSearch',
                path: '/admin/newsSearch'
            },
            {
                label: '승인관리',
                value: 'newsList',
                path: '/admin/newsList'
            },
        ]
    },
    {
        label: '회원',
        value: 'member',
        icon: <Member/>,
        path: '/admin/member',
        child: [
            {
                label: '회원목록',
                value: 'memberList',
                path: '/admin/memberList'
            }
        ]
    },
]

const SideBar = () =>{
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const activeMenu = (value) => {
        let tmp = menuList.find(e => e.value == value)
        return (tmp.path == location.pathname || tmp.child.some(e => e.path == location.pathname))
    }
    const handleOverlay = () => {
        let element = document.getElementsByClassName('admin-main-wrap')[0];
        let isActive = false;
        element.classList.forEach(e => {
            if(e == 'active') isActive = true;
        })
        if(isActive){
            element.classList.remove('active')
        }
        else{
            element.classList.add('active')
        }

    }
    return(
        <>
        <div className='admin-active-overlay' onClick={handleOverlay}/>
        <aside className='leftaside'>
            <nav className='nav'>
                <ul>
                    {menuList.map(data => {
                        return(
                            <li className='nav-item'>
                                <Link 
                                    className={(activeMenu(data.value) ? 'active' : '' )} 
                                    to={data.path}
                                 
                                >
                                    {data.icon}
                                    <strong className='text'>{data.label}</strong>
                                </Link>
                                {data.child.length >= 1 &&
                                    <ul className='submenu-box'>
                                        {data.child.map((child, i) => {
                                            return(
                                                <li className={location.pathname == child.path && 'selected'}>
                                                    <Link to={child.path}>
                                                        {child.label}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </aside>
        </>
    )
}
export default SideBar