import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


const initSelectedList = [
  {
    label: '메모',
   value: 'memo' 
  }
]
export const getMemoCustom = createAsyncThunk( 
    'getMemoCustom'
    ,
    async() => {
    try{
      const res = await axios.get(url + '/user/custom/memo');
      if(res.data.table.length >= 1){
        return res.data.table
      }
      else{
        return initSelectedList
      }
    }
    catch(e){
      console.log(e)
    }
})


const updateMemoCustom = async(data) => {
  try{
    const res = await axios.post(url + '/user/custom/memo', data);
  }
  catch(e){
    console.log(e)
  }
}


export const memoCustomSlice = createSlice({
  name: 'memoCustom',
  initialState: initSelectedList,
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      updateMemoCustom(payload)
      return payload
    },
    reset: (state) => {
      return initSelectedList
    }
  },
  extraReducers: {
    [getMemoCustom.pending.type]: (state, action) => { // 호출 전
    },
    [getMemoCustom.fulfilled.type]: (state, action) => { // 성공
        return action.payload
    },
    [getMemoCustom.rejected.type]: (state, action) => {  // 실패
    },
}
})

export const { update, reset } = memoCustomSlice.actions

export default memoCustomSlice.reducer