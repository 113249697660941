import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


export const registerListSlice = createSlice({
  name: 'registerList',
  initialState: [],
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return []
    }
  },

})

export const { update, reset } = registerListSlice.actions

export default registerListSlice.reducer