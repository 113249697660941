import React, { useState, useEffect } from 'react';
import NvLabel from 'components/NvLabel'
import moment from 'moment';
import DateFormat_Year_Month_Date from 'utils/DateFormat_Year_Month_Date';

function BasicInfo({ date, ceo, employees, hompage}) {
  return (
    <div className='info_content_right_card'>
      <div className='info_content_title'>
        <div className='info_content_card_title'>일반 현황</div>
      </div>
      <div className='info_content_text_box'>
        {(date) &&
          <div className='info_content_text_item'>
            <p className='title'>설립일자</p>
            <p className='content'><NvLabel value={DateFormat_Year_Month_Date(date)}/></p>
          </div>
        }
        {(ceo) &&
          <div className='info_content_text_item'>
            <p className='title'>대표이사</p>
            <p className='content'><NvLabel value={ceo}/></p>
          </div>
        }
        {(employees) &&
          <div className='info_content_text_item'>
            <p className='title'>종업원 수</p>
            <p className='content'><NvLabel value={employees} unit='명'/></p>
          </div>
        }
        {(hompage) &&
          <div className='info_content_text_item'>
            <p className='title'>홈페이지</p>
            <p className='content'><a href={hompage} target='_blank'><NvLabel value={hompage} /></a></p>
          </div>
        }
      </div>
    </div>
  );
}

export default BasicInfo;