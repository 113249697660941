import React from 'react'
import _ from 'lodash'
import CardContainer from '../CardContainer';

const All = ({list}) =>{
    return(
        <div>
            <CardContainer list={_.filter(list, {value:'latest'})[0]} />
            <CardContainer list={_.filter(list,{value:'trending'})[0]}/>
            <CardContainer list={_.filter(list,{value:'startup'})[0]}/>
        </div>
    )
}

export default All