export const productList = [
    {text: '특허준비'},
    {text: '연구개발초기'},
    {text: '레터링'},
    {text: '목업'},
    {text: 'POC'},
    {text: '프로토타입'},
    {text: '시제품'},
    {text: '인허가'},
    {text: 'MVP'},
    {text: '파일럿'},
    {text: '양산준비'},
    {text: '양산중'},
    {text: '고도화'},
    {text: '신규제품준비'},
];
export const serviceList = [
    {text: '특허준비'},
    {text: '연구개발초기'},
    {text: '연구개발중기'},
    {text: '프리알파'},
    {text: '알파버전'},
    {text: '베타버전'},
    {text: '서비스준비'},
    {text: '서비스중'},
    {text: '유료전환시점'},
    {text: '유료화'},
    {text: '고도화'},
    {text: '신규서비스준비'},
]
export const medicineList = [
    {text: '특허준비'},
    {text: '논문준비'},
    {text: '후보물질발굴'},
    {text: 'IP패키징'},
    {text: '전임상준비'},
    {text: '전임상진행'},
    {text: '임상1상예정'},
    {text: '임상IND'},
    {text: '임상1상진행중'},
    {text: '임상1상완료'},
    {text: '임상2상IND'},
    {text: '임상2상진행중'},
    {text: '임상2상완료'},
    {text: '희귀질환치료제품목허가'},
    {text: 'R&D'},
    {text: '임상중'},
    {text: '출시'},
    {text: '판매중'},
]
export const medicalList = [
    {text: '지식재산권확보'},
    {text: '상용화준비'},
    {text: '시제품개발'},
    {text: '인허가준비'},
    {text: '인허가취득'},
    {text: '평가진행'},
    {text: '연구개발'},
    {text: '개발완료'},
    {text: '양산준비'},
    {text: '양산중'},
    {text: '허가준비'},
    {text: '허가취득'},
];

export const marketingList = [
    {text: '마케팅계획', name: 'marketing_plan'},
    {text: '사업활성화계획', name: 'active_plan'},
    {text: '홍보계획', name: 'promotion_plan'},
    {text: '영업전략', name: 'business_plan'},
    {text: '타켓별 마케팅전략', name: 'target_plan'},
    {text: '유통전략', name: 'circulation_plan'},
    {text: '판매전략', name: 'sales_plan'},
  ];
  
export const competitivenessList = [
    {text: '장점&강점', name: 'strength'},
    {text: '가격경쟁력', name:'price'},
    {text: '생산노하우 보유', name: 'production_knowhow'},
    {text: '양산/연구환경 보유', name: 'mass_production_research_environment'},
    {text: '핵심역량', name: 'core_capabilities'},
    {text: '경쟁력 분석'}
]
export const differentiationList = [
    {text: '차별화요소', name: 'differentation'},
    {text: '경쟁우위요소', name: 'competitive_advantage'},
]
export const technologyList = [
    {text: '기술진입장벽', name: 'barrier_to_technology'},
    {text: '기술개발능력', name: 'development_technology'},
    {text: '기술적 차별성', name: 'differentation_technology'},
    {text: '기술적 우수성', name: 'excellence_technology'},
    {text: '기술전략', name: 'skill_technology'},
    {text: '핵심보유기술', name: 'possession_technology' },
    {text: '주요기술', name: 'core_technology'},
    {text: '기술특징', name: 'characteristic_technology'},
    {text: '기술 개발현황', name: 'development_status_technology'},

]
export const existInducementList = [ //구주 유치기한
    {text: 'IR 완료 후 1개월'},
    {text: 'IR 완료 후 2개월'},
    {text: 'IR 완료 후 3~6개월'},
    {text: '투자 유치 후'},
  ];
export const newInducementList = [ //신주 유치기한
    {text: 'IR 완료 후 1개월'},
    {text: 'IR 완료 후 2개월'},
    {text: 'IR 완료 후 3~6개월'},
]
  export const purposeList = [ //제안내용
    {text: '구주인수'},
    {text: '컨설팅'},
    {text: '신주조건부'},
    {text: 'M&A'},
  ];
  export const judgementList = [ //최종판단
    {text: '관리'},
    {text: '재검토일 지정'},
    // {text: '미관리'},
  ];

  export const judgementList2 = [ //최종판단-관리
    {text: '자료 검토중'},
    {text: '투자 검토중'},
    // {text: '재미팅'},
  ];
  export const managerList = [ //제안자
    {text: '피투자사'},
    {text: '담당자'}
  ];
  export const typeList = [ //주식 형태
    {text: 'CS'},
    {text: 'IR 완료 후 1달'},
    {text: 'IR 완료 후 2달'},
    {text: 'RCPS'},
  ]
  export const sellerList = [ //매도인
    {text: '대표이사'},
    {text: '임직원'},
    {text: '기관투자자'},
    {text: '회사지인'},
    {text: '전략적투자자'},
    {text: '엔젤투자자'},
    {text: '일반법인'},
    {text: '자사주'},
  ];
export const sellerReasonList = [ //매도사유
    {text: '펀드만기'},
    {text: '차익실현'},
    {text: '상환청구'},
    {text: '채무상환'},
    {text: '신주조건부'},
];
export const writeManagerList = [ //작성 담당자
    {text: '대표이사'},
    {text: '부대표'},
    {text: 'CFO'},
    {text: '이사'},
];

export const writeReferenceList = [ //작성 레퍼런스
    {text: '창업경험', value: '', value2: ''},
    {text: '투자유치경험', value: '', value2: ''},
    {text: 'IR 작성경험', value: ''}
];
export const investmentTypeList = [ //투자형태
    // {text: 'ALL Type'},
    {text: 'CS'},
    {text: 'CS/RCPS'},
    {text: 'RCPS'},
    {text: 'CPS'},
    {text: 'CB'},
    {text: 'BW'},
];
export const investmentSustainable = [ //(신규투자) 유치가능성
    {text: '부정적'},
    {text: '긍정적'},
    {text: '노코멘트'},
]

export const experienceList = [ //작성 레퍼런스-창업경험
    {text: '현재운영'},
    {text: 'M&A'},
    {text: '폐업'},
    {text: 'IPO'},
];
export const certTypeList = [
    {text: '벤처인증'}
];
export const improvementsList = [
    {text: 'test'},
];
  
export const salesPeriodList = [ //매출 시점
    {text: '과거'},
    {text: '미래'},
];
export const salesAnalysisList = [ // 매출분석
    {text: 'sustainable'},
    {text: 'temporary'},
    {text: 'upturn'},
    {text: 'downturn'},
];
export const possibilityList = [ //매출 달성 가능성
    {text: '긍정적'},
    {text: '부정적'},
    {text: '통상적'}
];
export const basisList = [
    {text: 'test'},
];
export const growthList = [ //기업 성장 단계
    {text: 'Angel'},
    {text: 'Seed'},
    {text: 'Start up'},
    {text: 'Series Pre-A'},
    {text: 'Series A'},
    {text: 'Series A Bridge'},
    {text: 'Series B'},
    {text: 'Series B Bridge'},
    {text: 'Series C'},
    {text: 'Series D'},
    {text: 'Series E'},
    {text: 'Pre-IPO'},
];
export const excavationList = [ //기업 발굴 경로
    {text: '기업소싱부'},
    {text: '네트워크'},
    {text: 'ID'},
];
export const certificationList = [ //인증 내역
    {text: '벤처기업'},
    {text: '기업부설연구소'},
    {text: '기술혁신형중소기업'},
    {text: '가족친화인증'},
    {text: '기업부설연구소'},
    {text: '여성기업'},
    {text: '사회적기업'},
    {text: '경영혁신 인증기업'},
    {text: '강소기업'},
    {text: '의약품 GMP 인증'},
    {text: '우수화장품 GMP 인증'},
    {text: '주관사 체결'},
    {text: '이노비즈'},
    {text: '외감기업'},
    {text: 'TIPS'},
];
export const stockTypeList = [ //주권 형태
    {label: '통일주권', value: '통일주권'},
    {label: '명의개서', value: '명의개서'},
];
export const riskList = [ //거래 위험도
    {text: '안전'},
    {text: '주의'},
    {text: '경계'},
];
export const exposureList = [ //노출
    {text: '무'},
    {text: '온라인'},
    {text: '오프라인'},
];
export const classificationList2_2 = [ //분류
    {text: '중계형 플랫폼'},
    {text: '중계형 콜센터'},

];
export const classificationList2_3 = [ //분류
    {text: '유사투자자문'},
    {text: '크라우드펀딩'},
    {text: '오프라인 다단계법인'},
    {text: '전문투자 BOTIQUE'},
];
export const codeList = [
    {text: 'test'},
];
export const bigList = [
    {text: 'test'},
];
export const middleList = [
    {text: 'test'},
];
export const smallList = [
    {text: 'test'},
];
  
export const roleList = [ //현 역할
    {text: '경영 총괄'},
    {text: '재무 총괄'},
    {text: '기술 총괄'},
    {text: '관리 총괄'},
    {text: '마케팅 총괄'},
    {text: '생산 총괄'},
];
  
export const educationList = [ //학력 전공
    {text: '학사'},
    {text: '석사'},
    {text: '박사'},
];

export const reviewList1 = [ // 검토 차수 1
    {text: '1차'},
    {text: '2차'},
    {text: '3차'}
];
export const reviewList2 = [ // 검토 차수 2
    {text: '지원부'},
    {text: '심사부'},
    {text: '관리부'}
];
export const reviewList3 = [ // 검토 차수 3
    {text: '소싱일'},
    {text: '작성일'},
    {text: '탐방일'},
    {text: '재검토일'},
    {text: '리서치일'}
];
export const divisionList = [ // 기업 구분
    {text: '관리'},
    {text: '미관리'},
    {text: '재검토일지정'},
    {text: '신규'}
];
export const divisionList1_2 = [ // 기업 구분 - 관리
    {text: '딜 진행중'},
    {text: '자료 검토중'},
    {text: 'Team sheet 확정'},
    {text: '신주투자 완료 시점'},
    {text: '재미팅'}
];
export const nowSituationList = [ // 현재 상황
    {text: 'IR진행중'},
    {text: '부분유치'},
    {text: '유치확정'},
    {text: '완료'}
]
export const nowSituation1_1List = [ //현재 상황 - IR진행중
    {text: 'IR진행 횟수'},
    {text: '검토중인투자자 수'},
];
export const nowSituation1_2List = [ //현재 상황 - 부분유치
    {text: 'Volume'},
];
export const nowSituation1_3List = [ //현재 상황 - 유치확정
    {text: '확정금액'},
];
export const nowSituation1_4List = [ //현재 상황 - 완료
    {text: '완료'},
];
export const attractabilityList = [ //(신규투자)유치 가능성
    {text: '부정적'},
    {text: '긍정적'},
    {text: '보통'},
];
export const reasonList = [ //사유 - 부정적
    {text: '자금소요 및 조달계획 불분명'},
    {text: '미팅한 투자사가 주목적펀드 없음'},
    {text: '불확실한 앵커투자사'},
    {text: '회사본질평가 상 어려움'},
    {text: '주니어심사역 담당으로 승인 난관'},
    {text: '보유기술의 난해성 (승인권자 or 매니저가 어려운 기술로 이해불가)'},
    {text: '회사제시 기업가치 High'},
    {text: 'IPO심사시 기업지배구조문제'},
];
export const reason2List = [ //사유 - 긍정적
    {text: '기존주주(투자사) Follow on 검토 or 확정'},
    {text: '신규주주 일부 확정'},
    {text: '자금소요 및 조달계획이 명확'},
    {text: '앵커투자사 선정/진행 中'},
    {text: '내부투심'},
    {text: '외부투심'},
    {text: '회계/법률실사 완료'},
    {text: '납입일정 조정'},
];
  
export const measureList = [ //방안 - 내부투심
    {text: '일정조율 OR 승인'},
    {text: '승인'}
];
  
export const measure2List = [ //방안 - 외부투심
    {text: '일정확정 or 진행 中'},
    {text: '승인'}
];


//@필터 -첫번째줄
//부서
export const departmentList = [
    {text: '지원부'},
    {text: '내부심사부'},
    {text: '외부심사부'},
    {text: '관리부'},
  ];
//담당역
export const chargeOfList = [
    {text: 'C'},
    {text: 'P'},
    {text: 'Y'},
    {text: 'K'},
  ];
//담당역(C에 따른) 작성일
export const writeDateList = [
    {text: '소싱일'},
    {text: '작성일'},
    {text: '재검토일'},
    {text: '도래일'},
    {text: '리서치일'},
  ]; 
//검토차수
export const reviewNumList = [
    {text: '1차'},
    {text: '2차'},
    {text: '3차'},
  ];

//@기본 사항
export const bigCategoryList = [
    {text: '전체'},
  ];
export const middleCategoryList = [
    {text: '전체'},
  ];
export const smallCategoryList = [
    {text: '전체'},
  ];
//@신규투자
//현재상황
export const statusList = [
    {text: 'IR 진행중'},
    {text: '부분 유치'},
    {text: '유치 확정'},
    {text: '완료'},
  ];
  
//@유통
//주권형태
export const cirTypeList = [
    {text: '통일주권'},
    {text: '명의개서'},
  ];
  
//@제안
//제안내용
export const contentList = [
    {text: '구주인수'},
    {text: '컨설팅'},
    {text: '신주조건부'},
    {text: 'M&A'},
  ]; 
  //제안자
export const dealerList = [
    {text: '피투자사'},
    {text: '담당자'},
  ]; 
  //최종판단
export const decisionList = [
    {text: '전체'},
    {text: '관리'},
    {text: '재검토일 지정'},
    {text: '미관리'},
    {text: '신규'},
  ];

  //'최종판단-관리'에 따른 2depth값
export const decisionDetailList = [
    {text: '딜 진행중'},
    {text: '자료 검토중'},
    {text: 'Term sheet 확정'},
    {text: '재미팅'},
    {text: '신규투자 완료 시점'},
]; 
//유통
export const circulationList = [
    {text: '시장가'},
    {text: '추정가'}
]  
//재무  미래 > 달성 근거
export const futureReasonList = [
    {text: ''}
]
//재무 현금흐름 > CB/BW 
export const CBBWList = [
    {text: 'CB발행'},
    {text: 'BW발행'},
    {text: 'CB + BW 합계'},
    {text: '신보'},
    {text: '기보'},
]
//DEAL 회수 시기
export const recoveryList = [
    {text: '초기기업'},
    {text: '매각처확보'},
    {text: 'IPO시 장내매각'},
    {text: 'Next 신주유치 시점'},
    {text: '일정 시가총액 달성 후'},
    {text: '전략적 투자자'},
]
export const recoveryList1_2 = [
    {text: '현재는 가늠이 어려움'}
]
export const recoveryList2_2 = [
    {text: '매각처'},
    {text: 'Value', unit: '억'},
    {text: 'Volume', unit: '억'},
];
export const recoveryList3_2 = [
    {text: 'IPO 시점'},
    {text: '예상시총', unit: '억'},
    {text: '예상수익율', unit: '%'},
];

export const recoveryList4_2 = [
    {text: '예상유치시점'}
];
export const recoveryList5_2 = [
    {text: 'secondary market'}
];
export const recoveryList6_2 = [
    {text: 'M&A'}
];

//사업현황 성장전략
export const growthStrategyList = [
    {name: 'growth_plan_rnd', text: '연구개발전략'},
    {name: 'growth_plan_entering_market', text: '시장진입전략'},
    {name: 'growth_plan_scale_up', text: 'Scale up전략'},
    {name: 'growth_plan_long_plan', text: '중장기전략'},
    {name: 'growth_plan_detail', text: '세부전략'},
    {name: 'growth_plan_development', text: '연구(기술)개발 현황'},
];
//사업현황 기존실적
export const existPerformanceList = [
    {name: 'existing_performance_sales', text: '매출'},
    {name: 'existing_performance_performance', text: '성과'},
    {name: 'existing_performance_now', text: '현황'},
]

//@투자
//투자사 분류 리스트
//(초기 투자자)
export const initialInvestorList = [
    {eng: 'AN', kor: '엔젤'},
    {eng: 'ANC', kor: '엔젤클럽'},
    {eng: 'AC', kor: '액셀러레이터'},
    {eng: 'GA', kor: '해외액셀러레이터'},
    {eng: 'FO', kor: '재단'},
    {eng: 'CR', kor: '크라우드펀딩'},
    {eng: 'KV', kor: '한국벤처투자'},
    {eng: 'PEA', kor: '개인투자조합'},
    {eng: 'TIPS', kor: 'TIPS 운용사'},
]

//(공공 기관)
export const publicInstitutionList = [
    {eng: 'KC', kor: '신용보증기금'},
    {eng: 'KT', kor: '기술보증기금'},
    {eng: 'KM', kor: '중소기업진흥공단'},
    {eng: 'WO', kor: '각종 원'},
    {eng: 'CE', kor: '각종 센터'},
]

//(투자 기관)
export const investmentInstitutionList = [
    {eng: 'VC', kor: '창투사'},
    {eng: 'NT', kor: '신기술금융사'},
    {eng: 'SC', kor: '증권사'},
    // {eng: 'SC', kor: '금융사'},
    {eng: 'MA', kor: '자산운용사'},
    {eng: 'IA', kor: '투자자문사'},
    {eng: 'BA', kor: '은행'},
    {eng: 'CA', kor: '캐피탈'},
    {eng: 'KOQ', kor: '코스닥'},
    {eng: 'KOP', kor: '코스피'},
    {eng: 'KON', kor: '코넥스'},
    {eng: 'GC', kor: '해외 법인'},
    {eng: 'GV', kor: '해외투자기관'},
    {eng: 'PE', kor: '사모투자'},
    {eng: 'JJ', kor: '지주사'},
]

//(기타 투자자)
export const otherInvestorsList = [
    {eng: 'CO', kor: '벤처투자법인'},
    {eng: 'MNC', kor: '대기업'},
    {eng: 'MSC', kor: '중견기업'},
]

//파일 유형 리스트
export const fileTypeList = [
    {text: 'IR자료'},
    {text: '등기부등본'},
    {text: '폐쇄등기'},
    {text: '재무제표'},
    {text: '주주명부'},
    {text: '기타자료'},
    {text: '투심보고서'},
    {text: '실사보고서'},
    // {text: '주요경쟁사'},
    {text: '이미지'},
    {text: '로고'},
    {text: 'nda'},
]

export const marketList = [ //시장 현황
    {name: 'summary',text: '시장의 개요'},
    {name: 'growth',text: '시장 성장 정도'},
    {name: 'importance',text: '시장의 중요성'},
    {name: 'competition',text: '시장 경쟁 정도'},
    {name: 'trend',text: '시장의 동향'},
    {name: 'current_issue',text: '시장의 트렌드'},
]
export const marketDomesticList = [ //국내 시장
    {name: 'domestic_size', text: '규모'},
    {name: 'domestic_core_consumer', text: '주 소비자층'},
    {name: 'domestic_core_target', text: '주 타겟시장'},
    {name: 'domestic_apply_type', text: '적용 분야'},
] 
export const marketOverseaList = [ //해외 시장
    {name: 'foreign_size', text: '규모'},
    {name: 'foreign_core_consumer', text: '주 소비자층'},
    {name: 'foreign_core_target', text: '주 타겟시장'},
    {name: 'foreign_apply_type', text: '적용 분야'},
] 
export const marketRestList = [ //국내외 시장
    {name: 'domestic_foreign_size', text: '규모'},
    {name: 'domestic_foreign_core_consumer', text: '주 소비자층'},
    {name: 'domestic_foreign_core_target', text: '주 타겟시장'},
    {name: 'domestic_foreign_apply_type', text: '적용 분야'},
] 
export const marketProblemList = [ //시장 문제 인식
    {name: 'problem',text: '문제점'},
    {name: 'limit',text: '한계'},
    {name: 'motivation',text: '개발 동기'},
    {name: 'vision',text: '비전'},
    {name: 'needs',text: '필요성'},
    {name: 'requirement',text: '시장/고객사 요구사항'},
    {name: 'solution',text: '해결방안'},
]
export const usePurposeList = [ //자금 사용처
    {name: 'operating_funds', text: '운영 자금'},
    {name: 'rnd_funds', text: 'R&D'},
    {name: 'recruiting_funds', text: 'Recruting'},
    {name: 'marketing_funds', text: '마케팅'},
    {name: 'facility_funds', text: '설비 투자'},
    {name: 'land_funds', text: '부지 매입 자금'},
    {name: 'mass_production_funds', text: '양산 자금'},
    {name: 'advanced_funds', text: '고도화 자금'},
]
export const IPList = [
    {name: 'patent_application', text: '특허 출원'},
    {name: 'patent_registration', text: '특허 등록'},
    {name: 'pct_application', text: 'PCT 출원'},
    {name: 'pct_registration', text: 'PCT 등록'},
    {name: 'technology_transfer', text: '기술 이전'},
    {name: 'design_registration', text: '디자인 등록'},
    {name: 'brand_registration', text: '상표 등록'},
    {name: 'prepare_application', text: '출원 준비중'},

]
export const ReviewStageList = [
    {name: 'ir', text: 'First IR'},
    {name: 'next_step', text: 'Proceeding to the next step'},
    {name: 'casual_meeting', text: 'Casual Meeting'},
    {name: 'pre_investment', text: '예비투심'},
    {name: 'main_investment', text: '본투심'},
    {name: 'investing', text: '투심중'},
    {name: 'deal_closing', text: '딜클로징'},
]

export const marketDomesticList2 = [
    {text: '규모', value: 'size'},
    {text: '개요', value: 'summary'},
    {text: '특징', value: 'characteristic'},
    {text: '현황', value: 'status'},
    {text: '비전/성장성', value: 'vision'},
    {text: '동향/트렌드', value: 'trend'},
    {text: '경쟁흐름', value: 'competitive'},
    {text: '진입장벽', value: 'barrier'},
    {text: '전망', value: 'vision'},
    // {text: '현황', value: ''},
]
export const marketBusinessList2 = [
    {text: '문제점/한계', value: 'problem'},
    {text: '요구사항', value: 'requirement'},
    {text: '필요성', value: 'necessity'},
    {text: '목적', value: 'purpose'},
]
export const marketCompanyList2 = [
    {text: '해결방안', value: 'solution'}, //default
    {text: '대응방안', value: 'response'},
    {text: '사업화방안', value: 'commercial'},
    {text: '개발포지셔닝', value: 'development_positioning'},
]
export const performanceList = [
    {text: '주요성과', value: 'major_performance'},
    {text: '주요이력', value: 'major_history'},
    {text: '매출현황', value: 'sales_status'},
    {text: '매출전략/예상매출', value: 'strategy_sales'},
]
export const productGrowthStrategyList =[
    {text: '연구개발전략'},
    {text: '시장진입전략'},
    {text: 'Scale up전략'},
    {text: '중장기전략'},
    {text: '세부전략'},
    {text: '확장전략'},
]
export const productMarketingStrategyList = [
    {text: '마케팅계획'},
    {text: '사업활성화계획'},
    {text: '홍보계획'},
    {text: '영업계획'},
    {text: '마케팅전략'},
    {text: '유통전략'},
    {text: '판매전략'},
    {text: '마케팅현황'},
    {text: '영업현황'},
]
export const businessStatusList = [
    {text: '사업화현황'},
    {text: '분야별사업현황'},
    {text: '주요제품현황'},
    {text: '주요실적'},
    {text: '개발현황'},
    {text: '인증현황'},
    {text: '수상현황'},
    {text: '해외진출현황'}
    

]
export const marketingStatusList = [
    {text: '마케팅현황'},
    {text: '홍보현황'},
    {text: '영업현황'},
    {text: '유통현황'},
    {text: '판매현황'},
]

export const GrowthStrategyList = [
    {text: '연구개발전략'},
    {text: '시장진입전략'},
    {text: 'Scale up전략'},
    {text: '중장기전략'},
    {text: '세부전략'},
    {text: '확장전략'},
]
export const MarketingStrategyList = [
    {text: '마케팅전략'},
    {text: '홍보전략'},
    {text: '영업전략'},
    {text: '유통전략'},
    {text: '판매전략'},
]
export const WorkTypeList = [
    {text: '상근'},
    { text:'비상근'}
]

export const BorrowingAccountList = [
    {text: '단기'},
    {text: '장기'}
]
export const ShareHolderTypeList = [
    {text: '보통주'},
    {text: '우선주'}
]
export const ShareHolderDivisionList = [
    {text: '대표이사'},
    {text: '임원'},
    {text: '기관투자'},
    {text: '특수관계인'},
    {text: 'AC'},
    {text: 'VC'},
    {text: 'SI투자'},
    {text: 'FI투자'},
    {text: '지인'},
    {text: '자문역'},
    {text: '연구원'},
]
export const CapitalIncreaseList = [
    {text: '설립자본금'},
    {text: '유상증자'},
    {text: '자본감소'},
    {text: '무상증자'},
    {text: '액면분할'},
    {text: '주식매수선택권행사'},
    {text: '기존주주배정'},
    {text: '제3자배정'},
    {text: '전환권행사'},
    {text: '현물출자'},
]
export const AssignmentTypeList =[
    {text: '완료'},
    {text: '진행중'}
]
export const ContractTypeList = [
    {text: '계약'},
    {text: '체결'},
    {text: '협약'},
    {text: '제휴'},
    {text: 'MOU'},
    {text: 'LOI'},
    {text: 'LOC'},
]
export const ContractContentList = [
    {text: '공통연구개발'},
    {text: '전략적 파트너십'},
    {text: '매출계약'},
    
]
export const PlaceList = [
    {text: '해당회사'},
    {text: '비대면'},
    {text: '당사'},
]

export const CouncilTypeList = [
    {text: '사내이사'},
    {text: '기타비상무이사'},
    {text: '사외이사'},
    {text: '감사'},
]
export const IPTypeList = [
    {text: '특허'},
    {text: '디자인'},
    {text: '상표'}
]
export const IPStatusList = [
    {text: '거절'},
    {text: '등록'},
    {text: '출원'},
    {text: '취하'},
    {text: '포기'},
]

export const BriefsList = [
    {label: '회계감사', value: '회계감사'},
    {label: '법률감사', value: '법률감사'},
    {label: '관리보수', value: '관리보수'},
    {label: '성과보수', value: '성과보수'},
]
export const InvestmentMethodList = [
    {label:'신주',value:'new_stock'},
    {label:'구주인수',value:'old_stock'},
    {label:'신주+구주인수',value:'new_and_old_stock'},
]