import React, { useState, useEffect } from 'react';

function DetailBox({data, title, isSmall = false, children, stage}) {

  return (
    <div className={(isSmall)? "market_small_box" : "market_big_box"}>
      <div className="market_title">
        <span style={{display: 'flex', alignItems: 'center'}}>
          {(title) && <span style={{fontSize:10, marginRight:5}} >●</span>}
          <span style={{color: '#444444'}}>{title}</span>
          {(stage != undefined) && '(' + stage + ')'}
        </span>
      </div>
      {(children != undefined)
      &&
      children
      }
  </div>
  );
}

export default DetailBox;