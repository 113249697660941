import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import NvTextInput from 'components/NvTextInput';
import NvTextArea from 'components/NvTextArea';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AutoSearchInput from 'components/AutoSearchInput'
import CheckAuth from 'hooks/CheckAuth';
import axios from 'axios';
import url from 'utils/backend'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from 'components/navbar/NavBar';
import MenuTitle from 'components/discussion/MenuTitle'
import { SubTitle } from 'components/discussion/styled';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
const Root = styled.div`
    background-color: #f9f9f9;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ContainerWrap = styled.div`
max-width: 1140px;
margin: 0 auto;
width: 100%;
`;
const NavTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: white;
`;
const Title = styled.div`
    color: rgb(51,51,51);
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 18px;
    margin-top: 40px;
`;
const MText = styled.div `
    color: rgb(51,51,51);
    font-size: ${(props) => props.fontSize};        
`;
const Card = styled.div `
    border-radius: 5px;
    border: 1px solid black;
    padding: 20px 30px 30px 30px;
    background-color:#fff

`;
const CardLabel = styled.div `
    font-weight: 900;
    font-size: ${(props) => props.fontSize};        
    color: #FFFFFF;
`;
const WriteBox = styled.div `
    background-color: #FFFFFF;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    textarea{
        width: 100%;
        height: 98% !important;
    }
`;
const Bottom = styled.div`
    margin-top: 30px;
    width: 100%;
    text-align: right;
    button{
        background-color: white;
        color: #444;
        width: 100%;
        padding: 6px 0px;
        border: 1px solid black;
        color: black;
        font-weight: 800;
        box-shadow: none;
    }


    }
    button:hover{
        background-color: gray;
    }
`;
const CheckBox = styled.div `
    .css-i4bv87-MuiSvgIcon-root{
        fill: #444 !important;
    }
`;
const DiscussionEditReview = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [board, setBoard] = useState('');
    const [name, setName] = useState('');
    const [investor, setInvestor] = useState('');
    const [content, setContent] = useState('');
    const [check, setCheck] = useState(false);
    const checkEmpty = () => {
        if((investor.investor_id || name) && content){
            return false;
        }
        else{
            return true;
        }
    }
    const getBoardInfo = async() => {
        try{
            const res = await axios.get(url + '/forum/review/' + id);
            if(res.data.review.deleted_at || !res.data.review || res.data.review.type != 'review'){
                navigate('/discussion/review');
            }
            else{
                setBoard(res.data.review)
            }

        }
        catch(e){
          console.log(e)
        }
      }
    const updateBoard = async() => {
        try{
            if(!checkEmpty()){
                Swal.fire({
                    title: '글을 수정하시겠습니까?',
                    showCancelButton: true,
                    confirmButtonText: '확인',
                    cancelButtonText: '닫기',
                    customClass:{
                        popup: 'swal-confirm-container',
                        title: 'swal-confirm-title',
                        confirmButton: 'swal-confirm-btn',
                        cancelButton: 'swal-confirm-cancel'
                    }
                  }).then(async(result) => {
                    if(result.isConfirmed) {
                        let tmp = {content: content, next_step: check};
                        const res = await axios.put(url + '/forum/review/' + id, tmp);
                        if(res.status == 200){
                            navigate('/discussion/view/review/' + id)
                        }
                    } else if (result.isDenied) {
                    }
                })

            }
            else{
                Swal.fire({
                    title: '제목과 내용을 모두 입력해주세요.',
                    confirmButtonText: '확인',
                    showClass: {
                      popup: 'animate__animated animate__fadeInUp'
                    },
                    hideClass: {
                      popup: 'animate__animated animate__fadeOutDown'
                    },
                    customClass:{
                        icon: 'swal-confirm-icon',
                        popup: 'swal-confirm-container',
                        title: 'swal-confirm-title animate__animated animate__bounceIn',
                        confirmButton: 'swal-confirm-btn',
                        cancelButton: 'swal-confirm-cancel'
                    }
                  })
            }
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        getBoardInfo();
        window.scrollTo(0, 0);
      },[])
    useEffect(() => {
        if(board){
            console.log(board)
            if(board.investor_id){
                setInvestor({investor_id: board.investor_id});
                setName(board.investor_name)
            }
            else if(board.investor_name){
                setName(board.investor_name);
            }
            if(board.content){
                setContent(board.content)
            }
            if(board.next_step){
                setCheck(board.next_step)
            }
        }
    },[board])
    return(
        <div className='main-body-wrap'>
            <NavBar/>
            <Root>
            
            <ContainerWrap className='blind-containerwrap'>

                
<Title><span onClick={()=>navigate('/discussion/review')} style={{cursor:'pointer'}}>블라인드토론</span> &#62; <span style={{opacity:.7}}>후기쓰기</span></Title>
<SubTitle>
스타트업 창업자들의 투자 리뷰
</SubTitle>
<MText fontSize='16px'>
투자자에 대한 미팅 후기를 남겨주세요
<br/>
<br/>
모든 글은 등록할 때 산업분류명으로 게시되며 최신순으로 보입니다.
</MText>
<Card style={{marginTop: 30}}>
    <div className='rc-sb'>
        <WriteBox width={'250px'} height={'50px'}>
            <div style={{width:250,height:50,fontSize:18,display:'flex',alignItems:'flex-end'}} >
                {name}
            </div>
        </WriteBox>
    </div>
    <hr style={{margin: '35px 0'}}/>
    <div className='rc-sb' style={{alignItems: 'center'}}>
        <CheckBox>                    
            <FormControlLabel  control={<Checkbox style={{color:'#444'}} checked={check} onChange={() => setCheck(!check)}/>} style={{color:'#444'}} label="Proceeding to the next stop" />
            {/* <CardLabel fontSize='15px'>Proceeding to the next stop</CardLabel> */}
        </CheckBox> 
    </div>
    {/* <WriteBox width={'100%'} style={{marginTop: 30}}> */}
        <NvTextArea minRows={12} defaultValue={content} style={{marginTop: 0}} onChange={(v) => setContent(v)} />
    {/* </WriteBox> */}
</Card>
<Bottom>
    <Button variant="contained"  onClick={() => { updateBoard() }}>
        등 록
    </Button>
</Bottom>
</ContainerWrap>
            </Root>
        </div>

    )
}
export default DiscussionEditReview;
