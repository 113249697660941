import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'
import { productList, serviceList, medicineList, medicalList, marketDomesticList2, marketBusinessList2, marketCompanyList2, attractabilityList, growthList, investmentTypeList, growthStrategyList, differentiationList, technologyList, competitivenessList, performanceList, marketingList, roleList, educationList, salesAnalysisList, possibilityList,} from 'utils/DropDownList';

const initialState = {
    industry: [],
    technology: []
}

export const getCategoryList = createAsyncThunk('getCategoryList',async() => {
    try{
        let industry = await axios.get(url + '/category/business')
        industry = industry.data.category
        let technology = await axios.get(url + '/category/technology')
        technology = technology.data.category
        return {industry, technology}
    }
    catch(e){
        console.log(e)
    }
})

export const categorySlice = createSlice({
  name: 'category',
  initialState: initialState,
  reducers: {
    reset: (state) => {
      return initialState
    }
  },
  extraReducers: {
    [getCategoryList.pending.type]: (state, action) => { // 호출 전
    },
    [getCategoryList.fulfilled.type]: (state, action) => { // 성공
        return {
            industry: action.payload.industry,
            technology: action.payload.technology,
        }
    },
    [getCategoryList.rejected.type]: (state, action) => {  // 실패
    },
}
})

export const { reset } = categorySlice.actions

export default categorySlice.reducer