import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import generateId from 'utils/GenerateId';
// import 'styles/common.scss';


function NvMultipleAutoComplete({ defaultValue, options, onChange, onEnter }) {
    // Data
    const [value, _value] = useState([]);

    // Functions
    const _getOptionLabel = (option) => {
        // if (typeof option === 'string') {
        //     return option;
        // }
        // if (option.inputValue) {
        //     return option.inputValue;
        // }
        // if(option.label) return option.label;
        return option.text;
    }


    const _onKeyDown = (e) => {
        // console.log(e)
        if (onEnter && e.keyCode == 13 && e.target.value != '') onEnter(e.target.value)
        // if(onChange) onChange(e.target.value)
        // if(onChange) console.log(e.target.value + e.code)
    }

    const _onKeyUp = (e) => {
        // console.log(e)
        // if(onEnter && e.keyCode == 13 && e.target.value != '') onEnter(e.target.value)
        if (onChange) onChange(e.target.value)
    }

    const _onChange = (e, val) => {
        if(!val) {
            val = ''
        } else if(val.text) {
            val = val.text;
        } else if (val.value) {
            val = val.value;
        }
        _value([...value, val])
        if(onChange) {
            onChange(val)
        }
    }

    // Rendering
    useEffect(() => {
        defaultValue && _value(defaultValue)
    }, [defaultValue])

    // Template
    return (
        <Autocomplete
            multiple
            // noOptionsText=''
            id="235rae"
            autoComplete
            disableClearable
            onKeyDown={_onKeyDown}
            onKeyUp={_onKeyUp}
            onChange={_onChange}
            // onInputChange={(event, value) => _onChange(event, value)}
            classes={{ option: 'nv-autocomplate-label' }}
            options={options}
            getOptionLabel={_getOptionLabel}
            size='small'
            className='nv-autocomplate'
            renderInput={(params) => {
                return(
                <TextField {...params} variant="outlined" size="small" />)
            }
            }
        />
    );

}
export default NvMultipleAutoComplete;