import React, {useState} from 'react'
const Banner = ({text, slider, setSlider, differ,isCurrent, isText,isAccount,navigate, topicType}) =>{
    const [isHover, setIsHover] = useState(true)
    return(
        <div className={'main-banner-wrap'+(differ?'-topic':'')} style={{borderBottom:(text=='BLIND' && 'none')}}>
                <div className={'main-banner'+(text=='BLIND'?' blind-banner' :'')} onMouseEnter={()=>setIsHover(false)} onMouseLeave={()=>setIsHover(true)}>
                    {isText?
                    <h1 className='mn h1front' style={{transform:(isHover?'rotateY(0)':'rotateY(180deg)')}}>{text?text:'INVEST'}</h1>
                        :
                    <h1 className='mn'>{text?text:'INVEST'}</h1>
                }
                    {isAccount &&
                    <p>
                        <button className='btn' >
                            <span className='span'>
                                <span>Sign out of your Pitchdeck account</span>
                            </span>
                        </button>
                    </p>
                    }
                    {isText && 
                        <p className="h1front pback" style={{transform:(isHover?'rotateY(180deg)':'rotateY(0)')}}>
                            {isText}
                        </p>
                    }
                </div>
                {isCurrent && <div className={'main-banner-category'+(text=='BLIND'?' mbc-blind':'')}>
                    <nav className={'main-banner-nav'+(text=='BLIND' ?' blind-border-top':'')}>
                        <div style={{position:"relative", overflow:'visible'}}>
                            <ul>
                                {isCurrent.map((d,i)=>{
                                    return(
                                        <li className='mn-extended' aria-current={topicType==d.value} id={i+1}>
                                            <a onClick={()=>navigate((text=='BLIND'?'/discussion/':'?type=')+(d.value))}>{d.label}</a>
                                        </li>
                                    )
                                })
}
                            </ul>
                            {text=='BLIND' && 
                            <p style={{fontSize:15, position:'absolute', top:-15, margin:0,right:10}}>
                                {(topicType=='review'||topicType=='post') ?
                                <span className='blind-span' style={{marginTop:19}}>
                                    <a href={(topicType=='review'?'/discussion/write/review':'/discussion/write/post')}>
                                        <span className='span-btn ver2' style={{fontSize:15, fontWeight:500}}>글 쓰기</span>
                                    </a>
                                </span>
                                :
                                <span className='blind-span' style={{marginTop:19, cursor:'pointer'}} onClick={()=>setSlider(!slider)}>
                                    <div className='my-blind-slider-sm' >
                                        <span className="blind-span-sm" style={{color:(slider?'#f9f9f9':'#168dd9')}}>My Review</span>
                                        <span className="blind-span-sm" style={{color:(slider?'#168dd9':'#f9f9f9')}}>My Rounge</span>
                                        <div style={{position:'absolute', top:0, bottom:0, width:'50%', left:(slider?'0%':'50%'), transition:'all .3s ease-in-out', backgroundColor:'#168dd9'}}></div>
                                    </div>
                                    <div className='my-blind-slider-lg'>
                                        <span className="blind-span-lg" onClick={()=>setSlider(!slider)}>{slider?'My Review':'My Rounge'}</span>
                                    </div>
                                </span>  
                            }
                            </p>}
                    
                        </div>
                    </nav>
                </div>}
            </div>
    )
}

export default Banner