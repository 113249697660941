import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'
import PanelFilterSelect from 'components/admin/PanelFilterSelect'
import { InputBase } from '@material-ui/core';
import NvNewDatePicker from 'components/NvNewDatePicker'
import DoneIcon from '@mui/icons-material/Done';
import _ from 'lodash'
import classnames from 'classnames'
import NewsSectionList from 'utils/NewsSectionList'
const filterList = [
    {
        value: 'cnt',
        child: [
            {
                label: '목록개수',
                value: 'default',
            },
            {
                label: '20개',
                value: 20,
            },
            {
                label: '50개',
                value: 50,
            },
            {
                label: '100개',
                value: 100,
            },
            {
                label: '1000개',
                value: 1000,
            },

        ]
    },
    {
        value: 'status',
        child: [
            {
                label: '전체 상태',
                value: 'default'
            },
            {
                label: '승인',
                value: 'approve'
            },
            {
                label: '미승인',
                value: 'not_approve'
            }
        ]
    },
    {
        value: 'area',
        child: [
            {
                label: '전체영역',
                value: 'default'
            },
            {
                label: '제목+부제목',
                value: 'title_and_subtitle'
            },
            {
                label: '본문',
                value: 'content'
            },
            {
                label: '기자이름',
                value: 'name'
            },
            {
                label: '기자ID',
                value: 'id'
            }
        ]
    },
    {
        value: 'level',
        child:[
            {
                label: '등급전체',
                value: 'default'
            },
            {
                label: '일반기사',
                value: 'basic'
            },
            {
                label: '중요기사',
                value: 'important',
            },
            {
                label: '헤드라인',
                value:'headline'
            }
        ],
    },
    {
        value: 'type',
        child: [
            {
                label: '형태전체',
                value: 'default'
            },
            {
                label: '일반',
                value: ''
            },
            {
                label: '카드뉴스',
                value: ''
            },
            {
                label: '갤러리',
                value: ''
            },
            {
                label: '동영상',
                value: ''
            }
        ]
    },
    {
        value: 'period',
        child: [
            {
                label: '전체기간',
                value: 'default'
            },
            {
                label: '오늘',
                value: 0
            },
            {
                label: '어제',
                value: -1
            },
            {
                label: '2일전',
                value: -2
            },
            {
                label: '3일전',
                value: -3
            },
            {
                label: '4일전',
                value: -4
            },
            {
                label: '5일전',
                value: -5
            },
            {
                label: '6일전',
                value: -6
            },
            {
                label: '7일전',
                value: -7
            },
            {
                label: '14일전',
                value: -14
            },
            {
                label: '30일전',
                value: -30
            }
        ]
    }
]
const sectionList = [
    {
        label: '전체',
        value: 'default'
    }
]
const trainList = [
    {
        label:'검색영역',
        value: 'area',
        child: [
            {
                label: '전체',
                value: 'default'
            },
            {
                label: '제목+부제목',
                value: 'title_and_subtitle'
            },
            {
                label: '본문',
                value: 'content'
            }
        ]
    },
    {
       label: '기사등급',
       value: 'level',
       child: [
        {
            label: '전체',
            value: 'default'
        },
        {
            label: '일반기사',
            value: 'basic'
        },
        {
            label :'중요기사',
            value: 'important'
        },
        {
            label: '헤드라인',
            value: 'headline'
        }
       ]
    },
    {
        label: '기사형태',
        value: 'type',
        child: [
            {
                label: '전체',
                value: 'default'
            },
            {
                label: '일반',
                value: 'basic'
            },
            {
                label :'카드뉴스',
                value: 'card'
            },
            {
                label: '갤러리',
                value: 'gallery'
            },
            {
                label: '동영상',
                value: 'media'
            }
        ]
    },
]
const SearchDetail = ({section, setSection, reporterName, setReporterName, period, setPeriod, word, setWord, sortType, setSortType, train, setTrain}) => {
    const navigate = useNavigate()
    // const [section, setSection] = useState('default');
    // const [reporterName, setReporterName] = useState('')
    // const [period, setPeriod] = useState({
    //     start_date: null,
    //     end_date: null
    // });
    // const [word, setWord] = useState({
    //     word1: null,
    //     word2: null,
    //     word_conjuction: 'or',
    // })
    // const [sortType, setSortType] = useState('approve');
    // const [train, setTrain] = useState({
    //     area: 'default',
    //     level: 'default',
    //     type: 'default',
    // })
    const changeWord = (v, level) => {
        setWord(prev => {
            let tmp = _.cloneDeep(prev);
            tmp[level] = v;
            return tmp
        })
    }
    const changePeriod = (v, level) => {
        setPeriod(prev => {
            let tmp = _.cloneDeep(prev);
            tmp[level] = v;
            return tmp;
        })
    }
    const handleDateBtn = (v) => {
        const start_date = new Date();
        const end_date = new Date();
        start_date.setDate(start_date.getDate() + Number(v));
        setPeriod(prev => {
            let tmp = _.cloneDeep(prev);
            tmp.start_date = start_date;
            tmp.end_date = end_date;
            return tmp;
        })
    }
    return(
        <section className='panel-container'>
            <header className='panel-header'>
                <div className='panel-title'>
                    <strong className='rc center'>
                        검색
                    </strong>
                </div>
            </header>
            <article className='panel-block'>
                {/* <div className='search-tab'>
                    <ul className='tabs'>
                        <li className='tabs-title active'>
                            <Link to=''>
                            기사검색
                            </Link>

                        </li>
                    </ul>
                </div> */}
                <div className='tabs-content'>
                    <div className='tabs-panel'>
                        <div className='tabs-row'>
                            <div class="tabs-label">
                                검색섹션
                            </div>
                            <div className='tabs-item'>
                                <div className='tabs-radio-group'>
                                    <label className={classnames({'checked': section == 'default'})} onClick={() => setSection('default')}>전체</label>
                                    {NewsSectionList.map(data => {
                                        return(
                                            <label className={classnames({'checked': section == data.sec1})} onClick={() => setSection(data.sec1)}>{data.sec1}</label>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                        {trainList.map(data => {
                            return(
                                <div className='tabs-row'>
                                    <div class="tabs-label">
                                        {data.label}
                                    </div>
                                    <div className='tabs-item'>
                                        <div className='tabs-radio-group'>
                                            {data.child.map(item => {
                                                return(
                                                    <label className={classnames('sc-train', {'checked': item.value == train[data.value]})}
                                                    onClick={() => {
                                                        setTrain(prev => {
                                                            let tmp = _.cloneDeep(prev);
                                                            tmp[data.value] = item.value;
                                                            return tmp;
                                                        })
                                                    }}
                                                    >    {item.label}
                                                    </label>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                        <div className='tabs-row'>
                            <div class="tabs-label">
                                기자이름
                            </div>
                            <div className='tabs-item'>
                                <InputBase
                                    defaultValue={reporterName}
                                    className='tabs-input'
                                    placeholder='기자이름'
                                    onChange={(e) => setReporterName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='tabs-submit'>
                            <button 
                                className='btn approve-btn'
                                onClick={() => {
                                    // getNewsList(section, train, reporterName, word, period, sortType)
                                    let uri = `/admin/newsSearch?page=1&stage=list&section=${section}&reporterName=${reporterName}&sortType=${sortType}&`;
                                    uri += Object.keys(train).map((key) => {
                                        return key + '=' + train[key];
                                    }).join('&');
                                    uri += '&' + Object.keys(word).map((key) => {
                                        return key + '=' + (word[key] ? word[key] : null);
                                    }).join('&');
                                    uri += '&' + Object.keys(period).map((key) => {
                                        return key + '=' + (period[key] ? period[key]?.toISOString() : null);
                                    }).join('&');
                                    navigate(uri)
                                }}
                            >
                                <DoneIcon/>
                                확인
                            </button>
                        </div>
                        <hr className='tabs-line'/>
                        <div style={{margin: '0.8rem 0'}}>
                            {filterList[filterList.length - 1].child.slice(1,  9).map((data, i) => {
                                return(
                                    <div className='filter-date-btn' onClick={() => handleDateBtn(data.value)}>
                                        {data.label}
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className='tabs-row'>
                            <div class="tabs-label">
                                검색단어
                            </div>
                            <div className='tabs-item keyword-group'>
                                <InputBase
                                    defaultValue={word.word1}
                                    className='tabs-input'
                                    placeholder='검색단어1'
                                    onChange={(e) => changeWord(e.target.value, 'word1')}
                                />
                                <div >
                                    <PanelFilterSelect
                                        defaultValue={word.word_conjuction}
                                        onChange={(v) => changeWord(v, 'word_conjuction')}
                                        list={[
                                            {
                                                label: '또는',
                                                value: 'or'
                                            },
                                            {
                                                label: '그리고',
                                                value: 'and'
                                            }
                                        ]}
                                    />
                                </div>
                                <InputBase
                                    defaultValue={word.word2}
                                    className='tabs-input'
                                    placeholder='검색단어2'
                                    onChange={(e) => changeWord(e.target.value, 'word2')}
                                />
                            </div>
                        </div>
                        <div className='tabs-row'>
                            <div className='tabs-label'>
                                검색기간
                            </div>
                            <div className='tabs-item '>
                                <div className='tabs-date'>
                                    <NvNewDatePicker
                                        defaultValue={period.start_date}
                                        onChange={(v) => changePeriod(v, 'start_date')}
                                    />
                                    <span>
                                        부터
                                    </span>
                                </div>
                                <div className='tabs-date'>
                                    <NvNewDatePicker
                                        defaultValue={period.end_date}
                                        onChange={(v) => changePeriod(v, 'end_date')}
                                    />
                                    <span>
                                        까지
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className='tabs-row'>
                            <div className='tabs-label'>
                                정렬방식
                            </div>
                            <div className='tabs-item tabs-sort'>
                                <PanelFilterSelect
                                    defaultValue={sortType}
                                    onChange={(v) => setSortType(v)}
                                    list={[
                                        {
                                            label: '승인일',
                                            value: 'approve'
                                        },
                                        {
                                            label: '조회수',
                                            value: 'view'
                                        }
                                    ]}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </article>
        </section>
    )
}

export default SearchDetail