import React from 'react';
import {Link} from 'react-router-dom'
const middleList = [
    {
        label: 'INVEST',
        value: 'invest',
        list: [
            {
                label: 'Start-Up',
                path: '/'
            }
        ]
    },
    {
        label: 'MORE',
        value: 'more',
        list: [
            {
                label: 'Finder',
                path: '/'
            },
            {
                label: 'Blind Discussion',
                path: '/'
            }
        ]
    }
    
]
const Footer = () => {
    return(
        <footer className='main-footer'>
            <div className='responsive-margin'>
                <div className='main-footer-middle'>
                    <div>
                        <p className='pitchdeck-name'>
                            PITCHDECK
                        </p>
                        <div className='become-member-btn'>
                            Become a member
                        </div>                    
                    </div>
                    <div className='rc'>
                        {middleList.map(parent => {
                            return(
                                <div className='main-footer-middle-list'>
                                    <p>{parent.label}</p>
                                    <div>
                                        <ul>
                                        {parent.list.map(data => {
                                            return(
                                                <li>
                                                    <Link to={data.path}>{data.label}</Link>
                                                </li>
                                            )
                                        })
                                        }
                                        </ul>
                                    </div>
        
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
                <ul className='bottom-ul'>
                    <li>
                        <Link to="">이용약관</Link>
                    </li>
                    <li>
                        <Link to="">개인정보처리방침</Link>
                    </li>
                </ul>
                <div className='main-footer-copyright'>
                    C<Link to='/admin'>o</Link>pyright © 2022 JEJESOFT
                </div>
            </div>
        </footer>
    )
}

export default Footer;