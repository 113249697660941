const statusList = [
    {
        label: '작성중',
        value: 'write'
    }, 
    {
        label: '승인신청',
        value: 'apply'
    }, 
    {
        label: '반려',
        value: 'reject'
    }, 
    {
        label: '승인',
        value: 'approve'
    }
]
const statusAdminList = [
    {
        label: '미승인',
        value: 'apply'
    }, 
    {
        label: '승인',
        value: 'approve'
    }, 
    {
        label: '반려',
        value: 'reject'
    }, 
]
const getValue = (label, type) => {
    if(type == 'admin'){
        return statusAdminList.find(e => e.label == label).value;
    }
    else if(statusList.some(e => e.label == label)){
        return statusList.find(e => e.label == label).value;
    }
    else return 'none';
}
const getLabel = (value, type) => {
    if(type == 'admin'){
        return statusAdminList.find(e => e.value == value).label;
    }
    else if(statusList.some(e => e.value == value)){
        return statusList.find(e => e.value == value).label;
    }
    else return 'none';
}
export {statusList, statusAdminList, getValue, getLabel}