import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import InputBox from 'components/profile/InputBox'
import ServiceModal from 'components/profile/ServiceModal'
import NewInvestmentModal from 'components/profile/NewInvestmentModal'
import InvestmentModal from 'components/profile/InvestmentModal'
import { Backdrop, RegisterModal } from 'components/modals/modals'
import 'styles/profile.scss'
import Swal from 'sweetalert2'
import 'animate.css';
import MarketModal from 'components/profile/MarketModal';
import axios from 'axios';
import url from 'utils/backend';
import IsArrayEmpty from 'utils/IsArrayEmpty';
import RemoveHtml from 'utils/RemoveHtml'
import Auth from 'utils/auth';
import getPureBusinessNumber from 'utils/getPureBusinessNumber'


const ProfileEdit = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [businessNumber, setBusinessNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isCoor, setIsCoor] = useState(false);
  const [rank, setRank] = useState('');
  const [email, setEmail] = useState('');
  const [sectors, setSectors] = useState([]);
  const [techs, setTechs] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [business, setBusiness] = useState('');
  const [team, setTeam] = useState('');
  const [gender, setGender] = useState('');
  const [serviceVisible, setServiceVisible] = useState(false);
  const [investmentVisible, setInvestmentVisible] = useState(false);
  const [newInvestmentVisible, setNewInvestmentVisible] = useState(false);
  const [marketVisible, setMarketVisible] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [productList, setProductList] = useState([
    // {
    //   name: '',
    //   stage: '',
    //   content: ''
    // }
  ])
  const [existList, setExistList] = useState([
    // {
    //   date1: '',
    //   date2: '',
    //   amount: '',
    //   round: '',
    //   investor: ''
    // }
  ]);
  const [marketList, setMarketList] = useState({
    domestic: {
      opts: [{value: false, label: '개요'}, {value: false, label: '비전/성장성'}, {value: false, label: '동향/트렌드'}, {value: false, label: '경쟁흐름'}, {value: false, label: '진입장벽'}],
      content: '',
      value: '',
      title: '국내외 시장의',
      empty: false
    },
    business: {
      opts: [{value: false, label: '문제점/한계'}, {value: false, label: '요구사항'}, {value: false, label: '필요성'}, {value: false, label: '목적'}],
      content: '',
      title: '사업/제품/서비스/개발에 대한',
      empty: false,
      value: '',
    },
    company:{
      opts: [{value: false, label: '해결방안'}, {value: false, label: '대응방안'}, {value: false, label: '사업화방안'}],
      content: '',
      title: '동사에 대한',
      empty: false,
      value: '',

    },
    similarity:{
      opts: [{value: false, label: '제품/서비스'}, {value: false, label: '아이디어'}, {value: false, label: '매출액/규모'}, {value: false, label: '기술'}, {value: false, label: '개발'}],
      content: [],
      title: '업계 유사 회사를 기입해 주세요',
      empty: false,
      value: '',

    }
  })
  const [newInvestment, setNewInvestment] = useState({
    starting_date: null,
    pre_value: '',
    volume: '',
    round: ''
  })

  const handleAdd = (label) => {
    if(label == '제품 및 서비스'){
      setServiceVisible(true)
    }
    else if(label == '투자현황'){
      setInvestmentVisible(true)
    }
    else if(label == '신규투자유치'){
      setNewInvestmentVisible(true)
    }
    else if(label == '시장'){
      setMarketVisible(true);
    }
  }

  const headerList = { // Total Width: 24
    investment:[
      {label: 'Date', value: 'date', width: 3.5},
      {label: 'Amount', value: 'amount', width: 3.5},
      {label: 'Type', value: 'type', width: 3.5},
      {label: 'Round', value: 'round', width: 3.5},
      {label: 'Investor', value: 'investor', width: 12.8, textAlign: 'left'},
    ],
    newInvestment: [
      {label: 'Starting', value: 'starting_date', width: 8},
      {label: 'Pre - value', value: 'pre_value', width: 8},
      {label: 'Volume', value: 'volume', width: 6.4},
      {label: 'Round', value: 'round', width: 6.4},
    ] 
  }
  const handleSuccessModal = () => {
    Swal.fire({
      title: '저장이 완료되었습니다.',
      confirmButtonText: '확인',
      showClass: {
        popup: 'animate__animated animate__fadeInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutDown'
      },
      customClass:{
        icon: 'swal-confirm-icon',
        popup: 'swal-confirm-container',
        title: 'swal-confirm-title ',
        confirmButton: 'swal-confirm-btn',
        cancelButton: 'swal-confirm-cancel'
      }
  })
    .then((result) => {
      if(result.isConfirmed) {
        setTimeout(() => {
          navigate('/')
        }, 500);
      }
    })
  }

  const getProfileInfo = async() => {
    try{
      const res = await axios.get(url + '/user/profile');
      const data = res.data.profile;
      if(data.name){
        setName(data.name);
      }
      if(data.role){
        setRank(data.role);
      }
      if(data.is_founder){
        setIsCoor(data.is_founder);
      }
    }
    catch(e){
      console.log(e);
    }
  }
 
  const getCompanyInfo = async() => {
    try{
      const res = await axios.get(url + '/user/company');
      const data = res.data.company;
        if(data.business_id){
            setBusinessNumber(data.business_id);
        }
      if(data.description){
        setBusiness(data.description)
      }
      if(data.team){
        setTeam(data.team)
      }
      if(data.market.length >= 1){
          data.market.map((market, i) => {
              if(market.group == '국내외 시장의'){
                marketList.domestic.opts.some((opt,i2) => {
                    if(opt.label == market.key){
                        marketList.domestic.opts[i2].value = true;
                        return true;
                    }
                })
                marketList.domestic.content = market.value;
                marketList.domestic.value = market.key;

              }
              else if(market.group == '사업/제품/서비스/개발에 대한'){
                marketList.business.opts.some((opt,i2) => {
                    if(opt.label == market.key){
                        marketList.business.opts[i2].value = true;
                        return true;
                    }
                })
                marketList.business.content = market.value;
                marketList.business.value = market.key;

              }
              else if(market.group == '동사에 대한'){
                marketList.company.opts.some((opt,i2) => {
                    if(opt.label == market.key){
                        marketList.company.opts[i2].value = true;
                        return true;
                    }
                })
                marketList.company.content = market.value;
                marketList.company.value = market.key;

              }
              else if(market.group == '업계 유사 회사를 기입해 주세요'){
                marketList.similarity.opts.some((opt,i2) => {
                    if(opt.label == market.key){
                        marketList.similarity.opts[i2].value = true;
                        return true;
                    }
                })
                marketList.similarity.content = market.value;
                marketList.similarity.value = market.key;
              }
          })
          setMarketList(JSON.parse(JSON.stringify(marketList)))
      }
      if(!IsArrayEmpty(data.services)){
        setProductList(data.services);
      }
      if(!IsArrayEmpty(data.technology)){
        setTechs(data.technology);
      }
      if(data.investments.length >= 1){
        setExistList(data.investments);
      }
      let newTmp = {date: '', pv: '', volume: '', round: ''};
      if(data.new_investment.pre_value){
        newTmp.pre_value = data.new_investment.pre_value;
      }
      if(data.new_investment.volume){
        newTmp.volume = data.new_investment.volume;
      }
      if(data.new_investment.starting_date){
        newTmp.starting_date = data.new_investment.starting_date;
      }
      if(data.new_investment.round){
        newTmp.round = data.new_investment.round;
      }
      if(data.business.length  >= 1){
        setSectors(data.business)
      }
      if(data.technology.length >= 1){
        setTechs(data.technology);
      }
      setNewInvestment(newTmp)
    }
    catch(e){
      console.log(e)
    }
  }
 

  const updateCompanyInfo = async() => {
    try{
      const market = [];
      Object.keys(marketList).map((key, i) => {
        if(key != 'similarity'){
          if(marketList[key].value && marketList[key].content){
            market.push({group: marketList[key].title, key: marketList[key].value, value: marketList[key].content})
          }
        }
      })
      await axios.post(url + '/user/profile', {
        name: name,
        role: rank,
        is_founder: isCoor,
      })
      .then((res) => console.log(res.status))
      await axios.post(url + '/user/company',{
        team: team,
        business_id: getPureBusinessNumber(businessNumber),
        description: business,
        business: sectors,
        technology: techs,
        market: market,
        competitor: marketList.similarity.content,
        services: productList,
        investments: existList,
        new_investment: newInvestment
      })
      handleSuccessModal();
    }
    catch(e){
      console.log(e)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if(!Auth.isLoggedIn()){
      navigate('/profile/create');
    }
    Auth.isProfile()
    .then((res) => {
      if(res){
        // navigate('/')
      }
    })
   
  },[isPreview])

  useEffect(() => {
    if(Auth.isLoggedIn()){
      getCompanyInfo();
      getProfileInfo();
    }
    
  }, [])
  return(
    <div className='investee_register_root'>
      <div className='top_description_container'>
        <div className='top_description_box'>
          <h2>
          내 정보 수정
          </h2>
          <div>
            <p>
              신주투자유치시 투자자와 스타트업의 최적의 매칭을 위해 아래의 정보를 입력해 주세요.
            </p>
            <br/>
            <p>
              투자자는 스타트업과 미팅을 하기 전에 투자의 계정이나 방향성등이 해당 스타트업과
              일치하는 지에 대한 과정이 필요합니다. 또한 이러한 과정을 통해 피투자사는 투자사와의 무분별한 미팅을 선별하여 회사의  투자유치에 할애하는 시간을 절약할 수 있습니다. 
            </p>
            <br/>
            <p>
              본 서비스는 신주투자유치중인 스타트업에게 6개월간 제공합니다. 
            </p>
          </div>
        </div>
      </div>
      <div className='investee-content-wrap'>
        <div className='form_box register-preview-box'>
            <InputBox required  option='company_name' state={companyName} setState={setCompanyName} />
            <InputBox required  option='business_number' state={businessNumber} setState={setBusinessNumber}/>
            <InputBox required option='name' state={name} setState={setName} />
            <InputBox required option={'gender'} state={gender} setState={setGender}/>
            <InputBox required  option='founder' state={isCoor} setState={setIsCoor} />
            <InputBox required  option='rank' state={rank} setState={setRank}/>
            <InputBox required option='email' state={email} setState={setEmail} />
            <InputBox required option='team' state={team} setState={setTeam} />
            <InputBox required option='main_business' state={business} setState={setBusiness} />
            <InputBox option='industry' state={sectors} setState={setSectors}/>
            <InputBox option='technology' state={techs} setState={setTechs} />
            <InputBox state={marketList} option={'market'} handleAdd={handleAdd}/>
            <InputBox state={productList} required  option={'product'} handleAdd={handleAdd}/>
            <InputBox required option={'investment'} state={existList} handleAdd={handleAdd}/>
            <InputBox required  option={'newInvestment'} handleAdd={handleAdd} state={newInvestment}/>
            <div className='rc-sb'>
                <div/>
                <div className='submit_btn' onClick={(e) => { updateCompanyInfo() }}>
                Submit
                </div>
            </div>
            <p className='bottom_description'>Never submit passwords through Airtable forms. Report malicious form</p>
        </div>            
      </div>      
      <RegisterModal
        className='nv-modal register-modal-scrollbar'
        show={serviceVisible}
        style={{overflow: 'auto'}}
        onHide={() => setServiceVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <ServiceModal onClose={() => setServiceVisible(false)} setVisible={setServiceVisible} arr={productList} setArr={setProductList}/>
      </RegisterModal>
      <RegisterModal
        className='nv-modal'
        show={investmentVisible}
        onHide={() => setInvestmentVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <InvestmentModal arr={existList} headers={headerList.investment} onClose={() => setInvestmentVisible(false)} setVisible={setInvestmentVisible} setArr={setExistList}/>
      </RegisterModal>
      <RegisterModal
        className='nv-modal'
        show={newInvestmentVisible}
        onHide={() => setNewInvestmentVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <NewInvestmentModal newInvestment={newInvestment} setNewInvestment={setNewInvestment} onClose={() => setNewInvestmentVisible(false)} setVisible={setNewInvestmentVisible}/>
      </RegisterModal>   
      <RegisterModal
        className='nv-modal register-modal-scrollbar'
        style={{height: 750, overflow: 'auto'}}
        show={marketVisible}
        onHide={() => setMarketVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <MarketModal arr={marketList} setArr={setMarketList} onClose={() => setMarketVisible(false)} setVisible={setMarketVisible}/>
      </RegisterModal>   
    </div>
  )
}

export default ProfileEdit;