import React from 'react'
import ContentsContainer from '../ContentsContainer'
import _ from 'lodash'
const Trending = ({list}) =>{
    return(
        <div className='main-body-above-content'>
            <ContentsContainer list={_.filter(list,{topic:'Trending'})[0]}/>
        </div>
    )
}

export default Trending