import React from 'react'

const Membership = () =>{
    return(
        <div className='membership mn'>
            <p>Become a member of Pitchdeck to understand the people and forces that are rewriting the rules of business.
                <a> Click here to learn more</a>
                .
            </p>
            <div style={{margin: '24px auto'}}>
                <a>
                    <span className='membership-btn'>Become a member</span>
                </a>
            </div>
        </div>
    )
}

export default Membership