const NewsSectionList=[
    {
        sec1:'업무협약',
        sec2:[]
    },
    {
        sec1:'자사이벤트',
        sec2:[]
    },
    {
        sec1:'실적',
        sec2:['실적발표', '자산매각', '매출•투자계약']
    },
    {
        sec1:'수상내역',
        sec2:['장관상']
    },
    {
        sec1:'ESG경영',
        sec2:['취약계층지원', '봉사활동',  '탄소중립', '직원격려']
    },
    {
        sec1:'주주친화적행보',
        sec2:['자사주 매입', '무증','소각','배당확대']
    },
    {
        sec1:'기술',
        sec2:['특허출원','기술개발']
    },
    {
        sec1:'제품및서비스소개',
        sec2:[]
    },
    {
        sec1:'채용',
        sec2:[]
    },
    {
        sec1:'지원사업선정',
        sec2:[]
    },
]
export default NewsSectionList