import React, {useState, useEffect} from 'react'
import Pannels from './component/Pannels'
import CurrentInfo from './component/CurrentInfo'
import ReplyLog from './component/ReplyLog'
import Header from '../component/Header'
import {debounce} from 'lodash'
import './news-room.scss'
import { useDidMountEffect } from 'hooks/useDidMountEffect'
import NavBarBtm from "components/navbar/NavBarBtm";
import axios from 'axios'
import url from 'utils/backend'
import _ from 'lodash'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {statusList} from 'utils/NewsStatus'

const NewsRoom = () =>{
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const statusList2 = [
        ...statusList,
    ]
    const [reactableSide, setReactableSide] = useState(true)
    const [reactableOpacity, setReactableOpacity] = useState(false)
    const [pageState, setPageState] = useState(false)
    const [list, setList] = useState({
        write: [],
        apply: [],
        reject: [],
        approve: []
    })
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const handleResize = debounce(()=>{
        setWindowSize({
            width:window.innerWidth,
            height:window.innerHeight
        })
    }, 300)
    const getMyList = async() => {
        try{
            axios.get(url + '/news/my/write?status=write')
            .then(res => {
                setList(prev => {
                    let tmp = _.cloneDeep(prev);
                    tmp.write = res.data.list;
                    return tmp;
                })
            })
            axios.get(url + '/news/my/write?status=apply')
            .then(res => {
                setList(prev => {
                    let tmp = _.cloneDeep(prev);
                    tmp.apply = res.data.list;
                    return tmp;
                })
            })
            axios.get(url + '/news/my/write?status=reject')
            .then(res => {
                setList(prev => {
                    let tmp = _.cloneDeep(prev);
                    tmp.reject = res.data.list;
                    return tmp;
                })
            })
            axios.get(url + '/news/my/write?status=approve')
            .then(res => {
                setList(prev => {
                    let tmp = _.cloneDeep(prev);
                    tmp.approve = res.data.list;
                    return tmp;
                })
            })
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(()=>{
        window.addEventListener('resize', handleResize);
        return()=>{
            window.removeEventListener('resize',handleResize)
        }
    },[])
    useEffect(()=>{
        if(windowSize.width>1023){
            setReactableSide(true)
            setReactableOpacity(false)
            setPageState(false)
        }
        else{
            setReactableSide(false)
            setPageState(true)
        }
    },[windowSize])
    useEffect(() => {
        getMyList()
    },[])
    return(
        <div className='mynews-main-wrap mg'>
            <Header title={'뉴스룸'} />
            <div className='mynews-main'>
                
                <section className='mynews-body mg' >
                    {/* <LeftSideBar reactableSide={reactableSide} setReactableSide={setReactableSide}/> */}
                    <article 
                    className='article mg' 
                    style={{opacity:(reactableOpacity?0.7:1)}} 
                    onClick={()=>{
                        setReactableOpacity(false); 
                        if(pageState){
                            setReactableSide(false)
                        }
                        }}>
                        <div className='dashboard'>
                            <Pannels 
                                list={list}
                                statusList={statusList2}
                            />
                            <article className='current-data mg'>
                                <CurrentInfo
                                    list={list}
                                    statusList={statusList2}
                                />
                            </article>
                        </div>
                    </article>                   
                </section>
            </div>
            <NavBarBtm type={"Main"} />
        </div>
    )
}

export default NewsRoom