import React from 'react';
import removeGray from 'assets/images/remove-gray.png';

const Badges = ({arr, handleRemove, style = null}) => {
    return(
        <div className="input-add-box-wrap" style={{width: '65%',minHeight: 40, backgroundColor: 'white', ...style}} >
            {arr && arr.map((data, i) => {
                if(data?.companyName){
                    return(
                        <div className='nv-badge' key={i}>
                        <p style={{color: 'black', fontWeight: 400}}>{data.companyName}</p>
                        {(handleRemove != undefined) &&
                            <img className="remove-gray" onClick={() => {
                                handleRemove(i);
                            }} src={removeGray} alt="" />
        
                        }
                    </div>    
                    )
                }
                else{
                    return(
                        <div className='nv-badge' key={i}>
                        <p style={{color: 'black', fontWeight: 400}}>{data}</p>
                        {(handleRemove != undefined) &&
                            <img className="remove-gray" onClick={() => {
                                handleRemove(i);
                            }} src={removeGray} alt="" />
        
                        }
                    </div>    
                    )
                }
            })}
      </div>
    )
}
export default Badges