import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImageIcon from '@mui/icons-material/Image';
import {getValue, getLabel} from 'utils/NewsStatus'
import { Link } from 'react-router-dom';
import DateFormat_Year_Month_Date_Hour_Minute from 'utils/DateFormat_Year_Month_Date_Hour_Minute'
import DisplayNewsTxt from 'utils/DisplayNewsTxt'
import getImageSignedURL from 'utils/getImageSignedURL'
const ImgTypeList = ({item}) =>{
    const [uri, setUri] = useState('')
    const location = useLocation()
    let LinkProps = {
        to: '/member/newsview/' + item._id,
        state: {prevPath: location.pathname}
    }
    const getImage = async() => {
        const res = await getImageSignedURL(item.main_image.key, item.main_image.mimetype);
        setUri(res)
    } 
    useEffect(() => {
        if(item.main_image.key){
            getImage()
        }
    }, [])
    return(
            <li className='li'>
                <Link className='img-in-li' {...LinkProps}>
                    <img src={uri}/> 
                </Link>
                <h4 className='h4-titles'>
                    <Link className='atag' {...LinkProps}>
                        {item.title}
                    </Link>
                </h4>
                <p className='p-text'>
                    <Link className='atag' {...LinkProps}>
                        {DisplayNewsTxt(item.data.find(e => e.type == 'txt'))}
                    </Link>
                </p>
                <div className='div-line'>
                    <em className='emtag'>{item.section.section_first}</em>
                    <em className='emtag'>{item.author.name}</em>
                    <em className='emtag'>{DateFormat_Year_Month_Date_Hour_Minute(item.updated_at)}</em>
                    <em className='emtag' >
                    <RemoveRedEyeIcon style={{width:12}}/>
                    </em>
                    <em className='emtag' style={{borderLeft:0,marginLeft:-5}}>{item.view}</em>
                    <em className='emtag'>
                        <ImageIcon style={{width:12}}/>
                    </em>
                    <em className='emtag'></em>
                    <div className='div-label'></div>
                </div>
            </li>
    )
}

export default ImgTypeList