import React,{useEffect, useRef, useState} from 'react';
import NvTextInput from 'components/NvTextInput'
import axios from 'axios';
import url from 'utils/backend';
import CheckIcon from '@mui/icons-material/Check';
import AlertConfirm from 'components/AlertConfirm';
import LoadingButton from '@mui/lab/LoadingButton';

const InputEmail = ({state, setState, isEmailCert, setIsEmailCert, setCertId, type}) => {
    const [isVisibleCode, setIsVisibleCode] = useState(false);
    const [code, setCode] = useState('')
    const [tmpEmail, setTmpEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isSend, setIsSend] = useState(false);
    const [isSendFirst, setIsSendFirst] = useState(false);
    const [isVisibleTimer, setIsVisibleTimer] = useState(false);
    const [time, setTime] = useState(0);
    const [sendLoading, setSendLoading] = useState(false);
    const sendEmail = async() => {
        setSendLoading(true);
        try{
            if(tmpEmail){
                if(validEmail(tmpEmail)){
                    const res = await axios.post(url + '/user/email/send',{
                        email: tmpEmail,
                        type: type
                    });
                    AlertConfirm({
                        title: '입력하신 이메일로 인증코드가 발송되었습니다.'
                    })
                    setState(tmpEmail)
                    setTime(179)
                    setIsSend(true);
                    setIsSendFirst(true);
                    setIsVisibleTimer(true);
                    setIsVisibleCode(true);
                }
                else{
                    AlertConfirm({
                        title: "이메일 형식이 올바르지 않습니다."
                    });
                }
            }
            else{
                AlertConfirm({
                    title: '이메일을 입력해주세요.'
                })
            }

        }
        catch(e){
            if(e.response.status == 403){
                if(e.response.message == 'exist'){
                    AlertConfirm({
                        title: '해당 이메일은 이미 가입되어 있습니다.'
                    })
                }
                else{
                    AlertConfirm({
                        title: '해당 이메일은 존재하지 않습니다.'
                    })
                }

            }
            else{
                AlertConfirm({
                    title: "이메일이 유효하지 않습니다."
                });
            }
            console.log(e)
        }
        finally{
            setSendLoading(false);
        }
    }
    const checkEmail = async() => {
        try{
            if(code){
                const res = await axios.post(url + '/user/email/verify',{
                    code: code,
                    email: state
                });
                if(!res.data.verify){
                    setIsValid(false);
                    setIsSend(false);
                    setIsEmailCert(false);
                }
                else{
                    setCertId(res.data._id)
                    setIsEmailCert(true);
                    setTmpEmail(state);
                    setIsSend(true);
                    setIsValid(true);
                }
            }
            else{
                AlertConfirm({
                    title: '인증번호를 입력해주세요.'
                })
            }

        }
        catch(e){
            AlertConfirm({
                title: '올바른 이메일을 입력해 주세요.'
            })
            console.log(e)
        }
    }
    const timeFormat = (time) => {
        const m = Math.floor(time / 60).toString()
        let s = (time % 60).toString()
        if (s.length === 1) s = `0${s}`
        return `${m}:${s}`
    }
    function validEmail(email){
        var email = email
        var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
        if(exptext.test(email)==false){
            //이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우			
            return false;
        }
        else{
            return true;
        }
        
    }

    useEffect(() => {
        if(!isEmailCert){
            if (time > 0) {
              const Counter = setInterval(() => {
                // const gap = Math.floor((new Date(expireAt).getTime() - new Date().getTime()) / 1000)
                setTime(time - 1)
              }, 1000)
              return () => {
                  clearInterval(Counter)
                }
            }
            else{
                setIsSend(false);
            }
        }
    }, [time])
    useEffect(() => {
        if(isSend && !isEmailCert){
            setTimeout(() => {
                setIsSend(false);
            }, 5000);
        }
    },[isSend])
    return(
        <div className='email-box'>
            <div className='rc-sb' style={{marginBottom: 15}}>
                <div className={'rc email-timer-box ' + (isSend && 'inactive')}>
                    <NvTextInput disabled={isSend} className={'text_input ' + (isSend && 'inactive') } defaultValue={tmpEmail} onChange={(v) => setTmpEmail(v)}/>
                    {(isVisibleTimer && !isEmailCert) &&
                        <div>{timeFormat(time)}</div>
                    }
                </div>
                <LoadingButton  loading={sendLoading}  classes={{root: 'email-btn ' + (isSend && 'inactive')}} onClick={(!isSend) ? sendEmail : null}>
                    {
                     !sendLoading && 
                     (!isSendFirst
                        ?
                            '인증메일 발송'
                        :
                            '인증메일 재발송'
                     )
                    }
                </LoadingButton>
            </div>
            {(isVisibleCode) &&
                <>
                <div className='rc-sb'  style={{marginBottom: 15}}>
                    <NvTextInput disabled={isEmailCert} style={{flex:1}} className={'text_input ' + (isEmailCert && 'inactive') } defaultValue={code} onChange={(v) => setCode(v)} placeholder='인증번호 6자리 숫자'  />
                    {(!isEmailCert)
                    ?
                        <div className='email-btn' style={{backgroundColor: 'black'}} onClick={checkEmail}>
                            인증번호 확인
                        </div>
                    :
                        <div className={'email-btn ' + (isSend && 'inactive')} style={{backgroundColor: 'black'}} >
                            <CheckIcon/>
                        </div>
                    }
                </div>
                {(!isValid) &&
                    <p className='email-invalid'>! 인증번호가 유효하지 않습니다. 다시 시도해 주세요.</p>
                }

                </>
            }
        </div>
    )
}
export default InputEmail;