import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import searchIcon from 'assets/images/searchIcon.png';
import axios from 'axios';
import url from 'utils/backend';
import { useNavigate } from 'react-router-dom';
import Chip from "@material-ui/core/Chip";

import { debounce } from 'lodash';


const AutoSearchInput = ({onHandleEnter, defaultValue, type = 'company', isNavigate = true, autoState, setAutoState, textState, setTextState, placeholder, isImg = true, renderOption, className, onChange}) => {
  const navigate = useNavigate();
  const [name, _name] = useState('');
  const [img, _img] = useState('');
  const [searchList, setSearchList] = useState([]);
  let getSearchList;
  const [debouncedCallApi] = useState(() => debounce((name) => getSearchList(name), 500));
  const objName = (type == 'company') ? 'investee_name' : 'investor_name';
  const objId = (type == 'company') ? 'investee_id' : 'investor_id'
  const objImg = (type =='company') ? 'investee_logo' : 'investor_logo'
  const filterOptions = createFilterOptions({
    stringify: (option) => option[objName],
    ignoreCase: false,
  });
  getSearchList = async(v) => {
    if(!v){
      setSearchList([]);
    }
    else if(type == 'company'){
      try {
        var uri = `/investee/autocomplete?name=${v}`;
        const res = await axios.get(url + uri);
        setSearchList(res.data.investees);
      }
      catch (e) {
        console.log(e);
      }
    }
    else if(type == 'investor'){
      try {
        var uri = `/investor/autocomplete?name=${v}`;
        const res = await axios.get(url + uri);
        setSearchList(res.data.investors);
      }
      catch (e) {
        console.log(e);
      }
    }
  }
  useEffect(() => {
    _name(defaultValue);
  }, [defaultValue])
  

  return(
    <Autocomplete
      freeSolo
      autoComplete
      id="search"
      classes={{
        root: 'nv-autocomplate ' + className,
        option: 'nv-autocomplate-label'
      }}
      style={{backgroundColor: 'white'}}
      value={name}
      onChange={(even, newValue) => {
        if(setAutoState) setAutoState(newValue)
        if(isNavigate) navigate('/investor/' + newValue[objId])
        if(onChange) onChange(newValue);
        _name(newValue[objName]);
        _img(newValue[objImg]);
      }}
      disableClearable
      filterOptions={filterOptions} 
      getOptionSelected={(option, value) => {
        if(value === "" || value === option[objName]) { return true; }
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }
        return option[objName];
      }}
      options={searchList}
      // renderOption={(props, option) => (
      //   <li {...props} onClick={() => {
      //     _name(option[objName])
      //     _img(option[objImg])
      //     setSearchList([]);
      //     if(isNavigate) navigate('/investor/' + option[objId])
      //     if(setAutoState) setAutoState(option)
      //     }}>
      //     {(type == 'company') 
      //     ?
      //         <img style={{width: 18, marginRight: 10, borderRadius: 100}} src={option.investee_logo}/>
      //     :
      //         <img style={{width: 18, marginRight: 10, borderRadius: 100}} src={option?.investor_logo }/>

      //     }
      //     {option[objName]}
      //   </li>
      // )}
      renderOption={(props, option) => {
        return(
          <span style={{display:'flex', alignItems: 'center', width: '100%'}}>
            {(renderOption)
            ?
             renderOption(props,option)
            :
            <li {...props}  
              style={{width: '100%'}}
              onClick={() => {
              setSearchList([])
              _name(option[objName])
              _img(option[objImg])
              if(isNavigate) navigate('/investor/' + option[objId])
              if(setAutoState) setAutoState(option)
            }}>
              <img style={{width: 18, marginRight: 10, borderRadius: 100}} src={option[objImg]}/>
              {option[objName]}
            </li>
            }
          </span>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          onChange={ev => {
              var v = ev.target.value
            if (v !== "" || v !== null) {
              if(setTextState) setTextState(v);
              debouncedCallApi(v);
            }
          }}
          onKeyPress={(ev) => {
              var v = ev.target.value
            if (ev.key === 'Enter') {
              if(onHandleEnter != undefined) {
                onHandleEnter(v, img)
              }
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (isImg) ?  <img className='nv-list-button-img' src={searchIcon}/> : null
          }}
        />
      )}
    />
  )
}
export default AutoSearchInput;