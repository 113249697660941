import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import NvTextInput from 'components/NvTextInput';
import NvTextArea from 'components/NvTextArea';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AutoSearchInput from 'components/AutoSearchInput'
import CheckAuth from 'hooks/CheckAuth';
import NavBar from 'components/navbar/NavBar';
import axios from 'axios';
import url from 'utils/backend'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import MenuTitle from 'components/discussion/MenuTitle'
import { SubTitle } from 'components/discussion/styled';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';

const Root = styled.div`
background-color: #f9f9f9;
width: 100%;
min-height: 100vh;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
`;
const ContainerWrap = styled.div`
max-width: 1140px;
margin: 0 auto;
width: 100%;
`;
const NavTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: white;
`;
const Title = styled.div`
    color: rgb(51,51,51);
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 18px;
    margin-top: 40px;
`;
const MText = styled.div `
    color: rgb(51,51,51);
    font-size: ${(props) => props.fontSize};        
`;
const Card = styled.div `
    background-color: #fff;
    padding: 20px 30px 30px 30px;
    border-radius:5px;
    border:1px solid black;

`;
const CardLabel = styled.div `
    font-weight: 900;
    font-size: ${(props) => props.fontSize};        
    color: #FFFFFF;
`;
const WriteBox = styled.div `
    background-color: #FFFFFF;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    textarea{
        width: 100%;
        height: 98% !important;
    }
`;
const Bottom = styled.div`
    margin-top: 30px;
    width: 100%;
    text-align: right;
    button{
        background-color: white;
        color: black;
        box-shadow:none;
        border:1px solid black;
    }
    button:hover{
        background-color: gray;
    }
`;
const CheckBox = styled.div `
    .css-i4bv87-MuiSvgIcon-root{
        fill: black !important;
    }
`;
const DiscussionWriteEtc = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [check, setCheck] = useState(false);
    const checkEmpty = () => {
        if(name && content){
            return false;
        }
        else{
            return true;
        }
    }
    const createBoard = async() => {
        try{
            if(!checkEmpty()){
                Swal.fire({
                    title: '글을 등록하시겠습니까?',
                    showCancelButton: true,
                    confirmButtonText: '확인',
                    cancelButtonText: '닫기',
                    customClass: {
                        confirmButton: 'swal-confirm-bt'
                    },
                    customClass:{
                        popup: 'swal-confirm-container',
                        title: 'swal-confirm-title',
                        confirmButton: 'swal-confirm-btn',
                        cancelButton: 'swal-confirm-cancel'
                    }
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        const res = await axios.post(url + '/forum/post', {
                            title: name,
                            content: content
                        });
                        if(res.status == 200){
                            navigate('/discussion/post')
                        }
                    } 
                })

            }
            else{
                Swal.fire({
                    title: '제목과 내용을 모두 입력해주세요.',
                    confirmButtonText: '확인',
                    showClass: {
                      popup: 'animate__animated animate__fadeInUp'
                    },
                    hideClass: {
                      popup: 'animate__animated animate__fadeOutDown'
                    },
                    customClass:{
                        icon: 'swal-confirm-icon',
                        popup: 'swal-confirm-container',
                        title: 'swal-confirm-title animate__animated animate__bounceIn',
                        confirmButton: 'swal-confirm-btn',
                        cancelButton: 'swal-confirm-cancel'
                    }
                  })
            }
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
    return(
        <div className='main-body-wrap'>
            <div className="nv-line"></div>
            <NavBar backgroundColor={'#f9f9f9'}/>
            {/* <BreadcrumbsHeader list={['discussion', 'discussionPost']} writing_type={'/discussion/post'}isPaddingLeft/> */}
            <Root >
                <ContainerWrap className='blind-containerwrap'>
                    <Title><span onClick={()=>navigate('/discussion/post')} style={{cursor:'pointer'}}>블라인드토론</span> &#62; <span style={{opacity:.7}}>글쓰기</span></Title>
                    <SubTitle>
                    창업자들의 업계라운지
                    </SubTitle>
                    <MText fontSize='16px'>
                    자유롭게 작성하고, 스타트업끼리의 의견 공유를 통해 투자업계 동향 및 트렌드에 대한 인사이트를 넓혀 보세요
                    <br/>
                    <br/>
                    모든 글은 등록할 때 산업분류명으로 게시되며 최신순으로 보입니다.
                    </MText>
                    <Card style={{marginTop: 30}}>
                        <CardLabel fontSize='15px'  style={{marginBottom: 20, color:'#444', fontWeight:'normal'}}>제목을 입력해주세요</CardLabel>
                        <div className='rc-sb'>
                            <WriteBox width={'100%'} height={'50px'}>
                                <NvTextInput style={{height: '100%'}} onChange={(v) => setName(v)}/>
                            </WriteBox>
                        </div>
                        <hr style={{margin: '35px 0 0 0'}}/>
                        <div style={{margin:'10px 0 20px 0', color:'#444'}}>내용</div>
                        {/* <WriteBox width={'100%'} height={'200px'} style={{marginTop: 30}}> */}
                            <NvTextArea minRows={5} onChange={(v) => setContent(v)} />
                        {/* </WriteBox> */}
                    </Card>
                    <Bottom>
                        <Button variant="contained" onClick={() => {createBoard()}} >
                            등 록
                        </Button>
                    </Bottom>
                </ContainerWrap>
            </Root>
        </div>

    )
}
export default DiscussionWriteEtc;