import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import NvSelect from 'components/NvSelect';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import _ from 'lodash'
import axios from 'axios';
import url from 'utils/backend';
import AlertConfirm from 'components/AlertConfirm';
const Changeoption = styled.div`
    height: 2.4375rem;
    margin: 0 0 1rem;
    width:100%;
    line-height:normal;
    min-height:40px;
    font-size:.813rem;
    letter-spacing:-.05em;
    margin-bottom:0;
    background-color: transparent;
    div > input {
        font-size: 12px;
        color: #444;
    }
    fieldset {
        border: 0px;
      }
    .nv-select{
        font-size: 12px;
        font-weight: 400;
        text-align:center;
        text-overflow:unset;
        width:${(props)=>props.width} !important;
        .MuiSelect-select{
            text-align:center;
        }
    }
`;
const Item = ({item, filterList, getUserList}) => {
    const [user, setUser] = useState(_.cloneDeep(item));
    const updateType = async(v) => {
        try{
            const res = await axios.post(url + '/user/type/' + user._id, {
                type: v
            })
        }
        catch(e){
            console.log(e)
        }
    }
    const updateManage = async(v) => {
        try{
            const res = await axios.post(url + '/user/manage/' + user._id, {
                manage: v
            })
        }
        catch(e){
            console.log(e)
        }
    }
    const acceptUser = async() => {
        try{
            const res = await axios.post(url + '/user/accept/' + user._id);
        }
        catch(e){
            console.log(e)
        }
    }
    const cancelAcceptUser = async() => {
        try{
            const res = await axios.delete(url + '/user/accept/' + user._id);
        }
        catch(e){
            console.log(e)
        }
    }
    const removeUser = async() => {
        try{
            const res = await axios.post(url + '/user/remove/' + user._id);
            getUserList()
        }
        catch(e){
            console.log(e)
        }
    }
    const confirmRemoveUser = () => {
        AlertConfirm({
            title: `${user.name} 회원을 삭제시키겠습니까?`,
            isConfirmed: removeUser,
            isDenied: () => null
        })
    }
    const handleChange = (v, level) => {
        if(level == 'type'){
            updateType(v)
        }
        else if(level == 'manage'){
            updateManage(v)
        }
        else if(level == 'status'){
            if(v == 'approve'){
                acceptUser()
            }
            else{
                cancelAcceptUser()
            }
        }
        setUser(prev => {
            let tmp = _.cloneDeep(prev);
            tmp[level] = v;
            return tmp;
        })
    }
    return(
        <tr className='tr'>
            <td className='text-center hideTh'>{user.name}</td>
            <td className='text-center hideTh'>{user.email}</td>
            <td className='hideTh'>
            <Changeoption
                width='30px'
            >
                <NvSelect
                    defaultValue={user.manage}
                    list={filterList.find(e => e.value == 'manage').child.filter(e => e.value != 'default')}
                    onChange={(v) => handleChange(v, 'manage')}
                    
                />
            </Changeoption>
            </td>
            <td className='hideTh'>
                <Changeoption
                    width='30px'
                >
                    <NvSelect
                        defaultValue={user.type}
                        list={filterList.find(e => e.value == 'type').child.filter(e => e.value != 'default')}
                        onChange={(v) => handleChange(v, 'type')}

                       
                    />
                </Changeoption>
            </td>
            <td className='text-center'>
                <Changeoption
                    width='30px'
                >
                    <NvSelect
                        defaultValue={user.status}
                        list={filterList.find(e => e.value == 'status').child.filter(e => e.value != 'default')}
                        onChange={(v) => handleChange(v, 'status')}

                    />
                </Changeoption>    
            </td>
            <td className='text-center'>
                {user.status == 'approve' 
                ?
                    <button 
                        className='manage-btn'
                        onClick={() => confirmRemoveUser()}
                    >
                        <DeleteOutlineIcon/>
                        삭제
                    </button>
                : 
                    <button 
                        className='manage-btn approve-btn'
                        onClick={() => handleChange('approve', 'status')}
                    >
                        <DoneIcon/>
                        승인
                    </button>                

                }
            </td>
        </tr>
    )
}
export default Item