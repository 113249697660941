import React from 'react'

const Form = ({wrong, type, _onKeyDown, input, handleInput}) => {
    return(
        <div style={{marginBottom:24}}>
            <div className='mn'>
                <div style={{position:'relative'}}>
                    <label className='label mn-extended'>{type}</label>
                    <div className='input-wrap'>
                    <input 
                    className={'input '+type} 
                    type={type}
                    autocomplete={type=='email' ?'username':'current-password'}
                    placeholder={type=='email' ?'e.g. example@pitchdeck.com':'Enter password'}
                    aria-invalid={wrong}
                    onKeyDown={_onKeyDown}
                    name={type} 
                    value={input} 
                    onChange={handleInput}
                    ></input>
                    </div>
                    {/* {type=='password' && <div className='show'>
                        <div className='btn'  role='switch'>
                            <span className='span'>
                            <span>show</span>
                            </span>
                        </div>
                    </div>} */}
                </div>
                {/* {type=='email'
                ?
                <div>
                    {wrong && <div className={'guide-text mn '+(wrong?'active':'')}>
                        Please provide a valid email address.
                    </div>}
                </div>
                :
                <div className={'guide-text mn '+(wrong?'active':'')}>
                    or email yourself <a href="/"> login link </a>instead
                </div>
                } */}
            </div>
        </div>
    )
}

export default Form