import React from 'react';
import styled from 'styled-components'
import TimeForToday from 'utils/TimeForToday'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
const IconBox = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    .icon-img{
        width: 16px;
        height: 16px;
        margin-right: 7px;
    }
    .icon-text{
        font-weight: 400;
        font-size: 14px;
        color: rgb(51,51,51);
    }
`;
export const IconContainer = styled.div`
    display:flex;
    align-items: center;
`;
export const ViewIcon = ({cnt}) => {
    return(
        <IconBox>
            <RemoveRedEyeOutlinedIcon className='icon-img' />
                {cnt}
        </IconBox>
        
    )
}
export const LikeIcon = ({cnt, onClick, isLike}) => {
    return(
        <IconBox style={{cursor: 'pointer'}} onClick={() => {
            if(onClick) onClick()
        }}>
            {(isLike)
            ?
            <ThumbUpAltIcon className='icon-img'/>
            :
            <ThumbUpOffAltIcon className='icon-img' />        
            }
                {cnt}
        </IconBox>
        
    )
}
export const ChatIcon = ({cnt}) => {
    return(
        <IconBox>
            <ChatBubbleOutlineOutlinedIcon className='icon-img' />
                {cnt}
        </IconBox>
        
    )
}
export const ClockIcon = ({time}) => {


    return(
        <IconBox>
            <img className='icon-img' src={require('assets/images/clock_icon.png')} />
                {TimeForToday(time)}
        </IconBox>
    )
}