import React, {useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'
import search_icon from 'assets/images/search_icon.png'
import styled from 'styled-components';
import Button from '@mui/material/Button';
import axios from 'axios';
import url from 'utils/backend';
import Swal from 'sweetalert2'
import moment from 'moment'
import Auth from 'utils/auth';
import ReplyCard from './ReplyCard';
import ReReplyBox from './ReReplyBox';

const ReplyBox = styled.div `
  width: 100%;
  margin-top: 10px;
`;
const ReplyHeader = styled.div `
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  p{
    font-size: 16px;
    font-weight: 900;
  }
  div{
    font-size:13px;
    font-weight: 400;
  }
  .title-box{
    display: flex;
    flex-direction: row;
    align-items: center
  }
  .delete-btn{
    color: red;
    margin-left: 10px;
    margin-bottom: 2px;
    cursor: pointer;
  }
`;
const ReplyContent = styled.pre`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
`;

const ReplyWrite = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .input-box{
    width: 88%;
  }
  textarea{
    width: 100% !important;
    min-height: 80px !important;
    background-color: white;
    border-radius: 5px;
    margin-right:10px;
  }
  button{
    background-color: white;
    color: black;
    height: 40px;
    width:80px;
    font-size:15px;
    &:hover{
      opacity:0.7
    }
  }

`;
const Reply = ({_id, replys, writer_id, getReplyList, getBoardInfo}) => {
  const [content, setContent] = useState('');
  const createReply = async() => {
    try{
      if(content){
        const res = await axios.post(url + '/forum/reply', {
          origin_id: _id,
          content: content
        })
        setContent('')
        getReplyList();
      }
      else{
        Swal.fire({
          text: '내용을 입력해주세요.',
          showClass: {
            popup: 'animate__animated animate__fadeInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutDown'
          },
          customClass:{
            icon: 'swal-confirm-icon',
            popup: 'swal-confirm-container',
            title: 'swal-confirm-title animate__animated animate__bounceIn',
            confirmButton: 'swal-confirm-btn',
            cancelButton: 'swal-confirm-cancel'
          }
        })
      }
    }
    catch(e){
      console.log(e)
    }
  }

  const deleteReply = async(id) => {
    try{
      Swal.fire({
        title: '댓글을 삭제하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '닫기'
      }).then(async(result) => {
      if(result.isConfirmed) {
        const res = await axios.delete(url + '/forum/reply/' + id);
        getReplyList();
      }
    })

    }
    catch(e){
      console.log(e)
    }
  } 

  return(
    <ReplyBox>
      <ReplyWrite style={{justifyContent:'unset'}}>
        <NvTextArea className='input-box' style={{borderColor:'rgba(0,0,0,.15)'}} defaultValue={content} onChange={(v) => setContent(v)}/>
        <Button variant="contained" style={{transition:'unset', boxShadow:'unset',border:'1px solid #168dd9',backgroundColor:'#fff', color:'#168dd9'}} onClick={() => {createReply()}} >
            등 록
        </Button>
      </ReplyWrite>
      {replys.filter((data) => data.deleted_at == null).map((item, i) => {
        return(
          <>
          <ReplyCard item={item} getBoardInfo={getBoardInfo} getReplyList={getReplyList} writer_id={writer_id} />
          <ReReplyBox item={item} getReplyList={getReplyList} writer_id={writer_id}/>
          </>
        )
      })
      }
    </ReplyBox>
  )
}
export default Reply;