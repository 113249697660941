import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


export const isCompanyOpenSlice = createSlice({
  name: 'registerIsCompanyOpen',
  initialState: false,
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return false
    }
  },

})

export const { update, reset } = isCompanyOpenSlice.actions

export default isCompanyOpenSlice.reducer