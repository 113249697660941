import React, { useState, useEffect } from 'react';
// import 'styles/home.css';

function NvGrid(props) {
    const WIDTH_UNIT = 30
    const HEIGHT_UNIT = 35
    
    const _className = () => {
        let class_names = '' 

        if(props.grid) {
            class_names += ' nv-grid'
        } else if(props.row) {
            class_names += ' nv-row'
        } else if(props.col) {
            class_names += ' nv-col'
        } else if(props.cell) {
            class_names += ' nv-cell'
        }
        let t = props.t || 5
        class_names += ' nv-t' + t

        if(props.className) class_names += ' ' + props.className
        return class_names     
    }

    const _style = () => {
        let style = {}
        if(props.style) style = props.style

        if(props.cell) {
            style.width = ((props.w || 4) * WIDTH_UNIT) + 'px'
            style.height = ((props.h || 1) * HEIGHT_UNIT) + 'px'
            if(!props.nlh) style.lineHeight = ((props.h || 1) * HEIGHT_UNIT) + 'px'
            if(props.h == 0) style.display = 'none'
        } else {
            if(props.w) style.width = (props.w * WIDTH_UNIT) + 'px'
            if(props.h) style.height = (props.h * HEIGHT_UNIT) + 'px'
            if(props.h && !props.nlh) style.lineHeight = (props.h * HEIGHT_UNIT) + 'px'
        }

        if(props.nba) style.border = 'none';
        if(props.nbt) style.borderTop = 'none';
        if(props.nbb) style.borderBottom = 'none';
        if(props.nbr) style.borderRight = 'none';

        return style 
    }
    // className='nv-cell nv-w1 nv-h1  nv-b1 nv-t1'
    return (
        <>
            <div className={_className()} style={_style()} onClick={props.onClick? props.onClick:null}>
                {props.children}
            </div>
        </>
    );
}

export default NvGrid;