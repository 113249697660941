import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


export const stageSlice = createSlice({
  name: 'registerStage',
  initialState: 'search',
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return ''
    }
  },

})

export const { update, reset } = stageSlice.actions

export default stageSlice.reducer