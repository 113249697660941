import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputBox2 from "components/profile/InputBox2";
import "styles/profile.scss";
import "animate.css";
import AlertConfirm2 from "components/AlertConfirm2";
import getPureBusinessNumber from "utils/getPureBusinessNumber";
import axios from "axios";
import url from "utils/backend";
import digestPW from "utils/digestPW";
import NavBar from "components/navbar/NavBar";
import Auth from "utils/auth";

const PasswordEdit = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [nowPassword, setNowPassword] = useState("");

  const changePassword = async () => {
    try {
      if (!nowPassword) {
        AlertConfirm2({
          title: "현재 비밀번호를 입력해 주세요.",
        });
      } else if (!newPassword || !passwordCheck) {
        AlertConfirm2({
          title: "새로운 비밀번호를 입력해 주세요.",
        });
      } else if (newPassword != passwordCheck) {
        AlertConfirm2({
          title: "새로운 두 비밀번호가 일치하지 않습니다.",
        });
      } else if (nowPassword == newPassword) {
        AlertConfirm2({
          title: "현재 비밀번호와 새로운 비밀번호를\n다르게 입력해 주세요.",
        });
      } else {
        const nowPassword2 = await digestPW(nowPassword);
        const newPassword2 = await digestPW(newPassword)

        const res = await axios.post(url + "/user/password/change", {
          // user_id: Auth.getId(),
          nowPassword: nowPassword2,
          newPassword: newPassword2,
        });

        AlertConfirm2({
          title: "비밀번호가 변경되었습니다.\n다시 로그인해 주세요.",
          isAfter: () => {
            Auth.logout();
            navigate("/Login");
          },
        });
      }
    } catch (e) {
      if (e.response.message == "not equal") {
        AlertConfirm2({
          title: "현재 비밀번호가 틀렸습니다.",
        });
      } else {
        AlertConfirm2({
          title: "유효하지 않은 토큰입니다.",
        });
      }
      console.log(e);
    }
  };

  return (
    <div className="investee_register_root profile-edit-password">
      <div className="profile-edit-content-container">
        <div className="form_box" style={{ backgroundColor: "unset" }}>
          <InputBox2
            option="password_now"
            state={nowPassword}
            setState={setNowPassword}
          />
          <InputBox2
            option="password"
            state={newPassword}
            setState={setNewPassword}
          />
          <InputBox2
            option="password_check"
            state={passwordCheck}
            setState={setPasswordCheck}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: 30,
              marginTop: 50,
            }}
          >
            <div className="profile-edit-btn" onClick={changePassword}>
              저장
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordEdit;
