import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color:#fff;
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const BodyWrap = styled.div`
    width: 900px;
    min-height: 500px;
    // border-bottom: 2px solid rgba(202, 193, 193, 0.33);
    .category{
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 50px;
    }
`;
export const BoardCard = styled.div`
    width: 100%;
    .board-head{
        padding-bottom: 15px;
    }
    .board-title{
        font-weight: 500;
        font-size: 19px;
        color: #000000; 
        margin-bttom: 5px;
    }

    .board-icon-container{
        margin-top: 10px;
        display:flex
    }
    .board-icon-box{
        display: flex;
        align-items: center;
        margin-right: 20px;
    }
    .board-icon{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .board-text{
        font-weight: 400;
        font-size: 14px;
        color: #7C7C7C;
    }
    .board-content{
        padding: 20px 10px;
        border-top:  2px solid rgba(202,193,193,0.5);
        border-bottom: 2px solid rgba(202,193,193,0.5);
    }
    .board-content-content{
        min-height: 200px;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
        word-break: break-all;
    }

`;
export const NameBox = styled.div`
    display: flex;
    align-items:center;
    margin-top: 8px;
    .board-name{
        font-weight: 500;
        font-size: 13px;
        color: #ADADAD;
    }
    .board-name:first-child{
        margin-right: 8px;
        color: #88a2ca
    }
    .board-writer{
        font-weight: 500;
        font-size: 10px;
        color: #da3238;
        margin-left: 5px;
    }
`;

export const ContentWrap = styled.div`
    .content-head{
        display: flex;
        border-bottom: 1px solid rgba(202, 193, 193, 0.33);
        align-items: center;
        justify-content: space-between;
    }
    .content-head-left{
        display: flex;
        align-items: center;
    }
    .content-head-title{
        font-weight: 600;
        font-size: 27px;
        color: #000000;
    }
    .content-img{
        width: 22px;
        height: 22px;
        margin-right: 10px;
        margin-bottom: 3px;
    }

    .content-btn{
        width: 150px;
        height: 40px;
        background: #695D45;
        font-weight: 500;
        font-size: 13px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 30px;
    }
    .content-search{
        background-color: white;
        width: 350px;
        height: 40px;
        font-weight: 500;
        font-size: 13px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-top: 30px;
        input{
            background-color: white;
            width: 300px;
        }
        fieldset{
            border: 0px !important;
        }
        img{
            margin-right: 15px;
        }
    }
    .content-body{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 0 100px 0px;
    }
    .content-card{
        width: 50%;
        height: 236px;
        background-color:  white;
        padding: 30px 20px 20px 20px;
        cursor: pointer;
        border-bottom: 1px solid #f5f5f5;
        &:nth-child(2n + 1){
            border-right: 1px solid #f5f5f5;
        }
    }
    .content-card-content{
        display: flex
    }
    .content-card-left{
        width: 80%
    }
    .content-card-img{
        width: 106px;
        height: 106px;
        background-color: blue
    }
    .content-card-title{
        height: 45px;
        font-weight: 500;
        font-size: 19px;
        color: #000000;
        margin-bottom: 20px;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .content-card-text{
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    
`;

export const HeaderWrap = styled.div`
    background-color: white
`;