import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


export const openSlice = createSlice({
  name: 'registerOpen',
  initialState: false,
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return false
    }
  },

})

export const { update, reset } = openSlice.actions

export default openSlice.reducer