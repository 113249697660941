import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Button, Popper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash'
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

// import 'styles/common.scss';

const filterOptions = createFilterOptions({
});

function AutoComplete({ defaultValue, options = [], onChange, onEnter, renderOption, classes, isWide = false, wide=650, isEndAdornment = true, placeholder = '', isEndEnter=false}) {
    // Data
    const [value, _value] = useState('');
    const inputRef = useRef()
    const [open, setOpen ]= useState(false);
    const [isFocus, setIsFocus] = useState(false);

    // Functions
    const _getOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }
        if(option.label){
            return option.label
        }
        return option.text ;
    }

    const _onKeyDown = (e) => {
        // console.log(e)
        if (onEnter && e.keyCode == 13 && e.target.value != '') {
            onEnter(e.target.value)
            e.target.blur()
            // ref.target.blur()
        }
        // if(onChange) onChange(e.target.value)
        // if(onChange) console.log(e.target.value + e.code)
    }

    const _onKeyUp = (e) => {
        // if(onEnter && e.keyCode == 13 && e.target.value != '') onEnter(e.target.value)
        if (onChange) onChange(e.target.value)
    }

    const _onChange = ( val) => {
        if(!val) {
            val = ''
        }
        else if(val.text) {
            val = val.text;
        } else if (val.value) {
            val = val.value;
        }
        // console.log('_onChange: ' + val)
        _value(val)
        if (onChange) onChange(val)
    }

    const PopperMy = function (props) {
        return (
        <Popper {...props} 
            placement='bottom'
            style={{ height: 0, width: wide}}            
        />)
    }
          // Rendering
    useEffect(() => {
        _value(defaultValue)
    }, [defaultValue])

    const handleOpen = () => {
        setOpen(!open);
    }
    const renderEnd = (e) => {
        if(isEndEnter){
          return(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{padding: 0, marginRight:5}}
                onClick={() => {onEnter(value); _value('')}}

                // onMouseDown={handleMouseDownPassword}
              >
                <SendIcon style={{fontSize:15, color: '#638ffe'}}/>
              </IconButton>
            </InputAdornment>
          )
        }
        else{
            return(
                <Button style={{cursor: 'pointer', width: 20, height: 20, borderRadius: '70%', minWidth: 'unset'}} onClick={(e) => handleOpen()}>
                    <InputAdornment
                        style={{marginLeft: 0}}
                        disablePointerEvents={true}
                        position="end"
                    >
                        <ArrowDropDownIcon />
                    </InputAdornment>
                </Button>
            )
        }
    }
    // Template
    return (
        <Autocomplete
            freeSolo
            value={value}
            open={open}
            // noOptionsText=''
            disablePortal={true}
            PopperComponent={(isWide) ? PopperMy : null}
            disableClearable
            onKeyDown={_onKeyDown}
            onKeyUp={_onKeyUp}
            onChange={_onChange}
            onInputChange={(event, v) => {
                if(value == v) return
                _value(v)
                if(onChange) onChange(v);
            }}

            classes={{ 
                option: 'nv-autocomplate-label', popper: 'nv-autocomplete-popper',...classes }}
            // filterOptions={filterOptions}
            // selectOnFocus
            // clearOnBlur 
            // disableClearable
            // handleHomeEndKeys
            options={options}
            // isOptionEqualToValue={(option, value) => value === undefined || value === "" || option.text === value}
            isOptionEqualToValue={(option, value) => false}
            getOptionLabel={_getOptionLabel}
            // renderOption={(props, option) => <li {...props}>{option.text}</li>}
            size='small'
            renderOption={(props, option) => {
                if(renderOption){
                    return renderOption(props, option, inputRef)
                }
                else{
                    return(
                        <li 
                            {...props}
                            onClick={() => {
                                if(option?.text == '직접입력'){
                                    _value('');
                                    if(onChange) onChange('')
                                    inputRef.current.focus()
                                    setOpen(false)
                                }
                                else{
                                    _onChange(option)
                                    inputRef.current.blur()
                                }
                                // setOpen(false);
                            }}
                        
                        >
                            {_getOptionLabel(option)}
                        </li>
                    )
                
                }
            }}
            className='nv-autocomplate'
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    inputRef={inputRef} 
                    variant="outlined" 
                    size="small" 
                    placeholder={!isFocus && (!placeholder ? '' : placeholder)}
                    onFocus={() => {setOpen(true); setIsFocus(true)}}
                    onBlur={() => {setOpen(false); setIsFocus(false)}}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: isEndAdornment ? (
                            renderEnd()
                        ) : null
                    }}
                />
            )}
        />
    );
}

export default AutoComplete;