import React from 'react';
const InputProduct = ({state}) => {
    return(
        state.length >= 1 ?
          <div className='top-row-container' style={{border: '0px solid #bdbdc2'}}>
            {state.map((data, i) => {
            return(
              <div>
                <div className='top-row-box'>
                <span className='name' style={{width: '75%'}}>{data.name}</span>
                <div style={{width: '25%'}}>
                  {data.stage &&
                    <>
                    <p style={{fontWeight: 600, fontSize: 12}}>사업화단계</p>
                    <span className='business-stage'>{data.stage}</span>
                    </>
                  }
                </div>          
              </div>
              <div className='text_input' style={{border: 'none'}}>{data.description}</div>
              </div>
            )
          })}
        </div>
        :
        <div className='top-row-container' style={{border: '0px solid #bdbdc2'}}>
          <div>
            <div className='top-row-box'>
            <span className='name'></span>
          </div>
            <pre className='pre_text_input' style={{border: 'none'}}></pre>
          </div>
        </div>
    )
}
export default InputProduct