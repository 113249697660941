import React from 'react'
import DateFormat_Year_Month_Date from 'utils/DateFormat_Year_Month_Date'
import ViewImage from './ViewImage';
import DisplayNewsTxt from 'utils/DisplayNewsTxt'
import NvTextRich from 'components/NvTextRich'

const Body = ({author, date, data}) =>{
    return(
        <div>
            <div className='detail-main-body-container'>
                <div className='detail-main-body-wrapper'>
                    <div className='detail-main-body pt'>
                        <div className='main-body-header'>
                            <div className='text-box mn'>
                                <div className='title'>
                                    <div className='by mn'>By 
                                        <span className='span mn'>
                                            <a style={{color:'inherit'}} href='/'> {author.name}</a>
                                        </span>
                                    </div>
                                </div>
                                <div className='date mn'>Published 
                                    <time style={{marginLeft: 5}}>{DateFormat_Year_Month_Date(date) || '-'}</time>
                                </div>
                            </div>
                        </div>
                        <div id='main-body-article-contents'>
                            <div>
                                {data.map(d => {
                                    let Tag = d.tagName;
                                    if(d.type == 'img'){
                                        return(
                                            <ViewImage item={d} src={d.innerHtml || d.file?.preview} containerStyle={{marginTop: 0}}/>
                                        )
                                    }
                                    else if(d.type == 'line'){
                                        return(
                                            <Tag/>
                                        )
                                    }
                                    else if(d.type == 'txt'){
                        
                                        return(
                                            <Tag>
                                            {DisplayNewsTxt(d)}
                                            </Tag>
                                        )
                                    }
                                    else{
                                        return(
                                            <Tag>
                                                {d.innerHtml}
                                            </Tag>
                                        )
                                    }
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body