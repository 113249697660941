import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import './style.scss'

import url from 'utils/backend';
import axios from 'axios';
import OperatingFund from './component/OperatingFund';
import Portfolio from './component/Portfolio';
import NvTextInput from 'components/NvTextInput';
import NvGrid from 'components/NvGrid'
import Button from '@mui/material/Button';
import NavBar from 'components/navbar/NavBar';
import BasicInfo from 'components/BasicInfo';
import StockInfo from 'components/StockInfo';
import MapInfo from 'components/MapInfo';
import NewsItem from './component/NewsItem';
import AutoSearchInput from 'components/AutoSearchInput';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';


const InvestorView = ({}) => {
  let location = useLocation();
  const [datum, setDatum] = useState([]);
  const navigate = useNavigate();
  const [name, _name] = useState('');
  const [menuList, setMenuList] = useState([{value: 'fund', label: '운영펀드'}, {value: 'portfolio', label: '포트폴리오'}]);
  const [menu, setMenu] = useState(menuList[0].label);
  const [merge, setMerge] =  useState(false);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  const [fund, setFund] = useState(null);
  const [portfolio, setPortfolio] = useState(null) 
  const [noCnt, setNoCnt] = useState(0);

  const fundColumns = React.useMemo(
      () => [
          {
              Header: '펀드명',
              accessor: 'name',
              width: 7,
              textAlign: 'flex-start'
            },
            {
              Header: '결성총액',
              accessor: 'total_ammount',
              width: 3,
              textAlign: 'flex-end'
            },
            // {
            //   Header: '투자총액',
            //   accessor: 'total_investment_amount',
            //   width: 3,
            //   textAlign: 'flex-end'
  
            // },
            // {
            //   Header: '소진율',
            //   accessor: 'burn_rate',
            //   width: 3,
            //   textAlign: 'flex-end'
  
            // },
            // {
            //   Header: 'GP',
            //   accessor: 'GP',
            //   width: 3,
            //   textAlign: 'center'
  
            // },
            {
              Header: '주목적',
              accessor: 'main_purposes',
              width: 12,
              textAlign: 'center'
  
            },
            {
              Header: '결성일',
              accessor: 'start_date',
              width: 4,
              textAlign: 'flex-end'
  
            },
            {
              Header: '만기일',
              accessor: 'finish_date',
              width: 4,
              textAlign: 'flex-end'
  
            },
      ],
      []
  )
  const portColumns = React.useMemo(
    () => [
      {
        Header: '기업명',
        accessor: 'investee_name',
        width: 7,
        textAlign: 'flex-start'
      },
      {
        Header: '투자분야',
        accessor: 'main_sector',
        width: 8,
        textAlign: 'flex-start'

      },
      {
        Header: '주요사업',
        accessor: 'main_business',
        width: 9,
        textAlign: 'flex-start'
      },
      {
        Header: '투자단계',
        accessor: 'round',
        width: 3,
        textAlign: 'center'

      },
      // {
      //   Header: 'Post MC',
      //   accessor: 'post_market_capitalization',
      //   width: 3,
      //   textAlign: 'flex-end'

      // },
      // {
      //   Header: '투자금액',
      //   accessor: 'investment_amount',
      //   width: 3,
      //   textAlign: 'flex-end'

      // },
      {
        Header: '투자일자',
        accessor: 'start_date',
        width: 3,
        textAlign: 'flex-end'

      },
    ],
    []
)

  const _searchInvestor = async (name) => {
    try {
      const res = await axios.get(url + `/investor/autocomplete?name=${name}`);
      let _id = null
      res.data.investors.map((record) => {
        if (record.investor_name == name) {
          _id = record.investor_id
        }
      })
      if (_id) {
        navigate(`/investor/${_id}`)
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  const renderContent = () => {
    if(menu == '운영펀드'){
      return <OperatingFund item={info} columns={fundColumns} />
    }
    else if(menu == '포트폴리오'){
      return <Portfolio item={info} columns={portColumns}/>
    }
  }

  const getInfo = async() => {
    try{
      const path = location.pathname
      const res = await axios.get(url + path);
      let cnt = 0;
      // res.data.investor.investment.list.map((data, i) => {
      //   if(data.name == '알 수 없음') cnt++;
      // })
      // res.data.investor.investment.list = res.data.invsetor.investment.list.filter((data) => data.name != '알 수 없음')
      setInfo(res.data.investor);
      setNoCnt(cnt);
    }
    catch(e){
      console.log(e)
    }
  }
  useEffect(() => {
    getInfo();
  },[window.location.href])
  return (
    <div className='main-body-wrap'>
    <div style={{backgroundColor:'#F5F5F5', height: '100vh'}}>
    <div className='investor-wrap'>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light" style={{minWidth:1219}}>
        <NavBar type={'investor'}/>
            {(info) &&
          <><BreadcrumbsHeader list={['find', 'findInvestor', 'investeeInfo']} investee_name={info.company.name} isPaddingLeft/>
          
          <div className='nv-company-header investor-header'>
            <div className='nv-company-header-wrapper' style={{ width: '1219px', height: '75%' }}>
            <div className="nv-company-brand">
                <div className="nv-company-brand-logo investor-header-logo">
                    <img src={info.company.summary.basic.logo} alt="" />
                </div>
                <div className='nv-company-brand-name-desc'>
                    <span className='investor-header-name'>{info.company.name}</span>
                </div>
            </div>
            <div>
                <NvGrid row t={'n'} style={{alignItems:'center'}}>
                    {/* <AutoSearchInput style={{height: '100%'}} setState={_name}  onHandleEnter={_searchInvestor} type='investor' /> */}
                    {/* <NvGrid cell t={'n'} w={3} nbb nbr><Button variant="contained" className='nv-button ipo-search-btn' style={{backgroundColor: 'black'}} onClick={(e) => { _searchInvestor() }}>검색</Button></NvGrid> */}
                </NvGrid>
            </div>
            </div>
            <div className='nv-company-nav-wrapper' style={{ width: '1200px', position: 'absolute', bottom: 0 }}>
                <ul className="d-none d-md-flex me-auto">
                {menuList.map((data, i) => {
                    return(
                        <li className={menu == data.label ? 'nv-company-nav-item investor-nav-item active' : 'nv-company-nav-item investor-nav-item'} onClick={(e) => setMenu( data.label)}><a className={menu ==  data.label ? 'nv-company-nav-link investor-nav-link  active' : 'nv-company-nav-link investor-nav-link' }  >{ data.label}</a></li>
                    )
                })}
            </ul>
            </div>
          </div>
          <div className='content_wrap' style={{paddingBottom: 100}}>
            <div className='investor-content-wrap'>
              {info &&
                <>
                <div className='info_content_left_box'>
                  <OperatingFund item={info} columns={fundColumns} />
                  <Portfolio item={info} columns={portColumns} noCnt={noCnt}/>
                  <div className='investor-news-container' >
                    <div className='investor-news-title'>뉴스</div>
                    <div className='investor-news-content-container'>
                        {info.news.map((data, i) => {
                            if(i < 9)
                            return(
                                <NewsItem item={data}/>
                            )
                        })
                        }
                    </div>
                </div>
                </div>
                <div className='info_content_right_box' >
                <div className='info_content_right_container'>
                  <div className='info_content_right_card' >
                    <div className='info_content_title'>
                        <div className='info_content_card_title' style={{fontSize: 15}}>{info.company.name}</div>
                    </div>
                    <div className='info_content_text_box'>
                        <div className='investor-introduce-box'>
                            {info.company.summary.basic.description}
                        </div>
                    
                    </div>
                  </div>
                    <BasicInfo date={info.company.summary.basic.establish_date} ceo={info.company.member.ceo.length ? info.company.member.ceo[0].name:''} employees={info.company.summary.basic.employees} hompage={info.company.summary.basic.homepage}/>
                    <StockInfo data={info.company} stockTotal={info.company.summary.stock.total} faceValue={info.company.summary.stock.par_value} capital={info.company.summary.stock.capital} commonStock={info.company.summary.stock.common_stocks} preferredStock={info.company.summary.stock.preferred_stocks}date={info.company.summary.meta.review_date}/>
                    <MapInfo data={info.company} address={info.company.summary.basic.address}/>
                  </div>
                </div>
                </>
              }

            </div>
            
          </div>
          </>

          }

      </div>
    </div>
  </div></div>
  )
}
export default InvestorView;