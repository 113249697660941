import React, {useEffect, useState} from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Header from 'components/admin/Header'
import LeftSideBar from 'components/admin/LeftSideBar'
import RightSideBar from 'components/admin/RightSideBar'
import '../admin.scss'

import NavBarBtm from "components/navbar/NavBarBtm";
import { useDidMountEffect } from 'hooks/useDidMountEffect'
import Paging from 'components/Paging'
import axios from 'axios'
import url from 'utils/backend'
import ConvertComma from 'utils/ConvertComma'
import {debounce} from 'lodash'
import Loading from 'components/Loading'
import SearchDetail from './component/SearchDetail';
import SearchList from './component/SearchList';



const viewModeList = [
    {
        label: '제목형',
        value: 'title'
    },
    {
        label: '요약형',
        value: 'summary'
    },
]
const NewsSearch = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const stage = searchParams.get('stage')
    const location = useLocation();
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [section, setSection] = useState('default');
    const [reporterName, setReporterName] = useState('')
    const [period, setPeriod] = useState({
        start_date: null,
        end_date: null
    });
    const [word, setWord] = useState({
        word1: null,
        word2: null,
        word_conjuction: 'or',
    })
    const [sortType, setSortType] = useState('approve');
    const [train, setTrain] = useState({
        area: 'default',
        level: 'default',
        type: 'default',
    })
    useEffect(() => {
        if(!stage){
            navigate('/admin/newsSearch?stage=search')
        }
    }, [location])

    return(
        <div className='admin-main-wrap mg'>
            <Loading loading={loading}/>
            <Header title={'기사'}/>
            <div className='admin-main'>
                <section className='admin-body mg' >
                    <LeftSideBar/>
                    <div className='admin-body-middle search-detail'>
                        {stage == 'search' &&
                            <SearchDetail 
                                section={section}
                                setSection={setSection}
                                reporterName={reporterName}
                                setReporterName={setReporterName}
                                period={period}
                                setPeriod={setPeriod}
                                word={word}
                                setWord={setWord}
                                sortType={sortType}
                                setSortType={setSortType}
                                train={train}
                                setTrain={setTrain}
                            />
                        }
                        {stage == 'list' &&
                            <SearchList list={list} total={total} setLoading={setLoading}/>
                        }

                    </div> 
                    <RightSideBar/>
                </section>
            </div>
        </div>
    )
}

export default NewsSearch