import React, { useState, useEffect } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
// import 'styles/common.scss';

function NvDatePicker({ defaultValue, onChange, unit='년월일', className, style }) {
    const [_value, _set_value] = useState('');

    const _inputFormat = () => {
        if(unit == '년월일') return 'yyyy-MM-dd'
        else if(unit == '년월일시') return 'yyyy-MM-dd HH시'
        else if(unit == '년월') return 'yyyy-MM'
    }

    const _mask = () => {
        if(unit == '년월일') return '____-__-__'
        else if(unit == '년월일시') return '____-__-__ __시'
        else if(unit == '년월') return '____-__'
    }

    useEffect(() => {
        _set_value(defaultValue)
        if(onChange) onChange(defaultValue)
    }, [defaultValue])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                className={className ? className  : ''}
                inputFormat={_inputFormat()}
                mask={_mask()}
                value={_value}
                onChange={(v) => {
                    if(v == null) v = ''
                    _set_value(v)
                    // console.log(v)
                    onChange(v)
                }}
                renderInput={(params) => <TextField {...params} className={className? className  + ' nv-datepicker': 'nv-datepicker'}  size="small" />}
            />
        </LocalizationProvider>
    );
}

export default NvDatePicker;