import React,{useState} from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

const PageModule = ({pageCount, onPageChange}) =>{
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page')
    const pageIndexRender = () =>{
        let arr =[]
        for(let i=(parseInt(page / 10) * 10); i < (parseInt((page+1) / 10) * 10); i++){
            if(i > pageCount - 1) break;
            arr.push(
                <li className='li'>
                    <a className={'a'+(page==i + 1?' current':'')} onClick={()=>onPageChange(i)}>{i + 1}</a>
                </li>
            )
        }
        return arr
    }
    return(
        <footer className='footer'>
            <div className='pagin-group'>
                <article className='pagin'>
                    <nav aria-label='Pagination'>
                        <ul className='ul'>
                            {page > 1 &&
                                <li className='li'>
                                    <a className='a' style={{letterSpacing:-3}} onClick={() => setSearchParams({'page': 1})}>
                                        &#60;&#60;
                                    </a>
                                </li>
                            }
                            {page > 1 &&
                                <li className='li'>
                                    <a className='a' style={{letterSpacing:-3}} onClick={() => setSearchParams({'page':  Number(page) - 1})}>
                                        &#60;
                                    </a>
                                </li>
                            }
                            {page > 1 &&
                                <li className='li onenext' onClick={() => setSearchParams({'page': Number(page) - 1})}>
                                    <a className='a'>
                                        &#60;
                                    </a>
                                </li>
                            }
                            {pageIndexRender()}
                            {page < pageCount &&
                                <li className='li onenext' onClick={() => setSearchParams({'page': Number(page) + 1})}>
                                    <a className='a'>
                                        &#62;
                                    </a>
                                </li>
                            }
                            {page < pageCount &&
                                <li className='li' onClick={() => setSearchParams({'page': Number(page) + 1})}>
                                    <a className='a'>
                                    &#62;
                                    </a>
                                </li>
                            }
                            {page < pageCount  &&
                                <li className='li' onClick={() => setSearchParams({'page': pageCount})}>
                                    <a className='a' style={{letterSpacing:-3}}>
                                    &#62;&#62;
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </article>
            </div>
        </footer>
    )
}

export default PageModule