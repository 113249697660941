
const NumberToKorean = (value, unit, display_unit = true, marketprice) => {
    let num = value
    if(num){
        num = String(num).replace(',', '')
        num = parseInt(num)
    }

    if(num >= 0 && num){
        if(unit){
            if (unit === '억' || unit === '주'){
                if (marketprice){
                    let num_str = ''
                    if (num >= 1000000000000) {
                        num_str += parseInt(num / 1000000000000) + ' 조 '
                        num = num % 1000000000000
                    }
                    if (num >= 100000000) {
                        num_str += parseInt(num / 100000000) + ' 억원'
                        num = num % 100000000
                    }
                    return num_str
                }
                num = parseFloat((num * 1).toFixed(2)).toLocaleString('ko-KR');
                if (display_unit) {
                    return num + unit;
                } else {
                    return num;
                }

                // return (num >= 10000)? parseInt(num / 10000) + '조 ' : '' + (num % 10000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (unit) ;
            } 
            else if (unit === '원') {
                let num_str = ''
                if (num >= 1000000000000) {
                    num_str += parseInt(num / 1000000000000) + ' 조 '
                    num = num % 1000000000000
                }
                if (num >= 100000000) {
                    num_str += parseInt(num / 100000000) + ' 억 '
                    num = num % 100000000
                }

                if (num >= 10000) {
                    num_str += parseInt(num / 10000) + ' 만원'
                    num = num % 10000
                }

                // if (num > 0) {
                //     num_str += parseInt(num)
                // }
                // num_str += '원'

                return num_str
            } else {
                return num.toLocaleString('ko-KR')
            }
        }
        else{
            return num;
        }
    }
    else{
        return '';
    }
}
export default NumberToKorean;