import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AspectRatio } from 'react-aspect-ratio';
import 'react-aspect-ratio/aspect-ratio.css'
const IssueCard = ({item}) => {
    const navigate=useNavigate();
    return(
        <li>
            <a href={'/detail?id='+(item.id)}>
                <div>
                    <AspectRatio ratio='220/122' className="img-wrap">
                        <img  className='issue-img'  src={item.image.all} />
                    </AspectRatio>
                    <div className='issue-text mn-extended'>
                        <div>{item.desc}</div>
                    </div>
                    <div className='issue-title'>{item.title}</div>
                    <div className='issue-dates'>{item.date}</div>
                    
                </div>
            </a>
        </li>
    )
}
export default IssueCard