import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import DateFormat_Year_Month_Date_Hour_Minute from 'utils/DateFormat_Year_Month_Date_Hour_Minute'
const CurrentInfo = ({list, statusList}) => { 
    const [nowMenu, setNowMenu] = useState(statusList[0].value)
    console.log(list)
    return(
        <div className='current-list'>
            <article className='list'>
                <ul>
                    {statusList.map(data => {
                        return(
                            <li className='li'>
                                <a aria-selected={nowMenu == data.value} onClick={()=>setNowMenu(data.value)}>
                                    <span className='text'>{data.label}</span>
                                    <em className='circlenum'>{list[data.value].length}</em>
                                </a>
                            </li>
                        )
                    })
                    }
                </ul>
                <div className='myarticle-list-wrap'>
                    <div aria-hidden={false} className='myarticle-list' >
                        <table className='table'>
                            <colgroup>
                            <col width='*'></col>
                            <col width='120'></col>
                            </colgroup>
                            <thead className='thead'>
                                <tr className='tr'>
                                    <th scope='col' className='th mg'>제목</th>
                                    <th scope='col' className='th tc mg'>작성일</th>
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                                {list[nowMenu].map(data => {
                                    return(
                                        <tr className='tr'>
                                            <th className='th' scope='col'>
                                                <Link to={'/member/newsview/' + data._id}>
                                                    <span 
                                                        className='span'
                                                    >
                                                        <span className='span-label'>
                                                            {statusList.find(e => e.value == nowMenu).label}
                                                        </span>
                                                    </span>
                                                    {data.title}
                                                    &nbsp;
                                                    <i> 
                                                        <span>이미지기사</span>
                                                    </i>
                                                </Link>
                                            </th>
                                            <td>
                                                {DateFormat_Year_Month_Date_Hour_Minute(data.updated_at)}
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default CurrentInfo