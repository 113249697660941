import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { useNavigate } from 'react-router-dom';

function handleClick(event) {
    event.preventDefault();
}
const Container = styled.div`
    height:40px;
    display:flex;
    align-items:center;
    background-color:white;
    padding-left: ${props => props.isPaddingLeft ? '205px' : '15px'};
    ol{
        padding-left: 0px !important;
    }
    .MuiLink-root{
        font-size: 12px !important;
    }
    .MuiTypography-root{
        font-size: 12px;
    }
`;

const BreadcrumbsHeader = ({list, investee_id, investee_name, writing_type, isPaddingLeft = false, containerStyle}) => {
    const navigate = useNavigate();
    const [currentLinks, setCurrentLinks] = useState([]);
    const links = {
        investee: {
            name: '비상장기업',
            href: ''
        },
        my:{
            name: '내정보 수정',
            href: ''
        },
        myList: {
            name: '내 목록',
            href: ''
        },
        discussion:{
            name: '블라인드 토론',
            href: writing_type
        },
        profileEidt: {
            name: '회원정보 수정',
            href: '/profile/edit'
        },
        investeeList: {
            name: '신주유치',
            href: '/company'
        },
        investeeInfo: {
            name: investee_name,
            href: '/company/' + investee_id    
        },
        ipo: {
            name: 'IPO',
            href: '/ipo'
        },
        memoWrite: {
            name: 'Report',
            href: '/company/memo/write/' + investee_id
        },
        memoList: {
            name: '관리일지',
            href: '/meeting?type=my'
        },
        investorMyEdit: {
            name: '내회사 수정',
            href: '/investor/my/edit'
        },
        myInvesteeList: {
            name: '관심기업',
            href: '/company'
        },
        
        myDiscussionList: {
            name: '내 토론',
            href: '/discussion/my'
        },
        discussionReview: {
            name: '투자자 후기',
            href: '/discussion/review'
        },
        discussionPost: {
            name: '스타트업 라운지',
            href: '/discussion/post'
        },
        find:{
            name: '파인더',
            href:''
        },
        findInvestee:{
            name: '스타트업 탐색',
            href:''
        },
        findInvestor:{
            name: '투자기관 탐색',
            href:'/investor/find'
        }
    }

    useEffect(() => {
        console.log('list' , list)
        if(list){
            console.log(list)
            let tmp = [];
            list.map((data, i) => {
                if(typeof(data) === 'string'){
                    tmp.push(links[data])
                }
                else{
                    tmp.push(data)
                }
            })
            setCurrentLinks(tmp);
        }
    },[list])



    return(
    <Container isPaddingLeft={isPaddingLeft} role="presentation" onClick={handleClick} style={containerStyle}>
      <Breadcrumbs separator="›"  aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/"
          onClick={() => {
            navigate('/')
          }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>
        {currentLinks.map((data, i) => {
            if(i != currentLinks.length - 1 ){
                if(data?.href || data?.onClick){
                    return(
                        <Link
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="#808080"
                            href={data.href}
                            onClick={() => {
                                if(data?.onClick){
                                    data.onClick();
                                }
                                else{
                                    navigate(data.href)
                                }
                            }}
                        >
                            {data.name}
                        </Link>
                    )
                }
                else{
                    return(
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                            href={data.href}
                        >
                            {data.name}
                        </Typography>
                    )
                }
            }
            else{
                
                return(
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        {data.name}
                    </Typography>
                )
            }
        })
        }
      </Breadcrumbs>
    </Container>
    )
}

export default BreadcrumbsHeader;