import React from 'react';

const Pannels = ({list, statusList}) =>{
    return(
        <article className='pannels'>
            {statusList.map(data => {
                return(
                    <div className='pannel'>
                        <a className={'box ' + data.value}>
                            <em className='em'>{list[data.value]?.length}</em>
                            <span className='span'>{data.label}</span>
                        </a>
                    </div>
                )
            })
            }   

{/* 
            <div className='pannel'>
                <a className='box apply'>
                    <em className='em'>{applyList.length}</em>
                    <span className='span'>승인신청</span>
                </a>
            </div>
            <div className='pannel'>
                <a className='box return'>
                    <em className='em'>{rejectList.length}</em>
                    <span className='span'>반려</span>
                </a>
                <a className='box pass'>
                    <em className='em'>{approveList.length}</em>
                    <span className='span'>승인</span>
                </a>
            </div> */}
        </article>
    )
}

export default Pannels