import React, { useEffect, useState } from 'react';
import 'animate.css';
import Badges from './Badges'
import NvAutocomplete from 'components/NvAutoComplete';
import axios from 'axios';
import url from 'utils/backend';

const InputBadge = ({type, state, setState}) => {
    const [list, setList] = useState('');
    const [tmp, setTmp] = useState('');
    const handleEnter = (v) => {
        if(v && state.indexOf(v) == -1){
            state.push(v);
            setTmp('');
            setState([...state]);
        }
    }
    const handleRemove = (i) => {
        state.splice(i, 1);
        setState([...state]);
    }
    const getCategoryList = async() => {
        try{
            let uri = '';
            if(type == 'industry'){
                uri = 'business';
            }
            else if(type == 'technology'){
                uri = 'technology'
            }
            axios.get(url + '/category/' + uri)
            .then((res) => {
                setList(res.data.category);
            })
        }
        catch(e){
          console.log(e)
        }
    }
    useEffect(() => {
        setTimeout(() => {
            getCategoryList();
        }, 500)
    },[])
    return(
        list &&
        <div className="rc-sb">
            <div className="input-box-wrap" style={{width: '30%', height: 40, backgroundColor: 'white'}} >
            <NvAutocomplete  options={list} className='input-box' defaultValue={tmp} style={{minHeight: 40}} onChange={(v) => setTmp(v)} onEnter={(v) => handleEnter(v)}/>
            </div>
            <Badges arr={state} handleRemove={handleRemove} style={{minHeight: 40,backgroundColor: 'transparent'}}/>
        </div>
    )
}
export default InputBadge;