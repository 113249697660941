import React from 'react';
import IssueCard from './IssueCard';
import IconTopic from './IconTopic'
const Section = ({list, header}) =>{

    return(
        <section className='main-investee-flex-container-wrap'>
            {header && <IconTopic header={header} />}
            <ul>            
                {list.map((data, i) => {
                    return(
                        <IssueCard item={data}/>
                    )
                })
                    }        
                
            </ul>
        </section>
    )
}

export default Section