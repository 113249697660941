import React, { useEffect, useState } from 'react';
import 'animate.css';
import Badges from './Badges'
import AutoComplete from 'components/AutoComplete';
import axios from 'axios';
import url from 'utils/backend';
import AlertConfirm2 from 'components/AlertConfirm2';
import styled from 'styled-components';

const BadgeBox = styled.div`
    width: 100%;
    height: 40px;
    background-color: white;
    margin-top: 15px;
    fieldset{
        border: 0px;
    }
`;

const InputBadge2 = ({type, state, setState}) => {
    const [list, setList] = useState([]);
    const [tmp, setTmp] = useState('');
    const handleEnter = (v) => {
        if(state.length >= 5){
            AlertConfirm2({
                title: '최대 5개까지 선택이 가능합니다.'
            })
        }
        else if(v && state.indexOf(v) == -1 && state.length < 5){
            state.push(v);
            setTmp('');
            setState([...state]);
        }
    }
    const handleRemove = (i) => {
        state.splice(i, 1);
        setState([...state]);
    }
    const getCategoryList = async() => {
        try{
            let uri = '';
            if(type == 'industry'){
                uri = 'business';
            }
            else if(type == 'technology'){
                uri = 'technology'
            }
            axios.get(url + '/category/' + uri)
            .then((res) => {
                setList(res.data.category);
            })
        }
        catch(e){
          console.log(e)
        }
    }
    const renderOption = (props, data) => {
        return(
            <span {...props} style={(state.includes(data) ? {borderColor: 'blue'} : null)} onClick={() => handleEnter(data)}>
                {data}
            </span>
        )
    }

    useEffect(() => {
        setTimeout(() => {
            getCategoryList();
        }, 0)
    },[])
    return(
        <div className="badge-container">
            {(state.length >= 1) &&
                <Badges arr={state} handleRemove={handleRemove} style={{minHeight: 40,backgroundColor: 'transparent', width: '100%'}}/>
            }
            <BadgeBox className="input-box-wrap ">
                <AutoComplete isWide={true} classes={{listbox: 'profile-badge-listbox', popper: 'profile-badge-popper', option: 'nv-badge profile-badge-option', focused: 'profile-badge-option-focus'}}  options={list} className='input-box' defaultValue={tmp} style={{minHeight: 40}} renderOption={renderOption} onChange={(v) => setTmp(v)} onEnter={(v) => handleEnter(v)}/>
            </BadgeBox>
        </div>
    )
}
export default InputBadge2;