import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

const IdPw = ({setInfoState}) =>{
const navigate = useNavigate();
  const [inputId, setInputId] = useState('')
  const [inputPw, setInputPw] = useState('')
  const [inputRm, setInputRm] = useState('')
  const onForgotHandler = () => {
    alert('문의하세여')
  }
  // const checkProfile = async() => {
  //   const res = await axios.get(url + '/user/company');
  //   if(res.data.company == null){
  //     navigate('/register')
  //   }
  //   else{
  //     navigate('/')
  //   }
  // }
  const _onKeyDown = (e) => {
    // console.log(e)
    if (e.keyCode == 13 ) onSubmitHandler()
    // if(onChange) onChange(e.target.value)
    // if(onChange) console.log(e.target.value + e.code)
  }
  const onSubmitHandler = async () => {
    // console.log('Email', inputId);
    // console.log('Password', inputPw);
    if (input == "") {
      if(type=='email'){
        alert('email을 입력해주세요.')
        document.location.href = '/'
      } 
      else if (type=='password') {
        alert('Password를 입력해주세요.')
        document.location.href = '/'
      }
  }
}
    const [type, setType] = useState('email')
    const [wrong, setWrong] = useState(false)
    const [wrongPw, setWrongPw] = useState(false)
    const [input, setInput] = useState('')
    const[checkPw, setCheckPw] = useState('')
    const[checkPwState, setCheckPwState] = useState(false)
    const [btnState, setBtnState] = useState(true)
    const checkBtnState = (id) =>{
      if(id==''){
          setWrong(true);
          setBtnState(true)
        }
      else{
          setWrong(false)
          setBtnState(false)
      }
    }
    const checkBtnStatePw = (id) =>{
      if(id==''){
          setWrongPw(true);
          setBtnState(true)
        }
      else{
          setWrongPw(false)
          setBtnState(false)
      }
    }
    const handleInput = (e) => {
      setInput(e.currentTarget.value)
      checkBtnState(e.currentTarget.value)
    }
    const handleInputPw = (e)=>{
      setCheckPw(e.currentTarget.value)
      checkBtnStatePw(e.currentTarget.value)
    }
    return(
        <form className='form'>
            <div style={{paddingBottom:15}}>
                <div className='div mn'>
                    <label className='label mn-extended'>{type=='email'?'Email address':'Password'}</label>
                    <sup className='sup'>*</sup>
                </div>
                <div className='input-container mn'>
                    <input 
                    className={'input '+type} 
                    type={type}
                    autocomplete={type=='email' ?'username':'current-password'}
                    placeholder={type=='email' ?'e.g. example@pitchdeck.com':'Enter password'}
                    aria-invalid={wrong}
                    onKeyDown={_onKeyDown}
                    name={type} 
                    value={input} 
                    onChange={handleInput}
                    />
                </div>
            </div>
            {checkPwState && 
            <div style={{paddingBottom:15}}>
            <div className='div mn'>
                <label className='label mn-extended'>Check Password</label>
                <sup className='sup'>*</sup>
            </div>
            <div className='input-container mn'>
                <input 
                className={'input password'} 
                type='password'
                autocomplete='current-password'
                placeholder='Enter Password Again'
                aria-invalid={wrongPw}
                onKeyDown={_onKeyDown}
                name='password' 
                value={checkPw} 
                onChange={handleInputPw}
                />
            </div>
            </div>}
            <div className='btn-box'>
            <button 
            className='btn' 
            disabled={btnState} 
            type='submit' 
            onClick={()=>{
                setBtnState(true); 
                if(type=='email'){
                setType('password');
                setInputId(input)
                setInput('')
                }
                else if(type=='password'){
                setCheckPwState(true)
                }
                if(checkPwState){
                if(input==checkPw){
                    setInputPw(checkPw)
                    setInfoState(false)
                }
                else{
                    setWrong(true)
                    setWrongPw(true)
                    alert('비밀번호가 일치하지 않습니다.')
                }
                }
                }}>
                <span className='span-btn'>
                <span>Continue</span>
                </span>
            </button>
            </div>
            {type=='email' && <p className='p mn'>
            By providing your email, you agree to the
            <a> Pitchdeck Privacy Policy</a>
            . We'll use your email to provide complimentary access to the
            <a> Pitchdeck Daily Brief </a>
            along with other updates and promotions. This site is protected by reCAPTCHA and the Google
            <a> Privacy Policy </a>
            and
            <a> Terms of Service </a>
            apply.
            </p>}
        </form>
    )
}

export default IdPw