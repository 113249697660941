import React, { useState, useEffect} from 'react';

import { useNavigate } from 'react-router-dom';
import './style.scss'
import NavBar from 'components/navbar/NavBar';
import InvestorCard from './InvestorCard'
// import { arc } from "d3-shape"
// import { scaleLinear } from "d3-scale"
// import { ArcGauge } from "@progress/kendo-react-gauges";
import RankCard from 'components/RankCard';
import axios from 'axios';
import url from 'utils/backend';
import bg from 'assets/images/research_bg.png';
import  'styles/carousel.scss'
import Carousel from 'utils/carousel';

import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavBarBtm from 'components/navbar/NavBarBtm'
const colors = [
    {
      to: 25,
      color: "#0058e9",
    },
    {
      from: 25,
      to: 50,
      color: "#37b400",
    },
    {
      from: 50,
      to: 75,
      color: "#ffc000",
    },
    {
      from: 75,
      color: "#f31700",
    },
  ];
  const stages = [
    '프리시리즈 A',
    '시리즈 A',
    '시드투자',
    '엔젤투자',
    '시리즈 B'
]
const techologies = [
    '빅데이터',
    '머신러닝',
    '딥러닝',
    '사물지능(IOT)',
    '가상현실(VR)'
];
const investments = [
    '메타버스',
    '플랫폼/SaaS/서비스',
    '콘텐츠제작/관리',
    '의약품/의료/진단',
    '핀테크',
];

 
const Find = () => {
    const navigate = useNavigate();
    const [recommends, setRecommeds] = useState([]);
    const [rankSector, setRankSector] = useState([]);
    const [rankStage, setRankStage] = useState([]);
    const [rankTech, setRankTech] = useState([]);
    const getRecommedList = async() => {
        try{
            const res = await axios.get(url + '/investor/recommend');
            setRecommeds(res.data.investors)
        }
        catch(e){
            console.log(e)
        }
    }
    const getRankList = async() => {
        try{
            axios.get(url + '/investment/ranking/business')
            .then((res) => {
                setRankSector(res.data.ranking)
            })
           axios.get(url + '/investment/ranking/stage')
           .then((res) => {
                setRankStage(res.data.ranking)
           })
           axios.get(url + '/investment/ranking/technology')
           .then((res) => {
            setRankTech(res.data.ranking)
           })
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        getRecommedList()
        getRankList()
        setTimeout(() => {
            const galleryContainer = document.querySelector('.gallery-container');
            const galleryControlsContainer = document.querySelector('.gallery-controls');
            const galleryControls = ['previous', 'add', 'next'];
            const galleryItems = document.querySelectorAll('.gallery-item');
            const exampleCarousel = new Carousel(galleryContainer, galleryItems, galleryControls, galleryControlsContainer);
            // exampleCarousel.setControls();
            exampleCarousel.useControls();
            setTimeout(() => {
                exampleCarousel.setCurrentState({className: 'next'})
                
            }, 300);
            
        }, 1000);
    }, [])
    
    return(
        <div className='main-body-wrap' style={{overflowX: 'hidden'}}>
            <NavBar type={'investee'} backgroundColor={'#f9f9f9'}/>
            {/* <BreadcrumbsHeader list={['find', 'findInvestor']} isPaddingLeft/> */}
            <div className='investment-find-banner' style={{
                // background: 'url('+bg+')'
                backgroundColor:'#F9B761'
                }}>
            <div className='title'>최적의 투자자 • 파트너 탐색</div>
            <div className='desc'>AI 모델을 적용하여 투자를 유치하고자 하는 스타트업에게 최적화*  맞춤형 투자자 탐색</div>
            <div className='find-btn'>
                NEXT
            </div>
            </div>
            <div className='main-content-wrap' style={{paddingTop: 0}}>


                <div className='investment-find-investor-wrap'>
                    {/* {recommends.map((data,i) => {
                        if(i == 0)
                        return(
                            <InvestorCard item={data}/>
                        )
                    })
                    } */}
            <div className="gallery-controls">
                <button className='gallery-controls-previous'>
                    <ArrowBackIosIcon />
                </button>


                <button className='gallery-controls-next'>
                    <ArrowForwardIosIcon />
                </button>
            </div>
                    <div className="gallery">
                        <div className="gallery-container">
                            {recommends.map((data,i) => {
                                return(
                                    <InvestorCard item={data} i={i}/>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {/* <div className='main-investee-wrap' style={{marginTop: 20}}>
                    <div className='main-investee-flex-container'>
                        <RankCard title={'투자분야별'} img={require('assets/images/match_trend.png')} arr={rankSector}/>
                        <RankCard title={'투자단계별'} img={require('assets/images/match_stage.png')} arr={rankStage}/>
                        <RankCard title={'주요기술별'} img={require('assets/images/match_tech.png')} arr={rankTech}/>
                    </div>
                </div> */}
            </div>

        </div>
    )
}

export default Find;