import React from 'react'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {ReactComponent as Kakaotalk} from 'assets/images/kakaotalk.svg'
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const NvToggle = ({btmType}) => {
    return(
        <div aria-hidden={btmType!='Menu'}>
            <div className={btmType!='Menu'?'nv-toggle':'nv-toggle nv-toggle-changed'}>
                <div className='toggle'>
                    <p className='p mn-extended'>Discover</p>
                    <ul className='ul'>
                        <li className='li'>
                            <a href='/'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Main</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        <li className='li'>
                            <a href='/latest'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Latest</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        {/* <li className='li'>
                            <a href='/trending'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Trending</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        <li className='li'>
                            <a href='/topics'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Topics</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li> */}
                        <li className='li'>
                            <a href='/startup'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Start-Up</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        <li className='li'>
                            <a href='/investor'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Investor</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='toggle'>
                    <p className='p mn-extended'>Editions</p>
                    <ul className='ul'>
                        <li className='li'>
                            <a href='/member/newsroom'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>News-Room</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        <li className='li'>
                            <a href='/member/newswrite'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>News-Write</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        <li className='li'>
                            <a href='/member/newslist'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>News-List</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        {/* <li className='li'>
                            <a href='/member/newsreply'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>News-Reply</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li> */}
                    </ul>
                </div>
                <div className='toggle'>
                    <p className='p mn-extended'>More from Pitchdeck</p>
                    <ul className='ul'>
                        <li className='li'>
                            <a href='/investor/find'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Finder</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                        <li className='li'>
                            <a href='/discussion'>
                            <div className='txt-box'>
                                <div className='txt-title-box'>
                                <div className='txt-title mn'>Blind Discussion</div>
                                </div>
                                <KeyboardArrowRightOutlinedIcon className='svg'/>
                            </div>
                            </a>
                        </li>
                    </ul>
                </div>
                    
                    {/* <div className='toggle'>
                        <p className='p mn-extended'>Follow Pitchdeck</p>
                        <ul className='icon-list'>
                            <li className='list'>
                                <a>
                                    <div style={{height:33, marginBottom:8}}>
                                        <Kakaotalk className='svg-style'/>
                                    </div>
                                    <div className='icon-name mn'>Kakaotalk</div>
                                </a>
                            </li>
                            <li className='list'>
                                <a>
                                    <div style={{height:33, marginBottom:8}}>
                                        <TwitterIcon className='svg-style'/>
                                    </div>
                                    <div className='icon-name mn'>Twitter</div>
                                </a>
                            </li>
                            <li className='list'>
                                <a>
                                    <div style={{height:33, marginBottom:8}}>
                                        <InstagramIcon className='svg-style'/>
                                    </div>
                                    <div className='icon-name mn'>Instagram</div>
                                </a>
                            </li>
                            <li className='list'>
                                <a>
                                    <div style={{height:33, marginBottom:8}}>
                                        <YouTubeIcon className='svg-style'/>
                                    </div>
                                    <div className='icon-name mn'>Youtube</div>
                                </a>
                            </li>
                            <li className='list'>
                                <a>
                                    <div style={{height:33, marginBottom:8}}>
                                        <LinkedInIcon className='svg-style'/>
                                    </div>
                                    <div className='icon-name mn'>LinkedIn</div>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                           
                </div>
            <div className={(btmType!='Menu')? 'nv-after': 'nv-after nv-after-changed'}></div>
        </div>
    )
}

export default NvToggle