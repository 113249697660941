import React, { useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import Auth from "utils/auth";

const CheckAuth = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!Auth.isLoggedIn()) {
      navigate("/login");
    } 
    else {
      Auth.isProfile().then((res) => {
        if(!res) {
          // navigate("/profile/create");
        }
      });
    }
  }, [window.location]);
};

export default CheckAuth;
