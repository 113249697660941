import React,{useState, useCallback} from 'react';
import SliderBox from 'components/filter/SliderBox';
import { useSelector, useDispatch } from 'react-redux'
import {update} from 'store/filter/customFilter';
import CheckBox from 'components/filter/CheckBox'
import { Section } from 'pages/combination/combination-info/component/Filter';
import TitleBox from 'components/filter/TitleBox';
import InputBox from 'components/filter/InputBox';
import NvTextInput from 'components/NvTextInput';
import SubTitle from 'components/filter/SubTitle';
import _ from 'lodash'
import InputRangeBox  from 'components/filter/InputRangeBox';
const today = new Date();
const currentYear = today.getFullYear();
const portfolioTypeList = [
    {label: 'Memo', value: 'memo'},
    {label: 'Sorsing Report', value: 'sourcing'},
    {label: 'IR REPORT', value: 'support'},
    {label: 'Pre - Invest Report', value: 'investee'},
]
const managerList = [
    {label: '소싱역', value: 'sourcing'},
    {label: '지원역', value: 'support'},
    {label: '심사역', value: 'investee'},
]
const publishDateList = [
    {label: '전체', value: ''},
    {label: '최근 3개월', value: '3'},
    {label: '최근 6개월', value: '6'},
    {label: '최근 1년', value: '12'},
    {label: '사용자지정', value: 'custom'},
]
const statusList = ['신규등록', '관리', '재검토일 지정']
const statusNewList = [
    {label: '제외', value: '제외'},
    {label: '유치완료', value: '유치완료'},
]
const statusManageList = [
    {label: '자료 검토중', value: '자료 검토중'},
    {label: '투자 검토중', value: '투자 검토중'},
]

const CustomFilter = ({customFilter}) => {
    const dispatch = useDispatch();
    const [nowStatus, setNowStatus] = useState(statusList[0]);
    const updateFilter = (v) => {
        dispatch(update(v))
    }
    const updateId = useCallback(_.debounce((v) => {
        let items = _.cloneDeep(customFilter);
        items.manager_id = v;
        updateFilter(items)
    }, 300), [customFilter])
    return(
        <div>
            <Section>
                <CheckBox list={portfolioTypeList} cnt={2} state={customFilter} setState={updateFilter} obj='portfolio_type'/>
            </Section>
            <Section>
                <TitleBox title='관리자'>
                    <CheckBox list={managerList} type='radio'  state={customFilter} setState={updateFilter} obj='manager_type' containerStyle={{paddingLeft: 5}}/>
                    <InputBox>
                        <NvTextInput defaultValue={customFilter.manager_id} onChange={(v) => updateId(v)}/>
                    </InputBox>
                </TitleBox>
            </Section>
            <Section>
                <TitleBox title='작성일'>
                    <CheckBox list={publishDateList} cnt={2} type='radio' state={customFilter} setState={updateFilter} obj='publish_date' />
                    {customFilter.publish_date == 'custom' &&
                            <InputRangeBox unit='년월' state={customFilter} obj='publish_date_custom' setState={updateFilter}/>
                    }
                </TitleBox>
            </Section>
            <Section>
                <TitleBox title='Status'>
                    <SubTitle list={statusList} state={nowStatus} setState={setNowStatus} />
                    {nowStatus == '신규등록' &&
                        <>
                        <CheckBox list={statusNewList} cnt={1}  state={customFilter} setState={updateFilter} obj='new_type'/>
                        </>
                    }
                    {nowStatus == '관리' &&
                        <>
                        <CheckBox list={statusManageList} cnt={1}  state={customFilter} setState={updateFilter} obj='manage_type'/>
                        </>
                    }
                    {nowStatus == '재검토일 지정' &&
                        <>
                        <InputRangeBox unit='년월' state={customFilter} obj='review_date' setState={updateFilter}/>
                        </>
                    }
                </TitleBox>
            </Section>

        </div>
    )
}
export default React.memo(CustomFilter);
