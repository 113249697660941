import React, {
    useCallback,
    useRef,
    useState,
    useEffect
} from "react";
import Dropzone from 'react-dropzone'
import axios from 'axios';
import url from "utils/backend";
import { ToastContainer, toast } from 'react-toastify';
import { useParams,useLocation } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import generateId from 'utils/GenerateId'
import { useMutation, useQuery } from '@tanstack/react-query'
import AlertToast from 'components/AlertToast'
import {useSelector} from 'react-redux'
window.Buffer = window.Buffer || require("buffer").Buffer;

const FileCol = ({files = [], setFiles, isCompany = false}) => {
    const {id} = useParams()
    const handleDownload = async(data) => {
        if(data?._id){
            const res = await axios.get(url + '/file/' + data?._id);    
            const extension = res.data.name.split('.');
            if(extension[extension.length - 1] == 'pdf'){
              var newWindo = window.open(res.data.file, 'eee')
            }
            else{
              fetch(res.data.file, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/pdf',
                },
              })
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(
                  new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  res.data.name,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              });
            }
        }
        else{
            const url = window.URL.createObjectURL(
                new Blob([data.docs]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                data.name,
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
        }
    }

    const check_hash = async (file) => {
        return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.onload = function (ev) {
            crypto.subtle.digest('SHA-256', ev.target.result).then(hashBuffer => {
              const hashArray = Array.from(new Uint8Array(hashBuffer));
              var hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
              resolve(hashHex);
            }).catch(ex => console.error(ex));
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file)
        })
    }
    const showDupToast = () => {
        AlertToast({
          text: '중복된 파일이 있어 자동으로 정리했습니다.',
          toastId: 'combination_file_dup'
        });
      }
    const onChangeFiles = useCallback(
        async(items) => {
          let tempFiles = files;
          let isDup;
          for (const file of items) {
            isDup = false;
            const hash = await check_hash(file);
            tempFiles.map((item, i) => {
              if(item.checksum == hash){
                isDup = true;
              }
            })
            if(!isDup){
              tempFiles = [
                ...tempFiles,(isCompany?{
                      id: generateId(),
                    company_id: id,
                    docs: file,
                    checksum : hash,
                    type: '',
                    name: file.name,
                  }
                  :
                  {
                    id: generateId(),
                    combination_id: id,
                    docs: file,
                    checksum : hash,
                    type: '',
                    name: file.name,
                  }
                )
                
              ];
            }
          }
          if(isDup){
            showDupToast()
          }
          setFiles(tempFiles);
        },
    [files, id]);
    
    const getFiles = async () => {
        try {
          if(isCompany){
            const res = await axios.get(url + '/file/combination/company/' + id)
            return res.data.files
          }
          else{
            const res = await axios.get(url + '/file/combination/' + id);
            return res.data.files
          }
        }
        catch (e) {
    
        }
    }
    const {data: fileList = []} = useQuery({
        queryKey: ['combination_file_list', id],
        queryFn: getFiles,
        onSuccess: (data) => {
            if(setFiles){
                setFiles(data);
            }
        },
        refetchOnWindowFocus: false
        
    })
    const handleRemove = async(id) => {
        setFiles(prev => {
            let findIndex = prev.findIndex(e => e.id == id);
            prev[findIndex].remove = true;
            return [...prev]
        })
    }
    useEffect(()=>{
      console.log(files)
    },[files])
    return(
        <>
        <div className="combination-file-container">
            {setFiles ?
            <>
            <Dropzone
                onDrop={acceptedFiles => onChangeFiles(acceptedFiles)}
                multiple={true}
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <label className={'combination-file-box'}  >
                            <div className="combination-file-box__text">
                                <AttachFileIcon className="file-clip-icon"/> 클릭 및 드래그하여 파일 업로드
                            </div>
                        </label>
                        <input {...getInputProps()} />
                    </div>
                )}
        
            </Dropzone>
            <div className="combination-file-list">
                {files.filter(e => !e?.remove).map(data => {
                    return(
                        <span key={data.id}>
                            <AttachFileIcon className="file-clip-icon"/> <span className="file-download" onClick={() => handleDownload(data)}>{data.name}</span> <CloseIcon className="file-remove-icon" onClick={() => handleRemove(data.id)} />
                        </span>
                    )
                })
                }
            </div>
            </>
            :
            <>
            <div className="combination-file-list" style={{paddingLeft: 0}}>
                {fileList.map(data => {
                    return(
                        <span key={data.id}>
                            <AttachFileIcon className="file-clip-icon"/> <span className="file-download" onClick={() => handleDownload(data)}>{data.name}</span>
                        </span>
                    )
                })
                }
            </div>
            </>
            }
        </div>
        </>
    )
}
export default FileCol