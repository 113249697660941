import React from 'react'

const Form = () =>{
    return(
        <form className='form'>
            <div style={{marginBottom:24}}>
            <label style={{position:'relative'}}>
                <div style={{marginBottom:8}}>
                <div className='label-title'>회사명</div>
                <p className='label-desc'>피치덱</p>
                </div>
            </label>
            </div>
            <div style={{marginBottom:24}}>
            <label style={{position:'relative'}}>
                <div style={{marginBottom:8}}>
                <div className='label-title'>사업자번호</div>
                <p className='label-desc'>000-00-00000 </p>
                </div>
            </label>
            </div>
            <div>
            </div>
        </form>
    )
}

export default Form