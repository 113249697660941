import { createSlice } from '@reduxjs/toolkit'

const memberFilter = {
  employee: '',
  rate: ''
}
export const memberFilterSlice = createSlice({
    name: 'memberFilter',
    initialState: memberFilter,
    reducers: {
      update: (state, action) => {
        const {payload} = action;
        return payload
      },
      reset: (state) => {
        return memberFilter
      }
    },
})

export const { update , reset} = memberFilterSlice.actions

export default memberFilterSlice.reducer