import React from 'react';
import InputMask from "react-input-mask";

const InputBusinessNumber = ({state, setState}) => {
    const onChangeBusinessNumber = (event) => {
      var value = event.target.value;
      var newState = {
        mask: '999-99-99999',
        value: value
      };
      if (/^3[47]/.test(value)) {
        newState.mask = '999-99-99999';
      }
      setState(newState.value);
    }
    return(
      <InputMask style={{border:0}} defaultValue={state} className='text_input' mask="999-99-99999" onChange={onChangeBusinessNumber}/>
    )
}
export default InputBusinessNumber