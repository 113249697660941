import React, {useState, useRef, useEffect} from 'react';
import NavBar from 'components/navbar/NavBar';
import './style.scss';
import NvTextInput from 'components/NvTextInput';
import Tooltip from "@material-ui/core/Tooltip";
import axios from 'axios';
import url from 'utils/backend';
import { useNavigate } from 'react-router-dom';
import AutoSearchInput from 'components/AutoSearchInput'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import NavBarBtm from 'components/navbar/NavBarBtm'
const InvestorFind = () => {
    const navigate = useNavigate();
    const nameRef = useRef();
    const [tooltipTopic, setTooltipTopic] = useState([false]);
    const [name, setName] = useState('');
    const [list, setList] = useState([]);
    const [topic, setTopic] = useState(['']);
    const [recents, setRecents] = useState([]);

    const addTopic = () => {
        if(topic.length < 2){
            setTopic([...topic, ''])
            setTooltipTopic([...tooltipTopic, false])
        }
    }
    const onChange = (v, i) => {
        let tmp = topic;
        tmp[i] = v;
        setTopic([...tmp]);
    }
    const checkInputEmpty = () => {
        let stop = false;
        topic.filter((data, i) => {
            if(stop) return false
            if(!data){
                stop = true;
                let tmp = tooltipTopic;
                tmp[i] = true;
                setTooltipTopic([...tmp])
                setTimeout(() => {
                    let tmp = tooltipTopic;
                    tmp[i] = false;
                    setTooltipTopic([...tmp])
                }, 3000);
            }
            else{
                let tmp = tooltipTopic;
                tmp[i] = false;
                setTooltipTopic([...tmp])
            }
        })
    }
    
    const getInvestorList = async () => {
        try {
            const res = await axios.get(url + `/investor/autocomplete?name=${name}`);
          // let search = `?query=${name}&page=${page}`
          // if(location.search != search) {
          //   window.history.pushState('', 'Company List', `/companies?query=${name}&page=${page}`)
          // }
          // _page(page)
          setList(res.data.investors);
        }
        catch (e) {
          console.log(e);
        }
    }
    const handleNavigate = (data) =>{
        saveRecent(data.investor_id, data.investor_name)
        navigate('/investor/' + data.investor_id);
    } 
    const deleteRecent = (id) => {
        let nowList = recents;
        console.log(id, nowList)
        nowList = nowList.filter((item, i) => item._id != id);
        window.localStorage.setItem("investorRecents", JSON.stringify(nowList));
        setRecents(JSON.parse(JSON.stringify(nowList)))
    }
    const saveRecent = (id, name) => {
        let nowList = recents;
        let isDup = false;
        nowList.filter((item, i) => {
            if(item._id == id) isDup = true;
        });
        if(!isDup){
            nowList.unshift({ name: name, _id: id});
            if(nowList.length > 10){
                nowList = nowList.slice(0, 10);
            }
            window.localStorage.setItem("investorRecents", JSON.stringify(nowList));
        }
    };

    const getRecent = () => {
        return window.localStorage.getItem('investorRecents');
    };
    const renderOption = (props, data) => {
        return(
            <span {...props} style={{display: 'flex', alignItems:'center', width: '100%', justifyContent: 'space-between'}} onClick={() => handleNavigate(data)}>
                <div className='company-find-search-left-box'>
                    <img className='company-find-search-logo' style={{width: 30}} src={data.investor_logo }/>
                    {data.investor_name}
                </div>
                <div className='company-find-search-btn center'>이동하기</div>
            </span>
        )
    }
    
    useEffect(() => {
        if(name){
            getInvestorList()
        }
        else{
            setList([])
        }
        const recentList = JSON.parse(getRecent());
        if(recentList){
            setRecents(recentList)

        }
    },[name])
    return(
        <div className='main-body-wrap'>
            <NavBar backgroundColor={'#f9f9f9'}/>
            {/* <BreadcrumbsHeader list={['find', 'findInvestor']} isPaddingLeft/> */}
            <div className='company-find-wrap'>
                <div className='company-find-body-wrap'>
                    <div className='company-find-content-container'>
                        <div className='company-find-title'>Finder</div>
                        <div className='company-find-desc'>펀드결성, 투자총액, 투자활동, 투자분야 또는 투자자 공간에 누가 있는지 단순히<br/>이해 하려는 경우 피치덱은 스타트업에게 신뢰할 수 있는 데이터를 제공합니다</div>
                        <div  className='company-find-search-box' ref={nameRef}>
                            {/* <NvTextInput onEnter={checkInputEmpty}  state={name} onChange={(v) => setName(v)} placeholder={'투자자명을 입력해주세요'} className='company-find-search'/> */}
                            <AutoSearchInput type='investor' renderOption={renderOption}   state={name} onChange={(v) => handleNavigate(v)} placeholder={'투자자명을 입력해주세요'} className='company-find-search' isNavigate={false} />
                        </div>
                        {/* {(list.length >= 1) &&
                            <div className='company-find-search-list' style={{top: nameRef.current.offsetTop + 40, width: nameRef.current.clientWidth}}>
                                {list.map((data, i) => {
                                    return(
                                        <div className='rc-sb company-find-search-box' onClick={() => handleNavigate(data.investor_id, data.investor_name)}>
                                            <div className='company-find-search-left-box'>
                                                <img className='company-find-search-logo' style={{width: 30}} src={data.investor_logo}/>
                                                {data.investor_name}
                                            </div>
                                            <div className='company-find-search-btn center'>이동하기</div>
                                        </div>
                                    )
                                })}
                            </div>
                        } */}
                    </div>
                    <div className='company-find-content-container'>
                        <div className='company-find-title'>Matching Reseacher</div>
                        <div className='company-find-desc'>AI 알고리즘을 통해 투자를 유치하고자 하는 스타트업에게 최적의 파트너가 될 투자자를 찾아드립니다</div>
                        <div className='company-find-trendy-btn' onClick={() => navigate('/investor/match')}>
                            START
                        </div>
                    </div>
                </div>
                <div className='company-find-body-wrap' style={{marginTop: 15}}>
                    <div className='company-find-content-container'>
                        <div className='company-find-title' style={{marginBottom: 0}}>최근 검색</div>
                    </div>
                    <div className='company-find-content-container'>
                        {recents.length >= 1 && 
                            recents.map((data, i) => {
                            return(
                                    <div className='rc-sb company-find-recent-box' >
                                        <div className='company-find-recent-text center'>{data.name}</div>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <IconButton style={{color: 'white'}} onClick={() => deleteRecent(data._id)}>
                                                <CloseIcon/>
                                            </IconButton>
                                            <div className='company-find-recent-btn center' onClick={() => navigate('/investor/' + data._id)}>이동하기</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <NavBarBtm type={'Find'}/>
        </div>
    )
}
export default InvestorFind;
