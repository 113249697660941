import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const Form = () =>{
    const navigate = useNavigate()
    return(
        <form className='form-profile'>
            <div style={{marginBottom:24}}>
            <label style={{position:'relative'}}>
                <div style={{marginBottom:8}}>
                <div className='label-title'>회사명</div>
                <p className='label-desc'>회사명을 입력해 주세요. ex. (주)피치덱 -> 피치덱</p>
                </div>
                <input 
                aria-invalid={false} 
                autocomplete='given-name' 
                className='input mn'
                placeholder='Enter first name'
                type='text'
                />
            </label>
            </div>
            <div style={{marginBottom:24}}>
            <label style={{position:'relative'}}>
                <div style={{marginBottom:8}}>
                <div className='label-title'>사업자번호</div>
                <p className='label-desc'>000-00-00000 (법인의 사업자번호를 입력해 주세요.)</p>
                </div>
                <input 
                aria-invalid={false} 
                autocomplete='organization' 
                className='input mn'
                placeholder='Enter Business Number'
                type='text'
                />
            </label>
            </div>
            <div>
            <button className='btn' type='submit' onClick={()=>navigate('/')}>
                <span className='span-btn'>
                <span>Submit</span>
                </span>
            </button>
            </div>
        </form>
    )
}

export default Form