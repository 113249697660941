import React, {useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import '../style.scss'
import { useRecoilState} from 'recoil'
import {btmTypeState} from 'utils/state'
import CheckAuth from 'hooks/CheckAuth';
import Auth from 'utils/auth';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import url from 'utils/backend'
const Header = ({title, backgroundColor}) =>{
    CheckAuth()
    const location = useLocation()
    const [isHover, setIsHover] =useState(false)
    const [hidden, setHidden] = useState(true)
    const navigate = useNavigate()
    const [btmType, setBtmType] = useRecoilState(btmTypeState)  
    const handleLogout = () => {
        Auth.logout()
        navigate('/login', {replace: true})
    }
    const handleNavIcon = () => {
        let element = document.getElementsByClassName('admin-main-wrap')[0];
        let isActive = false;
        element.classList.forEach(e => {
            if(e == 'active') isActive = true;
        })
        if(isActive){
            element.classList.remove('active')
        }
        else{
            element.classList.add('active')
        }

    }
    const handleCheckAdmin = async() => {
        try{
            const res = await axios.get(url + '/user/admin');
            if(!res.data.admin){
                handleLogout()
            }
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        handleCheckAdmin()
    }, [location])
    return(
        <header className={'member-admin-topbar'+(backgroundColor?' border':'')} style={{backgroundColor:backgroundColor}}>
            <div 
                className='nv-nav-item-menu news-nav'
                onClick={()=>{
                if(btmType=='Menu'){
                    setBtmType('')
                }
                else{
                setBtmType('Menu');
                }
            }}
            >
                <div className='nv-nav-btn' >
                    <div className='topbar-pitchdeck-btn'>
                        
                    </div>
                </div>
            </div>
            <article className='newsroom'>
                <div className='admin-nav-btns' onClick={handleNavIcon}>
                    <i className='icon'></i>
                </div>
                <span className='admin-nav-title' style={{transition:'all .3s ease-in-out',color:(backgroundColor && 'black'),transform:(hidden?'translateY(0)':'translateY(100%)')}}>{title}</span>
            </article>
            <article className='user-section' style={{backgroundColor: '#5684ff'}}>
                <div className='main-btn mg'>
                    <a className='a' href='/'>
                        <HomeIcon className='svg'/>
                        <span className='text'>메인</span>
                    </a>
                </div>
                <div className='main-btn mg'>
                    <div className='menu-btn' onMouseEnter={()=>{setIsHover(true)}} onMouseLeave={()=>{setIsHover(false)}}>
                        <button type='button' className='btn'>
                            <em className='img' style={{backgroundImage:'url(http://cms.weeklytoday.com/image/newsroom/default-user.png)'}}>
                            </em>
                            <span>{Auth.getName()}</span>
                            <MoreHorizIcon className='dotlist'/>
                        </button>
                        <div className='drop-menu' style={{visibility:(isHover?'visible':'hidden')}}>
                            {/* <a>
                                <AccountBoxIcon className='svg'/> 정보수정
                            </a>
                            <a>
                                <LockResetIcon className='svg'/> 비밀번호 수정
                            </a> */}
                            <a onClick={handleLogout}>
                                <PowerSettingsNewIcon className='svg'/> 로그아웃
                            </a>
                        </div>
                    </div>
                </div>
            </article>
        </header>
    )
}

export default Header