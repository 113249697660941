import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import "./style.scss";
import NavBar from "components/navbar/NavBar";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Banner from "../../components/Banner";
import _ from "lodash";
import All from "./component/Main-Classification/All";
import Latest from "./component/Main-Classification/Latest";
import Trending from "./component/Main-Classification/Trending";
import Topics from "./component/Main-Classification/Topics";
import DetailTopics from '../topicdetail/DetailTopics';
import NavBarBtm from 'components/navbar/NavBarBtm'

const Main = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const list = [
    {
      topic: "Latest",
      tail: "More stories",
      data: [
        {
          info: [
            {
              id: 1,
              title: "Who should Elon Musk be afraid of?",
              date: "8월 3, 2022",
              desc: "MUSK AGAINST THE WORLD",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/dcf157e979c021687357e1db6cf14b16.JPG",
                single:
                  "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_440,h_440,c_fill/dcf157e979c021687357e1db6cf14b16.JPG",
              },
            },
            {
              id: 2,
              title: "Japan is planning a big hike to its low minimum wages",
              date: "8월 3, 2022",
              desc: "THE PRICE OF A RAISE",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_720,h_399.2727272727273,c_fill/3854e9692765bfae2ff547d0c014f383.JPG",
                single:
                  "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_440,h_440,c_fill/3854e9692765bfae2ff547d0c014f383.JPG",
              },
            },
            {
              id: 3,
              title:
                "Despite fewer job openings, Americans keep quitting at record rates",
              date: "8월 3, 2022",
              desc: "WON'T STOP QUITTING",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_720,h_399.2727272727273,c_fill/3ca63def897c96b2eeb441b2b17e33ee.JPG",
                single:
                  "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_440,h_440,c_fill/3ca63def897c96b2eeb441b2b17e33ee.JPG",
              },
            },
          ],
        },
      ],
    },
    {
      topic: "Trending",
      tail: "More trending stories",
      data: [
        {
          info: [
            {
              id: 4,
              title: "How much money would you need to live your ideal life?",
              date: "7월 7, 2022",
              desc: "UNLIMITED WANTS",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/3cc5913d2b893a442ca0994fca351980.JPG",
                single:
                  "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_280,h_280,c_fill/3cc5913d2b893a442ca0994fca351980.JPG",
              },
            },
            {
              id: 5,
              title:
                "Yes, Abercrombie is back and its new look involves a lot of going out dresses",
              date: "7월 29, 2022",
              desc: "MAKEOVER",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/306c3dc7931802beef24ae089ea47ce2.JPG",
                single:
                  "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_440,h_440,c_fill/306c3dc7931802beef24ae089ea47ce2.JPG",
              },
            },
            {
              id: 6,
              title:
                "The Zelenskyys' Vogue cover story is a brilliant war tactic",
              date: "7월 30, 2022",
              desc: "WINNING HEARTS AND MINDS",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_720,h_399.2727272727273,c_fill/3ca63def897c96b2eeb441b2b17e33ee.JPG",
                single:
                  "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_440,h_440,c_fill/0d2694013092a5e15ce1c4428bd9c8af.PNG",
              },
            },
          ],
        },
      ],
    },
    {
      topic: "Topics",
      tail: "More topics",
      data: [
        {
          header: {
            title: "Emerging industries",
            icon: "",
            desc: "",
          },
          info: [
            {
              id: 7,
              title: "Who should Elon Musk be afraid of?",
              date: "8월 3, 2022",
              desc: "MUSK AGAINST THE WORLD",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/dcf157e979c021687357e1db6cf14b16.JPG",
              },
            },
            {
              id: 8,
              title:
                "Nigeria-based Bumpa is adapting Instagram messaging for vendors selling through DMs",
              date: "8월 2, 2022",
              desc: "DOWN IN THE DM",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_720,h_399.2727272727273,c_fill/3854e9692765bfae2ff547d0c014f383.JPG",
              },
            },
            {
              id: 9,
              title:
                "Why Proton is lobbying against Apple and Google’s app store monopolies",
              date: "7월 30, 2022",
              desc: "APP STORE WARS",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/d0e25c7ea62e31ca00a37d7702ffbe9d.JPG",
              },
            },
          ],
        },
        {
          header: {
            title: "Tech",
            icon: "",
            desc: "",
          },
          info: [
            {
              id: 10,
              title:
                "BeReal is the social-media app for people who hate social media",
              date: "8월 2, 2022",
              desc: "GENUINE ARTICLE",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/a85b3ebd9e4fe269286c04c7f693293e.JPG",
              },
            },
            {
              id: 11,
              title:
                "Reliance Jio is the top bidder in India's 5G spectrum auction",
              date: "8월 2, 2022",
              desc: "AND LET THE GAMES BEGIN...",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/df928688a8cd3cb68c82284663fd1162.JPG",
              },
            },
            {
              id: 12,
              title:
                "India's IT industry is a rare anomaly in its dismal jobs market",
              date: "8월 1, 2022",
              desc: "IT'S EASIER TO GET A JOB",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/df2ce666e7907008576991cb27628cf4.JPGG",
              },
            },
          ],
        },
        {
          header: {
            title: "New Invest",
            icon: "",
            desc: "",
          },
          info: [
            {
              id: 13,
              title: "Trader Joe's just got its first union",
              date: "7월 29, 2022",
              desc: "",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/87fb46056305f20d7e5860621aa4074f.JPG",
              },
            },
            {
              id: 14,
              title: "In the UK, design is still largely a man’s job",
              date: "7월 22, 2022",
              desc: "DESIGN DIVIDE",
              image: {
                all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/fb8adedf05cdec39c9e315191885f844.JPG",
              },
            },
          ],
        },
      ],
    },
  ];
  const [topicType, setTopicType] = useState("all");
  const [isCurrent, setIsCurrent] = useState([
    { value: "all", label:'all' },
    { value: '', label:'UNDEFINED' },
    { value: "", label:'UNDEFINED' },
    { value: "", label:'UNDEFINED' },
    { value: "", label:'UNDEFINED' },
    { value: "", label:'UNDEFINED' },
  ]);
  
  const result = () => {
    if (topicType == "all") {
      return <Trending list={list} />;
    } 
    else {
        return ;
    }
}
  
  useEffect(() => {
    const type = searchParams.get("type");
    if(type){
        setTopicType(type);
    }
    else{
        setTopicType('all');
    }
  }, [location]);
  
  return (
    <div className="main-body-wrap mn">
      <div className="nv-line"></div>
      <NavBar type={"investee"} backgroundColor={"#f9f9f9"} />
      <div className="main-body">
        <Banner differ={false} text='Trending' isCurrent={isCurrent} navigate={navigate} topicType={topicType}/>
        {result()}
      </div>
      <NavBarBtm type={'Main'}  />
    </div>
  );
};

export default Main;
