import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import NavBar from "components/navbar/NavBar";
import Banner from 'components/Banner'
import "./style.scss";
import _ from 'lodash';
import ContentsBox from './component/ContentsBox';
import ContentsContainer from './component/ContentsContainer';
const Detail = ({isCurrent, navigate, topicType}) =>{
    const [searchParams] = useSearchParams();
    const [data, setData] = useState(null)
    const getHeader = (text)=>{
        const index = list.data.findIndex(d=>d.header.title==text)
        setData(list.data[index])
    }
    useEffect(()=>{
        const text = searchParams.get('title')
        getHeader(text)
    },[])
    const list={
        topic: "Topics",
        tail: "More topics",
        data: [
          {
            header: {
              title: "Emerging industries",
              icon: "",
              desc: "",
            },
            info: [
              {
                id: 7,
                title: "Who should Elon Musk be afraid of?",
                date: "8월 3, 2022",
                desc: "MUSK AGAINST THE WORLD",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/dcf157e979c021687357e1db6cf14b16.JPG",
                },
              },
              {
                id: 8,
                title:
                  "Nigeria-based Bumpa is adapting Instagram messaging for vendors selling through DMs",
                date: "8월 2, 2022",
                desc: "DOWN IN THE DM",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_720,h_399.2727272727273,c_fill/3854e9692765bfae2ff547d0c014f383.JPG",
                },
              },
              {
                id: 9,
                title:
                  "Why Proton is lobbying against Apple and Google’s app store monopolies",
                date: "7월 30, 2022",
                desc: "APP STORE WARS",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/d0e25c7ea62e31ca00a37d7702ffbe9d.JPG",
                },
              },
            ],
          },
          {
            header: {
              title: "Tech",
              icon: "",
              desc: "",
            },
            info: [
              {
                id: 10,
                title:
                  "BeReal is the social-media app for people who hate social media",
                date: "8월 2, 2022",
                desc: "GENUINE ARTICLE",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/a85b3ebd9e4fe269286c04c7f693293e.JPG",
                },
              },
              {
                id: 11,
                title:
                  "Reliance Jio is the top bidder in India's 5G spectrum auction",
                date: "8월 2, 2022",
                desc: "AND LET THE GAMES BEGIN...",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/df928688a8cd3cb68c82284663fd1162.JPG",
                },
              },
              {
                id: 12,
                title:
                  "India's IT industry is a rare anomaly in its dismal jobs market",
                date: "8월 1, 2022",
                desc: "IT'S EASIER TO GET A JOB",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/df2ce666e7907008576991cb27628cf4.JPGG",
                },
              },
            ],
          },
          {
            header: {
              title: "New Invest",
              icon: "",
              desc: "",
            },
            info: [
              {
                id: 13,
                title: "Trader Joe's just got its first union",
                date: "7월 29, 2022",
                desc: "",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/87fb46056305f20d7e5860621aa4074f.JPG",
                },
              },
              {
                id: 14,
                title: "In the UK, design is still largely a man’s job",
                date: "7월 22, 2022",
                desc: "DESIGN DIVIDE",
                image: {
                  all: "https://i.kinja-img.com/gawker-media/image/upload/q_75,w_1332,h_738.6545454545454,c_fill/fb8adedf05cdec39c9e315191885f844.JPG",
                },
              },
            ],
          },
        ],
      }

    return(
        <div className="main-body-wrap mn">
            <div className="nv-line"></div>
            <NavBar type={"investee"} backgroundColor={"#f9f9f9"} />
            {data &&<div className='main-body'>
                 <Banner differ={true} text = {data.header.title}/>
                <ContentsContainer list={data}/>
                <div className='below-contents-container'>
                    <button className='btn-box' type='button' >
                        <span className='btn'>
                            Load more
                        </span>
                    </button>
                </div>
            </div>}
            
        </div>
    )
}

export default Detail