import { createSlice } from '@reduxjs/toolkit'

const investmentFilter = {
  newInvestment: {
    period: '',
    history: '',
    pv: {min: '', max: ''},
    stageSlide: '',
    stageCheck: '' 
  },
  investment:{
    isHistory: true,
    history: '',
    historyCustom: {min: '', max: ''},
    stageSlide: '',
    stageCheck: '',
    pv: {min: '', max: ''},
  },
  investor:{
    type: '',
    investor: []
  }
}
export const investmentFilterSlice = createSlice({
  name: 'investmentFilter',
  initialState: investmentFilter,
  reducers: {
    update: (state, action) => {
      const {payload} = action
      return payload
    },
    reset: (state) => {
      return investmentFilter
    }
  },
})

export const { update, reset } = investmentFilterSlice.actions

export default investmentFilterSlice.reducer