import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { TextField } from '@material-ui/core';
import _ from 'lodash'
// import 'styles/common.scss';

function NvTextInput2({id, defaultValue, onChange, multiline, rows, style, className, onEnter, placeholder, required = false, disabled = false, onBlur, isPassword = false}) {
    const [value, _value] = useState('');
    const [isFocus, setIsFocus] = useState(false);

    const _onKeyDown = (e) => {
        if (onEnter && e.keyCode == 13) onEnter(e.target.value)
    }

    useEffect(() => {
        _value(defaultValue)
    }, [defaultValue])

    return (
        <OutlinedInput 
        id={id}
        disabled={disabled}
        onFocus={(e) => {setIsFocus(true)}}
        className={className ? className + ' nv-textinput' : 'nv-textinput'}
        style={style}
        required={required}
        placeholder={!isFocus && placeholder}
        onKeyDown={_onKeyDown}
        onBlur={(e) => {
            if(onBlur) onBlur()    
            setIsFocus(false)
        }}
        value={value} 
        inputProps={{type: isPassword ? 'password' : 'text'}}
        onChange={(e) => {
            _value(e.target.value)
            onChange(e.target.value)
        }} size="small" />
    );
}

export default NvTextInput2;