import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import axios from 'axios'
import url from 'utils/backend'
import packageJson from '../../../package.json';
global.appVersion = packageJson.version;
const getCurrentVersion = async() => {
    try{
        const res = await axios.get(url + '/frontend/version')
        return {
            version : res.data.version,
            isNew:  res.data.version ? res.data.version != global.appVersion : false
        }
    }
    catch(e){
        console.log(e)
    }
}
const useGetFrontendVersion = () => {
    return useQuery({
        queryKey: ['current_version'],
        queryFn:getCurrentVersion,
        onSuccess: (data) => {
        },
        onError: (data) => {
        },
        staleTime: 1 * 60 * 1000,
    })
}

export default useGetFrontendVersion