class ConvertComma {
    static comma(str){
        // if(!str) return 0
        str = String(str);
        return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    }
    static uncomma(str){
        str = String(str);
        return str.replace(/,/g, '');
    }
};
export default ConvertComma;