import React, {useState, useEffect} from 'react';
import {WriteDragTypes} from 'utils/WriteDragTypes'
import NvTextArea from 'components/NvTextArea'
import ClearIcon from '@mui/icons-material/Clear';
import Dropzone from 'react-dropzone'
import HeightOutlinedIcon from '@mui/icons-material/HeightOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import NvTextInput from 'components/NvTextInput';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ReactComponent as DragIcon} from 'assets/images/drag_icon.svg'
import NvTextRich from 'components/NvTextRich'
import getImageSignedURL from 'utils/getImageSignedURL'

const thumb = {
    display: 'inline-flex',
    marginBottom: 30,
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    fontSize: 25,
    color: '#808080',
    alignItems: 'center',
    justifyContent: 'center',
};
const thumbEmpty = {
    display: 'inline-flex',
    borderRadius:'50%',
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    fontSize: 25,
    color: '#808080'
}
  
const thumbInner = {
    display: 'flex',
    overflow: 'hidden',
    width:'100%'
};
  
const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
};
const NewsWriteContent = ({item, handleUpdate, isMenu = false, isWrite, handleClear, drag, index, isNecessary = false}) => {
    const {type, id} = item
    const [uri, setUri] = useState('');
    const getImage = async() => {
        var tmp = ''
        if(!item?.file?.preview && item.key){
            tmp = await getImageSignedURL(item.key, item.mimetype)
        }
        else if(item?.file?.preview){
            tmp = item.file.preview
        }
        setUri(tmp)
    }
    useEffect(() => {
        if(type == WriteDragTypes.IMG){
            getImage()
        }
    }, [item])
    const thumbs = () => {
        return(
            <div style={{...thumb,padding:('15px 0 0 0')}} key={item.id}>
                <div style={{...thumbInner,borderRadius:2}}>
                    <img
                        src={uri}
                        style={img}
                    // onLoad={() => { 
                    //     if(item.file?.preview){
                    //         URL.revokeObjectURL(item.file.preview) 
                    //     }
                    // }}
                    />
                </div>
            </div>
        )
    }
    const handleImageDrop = async(files) => {
        let tmp = files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))                                
        handleUpdate(id, tmp[0])
    }

    const dropBoxIcon = () =>{
        if(isMenu){
            return(
                <div className={' news-write-item-btn-box' }>
                    <div className='news-write-item-remove-btn'>
                        <DeleteOutlineOutlinedIcon 
                            onClick={handleClear}
                        />                 
                    </div>
                    <div ref={drag} className='news-write-item-move-btn'>
                        <DragIcon/>
                    </div>
                </div>
            )
        }
        else return null
    }

    const necessaryMark = () => {
        if(!isWrite) return;
        return(
            <div className='news-write-necessary-mark'>
                *
            </div>
        )
    }
    
    if(type == WriteDragTypes.TXT){
        return(
                <div id={type} className={'drag-input-box ' + (isNecessary && ' visible')}>
                    {isNecessary 
                    ?
                        necessaryMark()
                    :
                        dropBoxIcon()
                    }
                    {/* <NvTextArea 
                        defaultValue={item.innerHtml}
                        placeholder={'텍스트'} className='news-write-txt'
                        onChange={(v) => handleUpdate(id, v)}
                    /> */}
                    <NvTextRich
                        defaultValue={item.innerHtml}
                        placeholder={'텍스트'} 
                        initFocus
                        className='news-write-txt'
                        onChange={(v) => handleUpdate(id, v)}
                    />
                </div>
        )
    }
    else if(type == WriteDragTypes.TITLE){
        return(
            <div id={type} className='drag-input-box visible'>
                {necessaryMark()}
                <NvTextInput 
                    defaultValue={item.innerHtml}
                    placeholder={'제목'} 
                    className=' news-write-title '
                    onChange={(v) => handleUpdate(id, v)}
                />
            </div>
        )
    }
    else if(type == WriteDragTypes.LINE){
        return(
            <div id={type} className='drag-line visible'>
                {dropBoxIcon()}
                <div className='line'>
                    <div style={{width:'100%', height:1, backgroundColor:'#444'}}></div>
                </div>
            </div>
        )
    }
    else if(type == WriteDragTypes.SUBTITlE){
        return(
            <div id={type} className='drag-input-box visible'>
                {isNecessary 
                ?
                    necessaryMark()
                :
                    dropBoxIcon()
                }
                <NvTextInput 
                defaultValue={item.innerHtml}
                placeholder={'부제목'} className=' news-write-subtitle'
                onChange={(v) => handleUpdate(id, v)}
                />
            </div>
        )
    }
    else if(type == WriteDragTypes.MIDDLETITLE){
        return(
            <div id={type} className='drag-input-box'>
                {dropBoxIcon()}
                <NvTextInput 
                    defaultValue={item.innerHtml}
                    placeholder={'중제목'} className='news-write-middle-title'
                    onChange={(v) => handleUpdate(id, v)}
                    initFocus
                />
            </div>
        )
    }
    else if(type == WriteDragTypes.IMG){
        return(
            <div id={type} className={' drag-input-box  visible' }>
                {isNecessary 
                ?
                    necessaryMark()
                :
                    dropBoxIcon()
                }
                <div className='news-write-imgBox' style={{padding:(isWrite && '10px 0')}}>
                    <Dropzone
                        accept= {{
                            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
                        }}
                        onDrop={acceptedFiles => handleImageDrop(acceptedFiles)}
                        multiple={false}
                        maxFiles={1}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} style={{width:'100%',padding:0,cursor:'pointer'}}>
                            {(item.file || item.key) &&
                                thumbs()
                            }
                            {(!item.file && !item.key) 
                            ?
                                <div className='imgBox-upload-box' style={{padding:(isWrite? '' :'10px 0')}}>
                                    <PanoramaOutlinedIcon/>
                                    <div>여기에 이미지를 드래그 하세요</div>
                                    <div className='or-text'>
                                        <span>또는</span>
                                    </div>
                                    <div className='upload-btn'>업로드</div>
                                </div>
                            :
                                (isWrite && 
                                    <p className='upload-other-box'>
                                        <FileUploadIcon/>
                                        &nbsp;다른 이미지파일 첨부하기
                                    </p>
                                )}
                                <input {...getInputProps()} />
                                </div>
                        )}
                    </Dropzone>
                </div>
                <div>
                    <NvTextInput 
                        defaultValue={item.desc}
                        placeholder={'이미지 설명'} className='news-write-img-desc'
                        onChange={(v) => handleUpdate(id, v, 'desc')}
                        initFocus
                    />
                </div>
            </div>
        )
    }
}

export default NewsWriteContent