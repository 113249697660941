import React from 'react';

import Table from 'components/Table'
import NvLabel from 'components/NvLabel'
const Portfolio = ({item, columns}) => {
    const {company, ranking, investment, news} = item;
    const BriefStyle = {
        boxShadow:'0px 2px 0px rgba(0, 0, 0, 0.25)',
    }
    const renderTd = (cell, data) => {
        const header = cell.column.Header;
        const accessor = cell.column.id;
        const index = cell.row.index;
        if(!cell.value){
            return '-';
        }
        else if(header == '회사명'){
          return(
            <span>{cell.render('Cell')}</span>
          )
        }
        else if(header == '투자금액'){
            return(
                <NvLabel value={cell.value} unit={'조억'} style={{marginLeft: 0}}/>
            )
        }
        else if(header == 'Post MC'){
            return(
                <NvLabel value={cell.value} unit={'조억'} style={{marginLeft: 0}}/>
            )
        }
        else if(header == '투자단계'){
            return(
                <NvLabel value={cell.value} style={{marginLeft: 0}}/>
            )
        }
        else if(header == '투자총액'){
            return(
                <NvLabel value={cell.value} unit={'조억'} style={{marginLeft: 0}}/>
            )
        }
        else if(header == '투자분야'){
            return(
                <span className='investors-table-label '>
                    <p className=''>
                    {(data[index].main_sector.length >= 1) &&
                        data[index].main_sector.map((sector, i ) => {
                            return(
                                <NvLabel value={sector + ((i != data[index].main_sector.length - 1) ? '/' : '')} style={{marginLeft: 0}}/>
                            )
                        })

                    }
                    </p>
                </span>  
            )
        }
        else if(header == '투자일자'){
            return(
                <NvLabel value={cell.value} unit={'년월'} style={{marginLeft: 0}}/>
            )
        }
        else{
          return(
            <span>{cell.render('Cell')}</span>
          )
        }
    }
    return(
        <div className='investor-left-card'>
            <div className='investor-left-content-box'>
                <div className='investor-brief-box'>
                    <div className='text-box'>
                        <span className='text-title'>투자금액 ( {(investment.total.investment_amount) ? <NvLabel tar value={investment.total.investment_amount} unit={'조억'} style={{marginLeft: 0, fontWeight: 'bold'}}/> : '-'} )</span>
                    </div>
                    &
                    <div className='text-box'>
                        <span className='text-title'>투자건수 ( {(investment.total.investments) ? <NvLabel tar value={investment.total.investments} unit={'개'} style={{marginLeft: 0, fontWeight: 'bold'}}/> : '-'} )</span>
                    </div>
                </div>
                <Table columns={columns} data={investment.list} renderTd={renderTd} />
            </div>
        </div>
    )
}
export default Portfolio;