import React, { useState, useEffect } from 'react';
import ServiceModal from './ServiceModal'
import NewInvestmentModal from './NewInvestmentModal'
import { Backdrop, RegisterModal } from 'components/modals/modals'
import NvLabel from 'components/NvLabel';
import IsArrayEmpty from 'utils/IsArrayEmpty';
import Badges from './Badges';
import InputMask from "react-input-mask";
import NvAutocomplete from 'components/NvAutoComplete';
import NvTextInput2 from 'components/NvTextInput2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import InputCheck2 from './InputCheck2';
import InputBusinessNumber from './InputBusinessNumber'
import InputBadge2 from './InputBadge2'
import InputMarket from './InputMarket';
import InputInvestment from './InputInvestment';
import InputNewInvestment from './InputNewInvestment';
import InputProduct from './InputProduct'
import InputEmail from './InputEmail';
import NvNumberInput2 from 'components/NvNumberInput2';
import InputCertification from './InputCertification';
import InputPrice2 from 'components/profile/InputPrice2'
const InputBox2 = ({label, arr = [], required, option, headers, handleAdd, newInvestment, width, isPreview, state, setState, isEmailCert, setIsEmailCert, setCertId}) => {
  // let investments  = item?.investment?.investment_records;
  const [list, setList ] = useState({
    company_name:{
      label: '회사명',
      desc: '회사명을 입력해 주세요.           ex. (주)엔사이트 -> 엔사이트',
    },
    business_number:{
      label: '사업자번호',
      desc: '000-00-00000 (법인의 사업자번호를 입력해 주세요.)',
    },
    name: {
      label: '이름',
      desc: '이름을 입력해 주세요.',
    },
    rank:{
      label: '직함',
      desc: '직함을 입력해 주세요.',
    },
    certification: {
      label: '투자사분류',
      desc: '해당 분류 체계에 체크해 주세요.'
    },
    email:{
      label: '회사 이메일',
      desc: '인증 절차를 위해 <span style="color: #d44e59">반드시 회사 이메일</span>을 기입해 주세요. 회사 도메인이 아닐 경우 가입 승인이 되지 않습니다.',
    },
    email_reset:{
      label: '이메일 인증',
      desc: '회원 가입 시 등록한 이메일 주소를 입력해주세요.',
    },
    password_now:{
      label: '현재 비밀번호',
      desc: '',
    },
    password:{
      label: '비밀번호',
      desc: '로그인에 사용될 비밀번호를 입력해 주세요.',
    },
    password_check:{
      label: '비밀번호 확인',
      desc: '',
    },
    round: {
      label: '선호 라운드',
      desc: '선호하는 투자단계를 입력해 주세요.'
    },
    price:{
      label: '주 투자 금액',
      desc: '주로 집행하는 투자금액을 최소에서 최대값으로  입력해 주세요.'
    },
    amount: {
      label: '투자재원',
      desc: '현재 투자가능한 운용금액을 기입해 주세요.'
    },
    industry:{
      label: '산업',
      desc: '관심투자산업을  기입해 주세요. (최대 5개)',
    },
    technology:{
      label: '기술',
      desc: '관심투자기술을 기입해 주세요. (최대 5개)',
    },

  })

  const renderOption = () => {
    if(option == 'company_name' || option == 'name' || option == 'rank' ||option == 'team' || option == 'main_business'){
      return (
        <NvTextInput2 className='text_input' defaultValue={state} onChange={(v) => setState(v)}  />
      );
    }
    else if(option == 'certification'){
      return(
        <InputCertification state={state} setState={setState} />
      )
    }
    else if(option == 'password' || option == 'password_check' || option == 'password_now'){
      return (
        <NvTextInput2 className='text_input' isPassword defaultValue={state} onChange={(v) => setState(v)}  />
      );
    }
    else if(option == 'email' || option == 'email_reset'){
      return(
        <InputEmail isEmailCert={isEmailCert} setIsEmailCert={setIsEmailCert} setState={setState} state={state} setCertId={setCertId} type={option == 'email' ? 'register' : 'reset'} />
      )
    }
    else if(option == 'business_number'){
      return(
        <InputBusinessNumber state={state} setState={setState}/>
      )
    }
    else if(option == 'industry' || option == 'technology'){
      return (
        <InputBadge2 state={state} setState={setState} type={option} />
      )
    }
    else if(option == 'round'){
      return(
        <InputCheck2 type='round' state={state} setState={setState }/>
      )
    }
    else if(option == 'price'){
      return(
        <InputPrice2 state={state} setState={setState}/>
      )
    }
    else if(option == 'amount'){
      return(
        <div >
          <NvNumberInput2 className='text_input' unit='억' state={state} setState={setState}/>
        </div>
      )
    }
    else{
      return null;
    }
  }
  return(
    <div className='input_form' style={{width: width}}>
      <div className='label'>
        <div>
        <p>{list[option]?.label}</p>
        {(required) && 
          <p className='required'> *</p>
        }
        {/* {(option == 'certification') &&
          <p style={{fontSize: 16, marginLeft: 20, color: '#444'}}>{state.kor}</p>
        } */}
        </div>
        {(!isPreview && (option === 'investment' || option == 'product' || option == 'newInvestment' || option == 'market')) &&
          <div className='modify_btn' onClick={() => handleAdd(list[option]?.label)}>입력하기</div>
        }
      </div>
      <div className='description'>
        {/* <pre>{list[option]?.desc}</pre> */}
        <div dangerouslySetInnerHTML={ {__html: list[option]?.desc} }></div>
      </div>
      {renderOption()}
    </div>
  )
}
export default InputBox2