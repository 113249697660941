import React, { useState, useEffect } from 'react';
import ServiceModal from './ServiceModal'
import NewInvestmentModal from './NewInvestmentModal'
import { Backdrop, RegisterModal } from 'components/modals/modals'
import NvLabel from 'components/NvLabel';
import IsArrayEmpty from 'utils/IsArrayEmpty';
import Badges from './Badges';
import InputMask from "react-input-mask";
import NvAutocomplete from 'components/NvAutoComplete';
import NvTextInput from 'components/NvTextInput'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import InputCheck from './InputCheck';
import InputBusinessNumber from './InputBusinessNumber'
import InputBadge from './InputBadge'
import InputMarket from './InputMarket';
import InputInvestment from './InputInvestment';
import InputNewInvestment from './InputNewInvestment';
import InputProduct from './InputProduct'

const InputBox = ({label, arr = [], required, option, headers, handleAdd, newInvestment, width, isPreview, state, setState}) => {
  // let investments  = item?.investment?.investment_records;
  const [list, setList ] = useState({
    company_name:{
      label: '회사명',
      desc: '회사명을 입력해주세요.',
    },
    business_number:{
      label: '사업자번호',
      desc: '000-00-00000 (법인사업자라면, 법인의 사업자번호를 보내주십시오.)',
    },
    name: {
      label: '이름',
      desc: '이름을 입력해주세요.',
    },
    founder: {
      label: '이 회사의 창업 멤버이신가요?',
      desc: '엔사이트는 창업멤버 또는 임원들에게만 오픈되는 서비스입니다.'
    },
    gender:{
      label: '성별',
      desc: '',
    },
    rank:{
      label: '직함',
      desc: '직함을 입력해주세요.',
    },
    email:{
      label: '회사 이메일',
      desc: '본인 인증을 위해서 *꼭* 회사 이메일을 기입해 주세요.',
    },
    team:{
      label: '팀',
      desc: '팀에 대해 한줄로 소개해 주세요.',
    },
    main_business:{
      label: '주요 사업',
      desc: '회사의 사업을 한줄로 간략히 기입해 주세요.',
    },
    industry:{
      label: '산업분야',
      desc: '서비스 분야를 기입 후 엔터를 눌러 주세요.',
    },
    technology:{
      label: '기술',
      desc: '기술 분야를 기입 후 엔터를 눌러 주세요.',
    },
    market:{
      label: '시장',
      desc: '시장의 문제점과 해결방안을 입력해 주세요.',
    },
    product:{
      label: '제품 및 서비스',
      desc: '제품 및 서비스 명과 그에 관련한 내용을 기입해 주세요.',
    },
    investment:{
      label: '투자현황',
      desc: '투자 받으신 내역이 있으시면 기입 또는 수정해 주세요.',
    },
    newInvestment:{
      label: '신규투자유치',
      desc: '투자 받고자 하는 희망 밸류 및 금액을 기입해 주세요.',
    }
  })

  const renderOption = () => {
    if(option == 'company_name' || option == 'name' || option == 'rank' || option == 'email' || option == 'team' || option == 'main_business'){
      return (
        <NvTextInput className='text_input' defaultValue={state} onChange={(v) => setState(v)}  />
      );
    }
    else if(option == 'name'){
      <NvTextInput className='text_input' defaultValue={state} onChange={(v) => setState(v)}  />

    }
    else if(option == 'business_number'){
      return(
        <InputBusinessNumber state={state} setState={setState}/>
      )
    }
    else if(option == 'gender' || option == 'founder'){
      return (
        <InputCheck state={state} setState={setState} type={option} />
      )
    }
    else if(option == 'industry' || option == 'technology'){
      return (
        <InputBadge state={state} setState={setState} type={option} />
      )
    }
    else if(option == 'product'){
      return(
        <InputProduct state={state} />
      )
    }
    else if(option == 'market'){
      return(
        <InputMarket state={state}/>
      )
    }
    else if(option == 'investment'){
      return(
        <InputInvestment state={state}/>
      )
    }
    else if(option == 'newInvestment'){
      return(
        <InputNewInvestment state={state}/>
      )
    }
    else{
      return null;
    }
  }
  return(
    <div className='input_form' style={{width: width}}>
      <div className='label'>
        <div>
        <p>{list[option]?.label}</p>
        {(required) && 
          <p className='required'> *</p>
        }
        </div>
        {(!isPreview && (option === 'investment' || option == 'product' || option == 'newInvestment' || option == 'market')) &&
          <div className='modify_btn' onClick={() => handleAdd(list[option]?.label)}>입력하기</div>
        }
      </div>
      <div className='description'>
        {list[option]?.desc}
      </div>
      {renderOption()}
    </div>
  )
}
export default InputBox