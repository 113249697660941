import React from 'react';
import { Link } from 'react-router-dom';
import ConvertComma from 'utils/ConvertComma'

const DashPanel = ({total, list, statusList}) =>{
    const {total_approve_news, total_not_approve_news, total_recover_news, total_remove_news, total_reserve_news,   total_sleeping_user, total_user} = total
    return(
        <>
            <article className='dash-panel'>
                <div className='item'>
                    <Link to='/admin/newsList?page=1&status=default' className='blocks all-articles'>
                        <em className='stat'>{ConvertComma.comma(total_approve_news)}</em>
                        <span className='subject'>전체기사</span>
                    </Link>
                </div>
                <div className='item din-2'>
                    <Link to='/admin/newsList?page=1&status=apply' className='blocks import rw'>
                        <em className='stat'>{ConvertComma.comma(total_not_approve_news)}</em>
                        <span className='subject'>미승인기사</span>
                    </Link>
                    <Link to='/admin/newsList?page=1&status=reserve' className='blocks import aw'>
                        <em className='stat'>{ConvertComma.comma(total_reserve_news)}</em>
                        <span className='subject'>예약기사</span>
                    </Link>
                    <Link to='' className='blocks icon trash'>
                        <em className='stat'>{ConvertComma.comma(total_remove_news)}</em>
                        <span className='subject'>최근삭제기사</span>
                    </Link>
                    <Link to='' className='blocks icon restore'>
                        <em className='stat'>{ConvertComma.comma(total_recover_news)}</em>
                        <span className='subject'>최근복원기사</span>
                    </Link>                                                
                </div>
                {/* <div className='item'>
                    <Link to='' className='blocks icon reply'>
                        <em className='stat'>{ConvertComma.comma(0)}</em>
                        <span className='subject'>기사댓글</span>                    
                    </Link>
                    <Link to='' className='blocks icon bbs-list'>
                        <em className='stat'>{ConvertComma.comma(0)}</em>
                        <span className='subject'>게시물</span>                    
                    </Link>
                    <Link to='' className='blocks icon bbs-reply'>
                        <em className='stat'>{ConvertComma.comma(0)}</em>
                        <span className='subject'>게시물댓글</span>                    
                    </Link>                                
                </div> */}
                <div className='item'>
                    <Link to='/admin/memberList?page=1' className='blocks member'>
                        <em className='stat'>{ConvertComma.comma(total_user)}</em>
                        <span className='subject'>전체회원</span>                    
                    </Link>
                    <Link to='/admin/memberList?page=1' className='blocks member sleep'>
                        <em className='stat'>{ConvertComma.comma(total_sleeping_user)}</em>
                        <span className='subject'>휴먼회원</span>                    
                    </Link>
                </div>
            
            </article>

        </>
    )
}

export default DashPanel