import NvLabel from 'components/NvLabel'
import research_price from 'assets/images/research_price.svg';
import research_homepage from 'assets/images/research_homepage.svg';
import research_address from 'assets/images/research_address.svg';
import research_name from 'assets/images/research_name.svg';
import research_date from 'assets/images/research_date.svg';
import research_type from 'assets/images/research_type.svg';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import React, { useEffect, useState } from 'react';

const InvestorCard = ({item, i}) => {
    const [ports, setPorts] = useState([]);
    const [restPorts, setRestPorts] = useState(0);
    const cutPortfolio = () => {
        let wordTotal = 0;
        let tmp = []
        item.portfolio.some((data, i) => {
            if(wordTotal + data.length > (29 * 2 - 3)){
                setRestPorts(item.portfolio.length - i )
                return true;
            }
            else{
                wordTotal += Number(data.length) 
                tmp.push(data)
            }
        })
        setPorts(tmp);
    }
    useEffect(() => {
        cutPortfolio()
    },[item])
    return(
        <div className='investor-match-card gallery-item'>
        {/* // <div className='gallery-item'> */}
            <div className='header-box'>
                {(item.picture) 
                ?                
                    <img className='investment-img' src={item.picture}/>
                : 
                    <div/>
                }
            </div>
            <div style={{marginBottom: 30}}>
                <span className='header-right' style={{marginBottom: 20}}>
                    <div className='header-title'>{item.name}</div>
                    <div  className='header-desc'>{item.description}</div>
                    {(item.iv_type) &&
                        <span className='header-sub-title-box'>
                            <span className='header-icon'>
                                <img src={research_type} />
                            </span>
                            <span className='header-sub-title'>{item.iv_type}</span>
                        </span>
                    }
                    {(item.establish_date) &&
                        <span className='header-sub-title-box'>
                            <span className='header-icon'>
                                <img src={research_date} />
                            </span>
                            <div className='header-sub-title' ><NvLabel value={item.establish_date} unit='년월일' /></div>
                        </span>
                    }
                    {(item.ceo) &&
                        <span className='header-sub-title-box'>
                            <span className='header-icon'>
                                <img src={research_name} />
                            </span>
                            <div className='header-sub-title'>{item.ceo}</div>
                        </span>
                    }
                    {(item.address) &&
                        <span className='header-sub-title-box'>
                            <span className='header-icon'>
                                <img src={research_address} />
                            </span>
                            <div className='header-sub-title'>{item.address}</div>
                        </span>
                    }
                    {(item.homepage) &&
                        <span className='header-sub-title-box'>
                            <span className='header-icon'>
                                <img src={research_homepage} />
                            </span>
                            <div className='header-sub-title'>{item.homepage}</div>
                        </span>
                    }
                    {(item.capital) &&
                        <span className='header-sub-title-box'>
                            <span className='header-icon'>
                                <img src={research_price} />
                            </span>
                            <div className='header-sub-title'><NvLabel style={{marginLeft: 0}} value={item.capital} unit='조억'/></div>
                        </span>
                    }
                </span>
                {(item.min_investment_amount && item.max_investment_amount) &&
                    <div className='rc' style={{marginTop: 20}}>
                        <img className='check-img check-margin-bottom' src={require('assets/images/research_check_icon.png')}/>
                        <div className='check-text'>
                            투자: &nbsp; 
                            <NvLabel style={{marginLeft: 0}} value={item.min_investment_amount} unit='조억'/> 
                            - 
                            <NvLabel style={{marginLeft: 0}} value={item.max_investment_amount} unit='조억'/> ( per dael )
                        </div>
                    </div>
                }
                {(item.main_investment_stage.length >= 1) &&
                    <div className='rc'>
                        <img className='check-img check-margin-bottom' src={require('assets/images/research_check_icon.png')}/>
                        <div className='check-text'>주 투자단계:  &nbsp;{item.main_investment_stage[0].stage}</div>
                    </div>
                }
                {(item.recent_investment_amount || item.recent_investment_count) ?
                    <div className='rc'>
                        <img className='check-img' src={require('assets/images/research_check_icon.png')}/>
                        <div className='check-text'>
                            활동중:  
                            {(item.recent_investment_amount > 0) &&
                                <>
                                &nbsp;투자금액&nbsp;
                                약&nbsp;
                                <NvLabel style={{marginLeft: 0}} value={item.recent_investment_amount / 100000000} unit='조억'/>                           
                                </>
                            } 
                            {(item.recent_investment_count > 0) &&
                                <>
                                &nbsp;투자건수&nbsp; 
                                <NvLabel style={{marginLeft: 0}} value={item.recent_investment_count} unit='건'/> ( in last 3 years )                    
                                </>
                            }
                        </div>
                    </div>
                    : null
                }
            </div>
            <div>
                {(item.portfolio.length >= 1) &&
                    <div className='major-list-box'>
                        <div className='major-list-title-box'>
                            <img className='major-list-title-icon' src={require('assets/images/research_portfolio.png')}/>
                            <div className='major-list-title'>
                                포트폴리오
                            </div>
                        </div>
                        <div className='major-list-item-box'>
                            {ports.map((data, i) => {
                                return(
                                    <div className='major-list-item center'>{data}</div>
                                )
                            })
                            }
                            {(restPorts >= 1) &&
                                <div className='major-list-item-rest'>
                                    + {restPorts}
                                </div>
                            }
                        </div>
                    </div>
                }
                {(item.main_technology.length >= 1) && 
                    <div className='major-list-box'>
                        <div className='major-list-title-box'>
                            <img className='major-list-title-icon' src={require('assets/images/research_tech.png')}/>
                            <div className='major-list-title'>
                            주요투자기술
                            </div>
                        </div>
                        <div className='major-list-item-box'>
                            {item.main_technology.map((data, i) => {
                                return(
                                    <div className='major-list-item center'>{data.technology}</div>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                {(item.main_sector.length >= 1) && 
                    <div className='major-list-box'>
                        <div className='major-list-title-box'>
                            <img className='major-list-title-icon' src={require('assets/images/research_sector.png')}/>
                            <div className='major-list-title'>
                            주요투자분야
                            </div>
                        </div>
                        <div className='major-list-item-box'>
                            {item.main_sector.map((data, i) => {
                                return(
                                    <div className='major-list-item center'>{data.sector}</div>
                                )
                            })
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default InvestorCard