import React from 'react';
import Head from './Head';
import Body from './Body';

const View = ({title, subtitle, mainImage, author, date, data, isPlaceholder = false, backgroundColor}) => {
    return(
        <main className="detail-main" style={{backgroundColor: (backgroundColor || 'unset')}}>
            <article>
                <Head title={title} subtitle={subtitle} mainImage={mainImage} isPlaceholder={isPlaceholder} />
                <Body author={author} date={date} data={data}/>
            </article>
        </main>
    )
}

export default View;