import React, {useEffect, useState} from 'react';
import Header from 'components/admin/Header'
import LeftSideBar from 'components/admin/LeftSideBar'
import RightSideBar from 'components/admin/RightSideBar'
import MainPannel from 'pages/admin/component/MainPannel'
import '../admin.scss'
import axios from 'axios'
import url from 'utils/backend'
import _ from 'lodash'
import CurrentInfo from './component/CurrentInfo'
import { useLocation } from 'react-router-dom';
const statusList =[
    {
        label: '미승인기사',
        value: 'apply'
    },
    {
        label: '예약기사',
        value: 'reserve'
    },
    {
        label: '최근등록기사',
        value: 'recent'
    },
]

const Main = () => {
    const location = useLocation()
    const [total, setTotal] = useState('')
    const [list, setList] = useState('')    

    const getDashboard = async() => {
        try{
            const res = await axios(url + '/news/admin/dashboard');
            setTotal(res.data);
            
        }   
        catch(e){
            console.log(e)
        }     
    }

    const getNewsList = async() => {
        try{
            let tmp = {}
            const res = await axios.get(url + '/news/admin?page=1&size=13&status=apply')
            tmp.apply = {
                list: res.data.list,
                total: res.data.total
            }
            const res2 = await axios.get(url + '/news/admin?page=1&size=13&status=reserve')
            tmp.reserve = {
                list: res2.data.list,
                total: res2.data.total
            }            
            const end_date = new Date()
            const start_date = new Date()
            start_date.setDate(start_date.getDate() - 30)
            const res3 = await axios.get(url + `/news/admin?page=1&size=13&start_date=${start_date}&end_date=${end_date}`)
            tmp.recent = {
                list: res3.data.list,
                total: res3.data.total
            }
            setList(tmp)
            
        }
        catch(e){
            console.log(e)
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        getDashboard()
        getNewsList()
    }, [location])

    return(
        <div className='admin-main-wrap mg'>
            <Header title={'대시보드'}/>
            <div className='admin-main'>
                <section className='admin-body mg' >
                    <LeftSideBar/>
                    <div className='admin-body-middle'>
                        <div className='admin-dashboard'>
                            {list &&
                                <MainPannel total={total}/>
                            }
                            {list &&
                                <article className='current-data mg'>
                                    <CurrentInfo
                                        list={list}
                                        statusList={statusList}
                                    />
                                </article>
                            }
                        </div>
                    </div>
                    <RightSideBar/>
                </section>
            </div>
        </div>
    )
}

export default Main