import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #C4C4C4;
    .title{
        cursor: pointer;
        font-weight: 400;
        font-size: 11px;
        color: #444;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        &.active{
            font-weight: 600;
            color: orange !important;
            border-bottom: 1px solid orange;
        }
    }

`;
const SubTitle = ({list, state, setState, obj = '', containerStyle, textStyle, borderNone = false}) => {
    const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #C4C4C4;
    .title{
        cursor: pointer;
        font-weight: 400;
        font-size: 11px;
        color: #444;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        &.active{
            font-weight: 600;
            color: orange !important;
            border-bottom: ${!borderNone ? '1px solid orange' : 'none'};
        }
        &:hover{
            background-color: white;
        }
    }

`;
    return(
        <Container style={containerStyle}>
            {list.map((data, i) => {
                return(
                    <div style={textStyle} onClick={() => setState(data)} className={'title ' + ((obj ? data[obj] : data) == (obj ? state[obj] : state) ? 'active' : '')}>{obj ? data[obj] : data}</div>
                )
            })
            }
        </Container>
    )
}

export default SubTitle;