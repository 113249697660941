// import 'styles/common.scss';
// import 'styles/Login.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import url from "utils/backend";
// import jwt_secret from 'secret/jwt_secret';
import { useNavigate } from "react-router-dom";
import Auth from "utils/auth";
import NavBar from "components/navbar/NavBar";
import "./style.scss";
import Header from "./component/Header";
import Form from "./component/Form";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ReactComponent as Kakaotalk } from "assets/images/kakaotalk.svg";
async function digest(password) {
  let pw = new TextEncoder().encode(password);
  pw = await crypto.subtle.digest("SHA-256", pw);
  pw = Array.from(new Uint8Array(pw));
  pw = pw.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  // console.log(pw)
  return pw;
}

function Login(props) {
  const navigate = useNavigate();
  const permissionList = {
    일반: 0,
    관리자: 1,
    리서치부: 2,
    기업소싱부: 3,
    지원부: 4,
    내부심사부: 5,
  };
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");
  const [inputRm, setInputRm] = useState("");
  const [wrongId, setWrongId] = useState(false);
  const [wrongPw, setWrongPw] = useState(false);
  const [btnState, setBtnState] = useState(true);
  const handleInputId = (e) => {
    setInputId(e.target.value);
    checkBtnState(e.target.value, inputPw);
  };
  const checkBtnState = (id, pw) => {
    if (id == "") {
      if (pw == "") {
        setWrongId(true);
        setWrongPw(true);
        setBtnState(true);
      } else {
        setWrongPw(false);
        setWrongId(true);
        setBtnState(true);
      }
    } else {
      if (pw == "") {
        setWrongId(false);
        setWrongPw(true);
        setBtnState(true);
      } else {
        setWrongId(false);
        setWrongPw(false);
        setBtnState(false);
      }
    }
  };
  const handleInputPw = (e) => {
    setInputPw(e.target.value);
    checkBtnState(inputId, e.target.value);
  };

  const checkProfile = async () => {
    const res = await axios.get(url + '/user/company');
    if (res.data.company == null) {
      navigate("/register");
    } else {
      navigate("/");
    }
  };
  const _onKeyDown = (e) => {
    // console.log(e)
    if (e.keyCode == 13) onSubmitHandler();
    // if(onChange) onChange(e.target.value)
    // if(onChange) console.log(e.target.value + e.code)
  };
  const onSubmitHandler = async () => {
    if (inputId == "") {
      setWrongId(true);
      alert("email을 입력해주세요.");
      return;
    } else if (inputPw == "") {
      setWrongPw(true);
      alert("Password를 입력해주세요.");
      return;
    }
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    const password = await digest(inputPw);
    axios
      .post(url + "/user/login", {
        email: inputId,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          const token = response.data.token;
          console.log(token)
          Auth.login(token);
          // var decoded = jwt_decode(token, jwt_secret);
          // const userName = Auth.getName();
          // const userPermission = decoded.permission;
          // sessionStorage.setItem('login_token', axios.defaults.headers.common['Authorization']);
          // sessionStorage.setItem('login_name', userName);
          // sessionStorage.setItem('permission', userPermission)
          // navigate("/", { replace: true });
          window.location.href = "/";
          
          // checkProfile();
        } else {
          alert("로그인에 실패하였습니다.");
          setWrongId(true);
          setWrongPw(true);
        }
      })
      .catch(function (error) {
        console.log("실패", error);
        alert("로그인에 실패하였습니다.");
        setWrongId(true);
        setWrongPw(true);
      });
  };
  return (
    <div className="login-main-wrap mn">
      <div className="nv-line"></div>
      <NavBar type={"investee"} backgroundColor={"#f9f9f9"} />
      <main className="login-main">
        <div className="login">
          <div className="contents-container">
            <Header />
            <div className="form">
              <Form
                type="email"
                wrong={wrongId}
                _onKeyDown={_onKeyDown}
                input={inputId}
                handleInput={handleInputId}
              />
              <Form
                type="password"
                wrong={wrongPw}
                _onKeyDown={_onKeyDown}
                input={inputPw}
                handleInput={handleInputPw}
              />
              <div>
                <div
                  className="btn btnstyle"
                  disabled={btnState}
                  onClick={() => onSubmitHandler()}
                >
                  <span className="span-btn">Log in</span>
                </div>
              </div>
            </div>
            <div className="social-login mn">
              {/* <p className="p mn-extended">or log in with social</p>
              <div style={{ display: "flex" }}>
                <button className="icon-btn">
                  <AppleIcon className="svg" />
                </button>
                <button className="icon-btn">
                  <Kakaotalk className="svg" />
                </button>
                <button className="icon-btn" >
                  <TwitterIcon className="svg" />
                </button>
                <button className="icon-btn">
                  <LinkedInIcon className="svg" />
                </button>
              </div> */}
            </div>
            <div className="help-login mn">
              <a style={{ marginTop: "1em" }}>I forgot my password</a>
              <p>
                Need help? Email<a> join@pitchdeck.com </a>for support.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
  // return (
  //   <div className='intro_root' style={{padding: '5% 35%'}}>
  //     <h2 style={{marginTop:80, textAlign: 'center'}}>
  //     피치덱 로그인
  //     </h2>
  //     <div className='col_btn_box'>
  //     <input className="input_box" type="text" style={{borderRadius:0, textAlign: 'center', fontSize: 16}} onKeyDown={_onKeyDown} placeholder="아이디" name='id' value={inputId} onChange={handleInputId} required />
  //       <input className="input_box" type="password" style={{borderRadius:0, textAlign: 'center', fontSize:16}} onKeyDown={_onKeyDown} placeholder="비밀번호" name='passwd' value={inputPw} onChange={handleInputPw} required />
  //       <div  onClick={onSubmitHandler}>로그인</div>
  //     </div>
  //   </div>
  // );
}

export default Login;
