import React, {useState, useRef} from 'react';
import {WriteDragTypes} from 'utils/WriteDragTypes'
import { useDrop, useDrag } from 'react-dnd'
import NvTextArea from 'components/NvTextArea'
import ClearIcon from '@mui/icons-material/Clear';
import Dropzone from 'react-dropzone'
import styled from 'styled-components';
import HeightOutlinedIcon from '@mui/icons-material/HeightOutlined';
import { useRecoilState} from 'recoil'
import {isDragState} from 'utils/state'
import LockIcon from '@mui/icons-material/LockOutlined';
import NewsWriteContent from './NewsWriteContent'
import _ from 'lodash';

const WriteItem = ({moveDrop,type, item, id, isWrite, handleUpdate,moveWrites, index, writes, setWrites}) => {
    const ref = useRef(null)
    const [dragPosition, setDragPosition] = useState('');
    const [{ canDrop, isOver, handlerId }, drop] = useDrop({
        accept:'WriteCard',
        collect:(monitor) => ({
            isOver: monitor.isOver(),
            handlerId: monitor.getHandlerId()
        }),
        hover: (item, monitor) => changeHover(item, monitor),
    })
    const [{ isDragging }, drag] = useDrag({
        type: 'WriteCard',
        item: { listboxtype:type, id:id,index:index},
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),

    })

    const changeHover = (item, monitor)=>{
        let {type, listboxtype, id, content}  = item;
        if(!ref.current){
            return;
        }           
        var dragIndex = item.index;
        var hoverIndex = index;
        if(dragIndex === hoverIndex) {
            return;
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top)/2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if(hoverClientY < hoverMiddleY){
            setDragPosition('up')
        }
        if(hoverClientY > hoverMiddleY){
            setDragPosition('down')
        }
        if(dragIndex < hoverIndex && hoverClientY < hoverMiddleY){
            return;
        }

        if(dragIndex > hoverIndex && hoverClientY > hoverMiddleY){
            return;
        }

        if(item.index != undefined){
            moveWrites(dragIndex, hoverIndex);
            item.index = hoverIndex
        }
        else{
            moveDrop(item, hoverIndex)
            console.log(item)
            item.index = hoverIndex
        }

    }

    const handleClear = () => {
        setWrites(prev => {
            let tmp = _.cloneDeep(prev);
            console.log(tmp)
            tmp.splice(index,1)
            return tmp;
        })
    }

    drag(drop(ref))

    return(
        <div 
            ref={isWrite ? ref : null} 
            className={'news-write-drag-box ' } 
            data-handler-id={handlerId}
        >
            {/* {isHover && <div
                className='news-isdragdrop-box'
                style={{
                    display:((upDownState&&isOver)?'flex':'none'),
                }}
            >
                추가
            </div>} */}
            {(isDragging || item.status == 'temp')
            ?
                <div className='dragging'/>
            :
                <NewsWriteContent item={item} isWrite={isWrite} handleUpdate={handleUpdate} isMenu handleClear={handleClear} drag={drag} index={index} />
            }
            {/* {isHover && <div
                className='news-isdragdrop-box'
                style={{
                    display:((!upDownState&&isOver)?'flex':'none'),
                }}
            >
                추가
            </div>} */}
        </div>
    )
}

export default WriteItem;