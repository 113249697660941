import axios from 'axios'
import url from 'utils/backend'
const getImageSignedURL = async(key, mimetype) => {
    try{
        const res = await axios.get(url + `/news/image?key=${key}&mimetype=${mimetype}`)
        return res.data.file;
    }
    catch(e){
        console.log(e)
    }
}
export default getImageSignedURL