import React from 'react'
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import { indexOf } from 'lodash';
const WriteLeftSide = ({setSideBtn, height,sideBtnDir, setSideBtnDir, setIsWrite, isWrite}) =>{
    return(
        <aside className='news-write-sidebar' style={{top:(isWrite?height-50:height)}}>
            <div className='stickybar-wrap'>
                <div className='stickybar'>
                    <div className='bar-in'>
                        <article className='tools'>
                            <strong className='str'>글쓰기도구</strong>
                            <div className='kit column-align'>
                                {/* <button type='button' className='icon-btn'>
                                    <PhotoSizeSelectActualOutlinedIcon className='icon'/>
                                    <div className='btn-txt'>사진</div>
                                </button>
                                <button type='button' className='icon-btn'>
                                    <SlideshowOutlinedIcon className='icon'/>
                                    <div className='btn-txt'>영상</div>
                                </button>
                                <button type='button' className='icon-btn'>
                                    <DriveFolderUploadOutlinedIcon className='icon'/>
                                    <div className='btn-txt'>파일</div>
                                </button>
                                <button type='button' className='icon-btn'>
                                    <PhotoLibraryOutlinedIcon className='icon'/>
                                    <div className='btn-txt'>포토DB</div>
                                </button>
                                <button type='button' className='icon-btn'>
                                    <SaveOutlinedIcon className='icon'/>
                                    <div className='btn-txt'>임시보관한</div>
                                </button>
                                <a className='icon-btn'>
                                    <AutoFixHighOutlinedIcon className='icon'/>
                                    <div className='btn-txt'>사진편집</div>
                                </a> */}
                                {/* <button type='button' className='icon-btn column-align'>
                                    <FormatQuoteIcon className='icon'/>
                                    <div className='btn-txt'>템플릿</div>
                                </button>
                                <button type='button' className='icon-btn column-align'>
                                    <AlternateEmailIcon className='icon'/>
                                    <div className='btn-txt'>특수문자</div>
                                </button> */}
                                {/* <button type='button' className='icon-btn column-align'>
                                    <HorizontalRuleIcon className='icon'/>
                                    <div className='btn-txt'>구분선</div>
                                </button> */}
                                <button 
                                type='button' 
                                className='icon-btn column-align' 
                                onClick={()=>{
                                    setIsWrite(!isWrite)
                                    setSideBtnDir(!sideBtnDir)
                                    setSideBtn(true)
                                }
                                }
                                >
                                    <ScreenshotMonitorIcon className='icon' />
                                    <div className='btn-txt'>{isWrite?'미리보기':'수정하기'}</div>
                                </button>
                                
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default WriteLeftSide