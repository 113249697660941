import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import axios from 'axios';
import url from 'utils/backend';
import Header from 'components/admin/Header';
import View from 'components/news/view/View'
import {getLabel} from 'utils/NewsStatus'
import './news-view.scss'
import AlertConfirm from 'components/AlertConfirm';
import BuildIcon from '@mui/icons-material/Build';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ListIcon from '@mui/icons-material/List';
const NewsView = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {id} = useParams();
    const [news, setNews] = useState('');
    const getNews = async() => {
        try{
            const res = await axios.get(url + '/news?id=' + id);
            setNews(res.data)
        }
        catch(e){
            console.log(e)
        }
    }
    const removeNews = async() => {
        try{
            const res = await axios.post(url + '/news/remove', {
                news_id: id
            })
            navigateList()
        }
        catch(e){
            console.log(e)
        }
    }
    const confirmRemove = () => {
        AlertConfirm({
            title: '기사를 삭제하겠습니까?',
            isConfirmed: removeNews,
            isDenied: () => null
        })
    }

    const navigateList = () => {
        if(location.state?.prevPath == '/member/newslist'){
            window.history.back()
        }
        else{
            navigate('/member/newslist')
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getNews();
    }, [id])


    return(
        <div className='mynews-main-wrap mg'>                
            <Header title={'내 기사보기'}/>
            <div className='mynews-main'>
                <section className='mynews'>
                    <div>
                        <div className='news-view-top-box member'>
                            <div className='top-btn-box'>
                                {news &&
                                    <div className='news-view-status'>
                                        {getLabel(news.status)}
                                    </div>
                                }
                                <div className='rc'>
                                    <div className='top-btn' onClick={navigateList}>
                                        <ListIcon/>
                                        목록
                                    </div>
                                    {(news.status == 'write' || news.status == 'reject') &&
                                        <>
                                        <div className='top-btn edit' onClick={() => navigate('/member/newswrite/' + id)}>
                                            <BuildIcon style={{transform: 'rotateY(180deg)'}}/>
                                            수정
                                        </div>
                                        <div className='top-btn' onClick={confirmRemove}>
                                            <DeleteOutlineOutlinedIcon/>
                                            삭제
                                        </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {news &&
                        <View
                            title={news.title}
                            subtitle={news.subtitle}
                            mainImage={news.main_image}
                            author={news.author}
                            date={news.created_at}
                            data={news.data}
                        />
                    }
                </section>
            </div>
        </div>
    )
}

export default NewsView