import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
// import 'styles/common.scss';
import ConvertComma from 'utils/ConvertComma'
function NvNumberInput({defaultValue, unit = '원', type = 'float', onChange, state, setState, isComma, className }) {
    const [value, _value] = useState('');

    const _onlyNumber = (v) => {
        if (type == 'float') {
            return v.replace(/[^0-9.-]/g, '');
        } else if (type == 'unsigned int') {
            return v.replace(/[^0-9]/g, '');
        } else if (type == 'signed int') {
            return v.replace(/[^0-9-]/g, '');
        } else {
            return v
        }
    }

    useEffect(() => {
        if(isComma){
            _value(ConvertComma.comma(defaultValue));
        }
        else{
            _value(defaultValue);
        }
    }, [defaultValue])

    return (
        <OutlinedInput 
        className={className? className + ' nv-numberinput' : 'nv-numberinput'}
        value={(state != undefined) ? state : value} onChange={(e) => {
            let v;
            if(isComma) {
                v = ConvertComma.comma(_onlyNumber(e.target.value))
            }
            else{
                v = _onlyNumber(e.target.value)
            }
            if(setState != undefined) setState(v)
            _value(v)
            if(onChange) onChange(v)
        }} endAdornment={<InputAdornment position="end">{unit}</InputAdornment>} size="small" />
    );
}

export default NvNumberInput;