import React, {useEffect, useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import { growthList } from 'utils/DropDownList';

const InputCheck2 = ({state, setState, type}) => {
    const gender = [{value: 'male', label: '남자'}, {value: 'female' , label: '여자'}];
    const founder = [{value: 'yes', label: 'Yes 창업 멤버입니다'}, {value: 'no', label: 'No 창업 멤버가 아닙니다'}]
    const [options, setOptions] = useState('');

    const CheckBox = styled.div `
    span{
      font-size: 14px !important;
    }
    .css-i4bv87-MuiSvgIcon-root{
      fill: #808080 ;
    }
    .Mui-checked{
      .MuiSvgIcon-root{
        fill: rgb(14, 81, 255);
      }
    }
  `;
  useEffect(() => {
    if(type == 'round'){
      setOptions(growthList);
      
    }
    else if(type == 'founder'){
      setOptions(founder)
    }
  },[])
  const handleChange = (value) => {
    if(state.includes(value)){
      state.splice(state.indexOf(value), 1)
    }
    else{
      state.push(value)
    }
    setState(JSON.parse(JSON.stringify(state)))
  }
    return(
      <div className='check_boxes'>
        {(options) &&
          <CheckBox>                    
            {options.map((data, i) => {
              return(
                  <FormControlLabel  control={<Checkbox  checked={state.includes(data.text)} onChange={() => handleChange(data.text)}/>} style={(state.includes(data.text)) ? {color: 'rgb(14, 81, 255)'} : null} label={data.text} />
              )
            })}
          </CheckBox> 
        }
      </div> 
    )
}
export default InputCheck2;