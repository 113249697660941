import React from 'react'
import IssueCard from './IssueCard';
import {useNavigate} from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Section from './Section'
const CardContainer = ({list}) =>{
    const navigate = useNavigate()
    const handleIsUrl = () =>{
        navigate('?type='+(list.topic))
    }
    
    return(
        <section className='main-investee-wrap'>
            <div className='main-investee'>
                <div className='header-box'>                            
                    <h2 className='header-title'>{list.topic}</h2>        
                </div>
                {list.data.map(d=>{
                    return(
                        <Section list={d.info} header={d.header}/>
                    )
                    }) 
                }
                <a className='tail-box' onClick={()=>handleIsUrl()}>
                    <p>{list.tail}</p>
                    <ArrowForwardIosIcon style={{width:12, height:24}}/>
                </a>
            </div>
        </section>
    )
}

export default CardContainer