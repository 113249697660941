import jwt_decode from "jwt-decode";
import jwt_secret from "./jwt_secret";
import axios from "axios";
import url from "./backend";

class Auth {
  static isLoggedIn() {
    if (
      window.localStorage.getItem("pitchdeck_auth_token") &&
      window.localStorage.getItem("pitchdeck_auth_name")
    ) {
      let token = window.localStorage.getItem("pitchdeck_auth_token");
      var decoded = jwt_decode(token);
      if (decoded.exp * 1000 < Date.now()) {
        this.logout();
      }
      // axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
      return true;
    } else {
      return false;
    }
  }
  static isMy(id) {
    var token = window.localStorage.getItem("pitchdeck_auth_token");
    var decoded = jwt_decode(token);
    if (id == decoded.user_id) {
      return true;
    } else {
      return false;
    }
  }
  static login(token) {
    var decoded = jwt_decode(token, jwt_secret);
    window.localStorage.setItem("pitchdeck_auth_token", token);
    window.localStorage.setItem("pitchdeck_auth_name", decoded?.name);
    window.localStorage.setItem("pitchdeck_auth_email", decoded?.email);
    window.localStorage.setItem("pitchdec_auth_type", decoded?.type);
    window.localStorage.setItem("pitchdeck_auth_category", decoded?.category);
    window.localStorage.setItem(
      "pitchdeck_auth_companyName",
      decoded?.company_name
    );
    window.localStorage.setItem("pitchdeck_auth_nickname", decoded?.nickname);
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    console.log(token)

  }
  
  static logout() {
    window.localStorage.removeItem("pitchdeck_auth_token");
    window.localStorage.removeItem("pitchdeck_auth_name");
    window.localStorage.removeItem("pitchdeck_auth_email");
    window.localStorage.removeItem("pitchdec_auth_type");
    window.localStorage.removeItem("pitchdeck_auth_category");
    window.localStorage.removeItem("pitchdeck_auth_companyName");
    window.localStorage.removeItem("pitchdeck_auth_nickname");
    axios.defaults.headers.common["Authorization"] = "";
  }
  static getId() {
    var token = window.localStorage.getItem("pitchdeck_auth_token");
    var decoded = jwt_decode(token, jwt_secret);
    return decoded.user_id;
  }
  static getCompanyId() {
    var token = window.localStorage.getItem("pitchdeck_auth_token");
    var decoded = jwt_decode(token, jwt_secret);
    return decoded.company_id;
  }
  static async isProfile() {
    try {
      const res = await axios.get(url + "/user/company");
      if (res.data.company != null) {
        return true;
      } else {
        return false;
      }
    } catch (e) {}
  }
  static getType() {
    return window.localStorage.getItem("pitchdeck_auth_type");
  }
  static getName() {
    return window.localStorage.getItem("pitchdeck_auth_name");
  }
  static getCategory() {
    return window.localStorage.getItem("pitchdeck_auth_category");
  }
  static getCompanyName() {
    return window.localStorage.getItem("pitchdeck_auth_companyName");
  }
  static getNickname() {
    return window.localStorage.getItem("pitchdeck_auth_nickname");
  }
  static getEmail(){
    return window.localStorage.getItem("pitchdeck_auth_email");
  }
}
export default Auth;
