import React from 'react'

const Header = () =>{
    return(
        <header className='header'>
            <h1 className='mn'>Log in</h1>
            <p className='mn'>Don't have an account?
            <a href='/profile/create'> Become a member</a>.
            </p>
        </header>
    )
}

export default Header