import React, {
  useCallback,
  useRef,
  useState,
  useEffect
} from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import NvDatePicker from "components/NvDatePicker";
import NvNumberInput from "components/NvNumberInput";
import NvTextInput from 'components/NvTextInput'
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import NvAutocomplete from "components/NvAutoComplete";
import { growthList } from "utils/DropDownList";
const NewInvestmentModal = ({ id, name, visible, newInvestment, setNewInvestment, setVisible }) => {
  const [tmp, setTmp] = useState(JSON.parse(JSON.stringify(newInvestment)))
  const navigate = useNavigate();
  const handleSave = () => {
    setNewInvestment(tmp)
    setVisible(false)
  }
  return (
    <div className='register_modal_root'>
      <div>
        <div className='modal_header'>
          <div className="register-modal-btn-box">
            <h2>신규 투자 유치</h2>
          </div>
          <IconButton onClick={() => setVisible(false)} >
            <CancelIcon className="register-modal-btn" />
          </IconButton>
        </div>
        <hr/>
      </div>
      <div className='description2' style={{height: '100%'}}>
        <div className='input_box_stage'>
          <p>투자유시 시작 년,월을 입력해 주세요</p>
          <div className="input-box-wrap" style={{width: '70%'}}>
            <NvDatePicker className='input-box' defaultValue={tmp.starting_date} onChange={(v) => tmp.starting_date = v}  style={{fontSize:12, borderRadius: 5}} />
          </div>
        </div>
        <div className='input_box_stage'>
          <p>투자전 희망밸류를 입력해 주세요</p>
          <div className="input-box-wrap" style={{width: '70%'}}>
            <NvNumberInput className='input-box' unit='억' defaultValue={tmp.pre_value} onChange={(v) => tmp.pre_value = v }/>
          </div>
        </div>
        <div className='input_box_stage'>
          <p>투자 받고자 하는 금액을 입력해 주세요</p>
          <div className="input-box-wrap" style={{width: '70%'}}>
            <NvNumberInput className='input-box' unit='억' defaultValue={tmp.volume} onChange={(v) => tmp.volume = v}/>
          </div>
        </div>
        <div className='input_box_stage'>
          <p>투자 단계를 입력해 주세요</p>
          <div className="input-box-wrap" style={{width: '70%'}}>
            <NvAutocomplete options={growthList} className='input-box' defaultValue={tmp.round} onChange={(v) => tmp.round = v}/>
          </div>
        </div>
      </div>
      <div className='btn_box btn_box_center'>
        <div onClick={(e) => { handleSave()}}>저장하기</div>
      </div>
    </div>
  );
};

export default NewInvestmentModal;