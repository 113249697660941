import React, { useEffect, useState } from 'react'
import { AspectRatio } from 'react-aspect-ratio';
import ViewImage from './ViewImage';

const Head = ({title, subtitle, mainImage, isPlaceholder}) =>{
    return(
        <header className='detail-main-header'>
            <div className='header-textbox'>
                <div className='text-wrapper mn-extended'>
                    <div className='text'>
                    {isPlaceholder
                    ?
                        (subtitle || '부제목')
                    :
                        (subtitle)
                    }
                    </div>
                </div>
                <h1 className='h1-style mn'>
                    {isPlaceholder
                    ?
                        (title || '제목')
                    :
                        (title)
                    }    
                </h1>
            </div>
            {isPlaceholder
            ?
                (mainImage
                ? 
                    <ViewImage src={mainImage} item={mainImage}/> 
                :
                    <div className='img-empty news-view-img-container'>
                        대표 이미지
                    </div>
                )
            :
                <ViewImage src={mainImage} item={mainImage}/>
            }
        </header>
    )
}

export default Head