const RemoveHtml = (v) => {
    var entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };

    function swap(json){
        var ret = {};
        for(var key in json){
          ret[json[key]] = key;
        }
        return ret;
    }
    entityMap = swap(entityMap)
    let res = v.toString()
    res = res.replace(/<[^>]*>/g, '');
    res = res.replaceAll('&nbsp;', ' ');
    for(var char in entityMap) {
        var before = char;
        var after= entityMap[char]; 
        var pattern = new RegExp(before, 'g');
        res = res.replace(pattern,after);    
    }
    return res;

} 

export default RemoveHtml