import React from 'react'
const AccountWithdraw = ({handleUpdate}) => {
    return(
        <div className='profile-edit-content-container'>
            <div className='content-title' style={{marginTop: 35, textAlign: 'center', width: '100%'}}>
            정말 PITCHDECK을 탈퇴하시겠어요?
            </div>
            <br/>
            <br/>
            <div className='content-subtitle' style={{textAlign: 'center'}}>
                계정을 탈퇴하면 계정 정보 및서비스 이용 기록 등 모든 정보가 삭제됩니다.
                <br/>
                탈퇴된 계정 정보와 서비스 이용 기록 등은 복구할 수 없으니 신중하게 선택해주시기 바랍니다.
            </div>
            <div style={{width: '100%',display: 'flex', justifyContent: 'center', paddingBottom: 30, marginTop: 50}}>
                <div className='profile-edit-btn' onClick={handleUpdate}>
                    회원 탈퇴
                </div>
            </div>
        </div>
    )
}
export default AccountWithdraw