import React, {useEffect, useState, useMemo} from 'react';
import Header from 'components/admin/Header'
import LeftSideBar from 'components/admin/LeftSideBar'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import {debounce, throttle} from 'lodash'
import {WriteDragTagNames, WriteDragTypes} from 'utils/WriteDragTypes'
import GenerateId from 'utils/GenerateId'
import axios from 'axios'
import url from 'utils/backend'
import _ from 'lodash'
import CurrentInfo from 'pages/member/news-room/component/CurrentInfo'
import WriteLeftAside from 'components/news/write/WriteLeftAside'
import WriteRightAside from 'components/news/write/WriteRightAside'
import WriteCard from 'components/news/write/WriteCard'
import { useNavigate, useParams } from 'react-router-dom';
import AlertConfirm from 'components/AlertConfirm';
import {statusList} from 'utils/NewsStatus'
import Auth from 'utils/auth';
import View from 'components/news/view/View';
import NvTextInput from 'components/NvTextInput';
import AutoComplete from 'components/AutoComplete';
import update from 'immutability-helper'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {statusAdminList, getValue, getLabel} from 'utils/NewsStatus'
import classnames from 'classnames';
import { DatePicker, Select, Space, TimePicker } from 'antd';
import NvNewDatePicker from 'components/NvNewDatePicker';
import NvTimePicker from 'components/NvTimePicker';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
const sectionList=[
    {
        sec1: 'Emerging industries',
        sec2:[]
    },
    {
        sec1:'Tech',
        sec2:[]
    },
    {
        sec1:'New Invest',
        sec2:[]
    },
    {
        sec1:'업무협약',
        sec2:[]
    },
    {
        sec1:'자사이벤트',
        sec2:[]
    },
    {
        sec1:'실적',
        sec2:['실적발표', '자산매각', '매출•투자계약']
    },
    {
        sec1:'수상내역',
        sec2:['장관상']
    },
    {
        sec1:'ESG경영',
        sec2:['취약계층지원', '봉사활동',  '탄소중립', '직원격려']
    },
    {
        sec1:'주주친화적행보',
        sec2:['자사주 매입', '무증','소각','배당확대', 'IPO']    
    },
    {
        sec1:'기술',
        sec2:['특허출원','기술개발']
    },
    {
        sec1:'제품및서비스소개',
        sec2:[]
    },
    {
        sec1:'채용',
        sec2:[]
    },
    {
        sec1:'지원사업선정',
        sec2:[]
    },
    {
        sec1:'투자',
        sec2:[]
    },
    {
        sec1:'IP',
        sec2:[]
    },
    {
        sec1:'정부과제',
        sec2:[]
    },
    {
        sec1:'기술',
        sec2:[]
    },
]
const levelList = [
    {
        label: '일반기사',
        value: 'basic'
    },
    {
        label: '중요기사',
        value: 'important'
    },
    {
        label: '헤드라인',
        value: 'headline'
    }
]
const typeList = [  
    '연재',
    '포토뉴스'
]
const NewsWrite = () => {
    const {id} = useParams();
    const navigate = useNavigate()
    const [sideBtn, setSideBtn] = useState(true)
    const [sideBtnDir, setSideBtnDir] = useState(true)
    const [scroll, setScroll] = useState(false);
    const [isWrite, setIsWrite] = useState(true)
    const [newsId, setNewsId] = useState('');
    const [writes, setWrites] = useState([]);
    const [height, setHeight] = useState(50);
    const [loading, setLoading] = useState(true)
    const [activeAddOption, setActiveAddOption] = useState(false);
    const [addOptionDelay, setAddOptionDelay] = useState(false);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);

    const listener = useMemo(
      () =>
        throttle(() => {
          setHeight(window.pageYOffset + 50)
        }, 300),
      [height]
    )

    const [section, setSection] = useState({
        section_first: null,
        section_second: null,
        type: '연재'

    });

    const [author, setAuthor] = useState({
        name: Auth.getName(),
        email: Auth.getEmail()
    })
    const [level, setLevel] = useState(levelList[0].value)    
    const [status, setStatus] = useState(statusAdminList[0].value)
    const [necessaryItems, setNecessaryItems] = useState([
        {id:GenerateId(), type: WriteDragTypes.SUBTITlE, innerHtml: '', tagName: WriteDragTagNames.SUBTITlE}, 
        {id:GenerateId(), type: WriteDragTypes.TITLE, innerHtml: '', tagName: WriteDragTagNames.TITLE}, 
        {id:GenerateId(), type: WriteDragTypes.IMG, innerHtml: '', tagName: WriteDragTagNames.IMG},
        {id:GenerateId(), type: WriteDragTypes.TXT, innerHtml: '', tagName: WriteDragTagNames.TXT}
    ])


    //밑에 드래그 관련 함수
    const handleDrop = (item, num) => {
        let {type, tagName,listboxtype, id, content}  = item;
        var index = -1;
        let newWrite = {
            id: GenerateId(),
            type: type,
            tagName: tagName,
            innerHtml: ''
        }
        index = writes.findIndex(e => e.id == id)
        if(type){
        setWrites(prev => {
            let tmp = _.cloneDeep(prev);
            if(num){
                tmp.splice(num + 1, 0, newWrite)
            }
            else{
                tmp = [...prev, newWrite];            
            }
            return tmp;
        })}
        else{
        setWrites(
            update(writes, {
                $splice: [
                    [index, 1],
                    [writes.length, 0 , {id:id, type:listboxtype}]
                ]
            })
        )
    }
    }
    const uploadImg = async(news_id, list) => {
        try{
            await Promise.all(
                list.map(async data => {
                    if(data.type == 'img' && !data.innerHtml && data.file){
                        // console.log(data, data.file, data.docs)
                        const res = await axios.post(url + '/news/image', {
                            news_id: news_id,
                            mimetype: data.file.type,
                            name: data.file.name
                        });
                        const res2 = await fetch(res.data.uploadURL, {
                            method: 'put',
                            body: data.file
                        })
                        data.innerHtml = ''
                        data.key = res.data.key
                        data.mimetype = data.file.type
                        URL.revokeObjectURL(data.file.preview) 
                        delete data.file
                    }
                })
            )
            return list;
        }
        catch(e){
            console.log(e)
        }
    }
    const updateNews = async(news_id, necessaryData, writeData) => {
        try{
            let reserve_date = null;
            let approved_at = null;
            let rejected_at = null;
            let applying_at = null;
            if(status == 'approve'){
                approved_at = new Date();
            }
            if(status == 'reject'){
                rejected_at = new Date();
            }
            if(status == 'apply'){
                applying_at = new Date()
            }
            if(date != null){
                let tmpDate = new Date(date);
                let tmpTime = new Date(time) 
                if(time != null){
                    reserve_date = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), tmpTime.getHours(), tmpTime.getMinutes())
                }
                else{
                    reserve_date = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), 0, 0, 0)
                }
            }
            const res = await axios.post(url + '/news', {
                section: section,
                author: author,
                title: necessaryData.find(e => e.type == WriteDragTypes.TITLE).innerHtml,
                subtitle:  necessaryData.find(e => e.type == WriteDragTypes.SUBTITlE).innerHtml,
                main_image:  necessaryData.find(e => e.type == WriteDragTypes.IMG),
                data: [necessaryData.find(e => e.type == WriteDragTypes.TXT), ...writeData],
                news_id: news_id,
                status: status,
                level: level,
                reserve_date: reserve_date,
                approved_at: approved_at,
                rejected_at: rejected_at,
                applying_at: applying_at
            })
        }
        catch(e){
            console.log(e)
        }
    }
    const createNews = async() => {
        try{
            const res = await axios.put(url + '/news');
            return res.data._id

        }
        catch(e){
            console.log(e)
        }
    }

    const handleSave = async() => {
        let stop = false
        for(const key in section){
            if(!section[key]){
                AlertConfirm({
                    title: '섹션 항목을 모두 입력해 주세요.'
                })
                stop = true
            }
        }
        if(stop) return
        for(const key in author){
            if(!author[key]){
                let msg = ''
                if(key == 'name'){
                    msg = '기자명을 입력해 주세요.'
                }
                else if(key == 'email'){
                    msg = '이메일을 입력해 주세요.'
                }
                AlertConfirm({
                    title: msg
                })
                stop = true
            }
        }
        if(stop) return
        necessaryItems.some(data => {
            if(!data.innerHtml && (!data.file && !data.key)){
                let msg = ''
                if(data.type == WriteDragTypes.TITLE){
                    msg = '제목을 입력해 주세요.'
                }
                else if(data.type == WriteDragTypes.SUBTITlE){
                    msg = '부제목을 입력해 주세요.'
                }
                else if(data.type == WriteDragTypes.IMG){
                    msg = '대표 사진을 업로드해 주세요.'
                }
                else if(data.type == WriteDragTypes.TXT){
                    msg = '본문을 입력해 주세요.'
                }
                AlertConfirm({
                    title: msg
                })
                stop = true
                return true
            }
        })
        if(stop) return
        var writeData = []
        writes.map(data => {
            if(data.type == WriteDragTypes.IMG){
                if(data.file || data.key){
                    writeData.push(data)
                }
            }
            else if(data.type == WriteDragTypes.LINE){
                writeData.push(data)
            }
            else{
                if(data.innerHtml){
                    writeData.push(data)
                }
            }
        })
        var necessaryData = _.cloneDeep(necessaryItems)
        if(!newsId){
            const id = await createNews()
            writeData = await uploadImg(id, writeData)
            necessaryData = await uploadImg(id, necessaryData)
            await updateNews(id, necessaryData, writeData);
            navigate('/admin/newsview/' + id)

        }
        else{
            writeData = await uploadImg(newsId, writeData)
            necessaryData = await uploadImg(newsId, necessaryData)
            await updateNews(newsId, necessaryData, writeData);
            navigate('/admin/newsview/' + newsId)

        }
    }
    const confirmRemove = () => {
        AlertConfirm({
            title: '기사를 삭제하시겠습니까?',
            isConfirmed: handleRemove,
            isDenied:() => null
        })
    }
    const handleRemove = async() => {
        try{
            const res = await axios.post(url + '/news/remove', {
                news_id: newsId
            })
            navigate('/admin/newslist')
        }
        catch(e){
            console.log(e)
        }
    }
    const handleUpdateSection = (value, level) => {
        setSection(prev => {
            let tmp = _.cloneDeep(prev);
            tmp[level] = value;
            if(level == 'section_first'){
                tmp.section_second = ''
            }
            return tmp;
        })
    }
    const handleUpdateAuthor = (value, level) => {
        setAuthor(prev => {
            let tmp = _.cloneDeep(prev);
            tmp[level] = value;
            return tmp;
        })
    }
    const getNewsData = async() => {
        try{
            const res = await axios.get(url + '/news?id=' + id);
            const result = res.data
            setSection(result.section)
            setAuthor(result.author)
            setStatus(result.status)
            setNecessaryItems(prev => {
                let tmp = _.cloneDeep(prev);
                tmp.map((data, i) => {                
                    if(data.type == WriteDragTypes.SUBTITlE){
                        data.innerHtml = result.subtitle
                    }
                    if(data.type == WriteDragTypes.TITLE){
                        data.innerHtml = result.title
                    }
                    if(data.type == WriteDragTypes.IMG){
                        data.key = result.main_image.key
                        data.mimetype = result.main_image.mimetype
                    }
                    if(data.type == WriteDragTypes.TXT){
                        tmp[i] = result.data.shift()
                    }
                })
                return tmp;
            })
            setWrites(result.data)
            setLoading(false)

        }
        catch(e){
            console.log(e)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listener)
        return () => {
          window.removeEventListener('scroll', listener)
        }
    }, [height])

    useEffect(() => {
        if(id){
            setNewsId(id)
            getNewsData()
        }
        else{
            setLoading(false)
        }
    }, [id])

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        
        }, 300);
        
    }, [isWrite])
    

    return(
        <DndProvider backend={HTML5Backend}>
        <div className='admin-main-wrap mg'>
            <Header title={'대시보드'}/>
            <div className='admin-main'>
                <section className='admin-body mg' >
                    <LeftSideBar/>
                            <div 
                                className={'news-write-main admin '+ (!isWrite && ' preview ') +(sideBtn?' active-bar':'')}
                            >
                                <div className='news-writer'>
                                    <WriteLeftAside height={height} setSideBtnDir={setSideBtnDir} sideBtnDir={sideBtnDir} sideBtn={sideBtn} setSideBtn={setSideBtn} isWrite={isWrite} setIsWrite={setIsWrite}/>
                                    <div className='write-form-wrap'>
                                        <div className='write-form'>
                                            {isWrite && 
                                            <header className='topline'>
                                                <div className='title'>
                                                    <strong className='strong'>기사쓰기</strong>
                                                </div>
                                            </header>}
                                            <article className='write-body'>
                                                {isWrite && 
                                                    <>
                                                    <div className='write-type-wrap'>
                                                        <div className='write-label'>
                                                            등급&nbsp;
                                                            <HelpOutlineOutlinedIcon className='svg'/>
                                                        </div>
                                                        <div className='write-type'>
                                                            <div className='three-type'>
                                                                {levelList.map((data, i) => {
                                                                    return(
                                                                        <label className={'type-btn'+(level == data.value ?'-checked':'') + ' grade '} onClick={()=> setLevel(data.value)}>
                                                                            {data.label}
                                                                        </label>     
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                    <div className='write-type-wrap'>
                                                        <div className='write-label'>
                                                            상태&nbsp;
                                                        </div>
                                                        <div className='write-type'>
                                                            <div className='three-type'>
                                                                {statusAdminList.map((data, i) => {
                                                                    return(
                                                                        <label className={'type-btn'+(status == data.value ?'-checked':'')} onClick={()=> setStatus(data.value)}>
                                                                            {data.label}
                                                                        </label>     
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className='accordion gray' data-accordion="uqstqo-accordion" data-multi-expand="true" data-allow-all-closed="true">
                                                        <li className={classnames('accordion-item', {'active': activeAddOption})} data-accordion-item >
                                                            <a className="accordion-title" title="추가옵션" aria-controls="gx1mhc-accordion" role="tab" aria-expanded={activeAddOption} aria-selected="false" onClick={() => {
                                                                    setActiveAddOption(!activeAddOption)
                                                                    
                                                                }}
                                                            >
                                                                추가옵션
                                                            </a>
                                                            <div className='accordion-content' >
                                                                {/* <div className='write-type-wrap'>
                                                                    <div className='write-label'>
                                                                        형태&nbsp; 
                                                                    </div>
                                                                    <div className='write-type'>
                                                                        <div className='three-type'>
                                                                            {statusAdminList.map((data, i) => {
                                                                                return(
                                                                                    <label className={'type-btn'+(status == data.value ?'-checked':'')} onClick={()=> setStatus(data.value)}>
                                                                                        {data.label}
                                                                                    </label>     
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className='wt-forms'>
                                                                    <div className='wt-forms-label'>
                                                                        노출시간&nbsp; 
                                                                    </div>
                                                                    <div className='wt-forms-content'>
                                                                        <div className='wt-date-box'>
                                                                            <div className='wt-date-icon'>
                                                                                <EventAvailableOutlinedIcon/>
                                                                            </div>
                                                                            <NvNewDatePicker
                                                                                defaultValue={date}
                                                                                onChange={(v) => setDate(v)}
                                                                            />
                                                                        </div>
                                                                        <div className='wt-date-box'>
                                                                            <div className='wt-date-icon'>
                                                                                <AccessTimeOutlinedIcon/>
                                                                            </div>
                                                                            <NvTimePicker
                                                                                defaultValue={time}
                                                                                onChange={(time, timeString) => {
                                                                                    setTime(time._d)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>                                                                
                                                            </div>
                                                        </li>  
                                                    </ul>                                        
                                                    </>
                                                }
                                                {isWrite && 
                                                    <div className='write-section'>
                                                    <div className='section-title'>섹션</div>
                                                        <div className='section-contents'>
                                                            <div className='autocomplete-wrap'>
                                                                <div className='autocom'>
                                                                    <div className='select'>
                                                                        <AutoComplete
                                                                            options={sectionList.map(d => d.sec1)}
                                                                            defaultValue={section.section_first}
                                                                            onChange={(v) => handleUpdateSection(v, 'section_first')}
                                                                            placeholder='1차섹션'
                                                                            isEndAdornment
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='autocom'>
                                                                    <div className='select'>
                                                                        <AutoComplete
                                                                            options={sectionList.find(d => d.sec1 == section.section_first)?.sec2 || []}
                                                                            defaultValue={section.section_second}
                                                                            onChange={(v) => handleUpdateSection(v, 'section_second')}
                                                                            placeholder='2차섹션'
                                                                            isEndAdornment
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='autocom'>
                                                                    <div className='select'>
                                                                        <AutoComplete
                                                                            options={typeList}
                                                                            defaultValue={section.type}
                                                                            onChange={(v) => handleUpdateSection(v, 'type')}
                                                                            isEndAdornment
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {isWrite && 
                                                <div className='my-info-container'>
                                                    <div className='my-info-title'>기자명</div>
                                                    <div className='my-info-contents'>
                                                        <div className='my-info-wrap'>
                                                            <div className='my-info'>
                                                                <div className='input-box'>
                                                                    <NvTextInput
                                                                        defaultValue={author.name}
                                                                        placeholder='이름을 입력해 주세요.'
                                                                        onChange={(v) => handleUpdateAuthor(v, 'name')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='my-info'>
                                                                <div className='input-box'>
                                                                    <NvTextInput
                                                                        defaultValue={author.email}
                                                                        placeholder='이메일을 입력해 주세요.'
                                                                        onChange={(v) => handleUpdateAuthor(v, 'email')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {isWrite
                                                ?
                                                (!loading &&
                                                <WriteCard 
                                                    handleDrop={handleDrop} 
                                                    isWrite={isWrite} 
                                                    setWrites={setWrites} 
                                                    writes={writes} 
                                                    newsId={newsId}
                                                    necessaryItems={necessaryItems}
                                                    setNecessaryItems={setNecessaryItems}
                                                />)
                                                :
                                                <View
                                                    title={necessaryItems.find(e => e.type == WriteDragTypes.TITLE).innerHtml}
                                                    subtitle={necessaryItems.find(e => e.type == WriteDragTypes.SUBTITlE).innerHtml}
                                                    mainImage={necessaryItems.find(e => e.type == WriteDragTypes.IMG)}
                                                    author={author}
                                                    date={new Date()}
                                                    data={[necessaryItems.find(e => e.type == WriteDragTypes.TXT), ...writes]}
                                                    isPlaceholder
                                                />
                                                }
                                                {/* {isWrite && !loading &&
                                                <div style={{height: 3000}}></div>
                                                } */}
                                            </article>
                                        </div>
                                    </div>
                                </div>    
                            </div>        
                            <WriteRightAside 
                                setSideBtnDir={setSideBtnDir} 
                                borderLeft={(isWrite?'':'1px solid rgba(0,0,0,.15)')} 
                                sideBtnDir={sideBtnDir} 
                                isWrite={isWrite} 
                                setIsWrite={setIsWrite} 
                                scroll={scroll}
                                sideBtn={sideBtn} 
                                setSideBtn={setSideBtn}
                                handleSave={handleSave}
                                confirmRemove={confirmRemove}
                                newsId={newsId}
                                setWrites={setWrites}
                            />

                </section>
            </div>
        </div>
        </DndProvider>
    )
}

export default NewsWrite