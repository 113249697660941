import React, {useEffect, useState} from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import DateFormat_Year_Month_Date_Hour_Minute from 'utils/DateFormat_Year_Month_Date_Hour_Minute'
const CurrentInfo = ({list, statusList}) => { 
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [nowMenu, setNowMenu] = useState(statusList[0].value)
    const getLevelLabel = (v) => {
        if(v == 'important') return '중요'
        else if(v == 'headline') return '헤드'
        else return ''
    }

    useEffect(() => {
        const query_menu = searchParams.get('menu');
        if(query_menu) setNowMenu(query_menu);
    }, [location])

    return(
        <div className='current-list'>
            <article className='list'>
                <ul>
                    {statusList.map(data => {
                        return(
                            <li className='li'>
                                <a aria-selected={nowMenu == data.value} onClick={()=>{
                                    navigate('/admin?menu='+data.value, {replace:true})
                                }}
                                >
                                    <span className='text'>{data.label}</span>
                                    <em className='circlenum'>{list[data.value].total}</em>
                                </a>
                            </li>
                        )
                    })
                    }
                </ul>
                <div className='myarticle-list-wrap'>
                    <div aria-hidden={false} className='myarticle-list' >
                        <table className='table'>
                            <colgroup>
                            <col width='*'></col>
                            <col width='120'></col>
                            <col width='120'></col>
                            </colgroup>
                            <thead className='thead'>
                                <tr className='tr'>
                                    <th scope='col' className='mg'>제목</th>
                                    <th scope='col' className='mg text-center'>기자명</th>
                                    <th scope='col' className='tc mg text-center'>작성일</th>
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                                {list[nowMenu].list.map(data => {
                                    return(
                                        <tr className='tr'>
                                            <th className='' scope='col'>
                                                <Link to={'/admin/newsview/' + data._id}>
                                                    <span 
                                                        className='span'
                                                    >
                                                        {getLevelLabel(data.level) &&
                                                            <span className='span-label level'>
                                                                {getLevelLabel(data.level)}
                                                            </span>

                                                        }
                                                        <span className='span-label'>
                                                            {nowMenu != 'apply'
                                                            ?
                                                                statusList.find(e => e.value == nowMenu).label
                                                            :
                                                                '승인신청'
                                                            }
                                                        </span>
                                                    </span>
                                                    {data.title}
                                                    &nbsp;
                                                    <i> 
                                                        <span>이미지기사</span>
                                                    </i>
                                                </Link>
                                            </th>
                                            <td className='text-center'>
                                            {data.author.name}
                                            </td>
                                            <td className='text-center'>
                                                {DateFormat_Year_Month_Date_Hour_Minute(data.updated_at)}
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default CurrentInfo