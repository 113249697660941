import React from 'react';
import Swal from 'sweetalert2'

const AlertConfirm = ({title, warnDesc, isConfirmed, isDenied, isAfter}) => {
    let titleText = !warnDesc ? title :  `<div>${title}<div style='font-size:12px;margin-top:5px;color:#d4554f'>${warnDesc}</div></div>`;

    Swal.fire({
        title: titleText,
        showCancelButton: (isDenied) ? true : false,
        confirmButtonText: '확인',
        cancelButtonText: '닫기',
        customClass:{
            container: 'swal-confirm-wrap',
            popup: 'swal-confirm-container',
            title: 'swal-confirm-title',
            confirmButton: 'swal-confirm-btn',
            cancelButton: 'swal-confirm-cancel'
        }
      }).then(async(result) => {
        if(isAfter) isAfter()
        if(result.isConfirmed) {
            if(isConfirmed) isConfirmed();
        } else if (result.isDenied) {
            if(isDenied) isDenied();
        }
    })
}
export default AlertConfirm;