import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
    width: 100%;
    height: 25px;
    background-color: white;
    fieldset{
        border-radius: 0px !important;
        border: 0px !important;
    }
    .nv-textinput{
        height: 100%;
    }
`;

const InputBox = ({children, style}) => {
    return(
        <Box style={style}>
            {children}
        </Box>
    )
}
export default InputBox;