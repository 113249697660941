import React, {useState, useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import moment from 'moment'
import classnames from 'classnames'
import axios from 'axios'
import url from 'utils/backend'
import _ from 'lodash'
import { Link, useLocation} from 'react-router-dom';
const Item = ({item, approveNews, cancelApproveNews, updateLevel, handleCheck, isCheck}) => {
    const location = useLocation()
    const [data, setData] = useState(item);

    useEffect(() => {
        setData(item)
    }, [item])

    return(
        <tr className={classnames({'recog': data.status == 'apply'})}>
            <td>
                <label className='panel-checkbox'>
                    <Checkbox 
                        type='checkbox'
                        checked={isCheck(item._id)}
                        onClick={() => handleCheck(item._id)}
                    />
                </label>
            </td>
            <th className='text-left'>
                <Link to={"/admin/newsView/" + data._id} state={{prevPath : location.pathname}}>
                    {data.title}
                    {(data.status == 'apply' && data.reserved_at != null) &&
                    <span className='reserve-mark'>
                        예약
                    </span>
                    }

                </Link>
            </th>
            <td className='text-center'>{data.section.section_first}</td>
            <td className='text-center'>{data.author.name}</td>
            <td className='text-center'>
                {data.approved_at ? moment(data.approved_at).format('MM-DD HH:mm') : '-'}
            </td>
            <td className='text-center'>
                <div className='level-group'>
                    <div 
                        className={classnames('level', {'type-b': data.level == 'basic'})}
                        onClick={() => {
                            updateLevel(data._id, 'basic')
                            setData(prev => {
                                let tmp = _.cloneDeep(prev);
                                tmp.level = 'basic'
                                return tmp;
                            })
                        }}
                    >
                     일
                    </div>
                    <div 
                        className={classnames('level',{'type-i': data.level == 'important'})}
                        onClick={() => {
                            updateLevel(data._id, 'important')
                            setData(prev => {
                                let tmp = _.cloneDeep(prev);
                                tmp.level = 'important'
                                return tmp;
                            })
                        }}
                    >
                     중
                    </div>
                    <div 
                        className={classnames('level', {'type-h': data.level == 'headline'})}
                        onClick={() => {
                            updateLevel(data._id, 'headline')
                            setData(prev => {
                                let tmp = _.cloneDeep(prev);
                                tmp.level = 'headline'
                                return tmp;
                            })
                        }}
                    >
                     헤
                    </div>

                </div>
                {data.rank}
            </td>
            <td className='text-center'>{data.link}
                <div className='portal-link'>
                    <SearchIcon />
                </div>
            </td>
            <td className='text-center'>
                <div className='text-center'>
                    {data.status == 'apply' &&
                        <div 
                            className='approve-btn' 
                            onClick={() => {
                                approveNews([data._id])
                                setData(prev => {
                                    let tmp = _.cloneDeep(prev);
                                    tmp.status = 'approve';
                                    return tmp;
                                })
                            }}                        
                        >
                            <CheckBoxOutlinedIcon />
                            승인
                        </div>
                    }
                    {data.status == 'approve' &&
                        <div 
                            className='approve-cancel-btn' 
                            onClick={() => {
                                cancelApproveNews(data._id)
                                setData(prev => {
                                    let tmp = _.cloneDeep(prev);
                                    tmp.status = 'apply';
                                    return tmp;
                                })
                            }}                        
                        >
                            <CheckBoxOutlinedIcon />
                            취소
                        </div>
                    }
                </div>
            </td>
        </tr>
    )
}
export default Item