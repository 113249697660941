import React, {useCallback} from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import WriteDragItem from './WriteDragItem';
import {WriteDragTypes, WriteDragTagNames} from 'utils/WriteDragTypes'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import {ReactComponent as SaveIcon} from 'assets/images/save_icon.svg';
import {ReactComponent as RemoveIcon} from 'assets/images/trashcan_icon.svg'
import _ from 'lodash'
const WriteRightAside = ({sideBtn,setSideBtnDir, sideBtnDir, setSideBtn, borderLeft,setIsWrite, isWrite, handleSave, newsId, setWrites}) =>{
    const endDrop = useCallback(() => {
        setWrites(prev => {
            let tmp = _.cloneDeep(prev);
            tmp.map(data => {
                delete data.status;
            })
            return tmp;
        })
    }, [])
    
    const cancelDrop = useCallback(() => {
        setWrites(prev => {
            let tmp = _.cloneDeep(prev);
            return tmp.filter(e => !e.status)
        })
    }, [])

    return(
        <aside className={'write-right-aside '+(sideBtn?'':(isWrite?' bar-active':' bar-active-origin'))+(isWrite?'':' origin')} style={{borderLeft:borderLeft}}>
            <button className={'aside-btn'+(isWrite?'':' aside-btn-originver')} type='button' onClick={()=>setSideBtn(!sideBtn)}>
                {sideBtn
                ?
                (sideBtnDir?<ArrowBackIosIcon className='svg1'/>:<ArrowForwardIosIcon className='svg2'/>)
                :
                (sideBtnDir?<ArrowForwardIosIcon className='svg2'/>:<ArrowBackIosIcon className='svg1'/>)
                }
            </button>
            <div className='aside-contents-container'>
                <div className='contents-wrap'>
                    <div className='contents'>
                        <div className='bar-in bar-in-right'>
                            <article className='tools disabled'>
                                <strong className='str'>글쓰기 도구</strong>
                                <div className='kit'>
                                    {/* <button type='button' className='icon-btn'>
                                        <PhotoSizeSelectActualOutlinedIcon className='icon'/>
                                        <div className='btn-txt'>사진</div>
                                    </button>
                                    <button type='button' className='icon-btn'>
                                        <SlideshowOutlinedIcon className='icon'/>
                                        <div className='btn-txt'>영상</div>
                                    </button>
                                    <button type='button' className='icon-btn'>
                                        <DriveFolderUploadOutlinedIcon className='icon'/>
                                        <div className='btn-txt'>파일</div>
                                    </button>
                                    <button type='button' className='icon-btn'>
                                        <PhotoLibraryOutlinedIcon className='icon'/>
                                        <div className='btn-txt'>포토DB</div>
                                    </button>
                                    <button type='button' className='icon-btn'>
                                        <SaveOutlinedIcon className='icon'/>
                                        <div className='btn-txt'>임시보관한</div>
                                    </button>
                                    <a className='icon-btn'>
                                        <AutoFixHighOutlinedIcon className='icon'/>
                                        <div className='btn-txt'>사진편집</div>
                                    </a> */}
                                    {/* <button type='button' className='icon-btn'>
                                        <FormatQuoteIcon className='icon'/>
                                        <div className='btn-txt'>템플릿</div>
                                    </button>
                                    <button type='button' className='icon-btn'>
                                        <AlternateEmailIcon className='icon'/>
                                        <div className='btn-txt'>특수문자</div>
                                    </button> */}
                                    {/* <button type='button' className='icon-btn'>
                                        <HorizontalRuleIcon className='icon'/>
                                        <div className='btn-txt'>구분선</div>
                                    </button> */}
                                    <button 
                                        type='button' 
                                        className='icon-btn' 
                                        onClick={()=>{
                                            setIsWrite(!isWrite);
                                            setSideBtnDir(!sideBtnDir)
                                            setSideBtn(true)
                                        }}
                                    >
                                        <ScreenshotMonitorIcon className='icon'/>
                                        <div className='btn-txt'>{isWrite?'미리보기':'수정하기'}</div>
                                    </button>
                                </div>
                            </article>
                            {isWrite &&
                                <article className='drag-container'>
                                    <strong className='textbox-txt'>드래그 도구</strong>
                                    <div className='drag-contents'>
                                        <strong className='textbox-title'>텍스트</strong>
                                        <WriteDragItem  text={'중제목'} type={WriteDragTypes.MIDDLETITLE} tagName={WriteDragTagNames.MIDDLETITLE} endDrop={endDrop} cancelDrop={cancelDrop}/>
                                
                                        <WriteDragItem text={'텍스트박스'} type={WriteDragTypes.TXT} tagName={WriteDragTagNames.TXT} endDrop={endDrop} cancelDrop={cancelDrop}/>
                                    </div>
                                    <div className='drag-contents'>
                                        <strong className='textbox-title'>이미지</strong>
                                        <WriteDragItem  img={true} type={WriteDragTypes.IMG} tagName={WriteDragTagNames.IMG} endDrop={endDrop} cancelDrop={cancelDrop}/>
                                    </div>
                                    <div className='drag-contents'>
                                        <strong className='textbox-title'>구분선</strong>
                                        <WriteDragItem text={'구분선'} type={WriteDragTypes.LINE}  tagName={WriteDragTagNames.LINE}endDrop={endDrop} cancelDrop={cancelDrop}/>
                                    </div>
                                </article>
                            }
                        </div>
                    </div>
                    <div className='write-right-aside-bottom-box'>
                        {/* {newsId &&
                            <div className='news-write-btn remove-btn' onClick={confirmRemove}>
                                <RemoveIcon/>
                                삭제
                            </div>
                        } */}
                        <div className='news-write-btn' onClick={handleSave}>
                            <SaveIcon/>
                            {newsId
                            ?
                                '저장'
                            :
                                '등록'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default WriteRightAside