import React, {
  useCallback,
  useRef,
  useState,
  useEffect
} from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import NvDatePicker from "components/NvDatePicker";
import NvNumberInput from "components/NvNumberInput";
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'

const ServiceModal = ({ id, name, visible, setVisible, arr, setArr }) => {
  const navigate = useNavigate();
  const [tmpList, setTmpList] = useState(JSON.parse(JSON.stringify(arr)));
  const handleSave = () => {
    tmpList.map((data, i) => {
      let empty = true;
      Object.keys(data).map((key) => {
          if(data[key]) empty = false;
      })
      if(empty){
          tmpList.splice(i, 1);
      }
    })
    setArr(tmpList)
    setVisible(false)
      setArr(tmpList)
      setVisible(false)
  }
  const addNewList = () => {
      let arr = tmpList
      arr.push(
        {
          name: '',
          stage: '',
          description: '',
        }
      )
      setTmpList([...arr])
  }
  const removeNewList = (i) => {
    let tmp = JSON.parse(JSON.stringify(tmpList))
    tmp.splice(i, 1)
    setTmpList(tmp)
  }
  if(tmpList.length == 0){
    addNewList()
  }
  return (
    <div className='register_modal_root '>
      <div>
        <div className='modal_header'>
          <div className="register-modal-btn-box">
            <h2>제품 및 서비스</h2>
            <span className="icon-box">
            <IconButton onClick={addNewList} >
                <AddCircleIcon className="register-modal-btn add" />
            </IconButton>
            </span>
          </div>
          <IconButton onClick={() => setVisible(false)} >
              <CancelIcon className="register-modal-btn" />
          </IconButton>
        </div>
        <hr/>
        {tmpList.map((data, i) => {
          return(
            <>
            <div className="row-box">
              <div className='input_box_product' style={{width: '60%'}}>
                <div className="rc-sb">
                  <p >{i+1}. 제품 및 서비스명을 입력해주세요</p>
                  <IconButton onClick={removeNewList} >
                      <RemoveCircleIcon className="register-modal-btn remove" style={{paddingBottom: 4, color: 'white'}}  />
                  </IconButton>
                </div>
                <NvTextInput className='input-box' style={{height: 40}} defaultValue={data.name} onChange={(v) => data.name = v}/>
              </div>
              <div className='input_box_product' style={{width: '35%'}}>
                <p>{i+1}. 사업화 단계를 입력해 주세요</p>
                <NvTextInput className='input-box' style={{height: 40}} defaultValue={data.stage} onChange={(v) => data.stage = v}/>
              </div>
            </div>
            <div className='input_box_product'>
              <p>{i+1}.제품 컨셉 및 기능을  입력해 주세요</p>
              <NvTextArea className='input-box' minRows={3} defaultValue={data.description} onChange={(v) => data.description = v}/>
          </div>
            {(tmpList.length - 1 != i) && <hr/>}           
            </>
          )
        })
      }
      </div>
      <div className='btn_box'>
        <div onClick={(e) => { handleSave()}}>저장하기</div>
        {/* <div>이미지 파일 추가하기</div> */}
      </div>
    </div>
  );
};

export default ServiceModal;