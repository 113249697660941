import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'




export const searchListSlice = createSlice({
  name: 'registerSearchList',
  initialState: [],
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return []
    }
  },

})

export const { update, reset } = searchListSlice.actions

export default searchListSlice.reducer