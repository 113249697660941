import React,{useEffect, useState} from 'react';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {ReactComponent as Summary} from 'assets/images/filter_summary_icon.svg'
import {ReactComponent as KeywordIcon} from 'assets/images/filter_keyword_icon.svg'
import {ReactComponent as CertIcon} from 'assets/images/filter_cert_icon.svg'
import {ReactComponent as MemberIcon} from 'assets/images/filter_member_icon.svg'
import {ReactComponent as InvestIcon} from 'assets/images/filter_invest_icon.svg'
import {ReactComponent as SecondaryIcon} from 'assets/images/filter_secondary_icon.svg'
import {ReactComponent as GovIcon} from 'assets/images/filter_gov_icon.svg'
import {ReactComponent as CustomIcon} from 'assets/images/filter_custom_icon.svg'
import {ReactComponent as DealroomIcon} from 'assets/images/filter_dealroom_icon.svg'
import _ from 'lodash';
import KeywordFilter from 'components/KeywordFilter';
import SummaryFilter from 'components/SummaryFilter';
import CertificationFilter from 'components/CertificationFilter';
import SecondaryFilter from 'components/SecondaryFilter'
import AssignmentFilter from 'components/AssignmentFilter'
import InvestmentFilter from 'components/InvestmentFilter'
import MemberFilter from 'components/MemberFilter';
import CustomFilter from 'components/CustomFilter';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled as styledM } from '@mui/material/styles';
import 'react-pro-sidebar/dist/css/styles.css';
export const Section = styled.div`
    margin-bottom: 25px;
`;
const FilterBox = styled.div`
    position:absolute;
    height:100%;
    top:0;
    transition:all .3s;
    z-index:15;
    .pro-sidebar-layout{
        position: sticky !important;
        top: 0px;
        height: fit-content !important;
    }
    .pro-sidebar .pro-menu{
        padding-top: 0px !important;
    }
    .pro-sidebar{
        height: 100%;
        width: 240px;
        min-width: 240px;
        z-index:2;
        color: unset !important;
        &.collapsed{
            width: 60px !important;
            min-width: 60px !important;
        }
    }

    .pro-menu-item{
        &.open{
            .pro-inner-item{
                padding-right: 10px !important;
                background-color: #ffffff;
                border-right: 4px solid #4183fc;
                color: #4183fc !important;
                
            }
            .pro-icon{
                fill: #4183fc;
            }
        }
        &.active{
            .pro-inner-item{
                padding-right: 10px !important;
                background-color: #ffffff;
                border-radius: 7px;
                color: #4183fc !important;
                
            }
            .pro-icon{
                fill: #4183fc;
            }
        }
        &:not(.open){
            .pro-item-content{
                color:  #5d5d5d;
            }
        }
    }
    .pro-inner-item{
        padding-right: 5px !important;
        padding-left: 15px !important;
        margin: 5px;
        background-color: #f8fafe;
        &:hover{
            background-color: #dee6ee !important;
            margin: 5px;
            border-radius 7px;
            color: #242526 !important;
            .pro-icon{
                fill: #232528;
            }
        }
        &:focus{
            color: #242526;
        }
    }
    .pro-sidebar .pro-sidebar-inner{
        background-color: #f8fafe;
    }
    .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
        background-color: rgb(224,233,245);
        padding: 0 15px !important;
        fieldset{
            border-radius: 0px !important;
        }
    }
    .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
        z-index:2;
    }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content{
        overflow: unset;
    }
    .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
        background-color: unset;

    }
    .pro-icon{
        fill: #5d5d5d;
        width: 22px !important;
        background-color: inherit ;
    }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
        margin-right: 0px;
    }
    .pro-sidebar .pro-menu .pro-menu-item{
        font-weight: 600;
        font-size: 12px !important;
        color: #5d5d5d;
    }
    .pro-arrow-wrapper{
        display:none;
    }
    .pro-count-box{
        width: 20px;
        height: 20px;
        border:1px solid #4183fc;
        border-radius: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4183fc;
    }
    .divider{
        border: 0;
        height: 1px;
        background-color: #C4C4C4;
        margin: 15px 7px;
    }
`;
const Filter = ({isFilter,isOpen,setIsOpen,filterCollapsed, summaryFilter, keywordFilter, certificationFilter, memberFilter, secondaryFilter, assignmentFilter, investmentFilter, customFilter, resetFilter, setFilterCollapsed}) => {
    const location = useLocation();
    const [filter, setFilter] = useState({
        summary:{
            visible: true,
            title: '조합개요',
            icon: <Summary/>
        },
        // keyword:{
        //     visible: false,
        //     title: '주요키워드',
        //     icon: <KeywordIcon/>
        // },
        certification:{
            visible: false,
            title: '출자내역',
            icon: <CertIcon/>

        },
        member:{
            visible: false,
            title: '투자내역',
            icon: <MemberIcon/>

        },
        investment:{
            visible: false,
            title: '관리보수 지급 현황',
            icon: <InvestIcon/>

        },
        assignment:{
            visible: false,
            title: '분배내역',
            icon: <GovIcon/>
        },
        // custom: {
        //     visible: false,
        //     title: '커스텀 필터',
        //     icon: <CustomIcon/>
        // },
    });

    const handleOpen = (v) => {
        Object.keys(filter).map((key) => {
            if(key == v){
                filter[key].visible = !filter[key].visible;
            }
            else{
                filter[key].visible = false;
            }
        })
        setFilter(_.cloneDeep(filter))
    }
    const renderContent = (key) => {
        if(key == 'summary'){
            return <SummaryFilter summaryFilter={summaryFilter} />
        }
        else if(key == 'certification'){
            return <CertificationFilter certificationFilter={certificationFilter} />
        }
        else if(key == 'investment'){
            return <InvestmentFilter investmentFilter={investmentFilter} />
        }
        else if(key == 'assignment'){
            return <AssignmentFilter assignmentFilter={assignmentFilter} />
        }
        else if(key == 'member'){
            return <MemberFilter memberFilter={memberFilter}/>
        }
        else{
            return null
        }
    }
    const countFilterTotal = () => {
        let cnt = 0;
        cnt += countFilter(summaryFilter)
        cnt += countFilter(certificationFilter)
        cnt += countFilter(memberFilter)
        cnt += countFilter(investmentFilter.newInvestment)
        cnt += countFilter(investmentFilter.investor)
        if(investmentFilter.investment.isHistory){
            cnt += countFilter(investmentFilter.investment) - 1
        }
        cnt += countFilter(assignmentFilter)

        return cnt;

    } 
    const countFilter = (data, key) => {
        let cnt = 0;
        if(key == 'investment'){
            cnt += countFilter(data.newInvestment)
            cnt += countFilter(data.investor)
            if(data.investment.isHistory){
                cnt += countFilter(data.investment) - 1
            }
        }
        else{
            Object.keys(data).map((key) => {
                if(Array.isArray(data[key])){
                    if(data[key].length >= 1) cnt += 1;
                }
                else{
                    if(typeof(data[key]) == 'object'){
                        if(!key.includes('Custom') && !key.includes('custom')){
                            Object.keys(data[key]).some((key2) => {
                                if(data[key][key2] ){
                                    cnt += 1
                                    return true;
                                }
                            })
                        }
                    }
                    else{
                        if(data[key]){
                            cnt += 1;
                        }
                    }
                }
            })
        }
        return cnt;
    }
    const renderSuffix = (key) => {
        let cnt = 0;
        if(key == 'summary'){
            if(summaryFilter.address){
                cnt += summaryFilter.address.length
            }
        }
        else if(key == 'certification'){
            if(certificationFilter.certification){
            cnt += certificationFilter.certification.length}
        }
        else if(key == 'member'){
            if(memberFilter.employee){
                cnt += memberFilter.employee.length
            }
        }
        else if(key == 'investment'){
            if(investmentFilter.label){
                cnt += investmentFilter.label.length    
            }
        }
        else if(key == 'assignment'){
            if(assignmentFilter.totalPrice){
                cnt += assignmentFilter.totalPrice.length
            }
        }

        return(
            cnt > 0 
            ?
                <div className='pro-count-box'>
                    {cnt}
                </div>
            :
                null
        )
    }

    const getFilter = (key) => {
        if(key == 'summary'){
            return summaryFilter
        }
        else if(key == 'certification'){
            return certificationFilter
        }
        else if(key == 'member'){
            return memberFilter
        }
        else if(key == 'investment'){
            return investmentFilter
        }
        else if(key == 'assignment'){
            return assignmentFilter
        }

    }

    const closeAllFilter  = () => {
        Object.keys(filter).map((key) => {
            filter[key].visible = false;
        })
        setFilter(_.cloneDeep(filter))
    }
    const LightTooltip = styledM(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            width: 'fit-content',
            marginLeft: '0px !important' ,
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            // boxShadow: theme.shadows[1],
            fontSize: 11,
            border: '2px solid #e7e7e7'
        },
    }));

    useEffect(() => {
        if(filterCollapsed){
            closeAllFilter()
        }
        else{
            if(isOpen)
            handleOpen('summary')
        }
    },[filterCollapsed])
    
    return(
        <FilterBox style={{left:isFilter}}>
            <ProSidebar collapsed={filterCollapsed}>
                <Menu iconShape="square" >
                        <div className='company-list-filter-top'>
                        {(!filterCollapsed) 
                        ?
                            <>
                            <div className='filter-total-cnt'>
                                { countFilterTotal() > 0
                                ?
                                   '총 '+ countFilterTotal()+'개의 항목 수정'  
                                :
                                    ''
                                }
                            </div>
                            <div className='rc-sb center' style={{marginRight: -11}}>
                                <IconButton className='filter-reset-icon' onClick={() => {resetFilter();closeAllFilter() }}>
                                    <CachedIcon/>
                                </IconButton>
                                <IconButton className='filter-fold-icon' onClick={() => {setFilterCollapsed(true);setIsOpen(false)}}>
                                    <ArrowBackIosNewIcon/>
                                </IconButton>
                            </div>
                            </>
                        :
                        <>
                        {(countFilterTotal() > 0) &&
                            <div className='filter-total-cnt collapsed'>
                                {countFilterTotal()}
                            </div>
                        }
                        </>
                        }   
                        </div>
                    {Object.keys(filter).map((key) => {
                        // if(key == 'custom' && !customFilter) return;
                        return(
                            <>
                            {key == 'custom' &&
                                <>
                                <hr className='divider'/>
                                </>
                            }
                            {!filterCollapsed
                            ?
                                <SubMenu 
                                    key={key} 
                                    open={filter[key].visible} 
                                    title={!filterCollapsed ? filter[key].title : ''} 
                                    icon={filter[key].icon} 
                                    onOpenChange={() => handleOpen(key)}
                                    suffix={renderSuffix(key)}
                                >
                                    {renderContent(key)}
                                </SubMenu>
                            :
                                <div key={key}  onClick={() => {setFilterCollapsed(false); handleOpen(key);}}>
                                    <LightTooltip sx={{zIndex: 30000}} disableInteractive title={filter[key].title} placement="right">
                                    <MenuItem key={key} active={countFilter(getFilter(key), key) >= 1} icon={filter[key].icon}>
                                    </MenuItem>
                                    </LightTooltip>
                                </div>
                            }
                            </>
                            
                        )
                    })
                    }
                </Menu>
            </ProSidebar>
        </FilterBox>
    )
}
export default Filter;