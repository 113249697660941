import React from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImageIcon from '@mui/icons-material/Image';
import {getValue, getLabel} from 'utils/NewsStatus'
import DateFormat_Year_Month_Date_Hour_Minute from 'utils/DateFormat_Year_Month_Date_Hour_Minute'
import { Link, useLocation } from 'react-router-dom';

const TitleTypeList = ({item}) =>{
    const location = useLocation()
    return(
        <article className='items'>
            <h4 className='h4'>
                <Link className='a' to={'/member/newsview/' + item._id} state={{prevPath: location.pathname}}>
                    {item.title}
                </Link>
                <small className='small'>
                    <RemoveRedEyeIcon className='i'/>
                    &nbsp;{item.view}
                </small>
                <ImageIcon className='image-i'>
                    <span className='image-i-span'>이미지기사</span>
                </ImageIcon>
                <span className='current-i'>{getLabel(item.status)}</span>
            </h4>
            <div className='infos'>
                <em className='em'>{item.section.section_first}</em>
                <em className='em'>{item.author.name}</em>
                <em className='em'>{DateFormat_Year_Month_Date_Hour_Minute(item.updated_at)}</em>
            </div>
        </article>
    )
}

export default TitleTypeList