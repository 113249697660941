import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import AutoComplete from 'components/AutoComplete'
import NvSelect from 'components/NvSelect'
const Container = styled.div`
    height: 2.4375rem;
    margin: 0 0 1rem;
    width:100%;
    line-height:normal;
    min-height:40px;
    font-size:.813rem;
    letter-spacing:-.05em;
    margin-bottom:0;
    border: 1px solid #ced2db;
    border-radius:5px;
    background-color: #fff;
    div > input {
        font-size: 12px;
        color: #444;
    }
    fieldset {
        border: 0px;
    }
    .nv-select{
        .MuiSelect-select {
            min-width:${(props)=>props.width} !important;
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            text-overflow:unset;
        }
        &>div{
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
`;
const PanelFilterSelect = ({width, nvclasses,list = [], classes, defaultValue, onChange}) => {
   
    return(
        <Container width={width} style={classes} className='nv-select-container'>
            <NvSelect
                list={list}
                defaultValue={defaultValue}
                onChange={onChange}
                nvclasses={nvclasses}
            />
        </Container>
    )
}

export default PanelFilterSelect