import React from 'react'
import ContentsContainer from '../ContentsContainer'
import _ from 'lodash'
const Latest = ({list}) =>{
    return(
        <div className='main-body-above-content'>
            <ContentsContainer list={_.filter(list, {value:'latest'})[0]}/>
            <div className='below-contents-container'>
                <button className='btn-box' type='button' >
                    <span className='btn'>
                        Load more
                    </span>
                </button>
            </div>
        </div>
    )
}

export default Latest