import axios from 'axios';
import React, {useState,useRef,useEffect,useMemo} from 'react'
import ReactQuill, {Quill} from "react-quill";
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading'
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import url from 'utils/backend'
import getImageSignedURL from 'utils/getImageSignedURL'
import {useDidMountEffect} from 'hooks/useDidMountEffect'

Quill.register("modules/imageResize", ImageResize)

const InvestmentPlan = ({type,isRead,value,setValue, setImg, txt=''}) =>{
    const {id} = useParams();
    const [imgLoading, setImgLoading] = useState(false)
    const [save, setSave] = useState('')
    const quillRef = useRef(null);
    const [uri, setUri] = useState('')
    const toolbarOptions = [
        ["link", "image", "video"],
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
    ]; 
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "align",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "background",
        "color",
        "link",
        "image",
        "video",
        "width",
    ];
    const modules =useMemo(()=>({
          toolbar: 
            isRead ? false : {container: toolbarOptions},
          
          imageResize:{
            parchment:Quill.import("parchment"),
            modules: ["Resize", "DisplaySize", "Toolbar"]
          },
        }),[])
    ;
    const handleChange = (e) => {
        
        setValue(e)
    }
    const handleImage = () => {
        const input = document.createElement("input");
        let uri=""
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async() => {
            const file = input.files;
            setImgLoading(true)
            if(file !== null) {
                try{
                    const res = await axios.post(url + "/combination/company/image",{
                        name:file[0].name,
                        mimetype:file[0].type,
                        company_id:id
                    })
                    const res2 = await fetch(res.data.uploadURL, {
                        method: 'put',
                        body: file[0]
                    })
                    const res3 = await axios.get(url + `/combination/company/image?key=${res.data.key}&mimetype=${file[0].type}`)
                    uri = res3.data.file
                    // var Image = Quill.import('formats/image');
                    // Image.sanitize = function(uri) {
                    //   return uri; 
                    // };
                    let imgURL = 'https://nventure-image.s3.ap-northeast-2.amazonaws.com/' + res.data.key
                    setImg(prev => [...prev, imgURL])
                    const range = quillRef.current?.getEditor().getSelection()?.index;
                    if(range !==null && range !==undefined){
                        let quill = quillRef.current?.getEditor();
                        quill.insertEmbed(range, 'image', imgURL);
                        quill.setSelection(range+1)
                    }
                } catch(e) {
                    console.log(e)
                }
            }
            setImgLoading(false)
        }
    }
    // const handleImg = (files) =>{
    //     let preview = URL.createObjectURL(files)
    //     let tmp = JSON.parse(JSON.stringify(img))
    //     tmp.push({noteType:'img',data:files})
    //     setImg(tmp)
    //     return preview
    // }
  

    useEffect(() => {
        if (quillRef.current) {
          const toolbar = quillRef.current.getEditor().getModule("toolbar");
          toolbar.addHandler("image", handleImage);
        }
    }, []);
    return(
        <>
            <div style={{width:(isRead?'50%':'100%')}}>
                <div className='title' style={{paddingTop:45}}>{type=='point' ? '투자포인트' : 'Exit Plan'}</div>

                        {isRead
                        ?
                        (txt.length>=1?
                        <ReactQuill readOnly modules={modules} theme="snow" value={txt}  />
                        :
                        <div className="cb-company-investment-empty">
                            내용이 없습니다. 수정을 눌러 입력해주세요.
                        </div>
                        )

                        :
                            <ReactQuill ref={quillRef}  modules={modules} formats={formats} theme="snow" value={value} onChange={(e)=>handleChange(e)} />
                        }
                
            </div>
        </>
    )
}

export default InvestmentPlan