import React, {useState, useEffect} from 'react'
import _ from "lodash";
import moment from 'moment';
import { DatePicker, Space , ConfigProvider} from 'antd';
import 'antd/lib/date-picker/style/index.css'
import "moment/locale/ko";
import locale from "antd/lib/locale/ko_KR"
moment.locale("ko");

const NvNewDatePicker = ({defaultValue = null, onChange, unit, placeholder}) => {
    const [value, setValue] = useState(null)
    const [open, setOpen] = useState(false)
    const [mode, setMode] = useState('year')
    const getFormat = () => {
        if(unit == '년'){
            return 'YYYY'
        }
        else if(unit == '년월'){
            return 'YYYY-MM'
        }
        else{
            return 'YYYY-MM-DD'
        }
    }
    const getPicker = () => {
        if(unit == '년'){
            return 'year'
        }
        else if(unit == '년월'){
            return 'month'
        }
        else{
            return 'date'
        }
    }
    
    useEffect(() => {
        setValue(defaultValue ? moment(defaultValue) : null)
    }, [defaultValue])

    return(
        <ConfigProvider
            locale={locale}
        >
            <div className='nv-newdatepicker'>
                <DatePicker
                    inputReadOnly
                    value={value}
                    className={open && 'open'}
                    onOpenChange={(open) => setOpen(open)}
                    placeholder={getFormat()}
                    onChange={(v) => {
                        setValue(v)
                        if(onChange) onChange(v)
                    }}
                    picker={getPicker(unit)}
                    mode={mode}
                    onPanelChange={(value, mode) => {
                        if(mode == 'year') setMode('year')
                        else if(mode == 'month'){
                            setMode('month')
                        }
                        else if(mode == 'date'){
                            setMode('date')
                        }
                    }}
                />
            </div>

        </ConfigProvider>

    )
}
export default NvNewDatePicker