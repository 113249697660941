import React, {
    useCallback,
    useRef,
    useState,
    useEffect
  } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import NvDatePicker from "components/NvDatePicker";
import NvNumberInput from "components/NvNumberInput";
import NvTextInput from 'components/NvTextInput'
import styled from 'styled-components';
import NvAutoComplete from 'components/NvAutoComplete'
import { growthList, investmentTypeList } from "utils/DropDownList";
import AutoSearchInput from "components/AutoSearchInput";
import Badges from "./Badges";


  const InvestmentModal = ({ id, name, visible, setVisible, headers, arr, setArr}) => {
    const navigate = useNavigate();
    const [tmpList, setTmpList] = useState(JSON.parse(JSON.stringify(arr)));
    const handleSave = () => {
        tmpList.map((data, i) => {
            let empty = true;
            Object.keys(data).map((key) => {
                if(data[key]) empty = false;
            })
            if(empty){
                tmpList.splice(i, 1);
            }
        })
        setArr(tmpList)
        setVisible(false)
    }
    const addNewList = () => {
        tmpList.push(
          {
            date: '',
            amount: '',
            type: '',
            round: '',
            investor: []
          }
        )
        setTmpList([...tmpList])
    }
    const removeNewList = (i) => {
        tmpList.splice(i, 1)
        setTmpList([...tmpList])
    }
  
    if(tmpList == 0){
        addNewList()
    }
    return (
      <div className='register_modal_root'>
        <div>
            <div className='modal_header'>
                <div className="register-modal-btn-box">
                    <h2>투자현황</h2>
                    <span className="icon-box">
                    <IconButton onClick={addNewList} >
                        <AddCircleIcon className="register-modal-btn add" />
                    </IconButton>
 
                    </span>
                </div>
                <IconButton onClick={() => setVisible(false)} >
                    <CancelIcon className="register-modal-btn" />
                </IconButton>
            </div>
            <hr />
            <div className='info_table_box' style={{width: '100%', maxHeight: 280, marginTop: 30}}>
                <table className='info_tbl'>
                    <thead>
                    <tr className='info_tbl_header' style={{backgroundColor: '#e6e6e6'}}>
                        {headers.map((data, i) => {
                        return(
                            <td style={{ width: data.width * 35 }}><p className=''>{data.label}</p></td> 
                        )
                        })
                        }
                    </tr>
                    </thead>
                    <tbody className='table-scrollbar' style={{maxHeight: 300, minHeight: 300, backgroundColor: 'white'}}>
                        {tmpList.map((data, i) => {
                            const handleEnterInvestor = (v, setName) => {
                                tmpList[i].investor.push(v);
                                setTmpList(JSON.parse(JSON.stringify(tmpList)));
                                setName('');
                            }
                            const handleRemoveInvestor = (index) => {
                                tmpList[i].investor.splice(index, 1);
                                setTmpList(JSON.parse(JSON.stringify(tmpList)));
                            }
                            return(
                                <>
                                <tr>
                                    <td style={{ width: headers[0].width * 35 }}>
                                        <NvDatePicker  unit={'년월'} defaultValue={data.date} style={{fontSize:12}} onChange={(v) => data.date = v}/>
                                    </td> 
                                    <td style={{ width: headers[1].width * 35 }}>
                                        <NvNumberInput unit={'억'} defaultValue={data.amount}  onChange={(v) => data.amount = v}/>
                                    </td> 
                                    <td style={{ width: headers[2].width * 35 }}>
                                        <NvAutoComplete defaultValue={data.type} options={investmentTypeList} onChange={(val) => { data.type = val; }} />
                                    </td> 
                                    <td style={{ width: headers[3].width * 35 }}>
                                        <NvAutoComplete defaultValue={data.round} options={growthList} onChange={(val) => { data.round = val }} />
                                    </td> 
   
                                    <td style={{ width: headers[4].width * 35 - 20 }}>
                                        <AutoSearchInput  isNavigate={false} isImg={false} type='investor' onHandleEnter={handleEnterInvestor}  />
                                    </td> 
                                    <td style={{width: 20}}>
                                        <IconButton onClick={() => removeNewList(i)}>
                                            <RemoveCircleIcon className="register-modal-btn remove" />
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{width: '100%'}}>
                                        <Badges arr={data.investor} style={{width: '700px'}} handleRemove={handleRemoveInvestor}/>      
                                    </td>
                                </tr>
                                </>

                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        <div className='btn_box'>
          <div onClick={(e) => { handleSave()}}>저장하기</div>
          {/* <div>이미지 파일 추가하기</div> */}
        </div>
      </div>
    );
  };
  
  export default InvestmentModal;