import React, {
  Suspense,
  useCallback,
  useWindowEvent,
  useNavigate,
  useEffect,
} from "react";
import { Route, Routes, BrowserRouter, Navigate, } from "react-router-dom";
import Main from "pages/main/Main";
import Login from "pages/login/Login";
import Register from "pages/register/Register";
import Find from "pages/find/Find";
import Blind from 'pages/blind-discussion/BlindDiscussion'
import Discussion from "pages/discussion/Discussion";
import DiscussionWriteReview from "pages/discussion-write-review/DiscussionWriteReview";
import DiscussionWriteEtc from "pages/discussion-write-post/DiscussionWriteEtc";
import DiscussionEditReview from "pages/discussion-edit-review/DiscussionEditReview";
import DiscussionEditPost from "pages/discussion-edit-post/DiscussionEditPost";
import DiscussionView from "pages/discussion-view/DiscussionView";
import InvestorFind from "pages/investor-find/InvestorFind";
import InvestorView from "pages/investor-view/InvestorView";
import ProfileCreate from "pages/profile-create/ProfileCreate";
import CombinationCreate from "pages/combination/combination-create/combinationCreate";
import ProfileEdit from "pages/profile-edit/ProfileEdit";
import ProfileEdit2 from "pages/profile-edit2/ProfileEdit";
import Latest from 'pages/latest/Latest'
import Trending from 'pages/trending/Trending'
import CombinationList from 'pages/combination/combination-list/CombinationList'
import CompanyList from 'pages/combination/company-list/CompanyList'
import CompanyInfo from 'pages/combination/company-info/CompanyInfo'
import EnterpriseRegistration from 'pages/combination/company-edit/CompanyEdit'
import UnionList from 'pages/combination/union-list/UnionList'
import NoticeInfo from "pages/combination/notice/component/NoticeInfo";
import NoticeList from 'pages/combination/notice/NoticeList'
import CombinationMy from 'pages/combination/my-info/myinfo'
import UserList from 'pages/combination/user-list/UserList'
import ChapterList from 'pages/combination/chapterlist/ChapterList'
import ChapterRegistration from 'pages/combination/chapterRegistration/ChapterRegistration'
import Topics from 'pages/topics/Topics'
import StartUp from 'pages/start-up/Start-Up'
import Investor from 'pages/investor/Investor'
import Detail from "pages/news-view/NewsView";
import ProfileChange from 'pages/profile-change/ProfileChange'
import debounce from "lodash.debounce";
import DiscussionMy from "pages/discussion-my/DiscussionMy";
import NewsRoom from 'pages/member/news-room/NewsRoom'
import NewsList from 'pages/member/news-list/NewsList'
import NewsReply from 'pages/member/news-reply/NewsReply'
import NewsWrite from 'pages/member/news-write/NewsWrite'
import MyNewsView from 'pages/member/news-view/NewsView'
import CombinationInfo from 'pages/combination/combination-info/CombinationInfo'
import TopicDetail from 'pages/topicdetail/DetailTopics'
import "styles/reset.scss";
// import "animate.css";
import "styles/common.scss";
import 'styles/antd.css'
import axios from "axios";
import Auth from "utils/auth";
import AdminMain from 'pages/admin/main/Main'
import AdminNewsWrite from 'pages/admin/news-write/NewsWrite'
import AdminNewsList from 'pages/admin/news-list/NewsList'
import AdminMemberList from 'pages/admin/member-list/MemberList'
import AdminNewsSearch from 'pages/admin/news-search/NewsSearch'
import { ToastContainer, toast } from "react-toastify";
import AdminNewsView from 'pages/admin/news-view/NewsView'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
function App() {

  const setDefaultInterceptor = () => {
    axios.interceptors.response.use(function (config) {
      return config;
    }, function (error) {
      return Promise.reject(error);
    });
    
    axios.interceptors.request.use(
      function(config) {
        const token = localStorage.getItem("pitchdeck_auth_token"); 
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
  },
      function(error) {
        return Promise.reject(error);
      }
    );
  }

  return (
    <>
      {setDefaultInterceptor()}
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path='/' element={<Navigate to='/main'/>} />
            <Route path="/main" element={<Main />} />
            <Route path="/profile/create" element={<ProfileCreate />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/investor/match" element={<Find />} />
            <Route path="/discussion" element={<Navigate to='/discussion/review' />} />
            <Route path="/discussion/:type" element={<Blind />} />
            <Route
              path="/discussion/write/review"
              element={<DiscussionWriteReview />}
            />
            <Route
              path="/discussion/write/post"
              element={<DiscussionWriteEtc />}
            />
            <Route
              path="/discussion/edit/review/:id"
              element={<DiscussionEditReview />}
            />
            <Route
              path="/discussion/edit/post/:id"
              element={<DiscussionEditPost />}
            />
            <Route
              path="/discussion/view/:type/:id"
              element={<DiscussionView />}
            />
            {/* <Route path="/discussion/my" element={<DiscussionMy />} /> */}
            <Route path="/investor/find" element={<InvestorFind />} />
            <Route exact path="/investor/:id" element={<InvestorView />} />
            <Route path="/profile/edit" element={<ProfileEdit />} />
            <Route path="/profile/edit2" element={<ProfileEdit2 />} />
            <Route path="/detail" element={<Detail />} />
            <Route path='/topic' element={<TopicDetail/>}/>
            <Route path='/member/newsroom' element={<NewsRoom />} />
            <Route path='/member/newslist' element={<NewsList />} />
            <Route path='/member/newsreply' element={<NewsReply />} />
            <Route path='/member/newswrite' element={<NewsWrite />} />
            <Route path='/member/newswrite/:id' element={<NewsWrite />} />
            <Route path='/member/newsview/:id' element={<MyNewsView/>} />
            <Route path='/profile/change' element={<ProfileChange />} />
            <Route path='/latest' element={<Latest />} />
            <Route path='/topics' element={<Topics />} />
            <Route path='/trending' element={<Trending />} />
            <Route path='/startup' element={<StartUp />} />
            <Route path='/investor' element={<Investor />} />
            <Route path='/admin/' element={<AdminMain/>} />
            <Route path='/admin/newsWrite' element={<AdminNewsWrite/>} />
            <Route path='/admin/newsWrite/:id' element={<AdminNewsWrite/>} />
            <Route path='/admin/member' element={<AdminMemberList/>} />
            <Route path='/admin/memberlist' element={<AdminMemberList/>} />
            <Route path='/admin/newsList' element={<AdminNewsList/>} />
            <Route path='/admin/newsSearch' element={<AdminNewsSearch/>} /> 
            {/* <Route path='/combination' element={<CombinationList/>} /> 
            <Route path='/combination/company' element={<CompanyList/>} /> 
            <Route path='/combination/company/:id' element={<CompanyInfo/>} /> 
            <Route path='/combination/company/modify/:id' element={<EnterpriseRegistration/>} /> 

            <Route path='/combination/company/registration' element={<EnterpriseRegistration/>} /> 
            <Route path='/combination/union' element={<UnionList/>} /> 
            <Route path='/combination/notice/info' element={<NoticeInfo/>} /> 
            <Route path='/combination/notice' element={<NoticeList/>} /> 
            <Route path='/combination/user' element={<UserList/>} /> 
            <Route path='/combination/chapter' element={<ChapterList/>} /> 
            <Route path='/combination/chapter/registration' element={<ChapterRegistration/>} /> 
            <Route path='/combination/my' element={<CombinationMy/>} /> 
            <Route path='/combination/:id' element={<CombinationInfo/>} /> 
            <Route path='/combination/modify/:id' element={<CombinationCreate/>} /> 
            <Route path='/combination/union/registration' element={<CombinationCreate/>} />  */}
            <Route path='/admin/newsView/:id' element={<AdminNewsView/>} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <ToastContainer limit={1} closeButton={false} hideProgressBar={true}/>
    </>
  );
}

export default App;
