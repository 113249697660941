import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
const summaryFilter = {
    address:[],
    foundationDate: '',
    foundationDateCustom: {min: '', max: ''},
    foundationSlide: '',
    ceo: '',
    status: []
}
export const summaryFilterSlice = createSlice({
    name: 'summaryFilter',
    initialState: summaryFilter,
    reducers: {
      update: (state, action) => {
        const {payload} = action;
        return payload
      },
      reset: (state) => {
        return summaryFilter
      }
    },
})

export const { update, reset } = summaryFilterSlice.actions

export default summaryFilterSlice.reducer