import React, {useEffect, useRef, useState} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'
import search_icon from 'assets/images/search_icon.png'
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Reply from './Reply'
import axios from 'axios';
import url from 'utils/backend';
import Swal from 'sweetalert2'
import Auth from 'utils/auth';
import {ViewIcon, LikeIcon, ChatIcon} from 'components/discussion/Icon'
import { HorizontalRule } from '@mui/icons-material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
const DeleteButton = styled(Button)`
    height: 30px;
`;
const ModifyButton = styled(Button)`
    height: 30px;
    margin-right: 15px !important;
`;
const Card = ({item, type, getBoardInfo, getReplyList, replyList}) => {
    const navigate = useNavigate();
    const [isLike, setIsLike] = useState(item.like);
    const [dropDown, setDropDown] = useState(false);
    const dropDownRef= useRef();
    const handleLike = async() => {
        if(isLike){
            item.likes -= 1;
            await axios.delete(url + '/forum/' + item.type + '/' + item._id + '/unlike');
            setIsLike(false);
        }
        else{
          item.likes += 1;
            await axios.post(url + '/forum/' + item.type + '/' + item._id + '/like');
            setIsLike(true);
        }
    }
    const deleteBoard = async() => {
        try{
            Swal.fire({
                title: '글을 삭제하시겠습니까?',
                showCancelButton: true,
                confirmButtonText: '확인',
                cancelButtonText: '닫기',
                customClass:{
                    icon: 'swal-confirm-icon',
                    popup: 'swal-confirm-container',
                    title: 'swal-confirm-title animate__animated animate__bounceIn',
                    confirmButton: 'swal-confirm-btn',
                    cancelButton: 'swal-confirm-cancel'
                }
              }).then(async(result) => {
                if(result.isConfirmed) {
                    const res = await axios.delete(url + '/forum/'+item.type+'/' + item._id);
                    navigate('/discussion/' + type)
                } else if (result.isDenied) {
                }
            })

        }
        catch(e){
            console.log(e)
        }
    }
    function autolink(id) {
        var container = document.getElementById(id);
        var doc = container.innerHTML;
        var regURL = new RegExp("(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)","gi");
        var regEmail = new RegExp("([xA1-xFEa-z0-9_-]+@[xA1-xFEa-z0-9-]+\.[a-z0-9-]+)","gi");
        container.innerHTML = doc.replace(regURL,"<a href='$1://$2' style='text-decoration: underline' target='_blank'>$1://$2</a>").replace(regEmail,"<a href='mailto:$1'>$1</a>");
    }
    const clickOutside = event => {
        if (dropDown && !dropDownRef.current.contains(event.target)) {
          setDropDown(!dropDown)
        }
      };
    useEffect(() => {
        document.addEventListener('mousedown', clickOutside);
    
        return () => {
          document.removeEventListener('mousedown', clickOutside);
        };
      });
    useEffect(() => {
        setIsLike(item.like)
        if(item.content){
            autolink('board-content-content')
        }
    },[item])
    return(
        <div className='discussion-card mn' style={{marginBottom:200, marginRight:10, marginLeft:10, paddingBottom: 0, paddingTop:24}}>
            {/* <div className='discussion-title'>{item.investor_name}</div> */}
            <div className='rc-sb' style={{marginBottom: 10}}>
            {/* <div className='discussion-sub-title'>평균 점수</div> */}
            <p style={{marginBottom:'1.6em', fontWeight:700}}>{item.type=='review'? <><span style={{cursor:'pointer'}} onClick={()=>{type=='review'?navigate('/discussion/review'):navigate('/discussion/my')}}>블라인드토론 &#62;&nbsp;</span><span style={{opacity:.7}}>  투자자후기</span></>:<><span style={{cursor:'pointer'}} onClick={()=>navigate('/discussion/post')}>블라인드토론 &#62;&nbsp;</span><span style={{opacity:.7}}> 스타트업라운지</span></>}</p>
            {Auth.isMy(item.user_id) && <div className='discussion-sub-title' style={{color: 'red'}}>내글</div>}
            {/* <div className='discussion-sub-title'>작성자</div> */}
            </div>
            <p className='rc-sb'>
            {/* <p className='discussion-left-score'>9.0<span className='discussion-right-score'> / 10</span></p>                 */}
                <pre className='discussion-title mn'>{(item.type == 'review') ? item.investor_name : item.title}</pre>
                <p className='discussion-category mn'>{item.category}</p>
            </p>
            <div className='rc-sb mn' style={{marginTop:16, position:'relative'}}>
                {/* <p className='discussion-left-score small'>9.0<span className='discussion-right-score'> / 10</span></p>                 */}
                <p className='discussion-category small' style={{fontSize:14, fontWeight:'normal'}}>{item.author}</p>
                <IconButton style={{cursor:'pointer'}} onClick={()=>setDropDown(!dropDown)}>
                <MoreHorizIcon/>
                </IconButton>
                <div ref={dropDownRef} style={{display:(dropDown?'flex':'none'),flexDirection:'column',minHeight: 50,justifyContent:'space-evenly', alignItems:'center',position:'absolute', width: 80,border:'1px solid rgba(0,0,0,.15)',backgroundColor:'#f9f9f9', right:0, top:34}}>
                    <div style={{display:'flex',height:40,alignItems:'center', justifyContent:'flex-start', cursor:'pointer'}} onClick={() => navigate('/discussion/edit/'+item.type+'/' + item._id)}>수정</div>
                    <div style={{display:'flex',height:40,alignItems:'center', justifyContent:'flex-start', cursor:'pointer'}} onClick={() => deleteBoard()}>삭제</div>
                </div>
            </div>
            <hr className='discussion-line'/>
            <div>                
            <p className='discussion-category small' style={{fontSize:14, fontWeight:'normal'}}>{item.next_step && "Proceeding to the next step"}</p>

            <pre id='board-content-content' className='discussion-content-text mn'>
            {(item.deleted_at == null) ? item.content : '삭제된 게시글 입니다.'}
            </pre>
            <div className='rc-sb' style={{marginBottom:20}}>
            <div className='rc-sb mn' style={{fontSize:14}}>
                <ViewIcon cnt={item.views}/>
                <LikeIcon style={{cursor: 'pointer'}} cnt={item.likes} isLike={isLike} onClick={() => {if(!Auth.isMy(item.user_id)) handleLike()}}/>
                {(item.type == 'post') && <ChatIcon cnt={item.replies}/>}
            </div>
            {/* <div className='rc-sb'>
 
                {(true)
                ?
                    <img className='discussion-bookmark-img' src={require('assets/images/bookmark_icon.png')}/>
                :
                    <img className='discussion-bookmark-img' src={require('assets/images/bookmark_line.png')}/>
                }
                </div> */}
            </div>
            {/* <div style={{justifyContent: 'flex-end', display: 'flex', marginTop: 20}}>
                {Auth.isMy(item.user_id) && <ModifyButton variant="contained" onClick={() => navigate('/discussion/edit/'+item.type+'/' + item._id)}>수정</ModifyButton>}
                {Auth.isMy(item.user_id) && <DeleteButton variant="contained" color="error" onClick={() => deleteBoard()}>삭제</DeleteButton>}
            </div> */}
            {(item.type == 'post') && <Reply _id={item._id} replys={replyList} getBoardInfo={getBoardInfo} getReplyList={getReplyList} writer_id={item.user_id}/>}
        </div>
        </div>
    )
}
export default Card