import React, {
  useCallback,
  useRef,
  useState,
  useEffect
} from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import NvDatePicker from "components/NvDatePicker";
import NvNumberInput from "components/NvNumberInput";
import NvTextInput from 'components/NvTextInput'
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import NvTextArea from "components/NvTextArea";
import removeGray from 'assets/images/remove-gray.png';
import Badges from './Badges'
import AutoSearchInput from "components/AutoSearchInput";
const MarketBox = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: inline-block;
  p{
    font-weight: 900;
    font-size: 13px;
    color: white;
  }
  .MuiFormControl-root span{
    font-weight: 600;
    font-size: 12px !important;
    padding-right: 5px;
  }
  .css-i4bv87-MuiSvgIcon-root{
    width: 13px;
    fill: white !important;
  }
  textarea{
    min-height: 70px !important;
    font-size: 12px;
  }
  .warn{
    font-size:10px;
    color: white;
    margin-left: 5px
  }
  .title-box{
    display: flex;
    flex-direction: row;
    align-items: center
  }
`;
const MarketModal = ({ id, name, visible, arr, setArr, setVisible }) => {
  const [tmp, setTmp] = useState(JSON.parse(JSON.stringify(arr)));
  const [isEmpty, setIsEmpty] = useState(false);
  const [tmpInvestee, setTmpInvestee] = useState('');
  const navigate = useNavigate();
  const handleSave = () => {
    setArr(tmp)
    setVisible(false)
  }
  const handleOpt = (key, label) => {
    tmp[key].value = label
    tmp[key].empty = false;
    setTmp({...tmp}); 
  }
  const checkEmpty = (key) => {
    let empty = true;
    if(tmp[key].value){
      empty = false;
    }
    tmp[key].empty = empty;
    setTmp({...tmp});
  }
  
  const handleEnter = (v) => {
    setTmpInvestee('');
    if(v && tmp['similarity'].content.indexOf(v) == -1){
      tmp['similarity'].content.push({group: tmp['similarity'].value, companyName: v});
      setTmp({...tmp})
    }
  } 
  const handleRemove = (i) => {
    tmp['similarity'].content.splice(i, 1);
    setTmp({...tmp})
  }
  return (
    <div className='register_modal_root' >
      <div>
        <div className='modal_header'>
          <div className="register-modal-btn-box">
            <h2>Market</h2>
          </div>
          <IconButton onClick={() => setVisible(false)} >
            <CancelIcon className="register-modal-btn" />
          </IconButton>
        </div>
        <hr/>
      <div className='' style={{width: '100%' ,height: '100%', marginTop: 30}}>
        {Object.keys(tmp).map((key, i) => {
          return(
            <MarketBox>
              <div className="title-box">
                <p className={(tmp[key].empty && 'animate__animated animate__headShake')}>{tmp[key].title}</p>
                {(tmp[key].empty) &&
                  <p className="warn animate__animated animate__headShake">*체크박스를 선택해주세요</p>
                }
              </div>
              <FormControl component="fieldset" style={{paddingLeft: 10}}>
                <FormGroup aria-label="position" row>
                  {tmp[key].opts.map((opt, i) => {
                    return(
                      <FormControlLabel
                        style={{width: 110}}
                        value={opt.label}
                        control={<Checkbox checked={tmp[key].value == opt.label} onChange={() => handleOpt(key, opt.label)} />}
                        label={opt.label}
                        labelPlacement="center"
                      />
                    )
                  })
                  }
                </FormGroup>
              </FormControl>
              {!tmp[key].value 
              ?
                (key != 'similarity'
                ?
                <div className="input-box-wrap" style={{width: '100%', height: 70, cursor: 'pointer'}} onClick={() => checkEmpty(key)}>
                  <div readOnly className='input-box' style={{height: 70}}/>
                </div>
                :
                <div className="rc-sb"  onClick={() => checkEmpty(key)}>
                  <div className="input-box-wrap" style={{width: '30%', minHeight: 40}} >
                    <div className='input-box' style={{minHeight: 40}} onEnter={(v) => handleEnter(v)}/>
                  </div>
                </div>
                )
              :
                (key != 'similarity' 
                ?
                  <div className="input-box-wrap" style={{width: '100%', minHeight: 70}} >
                    <NvTextArea className='input-box' minRows={2} defaultValue={tmp[key].content} onChange={(v) => tmp[key].content = v} />
                  </div>
                :
                  <div className="rc-sb">
                    <div className="input-box-wrap" style={{width: '30%', minHeight: 40}} >
                      {/* <NvTextInput className='input-box' style={{minHeight: 40}} onEnter={(v) => handleEnter(v)}/> */}
                      <AutoSearchInput isNavigate={false} defaultValue={tmpInvestee} setAutoState={(v) => setTmpInvestee(v)} setTextState={(v) => setTmpInvestee(v)} isImg={false} type='company' onHandleEnter={(v) => handleEnter(v)} />
                    </div>
                    <Badges arr={tmp[key].content} handleRemove={handleRemove} style={{backgroundColor: 'transparent', marginLeft: 15}}/>
                  </div>
                )
              }
            </MarketBox>
          )
        })
        }
      </div>
      </div>
      <div className='btn_box btn_box_center'>
        <div onClick={(e) => { handleSave()}}>저장하기</div>
      </div>
    </div>
  );
};

export default MarketModal;