import React, { useState, useEffect, useRef, useCallback } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
// import 'styles/common.scss';

function NvTextInput({ defaultValue, onChange, multiline, rows, style, className, onEnter, placeholder, initFocus = false, isPassword = false }) {
    const ref = useRef()
    const [value, _value] = useState('');

    const _onKeyDown = (e) => {
        if (onEnter && e.keyCode == 13) {
            onEnter(e.target.value);
        }
    }

    useEffect(() => {
        _value(defaultValue || '')
        
    }, [defaultValue])

    useEffect(() => {
        if(initFocus){
            ref.current.focus()
        }
    }, [])

    return (
        <OutlinedInput 
        inputRef={ref}
        className={'nv-textinput ' + className}
        style={style}
        label={null}
        placeholder={placeholder}
        onKeyDown={_onKeyDown}
        value={value} 
        inputProps={{type: isPassword ? 'password' : 'text', autoComplete: 'new-password'}}
        onChange={(e) => {
            _value(e.target.value)
            if(onChange != undefined) onChange(e.target.value)
        }} size="small" />
    );
}

export default NvTextInput;