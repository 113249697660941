import React from 'react';
import NvLabel from 'components/NvLabel'
const InputNewInvestment = ({state}) => {
    const headers = [
        {label: 'Starting', value: 'starting_date', width: 8},
        {label: 'Pre - value', value: 'pre_value', width: 8},
        {label: 'Volume', value: 'volume', width: 6.4},
        {label: 'Round', value: 'round', width: 6.4},
    ];
    return(
        <div className='info_table_box' style={{backgroundColor: 'white'}}>
          <table className='info_tbl'>
            <thead>
              <tr className='info_tbl_header'>
                {headers.map((data, i) => {
                  return(
                    <td style={{ width: data.width * 30 }}><p className=''>{data.label}</p></td> 
                  )
                })
                }
              </tr>
            </thead>
            <tbody className='table-scrollbar' style={{maxHeight: 100}}>
              {(state[headers[0].value] || state[headers[1].value] || state[headers[2].value]) &&
                <tr className=''>
                  <td style={{ width: headers[0].width * 30 }}>
                    <NvLabel value={state[headers[0].value]} unit={'년월'}/>
                  </td> 
                  <td style={{ width: headers[1].width * 30 }}>
                    <NvLabel value={state[headers[1].value]} unit={'억'}/>
                  </td> 
                  <td style={{ width: headers[2].width * 30 }}>
                    <NvLabel value={state[headers[2].value]} unit={'억'}/>
                  </td> 
                  <td style={{ width: headers[3].width * 30 }}>
                    <NvLabel value={state[headers[3].value]} unit={''}/>
                  </td> 
                </tr>
              }
            </tbody>
          </table>
        </div>
    )
}
export default InputNewInvestment;