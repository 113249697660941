import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'

const initSelectedList = [
    {
    label: '일반현황',
    value: 'basic',
    },
    {
    label: '투자',
    value: 'invest',
    },
    {
    label: '요약재무제표',
    value: 'financial',
    },
    {
    label: '조직',
    value: 'organization',
    },
    {
    label: '산업',
    value: 'market',
    },
    {
    label: '사업현황',
    value: 'product_and_service',
    },
    {
    label: '경쟁우위요소',
    value: 'competitive',
    },
    {
    label: '주요현황',
    value: 'current_status',
    },
    {
    label: '향후전략',
    value: 'future_strategy',
    },
    {
    label: '연구개발과제',
    value: 'rnd_project',
    },
    {
    label: '금융거래 및 소송',
    value: 'transaction_and_suit',
    },
]
export const getTotalHeaderList = () => {
    return initSelectedList
}
export const getCustomType = () => {
  const type = window.localStorage.getItem('report_custom_type') || 'a';
  return type;
}

export const getCustomHeaderReport = createAsyncThunk( 
    'getCustomHeaderReport'
    ,
    async() => {
    try{
      const type = window.localStorage.getItem('report_custom_type') || 'a';
      const res = await axios.get(url + '/user/custom/report_header/' + type);
      if(res.data.table.length >= 1){
        return res.data.table
      }
      else{
        return initSelectedList
      }
    }
    catch(e){
      console.log(e)
    }
})


export const reportCustomHeaderListSlice = createSlice({
  name: 'reportCustomHeaderList',
  initialState: initSelectedList,
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return initSelectedList
    }
  },
  extraReducers: {
    [getCustomHeaderReport.pending.type]: (state, action) => { // 호출 전
    },
    [getCustomHeaderReport.fulfilled.type]: (state, action) => { // 성공
        return action.payload
    },
    [getCustomHeaderReport.rejected.type]: (state, action) => {  // 실패
    },
}
})

export const { update, reset } = reportCustomHeaderListSlice.actions

export default reportCustomHeaderListSlice.reducer