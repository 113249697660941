import NvTextInput2 from 'components/NvTextInput2';
import InputBox from 'components/profile/InputBox';
import InputCertification from 'components/profile/InputCertification';
import InputCheck2 from 'components/profile/InputCheck2';
import React, { useEffect } from 'react';
import ItemBox from '../ItemBox';
import InputPrice2 from 'components/profile/InputPrice2';
import NvNumberInput2 from 'components/NvNumberInput2';
import InputBadge2 from 'components/profile/InputBadge2';
import InputBox3 from 'components/profile/InputBox3';
import { Backdrop, RegisterModal } from 'components/modals/modals';
import ServiceModal from 'components/profile/ServiceModal';
import MarketModal from 'components/profile/MarketModal';
import InvestmentModal from 'components/profile/InvestmentModal'
import NewInvestmentModal from 'components/profile/NewInvestmentModal'
import axios from 'axios';
import url from 'utils/backend';
import 'styles/profile.scss';
import 'pages/profile-edit2/ProfileEdit.scss';
const InformEdit = ({setExistList, newInvestmentVisible, setNewInvestmentVisible, setNewInvestment, marketVisible, setMarketVisible, setMarketList, serviceVisible, setServiceVisible, setProductList, investmentVisible, setInvestmentVisible, headerList, certification,setCertification, round, setRound, price, setPrice, amount, setAmount, business, setBusiness, sectors, setSectors, marketList, handleAdd, productList, existList, newInvestment, techs, setTechs, handleUpdate}) => {

    return(
        <div className='investee-content-wrap'>
            <div style={{width:"100%", backgroundColor:'#eceff5'}}>
        <div className='form_box register-preview-box'>
            <div style={{width:"100%", backgroundColor:'#eceff5'}}>
            {/* <ItemBox title='투자사 분류'>
                <InputCertification state={certification} setState={setCertification}/>
            </ItemBox>
            <ItemBox title='선호 라운드'>
                <InputCheck2 type='round' state={round} setState={setRound} />
            </ItemBox> */}
            {/* <ItemBox title='주 투자 금액'>
                <InputPrice2  state={price} setState={setPrice} />
            </ItemBox>
            <ItemBox title='현재투자재원'>
                <div className='text_input'>
                    <NvNumberInput2 defaultValue={amount} onChange={(v) => setAmount(v)} unit='억' />
                </div>
            </ItemBox> */}
            <InputBox3 required option='main_business' state={business} setState={setBusiness} />
            <ItemBox title='산업분야'>
                <InputBadge2 state={sectors} setState={setSectors} type='industry' />
            </ItemBox>
            <ItemBox title='기술'>
                <InputBadge2 state={techs} setState={setTechs} type='technology' />
            </ItemBox>
            <InputBox3 state={marketList} option={'market'} handleAdd={handleAdd}/>
            <InputBox3 state={productList} required  option={'product'} handleAdd={handleAdd}/>
            <InputBox3 required option={'investment'} state={existList} handleAdd={handleAdd}/>
            <InputBox3 required  option={'newInvestment'} handleAdd={handleAdd} state={newInvestment}/>
            <RegisterModal
        className='nv-modal register-modal-scrollbar'
        show={serviceVisible}
        style={{overflow: 'auto'}}
        onHide={() => setServiceVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <ServiceModal onClose={() => setServiceVisible(false)} setVisible={setServiceVisible} arr={productList} setArr={setProductList}/>
      </RegisterModal>
      <RegisterModal
        className='nv-modal'
        show={investmentVisible}
        onHide={() => setInvestmentVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <InvestmentModal arr={existList} headers={headerList.investment} onClose={() => setInvestmentVisible(false)} setVisible={setInvestmentVisible} setArr={setExistList}/>
      </RegisterModal>
      <RegisterModal
        className='nv-modal'
        show={newInvestmentVisible}
        onHide={() => setNewInvestmentVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <NewInvestmentModal newInvestment={newInvestment} setNewInvestment={setNewInvestment} onClose={() => setNewInvestmentVisible(false)} setVisible={setNewInvestmentVisible}/>
      </RegisterModal>   
      <RegisterModal
        className='nv-modal register-modal-scrollbar'
        style={{height: 750, overflow: 'auto'}}
        show={marketVisible}
        onHide={() => setMarketVisible(false)}
        renderBackdrop={(props) => <Backdrop {...props} />}
      >
        <MarketModal arr={marketList} setArr={setMarketList} onClose={() => setMarketVisible(false)} setVisible={setMarketVisible}/>
      </RegisterModal>   
            <div style={{ width: '100%',display: 'flex', justifyContent: 'center', paddingBottom: 30, marginTop: 50}}>
                <div className='profile-edit-btn' onClick={handleUpdate} >
                    저장
                </div>
            </div></div></div></div>
        </div>
    )
}

export default InformEdit;