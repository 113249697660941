import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    img{
        margin-top: -15px;
        margin-bottom: 15px;
    }
`;
const EmptyList = ({label, containerStyle}) => {
    return(
        <Container
            style={containerStyle}
        >
            <img src={require('assets/images/empty_Icon.png')} />
            {label}
        </Container>
    )
}

export default EmptyList;