import DateFormat_Year_Month_Date from "utils/DateFormat_Year_Month_Date";

const NwesItem = ({item}) => {
    return(
        <a className='investor-news-item' href={item.url}>
            <div className='news-date'>{DateFormat_Year_Month_Date(item.date)}</div>
            <div className='news-title'>
                {item.title}
            </div>
            <div className='news-content'>
                {item.content}
            </div>
        </a>
    )
}
export default NwesItem;