import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


export const searchSlice = createSlice({
  name: 'registerSearch',
  initialState: '',
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return ''
    }
  },

})

export const { update, reset } = searchSlice.actions

export default searchSlice.reducer