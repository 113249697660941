import{
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil'
import {recoilPersist} from 'recoil-persist'
import HomeIcon from '@mui/icons-material/Home';
const { persistAtom } = recoilPersist();
export const labelType = atom ({
    key:'labelType',
    default:'combination',
    effects_UNSTABLE:[persistAtom]
})
export const btmTypeState = atom ({
    key:'btmType',
    default:''
})
export const isDragState = atom({
    key:'isDrag',
    default:'false'
})
export const tableEnterprise = atom({
    key:'tableEnterprise',
    default:[
        {arr:[{value:11,col:1},
            {value:'제이에스이앤엘',color:'green',align:'left',col:4},
            {value:'수출포장스틸용기및일반구조물, 자동차제조산업 , 전기차배터리/ 자동화공장전용물류 특화용기 설계/제작',col:4,align:'left'},
            {value:'22.07.27',col:2},{value:'Series Pre-A',col:3},{align:'right',value:'Pre 100억',col:2},
            {align:'right',value:'7.2억',col:2},
            {value:'RCPS',align:'right',col:2},{value:'Series Pre-A',col:3},
            {value:<HomeIcon style={{width:15,height:15}}/>,col:1,}],state:false},
        {arr:[{value:11,col:1},
            {value:'제이에스이앤엘',color:'green',align:'left',col:4},
            {value:'수출포장스틸용기및일반구조물, 자동차제조산업 , 전기차배터리/ 자동화공장전용물류 특화용기 설계/제작',col:4,align:'left'},
            {value:'22.07.27',col:2},{value:'Series Pre-A',col:3},{align:'right',value:'Pre 100억',col:2},
            {align:'right',value:'7.2억',col:2},
            {value:'RCPS',align:'right',col:2},{value:'Series Pre-A',col:3},
            {value:<HomeIcon style={{width:15,height:15}}/>,col:1,}],state:false},
        {arr:[{value:11,col:1},
            {value:'제이에스이앤엘',color:'green',align:'left',col:4},
            {value:'수출포장스틸용기및일반구조물, 자동차제조산업 , 전기차배터리/ 자동화공장전용물류 특화용기 설계/제작',col:4,align:'left'},
            {value:'22.07.27',col:2},{value:'Series Pre-A',col:3},{align:'right',value:'Pre 100억',col:2},
            {align:'right',value:'7.2억',col:2},
            {value:'RCPS',align:'right',col:2},{value:'Series Pre-A',col:3},
            {value:<HomeIcon style={{width:15,height:15}}/>,col:1,}],state:false},
        {arr:[{value:11,col:1},
            {value:'제이에스이앤엘',color:'green',align:'left',col:4},
            {value:'수출포장스틸용기및일반구조물, 자동차제조산업 , 전기차배터리/ 자동화공장전용물류 특화용기 설계/제작',col:4,align:'left'},
            {value:'22.07.27',col:2},{value:'Series Pre-A',col:3},{align:'right',value:'Pre 100억',col:2},
            {align:'right',value:'7.2억',col:2},
            {value:'RCPS',align:'right',col:2},{value:'Series Pre-A',col:3},
            {value:<HomeIcon style={{width:15,height:15}}/>,col:1,}],state:false},
        {arr:[{value:11,col:1},
            {value:'제이에스이앤엘',color:'green',align:'left',col:4},
            {value:'수출포장스틸용기및일반구조물, 자동차제조산업 , 전기차배터리/ 자동화공장전용물류 특화용기 설계/제작',col:4,align:'left'},
            {value:'22.07.27',col:2},{value:'Series Pre-A',col:3},{align:'right',value:'Pre 100억',col:2},
            {align:'right',value:'7.2억',col:2},
            {value:'RCPS',align:'right',col:2},{value:'Series Pre-A',col:3},
            {value:<HomeIcon style={{width:15,height:15}}/>,col:1,}],state:false},
        {arr:[{value:11,col:1},
            {value:'제이에스이앤엘',color:'green',align:'left',col:4},
            {value:'수출포장스틸용기및일반구조물, 자동차제조산업 , 전기차배터리/ 자동화공장전용물류 특화용기 설계/제작',col:4,align:'left'},
            {value:'22.07.27',col:2},{value:'Series Pre-A',col:3},{align:'right',value:'Pre 100억',col:2},
            {align:'right',value:'7.2억',col:2},
            {value:'RCPS',align:'right',col:2},{value:'Series Pre-A',col:3},
            {value:<HomeIcon style={{width:15,height:15}}/>,col:1,}],state:false},
        
     ]
})
export const tableEnterpriseList =atom({
    key:'tableEnterpriseList',
    default:[
        {label:'번호', col:1},
        {label:'기업명', col:4},
        {label:'주요사업', col:4},
        {label:'투자일자',col:2},
        {label:'투자단계',col:3},
        {label:'투자밸류', col:2,align:'right'},
        {label:'투자금액',col:2, align:'right'},
        {label:'투자형태', col:2},
        {label:'성장단계',col:3},
        {label:'홈',col:1}
    ]
})
export const chapterList = atom({
    key:'chapterList',
    default:[
        {arr:[{value:1,col:1},
            {value:'제이에스이앤엘',color:'blue',col:17},
            {value:'',col:3,align:'left'},
            {value:'22.07.27',col:3}]},
        
     ]
})
export const chapterListTable =atom({
    key:'chapterListTable',
    default:[
        {label:'번호', col:1},
        {label:'조합명', col:17},
        {label:'대기자', col:3},
        {label:'등록일',col:3},
    ]
})
export const overview =atom({
    key:'overview',
    default:[
        {label:'조합명',value:'name',th:2,td:10},
        {label:'조합구분',value:'division',th:2,td:2},
        {label:'결성일',value:'formation_date',th:2,td:2},
        {label:'만기일',value:'due_date',th:2,td:2},
        {label:'존속기간',value:'duration_period',th:2,td:2},
        {label:'결산월',value:'settlement_month',th:2,td:2},
        {label:'등록(설립)일',value:'establish_date',th:2,td:2},
        {label:'결성금액',value:'formation_amount',th:2,td:2},
        {label:'1좌당금액',value:'share_per_price',th:2,td:2},
        {label:'고유번호',value:'unique_number',th:2,td:2},
        {label:'기준수익률',value:'standard_rate',th:2,td:2},
        {label:'관리보수',value:'management_fee',th:2,td:2},
        {label:'성과보수',value:'performance_fee',th:2,td:2},
        {label:'조합상태',value:'status',th:2,td:2},
        {label:'투자금액',value:'investment_amount',th:2,td:2},
        {label:'투자잔액',value:'investment_balance',th:2,td:2},
        {label:'투자기업',value:'investment_company',th:2,td:2},
        {label:'관할구청',value:'district_office',th:2,td:2},
        {label:'세무관서',value:'customs_office',th:2,td:2},
        {label:'은행명',value:'bank_name',th:2,td:2},
        {label:'은행계좌',value:'bank_account',th:2,td:2},
        {label:'회계감사',value:'auditing',th:2,td:2},
        {label:'소재지',value:'address',th:2,td:10},
        {label:'첨부파일',value:'file',th:2,td:10},
        
    ]
})
export const unionInfo = atom({
    key:'unionInfo',
    default:[
        {label:'항목',value:'영업보고',th:2,td:10},
        {label:'제목',value:'NEORNAT MAGAZINE_11호_2022.10',th:2,td:10},
        {label:'담당자',value:'네오나',th:2,td:10},
        {label:'등록일',value:'2022-10-28',th:2,td:2},
        {label:'첨부파일',value:'',th:2,td:6},
        {label:'내용',value:'1. 대한약학회 및 대한부인중개연구회 추계학술대회 소개강연',th:2,td:10},
    ]
})
export const companyOverview =atom({
    key:'companyOverview',
    default:[
        {label:'기업명',value:'',th:2,td:10},
        {label:'대표이사',value:'',th:2,td:2},{label:'설립연도',value:'',th:2,td:2},{label:'종업원수',value:'',th:2,td:2},
        {label:'자본금',value:'',th:2,td:2},{label:'기업성장단계',value:'',th:2,td:2},{label:'주권형태',value:'',th:2,td:2},
        {label:'홈페이지',value:'',th:2,td:2},{label:'법인등록번호',value:'',th:2,td:2},{label:'사업자등록번호',value:'',th:2,td:2},
        {label:'주요사업',value:'',th:2,td:10},
        {label:'산업분류',value:'',th:2,td:10},
        {label:'인증현황',value:'',th:2,td:10},
        {label:'주소',value:'',th:2,td:10},
        {label:'첨부파일',value:'',th:2,td:10},
        
    ]
})
export const newStock =atom({
    key:'newStock',
    default:[
        {label:'투자조합/재원',value:'a',th:2,td:10},
        {label:'투자금액',value: ' ',th:2,td:2},{label:'주당단가',value:'',th:2,td:2},{label:'주식수',value:'',th:2,td:2},
        {label:'투자방식',value:'',th:2,td:2},{label:'투자형태',value:'',th:2,td:2},{label:'지분율',value:'',th:2,td:2},
        {label:'투자밸류',value:'',th:2,td:2},{label:'투자단계',value:'',th:2,td:2},{label:'주권형태',value:'',th:2,td:2},
        {label:'세부투자조건',value:'',th:2,td:10,row:2},
        {label:'동반투자1',value:'',th:2,td:10},{label:'동반투자2',value:['','','','',''],th:2,td:10},
        {label:'자금사용처',value:'',th:2,td:10},
        
    ]
})
export const oldStock =atom({
    key:'oldStock',
    default:[
        {label:'투자조합/재원',value:'b',th:2,td:10},
        {label:'투자금액',value:'',th:2,td:2},{label:'주당단가',value:'',th:2,td:2},{label:'주식수',value:'',th:2,td:2},
        {label:'투자방식',value:'',th:2,td:2},{label:'투자형태',value:'',th:2,td:2},{label:'지분율',value:'',th:2,td:2},
        {label:'투자밸류',value:'',th:2,td:2},{label:'투자단계',value:'',th:2,td:2},{label:'주권형태',value:'',th:2,td:2},
        {label:'세부투자조건',value:'',th:2,td:10,row:2},
        
    ]
})
export const newOldStock =atom({
    key:'newOldStock',
    default:[
        {label:'투자조합/재원',value:'c',th:2,td:10},
        {label:'투자금액',value:'',th:2,td:2},{label:'주당단가',value:'',th:2,td:2},{label:'주식수',value:'',th:2,td:2},
        {label:'투자방식',value:'',th:2,td:2},{label:'투자형태',value:'',th:2,td:2},{label:'지분율',value:'',th:2,td:2},
        {label:'투자밸류',value:'',th:2,td:2},{label:'투자단계',value:'',th:2,td:2},{label:'주권형태',value:'',th:2,td:2},
        {label:'세부투자조건',value:'',th:2,td:10,row:2},
        
    ]
})
export const investmentOverview =atom({
    key:'investmentOverview',
    default:[
        {label:'투자조합/재원',value:'d',th:2,td:10},
        {label:'투자금액',value:'',th:2,td:2},{label:'주당단가',value:'',th:2,td:2},{label:'주식수',value:'',th:2,td:2},
        {label:'투자방식',value:'',th:2,td:2},{label:'투자형태',value:'',th:2,td:2},{label:'지분율',value:'',th:2,td:2},
        {label:'투자밸류',value:'',th:2,td:2},{label:'투자단계',value:'',th:2,td:2},{label:'주권형태',value:'',th:2,td:2},
        {label:'세부투자조건',value:'',th:2,td:10,row:2},
        {label:'동반투자',value:'',th:2,td:10},
        {label:'자금사용처',value:'',th:2,td:10},
        
    ]
})
export const getFunds =atom({
    key:'getFunds',
    default:{arr:
        [
            {label:'번호',value:'no',col:1},
            {label:'조합원명',value:['포샤인벤처파트너스','이주연'],col:2},
            {label:'유형',value:['GP','LP'],col:1},
            {label:'구분',value:['법인','개인'],col:1},
            {label:'출자좌수',value:['50좌','80좌'],col:1},
            {label:'출자금액',value:['50,000,000원','80,000,000원'],col:1},
            {label:'출자비율',value:['6.667%','10.667%'],col:1},
            {label:'납입일자',value:['0000-00-00','0000-00-00'],col:1},
            {label:'은행명',value:['',''],col:1},
            {label:'계좌번호',value:['',''],col:1},
            {label:'납입계좌',value:['',''],col:1},
        ],
        total:2,
        totalCol:12
    }
})
export const memberChange =atom({
    key:'memberChange',
    default:{arr:[
        {label:'변동일자',value:[''],col:2},
        {label:'변동구분',value:[''],col:1},
        {label:'조합원',value:[''],col:1},
        {label:'보유좌수',value:[''],col:1},
        {label:'양수도좌수',value:[''],col:1},
        {label:'양수도출자금액',value:[''],col:2},
        {label:'대상조합원',value:[''],col:2},
        {label:'양수도금액',value:[''],col:2},
        ],
        total:1
    }
})
export const investmentView =atom({
    key:'investmentView',
    default:{arr:[
        {label:'기업명',value:['남송바이탈'],col:2,},
        {label:'투자일자',value:['2020-09-11'],col:1,align:'center'},
        {label:'주식구분',value:[''],col:1,align:'center'},
        {label:'투자형태',value:['CS(보통주)'],col:2,align:'center'},
        {label:'주당단가',value:['17,227원'],col:1,align:'right'},
        {label:'투자금액',value:['499,755,270원'],col:1,align:'right'},
        {label:'투자밸류',value:['Pre 120억'],col:1,align:'center'},
        {label:'비고',value:[''],col:3,align:'center'},
        ],
        total:1,
        totalCol:12
    }
})
export const withDraw =atom({
    key:'withDraw',
    default:{arr:[
        {label:'일자',value:['2020-09-15'],col:2,align:'center'},
        {label:'거래대상자',value:[''],col:1,align:'center'},
        {label:'적요',value:['법률감사'],col:1,align:'center'},
        {label:'은행',value:['신한'],col:1,align:'center'},
        {label:'계좌번호',value:['100-034-756175'],col:2,align:'center'},
        {label:'금액',value:['1,650,000원'],col:2,align:'center'},
        {label:'비고',value:['조합결성/청산 통합 1회 지급 계약, 부가세포함금액'],col:3,align:'center'},
        ],
        total:1,
        totalCol:12
    }
})
export const managementFee =atom({
    key:'managementFee',
    default:{arr:[
        {label:'년도',value:[''],col:1},
        {label:'회차',value:[''],col:1},
        {label:'지급일',value:[''],col:1},
        {label:'관리보수 기간',value:[''],col:2},
        {label:'기간일수',value:[''],col:2},
        {label:'보수률(액)',value:[''],col:1},
        {label:'금액',value:[''],col:1},
        {label:'출금계좌',value:[''],col:2},
        {label:'비고',value:[''],col:2},
        ],
        total:1,
        totalCol:12
    }
})
export const distribution =atom({
    key:'distribution',
    default:{arr:[
        {label:'번호',value:[''],col:1},
        {label:'조합원명',value:[''],col:1},
        {label:'출자비율',value:[''],col:1},
        {label:'출자원금',value:[''],col:2},
        {label:'수익금',value:[''], col:2},
        {label:'성과보수금액',value:[''],col:2},
        {label:'원천징수세액',value:[''],col:2},
        {label:'수익률', value:[''],col:1},
        ],
        total:1,
        totalCol:12
    }
})
export const summary = atom({
    key:'summary',
    default:[
        {label: '전체', value: '전체',checked:false},
        {label: '조기해산일', value: '조기해산일',add:3,checked:false},
        {label: '투자기간', value: '투자기간',add:3,checked:false},
        {label: '투자보고횟수:\n(연 1회)', value: '투자보고횟수:\n(연 1회)',add:3,checked:false},
        {label: '목표수익률', value: '목표수익률',add:3,checked:false},
        {label: '법률감사', value: '법률감사',add:3,checked:false},
        {label: 'GP', value: 'GP',add:3,checked:false},
        {label: '출자방법', value: '출자방법',add:3,checked:false},
        {label: '조합목적', value: '조합목적',add:1,checked:false},
        {label: '주요투자분야', value: '주요투자분야',add:1,checked:false},
        {label: '손실충당순서', value: '손실충당순서',add:1,checked:false},
        {label: '비고', value: '비고',add:1,checked:false},
    ]
})
export const secondary = atom({
    key:'secondary',
    default:[
        {label: '전체', value: '전체',checked:false},
        {label: '신주 총라운드 규모', value: '신주 총라운드 규모',add:3,checked:false},
        {label: '우선주 주요내용', value: '우선주 주요내용',add:3,checked:false},
        {label: '주요투자조건', value: '주요투자조건',add:3,checked:false},
        {label: '주목적 분야 여부', value: '주목적 분야 여부',add:1,checked:false},
        {label: '공동투자', value: '공동투자',add:1,checked:false},
        {label: '회수전략', value: '회수전략',add:1,checked:false},
        {label: '의결권', value: '의결권',add:1,checked:false},
        {label: '이해관계인', value: '이해관계인',add:1,checked:false},
        {label: '발행조건', value: '발행조건',add:1,checked:false},
        {label: '상환조건', value: '상환조건',add:1,checked:false},
        {label: '전환조건', value: '전환조건',add:1,checked:false},
        {label: '특약조건', value: '특약조건',add:1,checked:false},
        {label: '기타조건', value: '기타조건',add:1,checked:false},
        {label: '제제조건', value: '제제조건',add:1,checked:false},

    ]
})
export const keyword = atom({
    key:'keyword',
    default:[
        {label: '전체', value: '전체',checked:false},
        {label: '액면가', value: '액면가',add:3,checked:false},
        {label: '발행주식수', value: '발행주식수',add:3,checked:false},
        {label: '소싱경로', value: '소싱경로',add:3,checked:false},
        {label: '국세체납여부', value: '국세체납여부',add:3,checked:false},
        {label: '기술평가등급', value: '기술평가등급',add:1,checked:false},

    ]
})

export const member = atom({
    key:'member',
    default:[
        {label: '전체', value: '전체',checked:false},
        {label: '기업형태', value: '기업형태',checked:false},
        {label: '투자단계', value: '투자단계',checked:false},
        {label: '주식수', value: '주식수',checked:false},
        {label: '회수금액 & 투자잔액', value: '회수금액 & 투자잔액',checked:false},
        {label: '자본금', value: '자본금',checked:false},
        {label: '총발행주식수', value: '총발행주식수',checked:false},
        {label: '액면가', value: '액면가',checked:false},

    ]
})

export const investment = atom({
    key:'investment',
    default:[
        {label: '전체', value: '전체',checked:false},
        {label: '기준금액', value: '기준금액',checked:false},
        {label: '비율', value: '비율',checked:false},
        {label: '관리보수액', value: '관리보수액',checked:false},
    ]
})
export const assignment = atom({
    key:'assignment',
    default:[
        {label: '전체', value: '전체',checked:false},
        {label: '성과보수비율', value: '성과보수비율',checked:false},
        {label: '수익금', value: '수익금',checked:false},
        {label: '이자배당', value: '이자배당',checked:false},
        {label: '과세구분', value: '과세구분',checked:false},
        {label: '소득세', value: '소득세',checked:false},
        {label: '지방세', value: '지방세',checked:false},
        {label: '원리금 총 수령액', value: '원리금 총 수령액',checked:false},
        {label: '실지급 분배금액', value: '실지급 분배금액',checked:false},
        {label: '손실충당금', value: '손실충당금',checked:false},
        {label: '분배유보금', value: '분배유보금',checked:false},

    ]
})
export const certification = atom({
    key:'certification',
    default:[
        {label: '전체', value: '전체',checked:false},
        {label: '약정좌수', value: '약정좌수',checked:false},
        {label: '약정금액', value: '약정금액',checked:false},
        {label: '약정비율', value: '약정비율',checked:false},
        {label: '누계출자좌수', value: '누계출자좌 수',checked:false},
        {label: '누계출자금액', value: '누계출자금액',checked:false},
        {label: '누계비율', value: '누계비율',checked:false},

    ]
})