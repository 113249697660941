import React from 'react';
import NvLabel from 'components/NvLabel'
const InputInvestment = ({state}) => {
    const headers = [
        {label: 'Date', value: 'date', width: 3.5},
        {label: 'Amount', value: 'amount', width: 3.5},
        {label: 'Type', value: 'type', width: 3.5},
        {label: 'Round', value: 'round', width: 3.5},
        {label: 'Investor', value: 'investor', width: 12.8, textAlign: 'left'},
    ]
    return(
        <div className='info_table_box' style={{backgroundColor: 'white'}}>
          <table className='info_tbl'>
            <thead>
              <tr className='info_tbl_header'>
                {headers.map((data, i) => {
                  return(
                    <td style={{ width: data.width * 30 }}><p className=''>{data.label}</p></td> 
                  )
                })
                }
              </tr>
            </thead>
            <tbody className='table-scrollbar' style={{maxHeight: 300}}>
                {state.length >= 1 && 
                  state.map((data, i) => {
                    console.log(data)
                    return(
                      <tr className=''>
                        <td style={{ width: headers[0].width * 30 }}>
                          <NvLabel value={data[headers[0].value]} unit={'년월'}/>
                        </td> 
                        <td style={{ width: headers[1].width * 30 }}>
                          <NvLabel value={data[headers[1].value]} unit={'억'}/>
                        </td> 
                        <td style={{ width: headers[2].width * 30 }}>
                          <NvLabel value={data[headers[2].value]} />
                        </td> 
                        <td style={{ width: headers[3].width * 30 }}>
                          <NvLabel value={data[headers[3].value]} />
                        </td> 
                        <td style={{ width: headers[4].width * 30, textAlign:  headers[4].textAlign }}>
                          {/* <div>{data[headers[4].value]}</div> */}
                          <div style={{ width: headers[4].width * 30, whiteSpace: 'nowrap', overflowX: 'auto', overflowY: 'hidden', display: 'flex'}}>
                            {data[headers[4].value].map((text, i) => {
                              return(
                                <p style={{marginRight: 5}}>
                                  {text}
                                  {(data[headers[4].value].length - 1 != i) && ','}
                                </p>
                              )
                            })
                            }
                          </div>
                        </td> 
                      </tr>
                    )
                })}
            </tbody>
          </table>
        </div>
    )
}
export default InputInvestment;