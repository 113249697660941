const IsArrayEmpty = (arr) => {
    if(Array.isArray(arr)){
        if(arr.length >= 1){
            return false;
        }
        else{
            return true;
        }
    }
    else{
        return true;
    }
}

export default IsArrayEmpty;