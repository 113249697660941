import React, { useState, useEffect, useRef, useMemo , useCallback} from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
// import 'styles/common.scss';
import TextareaAutosize from 'react-textarea-autosize';
import _ from 'lodash'
import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';

function NvTextRich({ defaultValue, placeholder, onChange, className, style, readOnly = false, initFocus = false}) {
    const [value, _value] = useState('');
    const QuillRef = useRef();
    const components = useMemo(
        () => {
          let config = {
            toolbar: false,
            keyboard: {
                bindings: {
                    // 'list autofill': {
                    //     prefix: /^(-|\[ ?\])$/
                    // },
                    // 'linebreak': {
                    //     key: 13,
                    //     shiftKey: true,
                    //     format: ['list'],
                    //     handler: function (range, context) {
                    //         const indent = context.format.indent + 1 || 1
                    //         const content = `<p class='ql-indent-${indent}'><br/></p>`
                    //         this.quill.clipboard.dangerouslyPasteHTML(range.index + 1, content);
                    //         this.quill.setSelection(range.index + 1, 0);
                    //     }
                    // },
                    // 'tab': {
                    //     key: 9,
                    //     handler: function (range, context) {
                    //      if(context.offset === 0) {
                    //         this.quill.format('indent', 1, Quill.sources.USER);
                    //       } else {
                    //         return true
                    //       }
            
                    //     }
                    // }
                    // 'tab': {
                    //     key: 9,
                    //     handler:function (range, context){
                    //         console.log('indent')
                    //     }
                    // },
                    // 'tab': {
                    //     key: 9,
                    //     // shiftKey:true,
                    //     handler:function (range, context){
                    //         console.log('dd', context, range)
                    //         if(context.collapsed){
                    //             console.log(range, context)
                    //             const indent = context.format.indent + 1 || 1
                    //             const content = `<p class='ql-indent-${indent}'><br/></p>`
                    //         }
                    //     }
                    // }
                }
            },
            clipboard: {
                matchVisual: false,
                matchers: [
                    [ Node.ELEMENT_NODE, function(node, delta) {
                        var Delta = Quill.import('delta')
                        return delta.compose(new Delta().retain(delta.length(), 
                        { color: false,
                          background: false,
                          bold: false,
                          strike: false,
                          underline: false,
                          fontSize: false
                        }
                        ));
                      }
                    ] 
                ]
            }
          }
          return config
          },
        []
      );
    // const debouncedChange = useCallback(_.debounce((v) => onChange(v), 1000), [])
    const handleChange = (content, d, s) => {
        _value(content)
        if (content.replace(/<\/?[^>]+(>|$)/g, '') == '') {
            content = '';
        }
        setTimeout(() => {
            if(onChange) debouncedChange(content)
        }, 500);
    }

    // const debouncedChange = useCallback( _.debounce((v) => onChange(v), 0),[])
    const debouncedChange = (v) => onChange(v)
    useEffect(() => {
        _value(defaultValue)
        if(initFocus){
            QuillRef.current.focus()
        }
    }, [])

    return (
        !readOnly 
        ?
            <ReactQuill
                ref={(element) => {
                if (element !== null) {
                    QuillRef.current = element;
                }
                }}
                // style={{ height: ((rows * 35) - 33) + 'px' }}
                className={'nv-texteditor ' + className}
                value={value || ''}
                onChange={handleChange}
                placeholder={placeholder}
                modules={components}
                theme="snow"
                style={style}
                // placeholder="내용을 입력해주세요."
                onFocus={(e) => {
                // console.log('hi')
                // console.log(QuillRef.current)
                }}
                onBlur={(e) => {
                // console.log('bye')
                }}
                paste
                
            />
        :
            <ReactQuill
                ref={(element) => {
                if (element !== null) {
                    QuillRef.current = element;
                }
                }}
                // style={{ height: ((rows * 35) - 33) + 'px' }}
                className={'nv-texteditor readonly ' + className}
                value={value || ''}
                theme="bubble"
                readOnly
                style={style}
                // placeholder="내용을 입력해주세요."
                onFocus={(e) => {
                // console.log('hi')
                // console.log(QuillRef.current)
                }}
                onBlur={(e) => {
                // console.log('bye')
                }}
                
            />
    );

}

export default NvTextRich;