import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import InputBox from 'components/profile/InputBox'
import ServiceModal from 'components/profile/ServiceModal'
import NewInvestmentModal from 'components/profile/NewInvestmentModal'
import InvestmentModal from 'components/profile/InvestmentModal'
import { Backdrop, RegisterModal } from 'components/modals/modals'
import PreviewBox from './component/PreviewBox'
import 'styles/profile.scss'
import Swal from 'sweetalert2'
import 'animate.css';
import MarketModal from 'components/profile/MarketModal';
import axios from 'axios';
import url from 'utils/backend';
import IsArrayEmpty from 'utils/IsArrayEmpty';
import RemoveHtml from 'utils/RemoveHtml'
import Auth from 'utils/auth';
import getPureBusinessNumber from 'utils/getPureBusinessNumber'
import NavBar from 'components/navbar/NavBar'
import Banner from 'components/Banner'
import Profile from './component/Profile'
import Security from './component/Security'
import Membership from './component/Membership'
import NavBarBtm from 'components/navbar/NavBarBtm'
const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [businessNumber, setBusinessNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isCoor, setIsCoor] = useState(false);
  const [rank, setRank] = useState('');
  const [email, setEmail] = useState('');
  const [sectors, setSectors] = useState([]);
  const [techs, setTechs] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [business, setBusiness] = useState('');
  const [team, setTeam] = useState('');
  const [gender, setGender] = useState('');
  const [serviceVisible, setServiceVisible] = useState(false);
  const [investmentVisible, setInvestmentVisible] = useState(false);
  const [newInvestmentVisible, setNewInvestmentVisible] = useState(false);
  const [marketVisible, setMarketVisible] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [navType, setNavType] = useState('Profile')
  const listType=[{label:'Profile'}, {label:'Security'}, {label:'Membership'}]
  
  const [productList, setProductList] = useState([
    // {
    //   name: '',
    //   stage: '',
    //   content: ''
    // }
  ])
  const [existList, setExistList] = useState([
    // {
    //   date1: '',
    //   date2: '',
    //   amount: '',
    //   round: '',
    //   investor: ''
    // }
  ]);
  const [marketList, setMarketList] = useState({
    domestic: {
      opts: [{value: false, label: '개요'}, {value: false, label: '비전/성장성'}, {value: false, label: '동향/트렌드'}, {value: false, label: '경쟁흐름'}, {value: false, label: '진입장벽'}],
      content: '',
      value: '',
      title: '국내외 시장의',
      empty: false
    },
    business: {
      opts: [{value: false, label: '문제점/한계'}, {value: false, label: '요구사항'}, {value: false, label: '필요성'}, {value: false, label: '목적'}],
      content: '',
      title: '사업/제품/서비스/개발에 대한',
      empty: false,
      value: '',
    },
    company:{
      opts: [{value: false, label: '해결방안'}, {value: false, label: '대응방안'}, {value: false, label: '사업화방안'}],
      content: '',
      title: '동사에 대한',
      empty: false,
      value: '',

    },
    similarity:{
      opts: [{value: false, label: '제품/서비스'}, {value: false, label: '아이디어'}, {value: false, label: '매출액/규모'}, {value: false, label: '기술'}, {value: false, label: '개발'}],
      content: [],
      title: '업계 유사 회사를 기입해 주세요',
      empty: false,
      value: '',

    }
  })
  const [newInvestment, setNewInvestment] = useState({
    starting_date: null,
    pre_value: '',
    volume: '',
    round: ''
  })

  const handleAdd = (label) => {
    if(label == '제품 및 서비스'){
      setServiceVisible(true)
    }
    else if(label == '투자현황'){
      setInvestmentVisible(true)
    }
    else if(label == '신규투자유치'){
      setNewInvestmentVisible(true)
    }
    else if(label == '시장'){
      setMarketVisible(true);
    }
  }
  const removeNewList = (i) => {
    setExistList(existList.splice(i, 1))
  }
  const headerList = { // Total Width: 24
    investment:[
      {label: 'Date', value: 'date', width: 3.5},
      {label: 'Amount', value: 'amount', width: 3.5},
      {label: 'Type', value: 'type', width: 3.5},
      {label: 'Round', value: 'round', width: 3.5},
      {label: 'Investor', value: 'investor', width: 12.8, textAlign: 'left'},
    ],
    newInvestment: [
      {label: 'Starting', value: 'starting_date', width: 8},
      {label: 'Pre - value', value: 'pre_value', width: 8},
      {label: 'Volume', value: 'volume', width: 6.4},
      {label: 'Round', value: 'round', width: 6.4},
    ] 
  }
  const handleSuccessModal = () => {
    Swal.fire({
      title: '가입이 정상적으로 완료되었습니다.',
      confirmButtonText: '확인',
      showClass: {
        popup: 'animate__animated animate__fadeInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutDown'
      },
      customClass:{
        icon: 'swal-confirm-icon',
        popup: 'swal-confirm-container',
        title: 'swal-confirm-title ',
        confirmButton: 'swal-confirm-btn',
        cancelButton: 'swal-confirm-cancel'
      }
  })
    .then((result) => {
      if(result.isConfirmed) {
        setTimeout(() => {
          navigate('/')
        }, 500);
      }
    })
  }
  const isEmptyNewInvestment = () => {
    if(newInvestment.starting_date && newInvestment.pre_value && newInvestment.volume && newInvestment.round){
      return false;
    }
    else{
      return true;
    }
  }

  const isEmptyBusinessNumber = () => {
    var value = getPureBusinessNumber(businessNumber)
    if(value.length != 10){
      return true;
    }
    else{
      return false
    }
  }

  const isEmptyMarket = () => {
    let empty = true;
    Object.keys(marketList).map((key, i) => {
      if(key == 'similarity'){
        if(!IsArrayEmpty(marketList[key].content)){
          empty = false
        }
      }
      else if(marketList[key].content){
        empty = false;
      }
    })
    return empty
  }
  const getCompanyInfo = async() => {
    try{
      const res = await axios.get(url + '/investee');
      const data = res.data.investee;
      if(data.name){
        setCompanyName(data.name);
      }
      if(data.business.concept){
        setBusiness(RemoveHtml(data.business.concept))
      }
      else if(data.summary.basic.description){
        setBusiness(data.summary.basic.description)
      }
      if(!IsArrayEmpty(data.business.services)){
        let tmp = [];
        data.business.services.map((data, i) => {
          tmp.push({name: data.name, stage: data.stage.value, description: RemoveHtml(data.concept) || data.function})
        })
        setProductList(tmp);
      }
      if(!IsArrayEmpty(data.technology)){
        setTechs(data.technology);
      }
      if(data.investment.investment_records.length >= 1){
        let tmp = [];
        data.investment.investment_records.map((data, i) => {
          tmp.push({
            date: data.date_start,
            type: data.type,
            amount: data.volume,
            round: '',
            investor: data.investors
          })
        })
        setExistList(tmp);
      }
      let newTmp = {date: '', pv: '', volume: '', round: ''};
      if(data.investment.pre_value){
        newTmp.pre_value = data.investment.pre_value;
      }
      if(data.investment.volume){
        newTmp.volume = data.investment.volume;
      }
      if(data.investment.starting_date){
        newTmp.starting_date = data.investment.starting_date;
      }
      if(data.investment.round){
        newTmp.round = data.investment.round;
      }
      let newCategory = [];
      if(data.category.length >= 1){
        data.category.map((item, i) => {
          newCategory.push(item.text);
        })
        setSectors(newCategory)
      }
      setNewInvestment(newTmp)
    }
    catch(e){
      console.log(e)
    }
  }
  const checkEmptyData = () => {
    console.log(IsArrayEmpty(productList), IsArrayEmpty(productList), isEmptyMarket(), isEmptyNewInvestment(), isEmptyBusinessNumber())
    if(!name || !companyName || !rank || !email || !team  || !business || IsArrayEmpty(productList) || IsArrayEmpty(existList)  || isEmptyBusinessNumber()){
      Swal.fire({
        title: '필수 항목을 모두 입력해주세요.',
        confirmButtonText: '확인',
        showClass: {
          popup: 'animate__animated animate__fadeInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown'
        },
        customClass:{
            icon: 'swal-confirm-icon',
            popup: 'swal-confirm-container',
            title: 'swal-confirm-title ',
            confirmButton: 'swal-confirm-btn',
            cancelButton: 'swal-confirm-cancel'
        }
      })
    }
    else{
      setIsPreview(true)
    }
  }
  const updateCompanyInfo = async() => {
    try{
      const market = [];
      Object.keys(marketList).map((key, i) => {
        if(key != 'similarity'){
          if(marketList[key].value && marketList[key].content){
            market.push({group: marketList[key].title, key: marketList[key].value, value: marketList[key].content})
          }
        }
      })
      await axios.post(url + '/user/profile', {
        name: name,
        role: rank,
        is_founder: isCoor
      })
      .then((res) => console.log(res.status))
      await axios.post(url + '/user/company',{
        team: team,
        description: business,
        business_id: getPureBusinessNumber(businessNumber),
        business: sectors,
        technology: techs,
        market: market,
        competitor: marketList.similarity.content,
        services: productList,
        investments: existList,
        new_investment: newInvestment
      })
      handleSuccessModal();
    }
    catch(e){
      console.log(e)
    }
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if(!Auth.isLoggedIn()){
  //     navigate('/profile/create');
  //   }
  //   Auth.isProfile()
  //   .then((res) => {
  //     if(res){
  //       // navigate('/')
  //     }
  //   })
   
  // },[isPreview])

  useEffect(() => {
    if(Auth.isLoggedIn()){
      getCompanyInfo();
    }
    
  }, [])
  const result = () =>{
    if(navType=='Profile'){
      return <Profile />
    }
    else if(navType=='Security'){
      return <Security />
    }
    else if(navType=='Membership'){
      return <Membership />
    }
  }
  return(
    <div className='investee_register_root mn'>
      <div className="nv-line"></div>
      <NavBar type={"investee"} backgroundColor={"#f9f9f9"} />
      <main className='investee-content-wrap'>
        <div>
          <Banner differ={false} text={'Settings'} isAccount={true}/>
          <nav className='nav'>
            <div className='ul-wrapper'>
              <ul>
                <li className="mn-extended" aria-current={navType=='Profile'} onClick={()=>setNavType('Profile')}>
                  <a>Profile</a>
                </li>
                <li className="mn-extended" aria-current={navType=='Security'} onClick={()=>setNavType('Security')}>
                  <a>Security</a>
                </li>
                <li className="mn-extended" aria-current={navType=='Membership'} onClick={()=>setNavType('Membership')}>
                  <a>Membership & Billing</a>
                </li>
              </ul>
            </div>
          </nav>
          <div className='form-container'>
            {result()}
          </div>       
            
          <RegisterModal
            className='nv-modal register-modal-scrollbar'
            show={serviceVisible}
            style={{overflow: 'auto'}}
            onHide={() => setServiceVisible(false)}
            renderBackdrop={(props) => <Backdrop {...props} />}
          >
            <ServiceModal onClose={() => setServiceVisible(false)} setVisible={setServiceVisible} arr={productList} setArr={setProductList}/>
          </RegisterModal>
          <RegisterModal
            className='nv-modal'
            show={investmentVisible}
            onHide={() => setInvestmentVisible(false)}
            renderBackdrop={(props) => <Backdrop {...props} />}
          >
            <InvestmentModal arr={existList} headers={headerList.investment} onClose={() => setInvestmentVisible(false)} setVisible={setInvestmentVisible} setArr={setExistList}/>
          </RegisterModal>
          <RegisterModal
            className='nv-modal'
            show={newInvestmentVisible}
            onHide={() => setNewInvestmentVisible(false)}
            renderBackdrop={(props) => <Backdrop {...props} />}
          >
            <NewInvestmentModal newInvestment={newInvestment} setNewInvestment={setNewInvestment} onClose={() => setNewInvestmentVisible(false)} setVisible={setNewInvestmentVisible}/>
          </RegisterModal>   
          <RegisterModal
            className='nv-modal register-modal-scrollbar'
            style={{height: 750, overflow: 'auto'}}
            show={marketVisible}
            onHide={() => setMarketVisible(false)}
            renderBackdrop={(props) => <Backdrop {...props} />}
          >
            <MarketModal arr={marketList} setArr={setMarketList} onClose={() => setMarketVisible(false)} setVisible={setMarketVisible}/>
          </RegisterModal>    
        </div> 
      </main>   
      <NavBarBtm type={'Email'}/>

    </div>
  )
}

export default Register;