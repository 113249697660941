export const WriteDragTypes = {
    TXT:'txt',
    TITLE: 'title',
    SUBTITlE: 'subtitle',
    MIDDLETITLE:'middletitle',
    IMG:'img',
    LINE:'line',
}
export const WriteDragTagNames = {
    TXT:'p',
    TITLE: 'h1',
    SUBTITlE: 'div',
    MIDDLETITLE:'h2',
    IMG:'img',
    LINE:'hr',
}