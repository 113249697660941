import { createSlice } from '@reduxjs/toolkit'

const certificationFilter = {
  certification: []
}
export const certificationFilterSlice = createSlice({
    name: 'certificationFilter',
    initialState: certificationFilter,
    reducers: {
      update: (state, action) => {
        const {payload} = action
        return payload
      },
      reset: (state) => {
        return certificationFilter
      }
    },
})

export const { update, reset } = certificationFilterSlice.actions

export default certificationFilterSlice.reducer