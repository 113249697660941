import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'
import { productList, serviceList, medicineList, medicalList, marketDomesticList2, marketBusinessList2, marketCompanyList2, attractabilityList, growthList, investmentTypeList, growthStrategyList, differentiationList, technologyList, competitivenessList, nowSituationList, marketingList, roleList, educationList, salesAnalysisList, possibilityList, businessStatusList, marketingStatusList, MarketingStrategyList, productGrowthStrategyList, productMarketingStrategyList, WorkTypeList, BorrowingAccountList, ShareHolderTypeList, ShareHolderDivisionList, CapitalIncreaseList, reasonList, reason2List, AssignmentTypeList,} from 'utils/DropDownList';
const customTotalList = [
  {
    label: '산업분류', //fix
    value: 'industry',
    width: 10,
    order: 0,
    fix: true,
    parent: 'basic',
    render: 'AutoComboCol'
  },
  {
    label: '기술분류',//fix
    value: 'technology',
    width: 10,
    order: 1,
    fix: true,
    parent: 'basic',
    render: 'AutoComboCol'
  },
  {
    label: '주요사업', //fix
    value: 'description',
    width: 10,
    order: 2,
    fix: true,
    parent: 'basic',
    render: 'SentenceHorizonListCol'

  },
  {
    label: '회사연혁',
    value: 'company_history',
    width: 10,
    order: 3,
    type: 'bundle',
    parent: 'basic',
    render: 'OneHeaderListCol',
    child: [
      {
        label: '연도',
        value: 'company_date',
        width: 1,
        order: 0,
        type: 'date',
        unit: '년월'
      },
      {
        label: '주요사항',
        value: 'company_major',
        width: 9,
        order: 1,
        type: 'text'
      }
    ]
  },
  {
    label: '관계사 및 자회사',
    value: 'relative_and_subsidiary',
    width: 10,
    order: 4,
    type: 'bundle',
    parent: 'basic',
    render: 'OneHeaderListCol',
    child: [
      {
        label: '계열사명',
        value: 'company_name',
        width: 2,
        order: 0,
        type: 'text'
      },
      {
        label: '관계',
        value: 'relation',
        width: 1,
        order: 1,
        type: 'text'
      },
      {
        label: '주식수',
        value: 'stock_number',
        width: 1,
        order: 2,
        type: 'number',
        unit: '주'
      },
      {
        label: '지분율',
        value: 'stock_ratio',
        width: 1,
        order: 3,
        type: 'number',
        unit: '%'
      },
      {
        label: '취득원가',
        value: 'aquire_price',
        width: 1,
        order: 4,
        type: 'number',
        unit: '억'
      },
      {
        label: '비고',
        value: 'remarks',
        width: 4,
        order: 6,
        type: 'text',
      },
      
    ]
  },
  {
    label: '연락처',
    value: 'contact',
    parent: 'basic',
    independent: true,
    render: 'VerticalCol',
    order: 5,
    child: [
      {
        label: '담당자 이름',
        value: 'manager_name',
        width: 1,
        order: 0,
        type: 'text'
      },
      {
        label: '담당자 직급',
        value: 'manager_rank',
        width: 1,
        order: 1,
        type: 'text'
      },
      {
        label: '담당자 연락처',
        value: 'manager_phone',
        width: 1,
        order: 2,
        type: 'text'
      },
      {
        label: '담당자 이메일',
        value: 'manager_email',
        width: 2,
        order: 3,
        type: 'text'
      },
      {
        label: '홈페이지',
        value: 'homepage',
        width: 2,
        order: 4,
        type: 'text'
      },
      {
        label: '대표 연락처',
        value: 'representation_phone',
        width: 1,
        order: 5,
        type: 'text'
      },
      {
        label: '대표 이메일',
        value: 'representation_email',
        width: 2,
        order: 6,
        type: 'text'
      },
    ]
  },
  {
    label: '주주구성',
    value: 'shareholder_composition',
    parent: 'basic',
    render: 'OneHeaderListCol',
    order: 6,
    child: [
      {
        label: '주주명',
        value: 'shareholder_name',
        width: 3,
        order: 0,
        type: 'text'
      },
      {
        label: '종류',
        value: 'shareholder_type',
        width: 1,
        order: 1,
        type: 'combo',
        combo: ShareHolderTypeList
      },
      {
        label: '주식수(투자전)',
        value: 'stock_before',
        width: 1,
        order: 2,
        type: 'number',
        unit: '주'
      },
      {
        label: '지분율(투자전)',
        value: 'share_rate_before',
        width: 1,
        order: 3,
        type: 'number',
        unit: '%'
      },
      {
        label: '주식수(투자후)',
        value: 'stock_after',
        width: 1,
        order: 4,
        type: 'number',
        unit: '주'
      },
      {
        label: '지분율(투자후)',
        value: 'share_rate_after',
        width: 1,
        order: 5,
        type: 'number',
        unit: '%'
      },
      {
        label: '주주구분',
        value: 'shareholder_classification',
        width: 2,
        order: 6,
        type: 'combo',
        combo: ShareHolderDivisionList
      },
    ]
  },
  {
    label: '자본금변동내역',
    value: 'capital_change_history',
    type: 'list',
    parent: 'basic',
    render: 'OneHeaderListCol',
    child: [
      {
        label: '연도',
        value: 'date',
        width: 1,
        order: 0,
        type: 'date',
        unit: '년월'
      },
      {
        label: '주식종류',
        value: 'stock_type',
        width: 1,
        order: 1,
        type: 'text'
      },
      {
        label: '주식수',
        value: 'stock_number',
        width: 1,
        order: 2,
        type: 'number',
        unit: '주'
      },
      {
        label: '발행가액',
        value: 'issue_price',
        width: 1,
        order: 3,
        type: 'number',
        unit: '원'
      },
      {
        label: '납입금',
        value: 'payment',
        width: 1,
        order: 4,
        type: 'number',
        unit: '억'
      },
      {
        label: '자본잉여금',
        value: 'capital_surplus',
        width: 1,
        order: 5,
        type: 'number',
        unit: '억'
      },
      {
        label: '증자내역',
        value: 'capital_increase',
        width: 2,
        order: 6,
        type: 'combo',
        combo: CapitalIncreaseList
      },
      {
        label: '비고',
        value: 'remarks',
        width: 2,
        order: 7,
        type: 'text',
      },
    ]
  },
  {
    label: '스톡옵션 부여내역',
    value: 'stock_option_history',
    type: 'list',
    parent: 'basic',
    render: 'OneHeaderListCol',
    child: [
      {
        label: '이름',
        value: 'name',
        width: 1,
        order: 0,
        type: 'text'
      },
      // {
      //   label: '발행연도',
      //   value: 'date',
      //   width: 1,
      //   order: 1,
      //   type: 'date',
      //   unit: '년월'
      // },
      // {
      //   label: '행사연도',
      //   value: 'event_date',
      //   width: 1,
      //   order: 2,
      //   type: 'date',
      //   unit: '년월'
      // },
      {
        label: '행사기간',
        value: 'event_range_date',
        width: 2,
        order: 2,
        type: 'date_range',
        unit: '년월',
        init: [],
        placeholder: ['계약 시작', '계약 종료']
      },
      {
        label: '행사가격',
        value: 'event_price',
        width: 1,
        order: 3,
        type: 'number',
        unit: '원'
      },
      {
        label: '발행된 스톡옵션의 수',
        value: 'issued_stock_option_number',
        width: 2,
        order: 4,
        type: 'number',
        unit: '주'
      },
      {
        label: '부여가능 최대 스톡옵션',
        value: 'grantable_max_stock_option',
        width: 2,
        order: 5,
        type: 'number',
        unit: '주'
      },
      {
        label: '비고',
        value: 'remarks',
        width: 2,
        order: 6,
        type: 'text'
      }
    ]
  },
    {
      label: '투자유치현황',
      value: 'funding',
      parent: 'invest',
      render: 'OneHeaderListCol',
      child: [
        {
          label: '투자일', //fix
          value: 'range_date',
          width: 2,
          type: 'date_range',
          unit: '년월',
          order: 0,
          init: [],
          placeholder: ['YYYY-MM', 'YYYY-MM']
        },
        {
          label: 'Pre-Value',//fix
          value: 'pre_value',
          width: 1,
          order: 1,
          type: 'number',
          unit: '억'

        },
        {
          label: 'Volume',//fix
          value: 'volume',
          width: 1,
          order: 2,
          type: 'number',
          unit: '억'

        },
        {
          label: '투자형태',
          value: 'type',
          width: 1,
          combo: investmentTypeList,
          order: 3,
          type: 'combo',

        },
        {
          label: '투자단계',
          value: 'round',
          width: 1,
          combo: growthList,
          order: 4,
          type: 'combo'

        },
        {
          label: '투자사명',
          value: 'investors',
          width: 4,
          readWidth: 3,
          order: 5,
          dataType: 'array',
          type: 'badge'
        },
      ]
    },
    {
      label: '신주유치',
      value: 'newFunding',
      parent: 'invest',
      depth: 3,
      child: [
        {
          label: '신주유치',
          value: 'new_funding',
          subParent: 'newFunding',
          render: 'direct',
          type: 'spread',
          child: [
            {
              label: '시작일',//fix
              value: 'start_date',
              width: 1,
              order: 0,
              type: 'date',
              unit: '년월'
            },
            {
              label: '희망밸류',//fix
              value: 'pre_value',
              width: 1,
              order: 1,
              type: 'number',
              unit: '억'
            },
            {
              label: '희망금액',//fix
              value: 'volume',
              width: 1,
              order: 2,
              type: 'number',
              unit: '억'
  
            },
            {
              label: '투자형태',
              value: 'type',
              width: 1,
              combo: investmentTypeList,
              order: 3,
              type: 'combo',
  
            },
            {
              label: '투자단계',
              value: 'round',
              width: 1,
              combo: growthList,
              order: 4,
              type: 'combo',
  
            },
            {
              label: '검토회사',
              value: 'reviewed_group',
              width: 5,
              readWidth: 5,
              order: 5,
              dataType: 'array',
              type: 'badge'
            },
            {
              label: '유치현황',
              value: 'attraction_status',
              order: 6,
              width: 10,
              type: 'bundle',
              child: [
                {
                  label: '진행상황',
                  value: 'status',
                  width: 2,
                  combo: nowSituationList,
                  order: 0,
                  type: 'combo'
                },
                {
                  label: '유치의견',
                  value: 'type',
                  type: 'combo',
                  combo: attractabilityList,
                  width: 1
                },
                {
                  label: '부정적',
                  value: 'negative_cause',
                  type: 'combo',
                  width: 6,
                  combo: reasonList
                },
                {
                  label: '긍정적',
                  value: 'positive_cause',
                  type: 'combo',
                  width: 6,
                  combo: reason2List
                },
                {
                  label: '보통',
                  value: 'neutral_cause',
                  type: 'text',
                  width: 6,
                },
              ]
            },
          ]
        },
        {
          label: '추정판관비계산',
          value: 'estimate_budget_calculation',
          subParent: 'newFunding',
          render: 'direct',
          child: [
            {
              label: '보유금액',
              value: 'current_amount',
              width: 1,
              order: 0,
              type: 'number',
              unit: '억'
            },
            {
              label: '월 고정비',
              value: 'monthly_fixed_amount',
              width: 1,
              order: 1,
              type: 'number',
              unit: '억'
            },
            {
              label: '월 판매관리비',
              value: 'monthly_fee',
              width: 1,
              order: 2,
              type: 'number',
              unit: '억'
            },
            {
              label: '정부과제 잔여 금액',
              value: 'remain_amount_assignment',
              width: 2,
              order: 3,
              type: 'number',
              unit: '억'
            },
            {
              label: '추가 적정 고정비',//fix
              value: 'fair_fixed_amount',
              width: 2,
              order: 4,
              type: 'number',
              unit: '억',
              readOnly: true
            },
            {
              label: '추정 당해 N개월 비용',//fix
              value: 'this_year_required_amount',
              width: 2,
              order: 5,
              type: 'number',
              unit: '억',
              readOnly: true
            },
            {
              label: '추정 내년 예산',//fix
              value: 'next_year_required_amount',
              width: 1,
              order: 6,
              type: 'number',
              unit: '억',
              readOnly: true
            },
          ]
        },   
        {
          label: '자금 사용처',
          value: 'fund_used',
          order: 8,
          subParent: 'newFunding',
          render: 'VerticalAddCol',
          add_unit: '억',
          child: [
            {
              label: '운전 자금',
              value: 'operating_funds',
              width: 1,
              order: 0,
              type: 'number',
              unit: '억'
            },
            {
              label: 'R&D',
              value: 'rnd_funds',
              width: 1,
              order: 1,
              type: 'number',
              unit: '억'
            },
            {
              label: 'Recruiting',
              value: 'recruiting_funds',
              width: 1,
              order: 2,
              type: 'number',
              unit: '억'
            },
            {
              label: '마케팅',
              value: 'marketing_funds',
              width: 1,
              order: 3,
              type: 'number',
              unit: '억'
            },
            {
              label: '설비투자',
              value: 'facility_funds',
              width: 1,
              order: 4,
              type: 'number',
              unit: '억'
            },
            {
              label: '부지매입',
              value: 'land_funds',
              width: 1,
              order: 5,
              type: 'number',
              unit: '억'
            },
            {
              label: '양산자금',
              value: 'mass_production_funds',
              width: 1,
              order: 6,
              type: 'number',
              unit: '억'
            },
            {
              label: '제품 고도화',
              value: 'advanced_funds',
              width: 1,
              order: 7,
              type: 'number',
              unit: '억'
            },
          ]
        },
      ]
    },     
      {
        label: '손익현황',
        value: 'profit_and_loss',
        type: 'list',
        order: 0,
        parent: 'financial',
        render: 'OneHeaderListCol',
        child: [
          {
            label: '년월',//fix
            value: 'date',
            width: 1,
            order: 0,
            type: 'date',
            unit: '년월'
          },
          {
            label: '매출',//fix
            value: 'sales_amount',
            width: 1,              
            order: 1,
            type: 'number',
            unit: '억'
          },
          {
            label: '매출원가',
            value: 'cost_of_sales',
            width: 1,
            order: 2,
            type: 'number',
            unit: '억'
          },
          {
            label: '매출이익',
            value: 'profit_from_sales',
            width: 1,
            order: 3,
            type: 'number',
            unit: '억'
          },
          {
            label: '판매관리비',
            value: 'sales_and_management_cost',
            width: 1,
            order: 4,
            type: 'number',
            unit: '억'
          },
          {
            label: '영업이익',
            value: 'operating_profit',
            width: 1,
            order: 5,
            type: 'number',
            unit: '억'
          },
          {
            label: '당기순이익',
            value: 'net_income',
            width: 1,
            order: 6,
            type: 'number',
            unit: '억'
          },
        ]
      },
      {
        label: '재무현황',
        value: 'financial_status',
        type: 'list',
        parent: 'financial',
        render: 'VerticalListCol',
        order: 1,
        child:[
          {
            label: '년월',
            value: 'date',
            width: 1,
            order: 0,
            type: 'date',
            unit: '년월'
          },
          {
            label: '유동자산',
            value: 'working_capital',
            width: 1,
            order: 1,
            type: 'number',
            unit: '억'
          },
          {
            label: '비유동자산',
            value: 'non_working_capital',
            width: 1,
            order: 2,
            type: 'number',
            unit: '억'
          },
          {
            label: '자산총계',//fix
            value: 'total_asset',
            width: 1,
            order: 3,
            type: 'number',
            unit: '억'
          },  
          {
            label: '유동부채',
            value: 'working_debt',
            width: 1,
            order: 4,
            type: 'number',
            unit: '억'
          },  
          {
            label: '비유동부채',
            value: 'non_working_debt',
            width: 1,
            order: 5,
            type: 'number',
            unit: '억'
          },  
          {
            label: '부채총계',//fix
            value: 'total_debt',
            width: 1,
            order: 6,
            type: 'number',
            unit: '억'
          },  
          {
            label: '자본금',
            value: 'capital',
            width: 1,
            order: 7,
            type: 'number',
            unit: '억'
          },  
          {
            label: '자본잉여금',
            value: 'capital_surplus',
            width: 1,
            order: 8,
            type: 'number',
            unit: '억'
          },  
          {
            label: '이익잉여금',
            value: 'profit_surplus',
            width: 1,
            order: 9,
            type: 'number',
            unit: '억'
          },  
          {
            label: '자본총계',//fix
            value: 'total_capital',
            width: 1,
            order: 10,
            type: 'number',
            unit: '억'
          },  
        ]      
      },
      {
        label: '현금흐름',
        value: 'cash_flow',
        parent: 'financial',
        render: 'OneHeaderListCol',
        type: 'list',
        child: [
          {
            label: '년월',
            value: 'date',
            width: 1,
            type: 'date',
            unit: '년월',
            order: 0
          },
          {
            label: '영업활동현금흐름',
            value: 'operating_cash_flow',
            width: 1,
            type: 'number',
            unit: '억',
            order: 1
          },
          {
            label: '투자활동현금흐름',
            value: 'investment_activity_cash_flow',
            width: 1,
            order: 2,
            type: 'number',
            unit: '억',
          },
          {
            label: '재무활동현금흐름',
            value: 'financial_activity_cash_flow',
            width: 1,
            type: 'number',
            unit: '억',
            order: 3
          },
          {
            label: '비고',
            value: 'remarks',
            width: 6,
            type: 'text',
            order: 4
          },
        ]
      },      
      {
        label: '재무분석',
        value: 'financial_analysis',
        order: 2,
        parent: 'financial',
        type: 'list',
        render: 'OneHeaderListCol',
        child: [
          {
            label: '년월',
            value: 'date',
            width: 1,
            order: 0,  
            type: 'date',
            unit: '년월',

          },
          {
            label: '매출액성장률',
            value: 'sales_growth_rate',
            width: 1,
            order: 1,  
            type: 'number',
            unit: '%'
          },
          {
            label: '영업이익률',
            value: 'operating_margin_rate',
            width: 1,
            order: 2,  
            type: 'number',
            unit: '%'
          },
          {
            label: '당기순이익률',
            value: 'net_profit_margin_rate',
            width: 1,
            order: 3,  
            type: 'date',
            type: 'number',
            unit: '%'
            
          },
          {
            label: '부채비율',
            value: 'debt_rate',
            width: 1,
            order: 4,
            type: 'number',
            unit: '%'
            
          },
          {
            label: '유동비율',
            value: 'current_rate',
            width: 1,
            order: 5,
            type: 'number',
            unit: '%'
            
          },
          {
            label: '과거 매출 분석',
            value: 'past_sales_analysis',
            width: 1,
            combo: salesAnalysisList,
            order: 6,
            type: 'combo',
            
          },
          {
            label: '미래 매출 가능성',
            value: 'future_sales_possibility',
            width: 1,
            combo: possibilityList,
            order: 7,
            type: 'combo',

          },
          {
            label: '근거',
            value: 'cause',
            width: 2,
            order: 8,
            type: 'text'
          },
        ]
      },        
      {
        label: '차입금현황',
        value: 'borrowing',
        parent: 'financial',
        render: 'OneHeaderListCol',
        child:[
          {
            label: '유형',
            value: 'account',
            combo: BorrowingAccountList,
            width: 1,
            order: 0,
            type: 'combo'
          },
          {
            label: '차입처명',//fix
            value: 'financialInstitution_name',
            width: 2,
            order: 1,
            type: 'text'
          },
          {
            label: '차입일',//fix
            value: 'borrowing_date',
            width: 1,
            order: 2,
            type: 'date',
            unit: '년월',

          },
          {
            label: '만기일',//fix
            value: 'due_date',
            width: 1,
            order: 3,
            type: 'date',
            unit: '년월',

          },
          {
            label: '차입액',//fix
            value: 'borrowing_price',
            width: 1,
            order: 4,
            type: 'number',
            unit: '억'
          },
          {
            label: '대출잔액',
            value: 'loan_balance',
            width: 1,
            order: 5,
            type: 'number',
            unit: '억'
          },
          {
            label: '이자율',
            value: 'interest_rate',
            width: 1,
            order: 6,
            type: 'number',
            unit: '%'
          },
          {
            label: '상환방법',
            value: 'repayment_method',
            width: 1,
            order: 7,
            type: 'text',
          },
          {
            label: '용도',
            value: 'purpose_of_borrowing',
            width: 1,
            order: 8,
            type: 'text',
          },
          {
            label: '담보및보증',
            value: 'guarantee',
            width: 1,
            order: 9,
            type: 'text',
          },
        ]
      },
      {
        label: 'CB/BW/발행내역',
        parent: 'financial',
        value: 'cb_bw_history',
        render: 'OneHeaderListCol',
        child: [
          {
            label: '발행처',
            value: 'issued',
            width: 2,
            order: 0,
            type: 'text'
          },
          {
            label: '발행일',
            value: 'issued_date',
            width: 1,
            order: 1,
            type: 'date',
            unit: '년월',
          },
          {
            label: '만기일',
            value: 'due_date',
            width: 1,
            order: 2,
            type: 'date',
            unit: '년월',
          },
          {
            label: '보장수익률',
            value: 'guaranteed_rate',
            width: 1,
            order: 3,
            type: 'number',
            unit: '%'
          },
          {
            label: '발행금액',
            value: 'issued_amount',
            width: 1,
            order: 4,
            type: 'number',
            unit: '억'
          },
          {
            label: '전환가액',
            value: 'conversion_price',
            width: 1,
            order: 5,
            type: 'number',
            unit: '억'
          },
          {
            label: '비고',
            value: 'remarks',
            width: 3,
            order: 6,
            type: 'text'
          }
        ]
      },
      {
        label: '한줄평',
        parent: 'organization',
        value: 'member_comment',
        width: 10,
        render: 'SentenceHorizonListCol'
      },
      {
        label: '주요인력',
        value: 'member',
        parent: 'organization',
        render: 'direct',
        child: [
          {
            label: '이름',
            value: 'name',
            width: 1,
            order: 0,
            type: 'text'
          },
          {
            label: '직위',
            value: 'rank',
            width: 1,
            order: 1,
            type: 'text'
          },
          {
            label: '역할',
            value: 'role',
            width: 1,
            combo: roleList,
            type: 'combo',
            order: 2
          },
          {
            label: '근무형태',
            value: 'work_type',
            width: 1,
            combo: WorkTypeList,
            type: 'combo',
            order: 3
          },
          {
            label: '학위',
            value: 'degree',
            width: 1,
            combo: educationList,
            type: 'combo',
            order: 4
          },
          {
            label: '학력 & 전공',
            value: 'major',
            width: 5,
            type: 'text',
            order: 5
          },
          {
            label: '주요 이력',//fix
            value: 'career',
            width: 10,
            type: 'text',
            order: 6
          },
        ]
      },
      {
        label: '타겟시장',
        value: 'market_target',
        parent: 'market',
        type: 'sentence_list',
        render: 'SentenceComboListCol',
        combo: marketDomesticList2,
      },
      {
        label: '타겟시장에 대한',
        value: 'market_target_about',
        parent: 'market',
        combo: marketBusinessList2,
        type: 'sentence_list',
        render: 'SentenceComboListCol',
      },
      {
        label: '동사에 의한',
        value: 'market_company',
        parent: 'market',
        combo: marketCompanyList2,
        type: 'sentence_list',
        render: 'SentenceComboListCol',
      },
      {
        label: '경쟁사현황',
        value: 'market_domestic_competition',
        parent: 'market',
        render: 'SentenceHorizonListCol',
      },
      {
        label: '시압개요',
        value: 'product_content',
        width: 10,
        parent: 'product_and_service',
        render: 'SentenceHorizonListCol'
      },      
      {
        label: '서비스 및 제품', //list
        value: 'product',
        parent: 'product_and_service',
        render: 'direct',
        type: 'bundle',
        child: [
          {
            label: '서비스 및 제품명', 
            value: 'type',
            combo: ['제품명', '서비스명', '의약품명', '의료기기명', '사업명', '주요사업'],
            width: 3,
            order: 0,
            type: 'combo',
            init: '제품명'
          },
          {
            label: '이름', 
            value: 'name',
            width: 0,
            order: 1,
            type: 'text',
            pass: true
          },
          {
            label: '사업화단계',
            value: 'stage',
            width: 3,
            combo: [...productList, ...serviceList, ...medicineList, ...medicalList],
            order: 2,
            type: 'combo'
          },
          {
            label: '내용',
            value: 'content',
            width: 10,
            order: 3,
            type: 'text'
          },
        ]
      },
      {
        label: '신규 서비스 및 제품', //list
        value: 'new_product',
        parent: 'product_and_service',
        render: 'direct',
        type: 'bundle',
        order: 0,
        child: [
          {
            label: '서비스 및 제품명', 
            value: 'type',
            combo: ['제품명', '서비스명', '의약품명', '의료기기명', '사업명', '주요사업'],
            width: 3,
            order: 0,
            type: 'combo',
            init: '제품명'

          },
          {
            label: '이름', 
            value: 'name',
            width: 0,
            order: 1,
            type: 'text',
            pass: true
          },
          {
            label: '사업화단계',
            value: 'stage',
            width: 3,
            combo: [...productList, ...serviceList, ...medicineList, ...medicalList],
            order: 2,
            type: 'combo'
          },
          {
            label: '내용',
            value: 'content',
            width: 10,
            order: 3,
            type: 'text'
          },
        ]
      },        
      {
        label: '수익모델',
        value: 'product_business_model',
        width: 10,
        parent: 'product_and_service',
        render: 'SentenceHorizonListCol'

      },
      {
        label: 'Process',
        value: 'product_process',
        width: 10,
        parent: 'product_and_service',
        render: 'SentenceHorizonListCol'

      },
      {
        label: '현황',
        value: 'product_business_status',
        width: 10,
        combo: businessStatusList,
        parent: 'product_and_service',
        render: 'SentenceComboListCol'

      },
      {
        label: '계획 및 전략',
        value: 'product_growth_strategy',
        combo: growthStrategyList,
        width: 10,
        combo: productGrowthStrategyList,
        parent: 'product_and_service',
        render: 'SentenceComboListCol'

      },
      {
        label: '마케팅',
        value: 'product_marketing_strategy',
        combo: marketingList,
        width: 10,
        combo: productMarketingStrategyList,
        parent: 'product_and_service',
        render: 'SentenceComboListCol'

      },
      {
        label: '보유기술',
        value: 'technology_possessed',
        parent: 'product_and_service',
        render: 'VerticalAddCol',
        add_unit: '건',
        child: [
          {
            label: '특허 출원',
            value: 'patent_application',
            width:1,
            order: 0,
            type: 'number',
            unit: '건'
          },
          {
            label: '특허 등록',
            value: 'patent_registration',
            width:1,
            order: 1,
            type: 'number',
            unit: '건'
          },
          {
            label: 'PCT 출원',
            value: 'pct_application',
            width:1,
            order: 2,
            type: 'number',
            unit: '건'
          },
          {
            label: 'PCT 등록',
            value: 'pct_registration',
            width:1,
            order: 3,
            type: 'number',
            unit: '건'
          },
          {
            label: '기술 이전',
            value: 'technology_transfer',
            width:1,
            order: 4,
            type: 'number',
            unit: '건'
          },
          {
            label: '디자인 등록',
            value: 'design_registration',
            width:1,
            order: 5,
            type: 'number',
            unit: '건'
          },
          {
            label: '상표 등록',
            value: 'brand_registration',
            width:1,
            order: 6,
            type: 'number',
            unit: '건'
          },
          {
            label: '출원 준비중',
            value: 'prepare_application',
            width:1,
            order: 7,
            type: 'number',
            unit: '건'
          },
        ]
      },
      {
        label: '경쟁력',
        value: 'competitive_competition',
        combo: competitivenessList,
        parent: 'competitive',
        render: 'SentenceComboListCol',
      },
      {
        label: '차별성',
        value: 'competitive_difference',
        combo: differentiationList,
        parent: 'competitive',
        render: 'SentenceComboListCol',
      },
      {
        label: '기술력',
        value: 'competitive_technical',
        parent: 'competitive',
        combo: technologyList,
        render: 'SentenceComboListCol',
      },
      {
        label: '사업현황',
        value: 'current_status_business_status',
        parent: 'current_status',
        combo: businessStatusList,
        render: 'SentenceComboListCol',

      },
      {
        label: '마케팅현황',          
        value: 'current_status_marketing_status',
        combo: marketingStatusList,
        parent: 'current_status',
        render: 'SentenceComboListCol',

      },
      {
        label: '수익화모델',      
        value: 'current_status_business_model_situation',
        parent: 'current_status',
        render: 'SentenceHorizonListCol',

      },
      {
        label: '지적재산권현황',      
        value: 'current_status_intellectual_property_status',
        parent: 'current_status',
        render: 'SentenceHorizonListCol',

      },
      {
        label: '주요계약',
        value: 'contract',
        parent: 'current_status',
        render: 'OneHeaderListCol',
        child: [
          {
            label: '파트너사/고객사',
            value: 'partner',
            width: 2,
            order: 0,
            type: 'text'
          },
          // {
          //   label: '계약 시작',
          //   value: 'start_date',
          //   width: 1,
          //   order: 1,
          //   type: 'date',
          //   unit: '년월'
          // },
          {
            label: '체결기간',
            value: 'range_date',
            width: 2,
            order: 1,
            type: 'date_range',
            unit: '년월',
            init: [],
            placeholder: ['계약 시작', '계약 종료']
          },
          // {
          //   label: '계약 종료',
          //   value: 'end_date',
          //   width: 1,
          //   order: 2,
          //   type: 'date',
          //   unit: '년월'
          // },
          {
            label: '계약 금액',
            value: 'price',
            width: 1,
            order: 2,
            type: 'number',
            unit: '억'
          },
          {
            label: '계약 내용',
            value: 'content',
            width: 2,
            order: 3,
            type: 'text'
          },         
          {
            label: '비고',
            value: 'remarks',
            width: 3,
            order: 4,
            type: 'text'
          }, 
        ]
      },
      {
        label: '성장전략',
        value: 'future_strategy_growth_strategy',
        parent: 'future_strategy',
        combo: growthStrategyList,
        type: 'sentence_list',
        render: 'SentenceComboListCol',

      },
      {
        label: '마케팅전략',
        value: 'future_strategy_marketing_strategy',
        parent: 'future_strategy',
        combo: MarketingStrategyList,
        type: 'sentence_list',
        render: 'SentenceComboListCol',

      },
      {
        label: '수익화전략',
        value: 'future_strategy_business_model_strategy',
        parent: 'future_strategy',
        render: 'SentenceHorizonListCol',
      },      
      {
        label: '정부과제',
        value: 'research',
        parent: 'rnd_project',
        type: 'list',
        render: 'OneHeaderListCol',
        child: [
          {
            label: '구분',
            value: 'type',
            width: 1,
            type: 'combo',
            combo: AssignmentTypeList,
            order: 0,
          },
          {
            label: '과제기간',
            value: 'range_date',
            width: 2,
            type: 'date_range',
            unit: '년월',
            order: 1,
            init: [],
            placeholder: ['시작일', '종료일']
          },
          // {
          //   label: '사업 시작',
          //   value: 'start_date',
          //   width: 1,
          //   type: 'date',
          //   unit: '년월',
          //   order: 1,

          // },
          // {
          //   label: '사업 종료',
          //   value: 'end_date',
          //   width: 1,
          //   type: 'date',
          //   unit: '년월',
          //   order: 2,

          // },
          {
            label: '사업명',
            value: 'name',
            width: 4,
            type: 'text',
            order: 2,
          },
          {
            label: '주관부처',
            value: 'main_department',
            width: 2,
            type: 'text',
            order: 3,
          },
          {
            label: '사업비',
            value: 'price',
            width: 1,
            type: 'number',
            unit: '억',
            order: 4,
          },  
        ]
      },
      {
        label: '정부과제분석', 
        value: 'research_analysis',
        parent: 'rnd_project',
        render: 'VerticalCol',
        child: [
          {
            label: '창업 후 총 과제 금액',
            value: 'government_assignment_total_amount',
            width: 2,
            order: 0,
            type: 'number',
            unit: '억'
          },
          {
            label: '창업 후 총 과제 수',
            value: 'government_assignment_total_count',
            width: 2,
            order: 1,
            type: 'number',
            unit: '개'
          },
          {
            label: '당해 사업비',
            value: 'this_year_business_expends',
            width: 2,
            order: 2,
            type: 'number',
            unit: '억'
          },
          {
            label: '내년 사업비',
            value: 'next_year_business_expends',
            width: 2,
            order: 3,
            type: 'number',
            unit: '억'
          },
          {
            label: '잔여 사업비',
            value: 'left_business_expends',
            width: 2,
            order: 4,
            type: 'number',
            unit: '억'
          },

        ]
      },        
      {
        label: '금융 및 소송',
        value: 'financial_suit',
        parent: 'transaction_and_suit',
        type: 'spread',
        render: 'VerticalCol',
        child:[
          {
            label: '국세 및 지방세 체납',
            value: 'national_and_tax_arrears',
            width: 3,
            order: 0,
            type: 'text'
          },
          {
            label: '가압류 및 소송',
            value: 'provisionalAttachment_and_suit',
            width: 4,
            order: 1,
            type: 'text'
          },
          {
            label: '신용불량여부',
            value: 'bad_credit',
            width: 3,
            order: 2,
            type: 'text'

          },
        ]
      }     
]

const initSelectedList = [
  {
    label: '산업분류', //fix
    value: 'industry',
    width: 10,
    order: 0,
    fix: true,
    parent: 'basic',
    render: 'AutoComboCol'
  },
  {
    label: '기술분류',//fix
    value: 'technology',
    width: 10,
    order: 1,
    fix: true,
    parent: 'basic',
    render: 'AutoComboCol'
  },
  {
    label: '주요사업', //fix
    value: 'description',
    width: 10,
    order: 2,
    fix: true,
    parent: 'basic',
    render: 'SentenceHorizonListCol'

  },

]
export const findParent = (value) => {
  let parent = '';
  customTotalList.some(d => {
    if(d.value == value){
      parent = d.parent 
      return;
    }
  })
  return parent
}
export const getItem = (value) => {
  return customTotalList.find(d => d.value == value);
}
export const getCustomTotalList = () => {
  return customTotalList
}
export const getCustomType = () => {
  const type = window.localStorage.getItem('report_custom_type') || 'a';
  return type;
}
export const getCustomList = async(type) => {
  try{
    const res = await axios.get(url + '/user/custom/report/' + type);
    if(res.data.table.length >= 1){
      return res.data.table
    }
    else{
      return initSelectedList
    }
  }
  catch(e){
    console.log(e)
  }
}
export const updateCustomReport = createAsyncThunk( 
    'updateReportCustomList'
    ,
    async() => {
    try{
      const type = window.localStorage.getItem('report_custom_type') || 'a';
      const res = await axios.get(url + '/user/custom/report/' + type);
      if(res.data.table.length >= 1){
        return res.data.table
      }
      else{
        return initSelectedList
      }
    }
    catch(e){
      console.log(e)
    }
})


export const reportCustomListSlice = createSlice({
  name: 'reportCustomList',
  initialState: [],
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return initSelectedList
    }
  },
  extraReducers: {
    [updateCustomReport.pending.type]: (state, action) => { // 호출 전
    },
    [updateCustomReport.fulfilled.type]: (state, action) => { // 성공
      return action.payload
    },
    [updateCustomReport.rejected.type]: (state, action) => {  // 실패
    },
}
})

export const { update, reset } = reportCustomListSlice.actions

export default reportCustomListSlice.reducer