import React, {useEffect, useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';

const InputCheck = ({state, setState, type}) => {
    const gender = [{value: 'male', label: '남자'}, {value: 'female' , label: '여자'}];
    const founder = [{value: 'yes', label: 'Yes 창업 멤버입니다'}, {value: 'no', label: 'No 창업 멤버가 아닙니다'}]
    const [options, setOptions] = useState('');

    const CheckBox = styled.div `
    span{
      font-size: 15px !important;
    }
    .css-i4bv87-MuiSvgIcon-root{
      fill: black !important;
    }
  `;
  useEffect(() => {
    if(type == 'gender'){
        setOptions(gender);
    }
    else if(type == 'founder'){
        setOptions(founder)
    }
  },[])
    return(
      <div className='check_boxes'>
        {(options) &&
            <CheckBox>                    
                {options.map((data, i) => {
                    return(
                        <FormControlLabel  control={<Checkbox checked={state == data.value} onChange={() => setState(data.value)}/>} label={data.label} />
                    )
                })
                }
            </CheckBox> 
        }
      </div> 
    )
}
export default InputCheck;
