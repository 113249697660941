import React, {useState, useEffect} from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const NvSelect = ({list = [],nvclasses,defaultValue, onChange}) => {
    const [value, setValue] = useState('')
    const handleChange = (v) => {
        if(onChange) onChange(v.target.value);
        setValue(v.target.value)
    }
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])
    return(
        <Select
          value={value}
          onChange={handleChange}
          style={nvclasses}
          className='nv-select'
        >
            {list.map(data => {
                return(
                    <MenuItem key={data.value} value={data.value}>{data.label}</MenuItem>
                )
            })
            }
        </Select>
    )
}
export default NvSelect