import Modal from 'react-overlays/Modal'
import styled from "styled-components";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const RegisterModal = styled(Modal)`
  position: fixed;
  z-index: 1040;
  width: 800px;
  height: 450px;
  top: 50%!important;
  left: 50%!important;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 0px;
  overflow: hidden
`;

export {Backdrop, RegisterModal};