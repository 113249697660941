import React, { useState, useEffect, useRef, useCallback } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ConvertComma from 'utils/ConvertComma'
import _ from 'lodash'
function NvNumberInput2({ defaultValue, unit = '원', type = 'float', onChange, state, setState, isComma, onBlur, className, isUnit = true }) {
    const inputRef = useRef();
    const [value, _value] = useState('');
    const [isFocus, setIsFocus] = useState(false);

    // const debouncedChange = useCallback(_.debounce((v) => onChange(v),1000),[state])

    const _onlyNumber = (v) => {
        if (type == 'float') {
            return v.replace(/[^0-9.-]/g, '');
        } else if (type == 'unsigned int') {
            return v.replace(/[^0-9]/g, '');
        } else if (type == 'signed int') {
            return v.replace(/[^0-9-]/g, '');
        } else {
            return v
        }
    }

    useEffect(() => {
        _value(defaultValue);
    }, [defaultValue])

    return (
        <OutlinedInput 
            className={className? className + ' nv-textinput nv-textinput-right' : 'nv-textinput nv-textinput-right'}
            value={value} 
            onFocus={(e) => {setIsFocus(true)}}
            onBlur={(e) => {if(onBlur)onBlur(e); setIsFocus(false)}}
            onChange={(e) => {
                let v;
                if(isComma) {
                    v = ConvertComma.comma(_onlyNumber(e.target.value))
                }
                else{
                    v = _onlyNumber(e.target.value)
                }
                if(setState != undefined) setState(v)
                _value(v)
                if(onChange) onChange(v)
            }} 
            endAdornment={
                isUnit 
                ?
                    <InputAdornment position="end">{unit}</InputAdornment>
                :
                    <InputAdornment id='nv-endAndornment-hover' position="end">{unit}</InputAdornment>

            } 
            size="small" 
            inputRef={inputRef}
        />
    );
}

export default NvNumberInput2;