import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import Auth from "utils/auth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import "../style.scss";
import User from 'assets/images/User.png'
const AvatarBox = styled(IconButton)`
  &:hover {
    background-color: unset !important;
  }
  border-radius:0 !important;
`;

const MyAvatar = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(anchorEl);
  const handleClick = (event) => {
    if(Auth.isLoggedIn()){
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    }
    else{
      navigate('/login')
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          zIndex: 20000,
        }}
      >
          {/* <span 
            className='nv-nav-item'
            // to="/combination"
            onClick={() => {
              window.location.href = '/combination'
            }}
          >
            <div className='nv-nav-link mn-extended'>
              조합관리
            </div>
          </span> */}
        <AvatarBox
          onClick={(e)=>handleClick(e)}
          size="small"
          sx={{
            ml: 2,
          }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {/* <Avatar
            sx={{
              width: 23,
              height: 23,
              fontSize: 12,
              fontWeight: 600,
              backgroundColor: "#168dd9",
              color:'#f9f9f9'
            }}
          >
            A
          </Avatar> */}
          {/* <div
            style={{
              color: 'black',
              fontSize: 11,
              fontWeight: 500,
              marginRight: 7,
              marginLeft: 15,
              textAlign: "right",
            }}
          >
            <p>{Auth.getNickname()} 님</p>
            <p>{Auth.getCompanyName()}</p>
          </div> */}
          {/* <IconButton style={{ padding: 0 }}>
            <KeyboardArrowDownIcon style={{ color: "black" }} />
          </IconButton> */}
          <img style={{width:24, height:24}} src={User}/>
        </AvatarBox>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        classes={{
          paper: "nv-bar-avatar-paper",
        }}
        disablePortal={true}
        PaperProps={{
          elevation: 0,
          sx: {
            // overflow: "visible",
            // filter: "drop-shadow(0px 2px 1.5px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          style={{
            fontSize: 12,
            
            fontWeight: 500,
            marginBottom: 10,
            color: 'black'}}
          onClick={() => navigate("/register")}
        >
          <Avatar
            style={{ width: 21, height: 21, backgroundColor: "black" }}
          />{" "}
          내정보
        </MenuItem>
        <MenuItem
          style={{
            fontSize: 12,
            fontWeight: 500,
            marginBottom: 10,
            boxShadow: "none !important",
            color: 'black'
          }}
          onClick={() => navigate("/register")}
        >
          <Avatar
            style={{ width: 21, height: 21, backgroundColor: "black" }}
          >
            <img
              style={{ width: 10, marginRight: 1 }}
              src={require("assets/images/avatar_company_edit_icon.png")}
            />
          </Avatar>
          내회사 수정
        </MenuItem>
        {/* <Divider style={{backgroundColor: '#808080', color: 'white'}}/> */}
        {/* <MenuItem>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem> */}
        {/* <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem> */}
        <MenuItem
          onClick={() => {
            Auth.logout();
            navigate("/");
          }}
          style={{
            fontSize: 12,
            paddingLeft: 12,
            fontWeight: 500,
            color: 'black'
          }}
        >
          <Logout
            fontSize="small"
            style={{ marginRight: 8, color: "black" }}
          />
          로그아웃
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MyAvatar;
