
import React, {useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'
import search_icon from 'assets/images/search_icon.png'
import styled from 'styled-components';
import Button from '@mui/material/Button';
import axios from 'axios';
import url from 'utils/backend';
import Swal from 'sweetalert2'
import moment from 'moment'
import Auth from 'utils/auth';
import {ViewIcon, LikeIcon, ChatIcon, ClockIcon, IconContainer} from 'components/discussion/Icon'
import ReplyCard from './ReplyCard';
const ReReplyContainer = styled.div`
    margin: 10px 0;
    background-color: rgba(0,0,0,.07);
    padding: 5px 15px;
`;

const ReReplyBox = ({item, getReplyList, writer_id}) => {
    const [rereplies, setReReplies] = useState([]);
    const getReReplyList = async() => {
        try{
          const res = await axios.get(url + '/forum/reply/all?origin_id=' + item._id);
          setReReplies(res.data.replies);
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(() =>{
        getReReplyList()
    },[item])
    return(
        <>
        {rereplies.filter((data) => data.deleted_at == null).map((item, i) => {
            return(

                <ReReplyContainer>
                    <ReplyCard isReReply item={item} writer_id={writer_id} getReplyList={getReplyList} getReReplyList={getReReplyList} />
                </ReReplyContainer>
                
            )
        })

        }
        </>
    )
}
export default ReReplyBox;