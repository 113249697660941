import React, {useEffect, useState} from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import ExploreIcon from '@mui/icons-material/Explore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GridViewIcon from '@mui/icons-material/GridView';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import {useRecoilState} from 'recoil'
import {btmTypeState} from 'utils/state'
const NavBarBtm = ({type}) =>{
    const navigate = useNavigate()
    const [btmType, setBtmType] = useRecoilState(btmTypeState)
    const [searchParams] = useSearchParams()
    useEffect(()=>{
        type && setBtmType(type)
    },[])
    return(
        <nav className='navbar-btm mn'>
            <ul className='nav-ul'>
                <li className='nav-li'>
                    <a aria-current={btmType=='Main'} className='nav-a' onClick={()=>{setBtmType('Main'); navigate('/main')}}>
                        <ExploreIcon className='svg-icon'/>
                        <span>Main</span>
                    </a>
                </li>
                <li className='nav-li'>
                    <a aria-current={btmType=='Email'} className='nav-a' onClick={()=>{setBtmType('Email');navigate('/register')}}>
                        <AlternateEmailIcon className='svg-icon'/>
                        <span>Email</span>
                    </a>
                </li>
                <li className='nav-li'>
                    <a aria-current={btmType=='Find'} className='nav-a' onClick={()=>{setBtmType('Find');navigate('/investor/find')}}>
                        <SearchIcon className='svg-icon'/>
                        <span>Finder</span>
                    </a>
                </li>
                <li className='nav-li'>
                    <a aria-current={btmType=='Blind'} className='nav-a' onClick={()=>{setBtmType('Blind'); navigate('/discussion')}}>
                        <ChatBubbleOutlineOutlinedIcon className='svg-icon'/>
                        <span>Blind</span>
                    </a>
                </li>
                <li className='nav-li'>
                    <a 
                    aria-current={btmType=='Menu'} 
                    className='nav-a' 
                    onClick={()=>{
                        if(btmType=='Menu'){
                        setBtmType('')}
                        else {
                            setBtmType('Menu')
                        }

                    }}>
                        <MenuIcon className='svg-icon'/>
                        <span>Menu</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default NavBarBtm