import React, {useRef, useState, useEffect} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './style.scss';
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'
import search_icon from 'assets/images/search_icon.png'
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Card from './component/Card'
import CheckAuth from 'hooks/CheckAuth';
import axios from 'axios';
import url from 'utils/backend';
import NavBar from 'components/navbar/NavBar';
import { setISODay } from 'date-fns';
import MenuTitle from 'components/discussion/MenuTitle';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';

const ReplyBox = styled.div `
  width: 100%;
  margin-top: 50px;
`;
const ReplyHeader = styled.div `
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  p{
    font-size: 16px;
    font-weight: 900;
  }
  div{
    font-size:13px;
    font-weight: 400;
  }
`;
const ReplyContent = styled.div`
  width: 100%;
  height: 100px;
  font-size: 15px;
  font-weight: 400;
`;
const ReplyWrite = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .input-box{
    width: 88%;
  }
  textarea{
    width: 100% !important;
    min-height: 80px !important;
    background-color: white;
    border-radius: 5px;
  }
  button{
    background-color: white;
    color: black;
    height: 50px;
  }

`;
const DiscussionView = () => {
  const {id, type} = useParams();
  const navigate = useNavigate();
  const [board, setBoard] = useState('');
  const [replyList, setReplyList] = useState([]);
  const location = useLocation();

  const getBoardInfo = async() => {
    try{
      const res = await axios.get(url + '/forum/'+type + '/' + id);
      if(type == 'review'){
        if(!res.data.review || type != res.data.review.type ){
          navigate('/discussion/review');
        }
        setBoard({...res.data.review});
      }
      else{
        if(!res.data.post || type != res.data.post.type ){
          navigate('/discussion/post');
        }
        setBoard({...res.data.post})
      }
    
    }
    catch(e){
      console.log(e)
    }
  }
  const getReplyList = async() => {
    try{
      const res = await axios.get(url + '/forum/reply/all?origin_id=' + id);
      console.log(res.data)
      setReplyList(res.data.replies);
    }
    catch(e){

    }
  }
 
  useEffect(() => {
    window.scrollTo(0,0);
    if(!type || (type != 'review' && type != 'post' && type != 'my') || !id){
      navigate('/discussion/review');
    }
    else{
      getBoardInfo()
      if(type == 'post') getReplyList()
    }
  },[window.location])
  
  

  
  return(
    <div className='main-body-wrap-blind'>
    <div className="nv-line"></div>

      <NavBar backgroundColor={'#f9f9f9'}/>
      {/* <BreadcrumbsHeader list={['discussion', type=='review' ? 'discussionReview' : 'discussionPost']} writing_type={type=='review' ? '/discussion/review' : '/discussion/post'} isPaddingLeft/> */}
      <div className='intro_root'>
        {(board) &&
          <>
            
            <div className='discussion-content-wrap mn' style={{display:'flex', justifyContent:'center'}}>
            <Card item={board}  replyList={replyList} type={type} getReplyList={getReplyList} getBoardInfo={getBoardInfo}/>
            </div>
          </>
        }
      </div>
    </div>
  )
}
export default DiscussionView;