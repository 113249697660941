import React from 'react';

import Table from 'components/Table'
import NvLabel from 'components/NvLabel'
import DiffYear from 'utils/DiffYear'
import DateFormat_Year_Month from 'utils/DateFormat_Year_Month';

const OperatingFund = ({item, columns}) => {
    const {company, ranking, fund, news} = item;

    const BriefStyle = {
        boxShadow:'0px 2px 0px rgba(0, 0, 0, 0.25)',
    }
    const renderTd = (cell, data) => {
        const header = cell.column.Header;
        const accessor = cell.column.id;
        const index = cell.row.index;
        if(!cell.value){
            return '-';
        }
        else if(header == '회사명'){
          return(
            <>
            <span>{cell.render('Cell')}</span>
            </>
          )
        }
        else if(header == '결성총액'){
            return(
                <NvLabel value={cell.value} unit={'조억'} style={{marginLeft: 0}}/>
            )
        }
        else if(header == '투자총액'){
            return(
                <NvLabel value={cell.value} unit={'조억'} style={{marginLeft: 0}}/>
            )
        }
        else if(header == '소진율'){
            return(
                <>
                <NvLabel value={cell.value.toFixed(1)} style={{marginLeft: 0}}/>%
                </>
            )
        }
        else if(header == 'GP'){
            return(
                <span>
                    {data[index].general_partner.map((data, i) => {
                        return(
                            <span className='investors-table-label investor-label-GP '>
                                <p className='category'>{data}</p>
                                {(data == company.name) &&
                                    <p className='investment-number investor-label-CO-GP'>
                                    {<NvLabel value={'CO-GP'} style={{marginLeft: 0}}/>}
                                    </p>
                                }
                            </span>  
                        )
                    })}
                </span>
            )
        }
        else if(header == '평균투자금액'){
            return(
              <span>
                <NvLabel value={cell.value} tar unit={'조억'} style={{marginLeft: 0}}/>
                <div className='investors-table-average'>
                  {<NvLabel value={data[index].min_investment_amount} unit={'조억'} style={{marginLeft: 0}}/>} - {<NvLabel value={data[index].max_investment_amount} unit={'조억'} style={{marginLeft: 0}}/>}
                </div>
              </span>
            )
        }
        else if(header == '평균투자금액'){
          return(
            <span>
              <NvLabel value={cell.value} tar unit={'조억'} style={{marginLeft: 0}}/>
              <div className='investors-table-average'>
                {<NvLabel value={data[index].min_investment_amount} unit={'조억'} style={{marginLeft: 0}}/>} - {<NvLabel value={data[index].max_investment_amount} unit={'조억'} style={{marginLeft: 0}}/>}
              </div>
            </span>
          )
        }
        else if(header == '결성일'){
            var months;
            var today = new Date();
            var start_date = new Date(cell.value)
            months = DiffYear(start_date, today)
            return(
                <span>
                    {DateFormat_Year_Month(cell.value)}
                    <div className='investors-table-average'>
                        {months}년차
                    </div>
                </span>
            )
        }
        else if(header == '만기일'){
            var months;
            var today = new Date();
            var end_date = new Date(cell.value)
            months = DiffYear(today, end_date)
            return(
                <span>
                    {DateFormat_Year_Month(cell.value)}
                    <div className='investors-table-average'>
                        만기까지 {months}년
                    </div>
                </span>
            )
        }
        else if(header == '주목적'){
            return(
                <span>{cell.value.length >= 1 && cell.value[0]}</span>
            )
        }
        else{
          return(
            <span>{cell.render('Cell')}</span>
          )
        }
    }
    return(
        <div className='investor-left-card'>
            <div className='investor-left-content-box'>
                <div className='investor-brief-box'>
                    <div className='text-box'>
                        <span className='text-title'>AUM ( {(fund.total.investment_amount) ? <NvLabel tar value={fund.total.investment_amount} style={{marginLeft: 0, fontWeight: 'bold'}} unit={'조억'}/> : '-'} )</span>
                    </div>
                    &
                    <div className='text-box'>
                        <span className='text-title'>운용조합 수 ( {(fund.total.funds) ? <NvLabel tar value={fund.total.funds} unit={'개'} style={{marginLeft: 0, fontWeight: 'bold'}}/> : '-'} )</span>
                    </div>
                </div>
                <Table columns={columns} data={fund.list} renderTd={renderTd} />
            </div>
        </div>
    )
}
export default OperatingFund;