import { createSlice } from '@reduxjs/toolkit'

const keywordFilter = {
  company:[],
}
export const keywordFilterSlice = createSlice({
    name: 'keywordFilter',
    initialState: keywordFilter,
    reducers: {
      update: (state, action) => {
        const {payload} = action
        return payload
      },
      reset: (state) => {
        return keywordFilter
      }
    },
})

export const { update, reset } = keywordFilterSlice.actions

export default keywordFilterSlice.reducer