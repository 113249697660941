import React from 'react'
import { AspectRatio } from 'react-aspect-ratio';
const ContentsBox = ({list}) =>{
    return(
        <ul>
            {list.map(data=>{
                return(
                <li>
                    <a style={{display:'block'}} href={'/detail?id='+(data.id)}>
                        <div className='main-body-contents-box'>
                            <AspectRatio ratio='1/1' className='img-box'>
                                <img src={data.image.all}/>
                            </AspectRatio>
                            <div>
                                <div className='desc-box mn-extended'>
                                    <div className='desc'>{data.desc}</div>
                                </div>
                                <div className='title'>{data.title}</div>
                                <div className='dates'>
                                    <div>{data.date}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                )
            })
            }
        </ul>
    )
}

export default ContentsBox