import React from 'react'
import _ from 'lodash'
import Section from '../Section'

const StartUp = ({list}) =>{
    return(
        <div className='main-body-above-topics'>
            {_.filter(list,{value:'startup'})[0].data.map(d=>{
                return(
                    <Section list={d.info} header={d.header} isIconTopic={true}/>
                )
                }) 
            }
        </div>
    )
}

export default StartUp