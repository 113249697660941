import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import IsArrayEmpty from 'utils/IsArrayEmpty';
import DetailBox from './DetailBox';
import Badges from 'components/profile/Badges';

const PreviewContainer = styled.div `
  width: 100%;
  background: white;
//   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 10px 34px 10px 24px;
  .title-box{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #444444;
  }
  .content{
    minHeight: 100%;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-sizing: border-box;
    // box-shadow: 0px 1px 0px #e7e7e7;
    border-radius: 5px;
    padding: 16px;
    font-size: 13px;
  }
  .nv-label{
    margin-left: 0px;
  }
  .input_form{
    margin: 10px 0 !important;

    }
`;

const PreviewBox = ({label, content, width, type, children}) => {
    const renderContent = () => {
        if(type == 'product'){
            if(!IsArrayEmpty(content)){
                return(
                    content.map((data, i) => {
                        return(
                            <DetailBox title={data.name+'(' + data.stage + ')'}>
                                <div className='market_small_box'>
                                    <div className='market_title'>
                                        ○ 컨셉
                                    </div>
                                    <pre className='content' style={{fontSize: 13}}>
                                        {data.description}
                                    </pre>
                                </div>
                            </DetailBox>
                        )
                    })
                )
            }
        }
        else if(type == 'sectors'){
            return(
                content.length >= 1  &&
                    <div className='input-add-box-wrap'>
                    {content.map((data, i) => {
                        return(
                            <span style={(i != content.length - 1) ? {marginRight : 20} : null}>
                                #{data}
                            </span>
                        )
                    })}
                    </div>
                
            )
        }
        else if(type == 'market'){
            let isVisible = false;
            Object.keys(content).map((key, i) => {
                if(key == 'similarity'){
                    if(!IsArrayEmpty(content[key].content)){
                      isVisible = true
                    }
                  }
                  else if(content[key].content){
                    isVisible = true;
                  }
            })
            return(
                isVisible &&
                Object.keys(content).map((key, i) => {
                    if(key == 'similarity'){
                        return(
                          !IsArrayEmpty(content['similarity'].content) &&
                            <DetailBox title={content[key].title}>
                                <div className='market_small_box'>
                                    <div className='market_title'>
                                        ○ {content[key].value}
                                    </div>
                                    <div className='content' style={{fontSize: 13}}>
                                        <Badges arr={content['similarity'].content} style={{minHeight: 20}} />
                                    </div>
                                </div>
                            </DetailBox>
                        )
                    }
                    else{
                        return(
                            content[key].content &&
                            <DetailBox title={content[key].title}>
                                <div className='market_small_box'>
                                    <div className='market_title'>
                                        ○ {content[key].value}
                                    </div>
                                    <pre className='content' style={{fontSize: 13}}>
                                        {content[key].content}
                                    </pre>
                                </div>
                            </DetailBox>
                        )
                    }
                })
            )
        }
        else if(children != undefined){
            return(
                children
            )
        }
        else{
            if(content){
                return(
                    <div className='content'>
                        {content}
                    </div>
                )
            }
        }
    }
    return(
        <PreviewContainer style={(width) && {width: width}}>
            <div className='title-box'>
                <div className='title'>{label}</div>          
            </div>
            {renderContent()}
        </PreviewContainer>

    )
}
export default PreviewBox