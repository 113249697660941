import React, {useEffect, useState, useCallback} from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Header from 'components/admin/Header'
import LeftSideBar from 'components/admin/LeftSideBar'
import RightSideBar from 'components/admin/RightSideBar'
import '../admin.scss'
import _ from 'lodash'
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Checkbox from '@mui/material/Checkbox';
import PageModule from 'components/admin/PageModule';
import PanelFilterSelect from 'components/admin/PanelFilterSelect';
import NvTextInput from 'components/NvTextInput';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import axios from 'axios'
import url from 'utils/backend'
import ConvertComma from 'utils/ConvertComma';
import Item from './component/Item';
import Loading from 'components/Loading';
import classnames from 'classnames'
const filterList = [
    {
        value: 'size',
        child: [
            {
                label: '목록개수',
                value: 20,
            },
            {
                label: '50개',
                value: 50,
            },
            {
                label: '100개',
                value: 100,
            },
            {
                label: '1000개',
                value: 1000,
            },

        ]
    },
    // {
    //     value: 'status',
    //     child: [
    //         {
    //             label: '전체 상태',
    //             value: 'default'
    //         },
    //         {
    //             label: '승인',
    //             value: 'approve'
    //         },
    //         {
    //             label: '미승인',
    //             value: 'apply'
    //         }
    //     ]
    // },
    {
        value: 'area',
        child: [
            {
                label: '전체영역',
                value: 'default'
            },
            {
                label: '제목+부제목',
                value: 'title_and_subtitle'
            },
            {
                label: '본문',
                value: 'content'
            },
            {
                label: '기자이름',
                value: 'name'
            },
            {
                label: '기자이메일',
                value: 'email'
            }
        ]
    },
    {
        value: 'level',
        child:[
            {
                label: '등급전체',
                value: 'default'
            },
            {
                label: '일반기사',
                value: 'basic'
            },
            {
                label: '중요기사',
                value: 'important',
            },
            {
                label: '헤드라인',
                value:'headline'
            }
        ],
    },
    {
        value: 'type',
        child: [
            {
                label: '형태전체',
                value: 'default'
            },
            {
                label: '일반',
                value: 'basic'
            },
            {
                label: '카드뉴스',
                value: 'card'
            },
            {
                label: '갤러리',
                value: 'gallery'
            },
            {
                label: '동영상',
                value: 'media'
            }
        ]
    },
    {
        value: 'period',
        child: [
            {
                label: '전체기간',
                value: 'default'
            },
            {
                label: '오늘',
                value: 0
            },
            {
                label: '어제',
                value: -1
            },
            {
                label: '2일전',
                value: -2
            },
            {
                label: '3일전',
                value: -3
            },
            {
                label: '4일전',
                value: -4
            },
            {
                label: '5일전',
                value: -5
            },
            {
                label: '6일전',
                value: -6
            },
            {
                label: '7일전',
                value: -7
            },
            {
                label: '14일전',
                value: -14
            },
            {
                label: '30일전',
                value: -30
            }
            

        ]
    }
]

const NewsList = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0)
    const [checkNews, setCheckNews] = useState([]);
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('default')
    const [filter, setFilter] = useState({
        size: 20,
        // status: 'default',
        area: 'default',
        level: 'default',
        type: 'default',
        period: 'default',
    })

    const getNewsList = async() => {
        try{
            setLoading(true)
            const page = searchParams.get('page')
            if(!page) navigate('/admin/newsList?page=1&status=default')
            let uri = url + `/news/admin?page=${page}&size=${filter.size}`;
            if(status != 'default'){
                uri += `&status=${status}`
            }
            if(filter.level != 'default'){
                uri += `&level=${filter.level}`
            }
            if(filter.type != 'default'){
                uri += `&type=${filter.type}`
            }
            if(filter.period != 'default'){
                const start_date = new Date();
                start_date.setDate(start_date.getDate() + Number(filter.period))
                uri += `&start_date=${start_date}&date_type=apply`
            }
            if(search){
                if(filter.area == 'name'){
                    uri += `&name=${search}`
                }
                else if(filter.area == 'email'){
                    uri += `&email=${search}`
                }
                else{
                    uri += `&area=${filter.area}&search=${search}`
                }
            }

            const res = await axios.get(uri);
            setList(res.data.list)
            setTotal(res.data.total)
            setLoading(false)
        }
        catch(e){
            console.log(e)
        }
    }

    const handleCheck = useCallback((id) => {
        if(isCheck(id)){
            setCheckNews(prev => {
                return prev.filter(e => e != id);
            })
        }
        else{
            setCheckNews(prev => {
                return [...prev, id]
            })
        }
    }, [checkNews])
    const isCheck = useCallback((id) => {
        return checkNews.includes(id);
    }, [checkNews])
    
    const approveNews = useCallback(async(ids, reload) => {
        try{
            const res = await axios.post(url + '/news/admin/approve', {
                ids: ids
            })
            if(reload){
                getNewsList()   
            }
        }
        catch(e){
            console.log(e)
        }
    },[])
    const cancelApproveNews = useCallback(async(id, reload) => {
        try{
            const res = await axios.post(url + '/news/admin/cancel', {
                id: id
            })
            if(reload){
                getNewsList()   
            }

        }
        catch(e){
            console.log(e)
        }
    }, [])
    const removeNews = useCallback(async(ids) => {
        try{
            const res = await axios.post(url + '/news/admin/remove', {
                ids: ids
            })
            getNewsList()
        }
        catch(e){
            console.log(e)
        }
    },[])

    const updateLevel = useCallback(async(id, value) => {
        try{
            const res = await axios.post(url + '/news/admin/level', {
                id: id,
                level: value
            })
            // getNewsList()
        }
        catch(e){
            console.log(e)
        }
    }, [])

    const updateFilter = (v, level) => {
        setFilter(prev => {
            let tmp = _.cloneDeep(prev);
            tmp[level] = v;
            return tmp;
        })
    }
    const handleKeyDown = (e) => {
        if(e.keyCode == 13) getNewsList();
    }
    const handleStatus = (v) => {
        if(status == v) return;
        navigate('/admin/newsList?page=1&status='+v)
        setSearch('');
        setFilter({
            size: 20,
            area: 'default',
            level: 'default',
            type: 'default',
            period: 'default',
        })
    }
    
    useEffect(()=>{
        const page = searchParams.get('page');
        if(page == undefined){
            navigate('/admin/newsList?page=1', {replace:true})
        }
        getNewsList()
    },[location, status])

    useEffect(() => {
        const status = searchParams.get('status');
        if(!status) navigate('/admin/newsList?page=1&status=default')
        setStatus(status);
    }, [location])

    

    return(
        <div className='admin-main-wrap mg'>
            <Loading loading={loading}/>
            <Header title={'기사'}/>
            <div className='admin-main'>
                <section className='admin-body mg' >
                    <LeftSideBar/>
                    <div className='admin-body-middle'>
                        <div className='news-list-type-header'>
                            <ul className='menu'>
                                <li className={classnames({'active': status == 'default'})} onClick={() => handleStatus('default')}>전체기사</li>
                                <li className={classnames({'active': status == 'apply'})} onClick={() => handleStatus('apply')}>미승인기사</li>
                                <li className={classnames({'active': status == 'reserve'})} onClick={() => handleStatus('reserve')}>예약기사</li>
                            </ul>
                        </div>
                        <section className='panel-container'>
                            <header className='panel-header'>
                                <div className='panel-title'>
                                    <strong className='rc center'>
                                        기사목록
                                        <div className='label-group'>
                                            <span>총 : {ConvertComma.comma(total)}건</span>
                                        </div>
                                    </strong>
                                    <div className='header-level-group'>
                                        <span className='secondary'>일반기사</span>
                                        <span className='warning'>중요기사</span>
                                        <span className='alert'>헤드라인</span>
                                    </div>
                                </div>
                            </header>
                            <article className='panel-block'>
                                <div className='panel-filter integration'>
                                    <div className=''>
                                        {/* {dataList.map((data, i)=>{
                                            return(
                                                <article className='item'>
                                                    <Memberoption
                                                        width={activeDate[i].width}
                                                    >
                                                        <AutoComplete
                                                            defaultValue={activeDate[i].label}
                                                            options={data.data}
                                                            isDirect={false}
                                                            onChange={(v) => {
                                                                setActiveDate(prev => 
                                                                    update(prev, {
                                                                        [i]: {
                                                                            label:{$set:v}
                                                                        }
                                                                    })
                                                                )
                                                            }}
                                                        />
                                                    </Memberoption>
                                                </article>
                                            )
                                        })}
                                        */}
                                        {filterList.map(data => {
                                            return(
                                                <article className='item'>
                                                    <PanelFilterSelect 
                                                        width={'115px'} 
                                                        list={data.child} 
                                                        defaultValue={filter[data.value]}
                                                        onChange={(v) => updateFilter(v, data.value)}

                                                    />
                                                </article>
                                            )
                                        })
                                        }
                                        <article className='item searchitem'>
                                            <Paper>
                                                <InputBase
                                                    value={search}
                                                    placeholder="검색어를 입력하세요."
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    onKeyDown={handleKeyDown}

                                                />
                                                <IconButton 
                                                    onClick={getNewsList} 
                                                    className='btns' 
                                                    aria-label="search"
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </Paper>
                                        </article>
                                        <article className='item' style={{marginTop: '0.8rem'}}>
                                            {filterList[filterList.length - 1].child.slice(1,  9).map((data, i) => {
                                                return(
                                                    <div className='filter-date-btn'>
                                                        {data.label}
                                                    </div>
                                                )
                                            })
                                            }
                                        </article>
                                    </div>
                                </div>
                                <table className='panel-table'>
                                    <colgroup>
                                        <col width="50"></col>
                                        <col width="*"></col>
                                        <col width="80"></col>
                                        <col width="100"></col>
                                        <col width="100"></col>
                                        <col width="100"></col>
                                        <col width="80"></col>
                                        <col width="100"></col>
                                    </colgroup>
                                    <thead className='thead'>
                                        <tr>
                                            <th>
                                                <label className='panel-checkbox'>
                                                    <Checkbox type='checkbox'/>
                                                </label>
                                            </th>
                                            <th className='text-left'>제목</th>
                                            <th className=''>섹션</th>
                                            <th className=''>기자</th>
                                            <th className=''>일자</th>
                                            <th className=''>등급</th>
                                            <th className=''>링크</th>
                                            <th className=''>승인</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {(list) && list.map((data,i)=>{
                                            return <Item 
                                                key={data._id}
                                                item={data} 
                                                approveNews={approveNews} 
                                                removeNews={removeNews} 
                                                cancelApproveNews={cancelApproveNews}
                                                handleCheck={handleCheck}
                                                isCheck={isCheck}
                                                updateLevel={updateLevel}

                                            />
                                        })}
                                    </tbody>
                                </table>
                                <footer className='panel-footer'>
                                    <div className='footer-group'>
                                    <PageModule
                                        isNewsList={true}
                                        pageCount={Math.ceil(total / 20)}
                                        onPageChange={(v) => {
                                            let num = v + 1;
                                            navigate('/admin/newsList?page=' + num );
                                        }}
                                        handleApproveNews={() => approveNews(checkNews, true)} 
                                        handleRemoveNews={() => removeNews(checkNews, true)} 

                                    />
                                    </div>
                                </footer>
                            </article>
                        </section>
                    </div> 
                    <RightSideBar/>
                </section>
            </div>
        </div>
    )
}

export default NewsList