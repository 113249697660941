import React from 'react';
import 'styles/common.scss'
const TableArrow = ({sort}) => {
    if(sort == 'asc'){
        return(
            <span className='table-triangle-up table-triangle-margin' style={{marginBottom: 5}}/>
        )
    }
    else if(sort == 'desc'){
        return(
            <span className='table-triangle-down table-triangle-margin'/>
        )
    }
    else{
        return(
            <span className='table-triangle-margin'>
                <div className='table-triangle-up' style={{marginBottom: 3}}/>
                <div className='table-triangle-down'/>
            </span>
        )
    }

}
export default TableArrow