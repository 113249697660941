import React from 'react'
import ContentsBox from './ContentsBox'
const ContentsContainer = ({list}) =>{
    return(
        <div className='contents-container'>
            
                {
                    list.data.map(d=>{
                        return(
                            <ContentsBox list={d.info}/>
                        )
                    })
                }
            
        </div>
    )
}

export default ContentsContainer