import { createSlice } from '@reduxjs/toolkit'

const customFilter = {
  portfolio_type: [],
  manager_type: [],
  manager_id: '',
  publish_date: '',
  publish_date_custom: {min: '', max: ''},
  manage_type: '',
  new_type: [],
  review_date: {min: '', max: ''},
}
export const customFilterSlice = createSlice({
  name: 'customFilter',
  initialState: customFilter,
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return customFilter
    }
  },
})

export const { update, reset } = customFilterSlice.actions

export default customFilterSlice.reducer