import React,{useEffect, useState} from 'react';
import { initialInvestorList, publicInstitutionList, investmentInstitutionList, otherInvestorsList } from 'utils/DropDownList';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';

const CheckBox = styled.div `
    span{
        font-size: 14px !important;
    }
    .css-i4bv87-MuiSvgIcon-root{
        fill: #808080 ;
    }
    .Mui-checked{
        .MuiSvgIcon-root{
        fill: rgb(14, 81, 255);
        }
    }
`;
const InputCertification = ({state, setState}) => {
    const categories = [
        {label: '초기 투자자', list : initialInvestorList},
        {label: '공공 기관', list : publicInstitutionList},
        {label: '투자 기관', list : investmentInstitutionList},
        {label: '기타 투자자', list : otherInvestorsList},
    ]
    const [nowCategory, setNowCategory] = useState(categories[0]);

    const initCategory = () => {
        categories.some((category) => {
            return category.list.some((data) => {
                if(data.eng == state?.eng){
                    console.log(data)
                    setState({...state, kor: data.kor})
                    setNowCategory(category)
                    return true;
                }
            })
        })
    }

    useEffect(() => {
        initCategory()
    },[])


    return(
        <div className='certification-container' >
            <div className='rc certification-box' style={{marginRight: 70}}>
                {categories.map((data) => {
                    return(
                        <p 
                            className={'category ' + (data.label == nowCategory.label && 'active')}
                            onClick={() => setNowCategory(data)}
                        >
                            {data.label}
                        </p>
                    )
                })}                
            </div>
            <div className='check_boxes' style={{height: 168}}>
                <CheckBox>                    
                {nowCategory.list.map((data, i) => {
                    return(
                        <FormControlLabel  control={<Checkbox checked={state?.eng == data.eng} onChange={() => setState(data)}/>} style={(state?.eng == data.eng) ? {color: 'rgb(14, 81, 255)'} : null} label={data.kor} />
                    )
                })}
                </CheckBox>
            </div>

        </div>
    )
}
export default InputCertification;