import React, {useEffect, useState} from 'react';
import PageModule from 'components/admin/PageModule';
import ImgTypeList from 'components/news/list/ImgTypeList'
import TitleTypeList from 'components/news/list/TitleTypeList'
import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/List';
import ConvertComma from 'utils/ConvertComma'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import url from 'utils/backend'
const viewModeList = [
    {
        label: '제목형',
        value: 'title'
    },
    {
        label: '요약형',
        value: 'summary'
    },
]
const SearchList = ({ setLoading}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewMode, setViewMode] = useState(searchParams.get('mode') || viewModeList[0].value)
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0);
    const getNewsList = async() => {
        try{
            const page = searchParams.get('page') || 1
            const section = searchParams.get('section')
            const reporterName = searchParams.get('reporterName')
            const sortType = searchParams.get('sortType')
            const word1 = searchParams.get('word1')
            const word2 = searchParams.get('word2')
            const word_conjuction = searchParams.get('word_conjuction')
            const start_date = searchParams.get('start_date')
            const end_date = searchParams.get('end_date')
            const area = searchParams.get('area')
            const level = searchParams.get('level')
            const type = searchParams.get('type')
            if(!page) {
              navigate('/admin/newsSearch?page=' + 1 + '&mode=' + viewMode, { replace: true });
              return;
            }
            setLoading(true)
            let uri = url + `/news/admin?page=${page}&size=10&status=approve&date_type=approve`;
            if(section != 'default'){
                uri += `&section=${section}`;
            }
            if(sortType != 'default'){
                uri += `&sort_type=${sortType}`
            }
            if(word1 != 'null'){
                uri += `&word1=${word1}`
            }
            if(word2 != 'null'){
                uri += `&word2=${word2}`
            }
            if(word_conjuction){
                uri += `&word_conjuction=${word_conjuction}`
            }
            if(reporterName){
                uri += `&name=${reporterName}`
            }
            if(start_date != 'null'){
                uri += `&start_date=${start_date}`
            }
            if(end_date  != 'null'){
                uri += `&end_date=${end_date}`
            }
            if(area != 'default'){
                uri += `&area=${area}`
            }
            if(level != 'default'){
                uri += `&level=${level}`
            }
            if(type != 'default'){
                uri += `&type=${type}`
            }
            const res = await axios.get(uri)
            setList(res.data.list)
            setTotal(res.data.total)
            setLoading(false)
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        getNewsList()
    }, [location])
    return(
        <section className='member-admin-news-list'>
            <header className='header'>
                <div className='title'>
                    <strong className='strong'>내 기사목록</strong>
                    <small className='small'>(전체 {ConvertComma.comma(total)}건)</small>
                </div>
            </header>
            <article className='article' style={{width:'100%'}}>
                <section className='part'>
                    <header className='top'>
                        <h2 className='h2'>
                            <span className='span'>기사</span>
                        </h2>
                        <div className='type'>
                            <div className='btn-group'>
                                {viewModeList.map(data => {
                                    return(
                                        <a 
                                            className={'btn'+ (data.value == viewMode ?' unclicked':' clicked')} 
                                            onClick={()=>{
                                                setViewMode(data.value);
                                            }}
                                        >
                                            <MenuIcon className='i'/>
                                            <span className='btn-span'>&nbsp;{data.label}</span>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </header>
                    {viewMode == 'title'?
                        <section className='list'>
                            {list.map(data => {
                                return(
                                    <TitleTypeList item={data} to={'/admin/newsView'}/>
                                )
                            })
                            }
                        </section>
                        :
                        <section className='list'>
                            <ul className='ul'>
                                {list.map(data => {
                                    return(
                                        <ImgTypeList item={data} to={'/admin/newsView'}/>
                                    )
                                })
                                }
                            </ul>
                        </section>
                    }
                </section>
            </article>
            <PageModule
                pageCount={Math.ceil(total / 10)}
                onPageChange={(v) => {
                    let num = v + 1;
                    setSearchParams({page: Number(num)})
                
                }}
            />

            {/* <div className="pagination" style={{ top: '680px', width: '100%' }}>
                <Paging
                    pageCount={1}
                    page={instance.state.pageIndex}
                    onPageChange={(e) => {
                        let num = e.selected + 1;
                        instance.gotoPage(e.selected)
                        navigate('/meeting?page=' + num);
                    }}
                />
            </div> */}
        </section>
    )
}

export default SearchList