import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import "./style.scss";
import NavBar from "components/navbar/NavBar";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Banner from "../../components/Banner";
import _ from "lodash";
import All from "./component/Main-Classification/All";
import Latest from "./component/Main-Classification/Latest";
import Trending from "./component/Main-Classification/Trending";
import Topics from "./component/Main-Classification/Topics";
import Auth from 'utils/auth';
import DetailTopics from '../topicdetail/DetailTopics';
import NavBarBtm from 'components/navbar/NavBarBtm'
import eyepatch from 'assets/images/eye-patch.svg'
import DiscussCard from './component/DiscussCard'
import InfiniteScroll from "react-infinite-scroller";
import url from 'utils/backend'
import axios from 'axios'
const Main = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [isText, setIsText] = useState('')
  const {type} = useParams()
  
  const [arr, setArr] = useState([])
  const [boardList, setBoardList] = useState([]);
  const [page, _page] = useState(0);
  const [size, setSize] = useState(30);
  const [stop, setStop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [slider, setSlider] = useState(true)
  const [isCurrent, setIsCurrent] = useState([
    { value: "review", label:'review' },
    { value: "post", label:'rounge' },
    { value: "my", label:'my' },
  ]);
  
  const result = () => {
    if (type== "review") {
      setIsText('스타트업 창업자들이 투자자에 대한 생각을 남길 수 있는 공간')
    } 
    else if (type == "post") {
      setIsText('스타트업 창업자들이 투자생태계에 대한 여러가지 의견을 남길 수 있는 업계 라운지')
    } 
    else if (type=='my'){
      setIsText('내 글')
    }
}
const getBoardList = useCallback(
  async (page) => {
    console.log(stop, loading, page);
    if (stop || loading) return;
    setLoading(true);
    try {
      var pageValue = page != undefined ? page : 1;
      let uri =
        url + "/forum/" + (type=='my'?(slider?'review/my':'post/my'):type+"/all")+"?page=" + pageValue + "&size=" + size;
      if (search) {
        uri += " &keyword=" + search;
        setIsSearch(true);
        setSearchText(search);
      } else {
        setIsSearch(false);
      }
      const res = await axios.get(uri);
      if (type == "review") {
        if (res.data.reviews.length == 0) setStop(true);
        else {
          if (pageValue == 1) {
            setBoardList(res.data.reviews);
          } else {
            setBoardList(boardList.concat(res.data.reviews));
          }
        }
      } 
      else if(type=='post') {
        if (res.data.posts.length == 0) setStop(true);
        else {
          if (pageValue == 1) {
            setBoardList(res.data.posts);
          } else {
            setBoardList([...boardList, ...res.data.posts]);
          }
        }
      }
      else if(type=='my' && slider){
        if (res.data.reviews.length == 0) setStop(true);
        else {
          if (pageValue == 1) {
            setBoardList(res.data.reviews);
          } else {
            setBoardList(boardList.concat(res.data.reviews));
          }
        }
      } 
      else{
        if (res.data.posts.length == 0) setStop(true);
        else {
          if (pageValue == 1) {
            setBoardList(res.data.posts);
          } else {
            setBoardList([...boardList, ...res.data.posts]);
          }
        }
      }
      _page(page);
      setTotal(res.data.total);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  },
  
  [page, loading, boardList, search]
);

const handleSearchEnter = () => {
  setStop(false);
  _page(0);
  setLoading(false);
  setBoardList([]);
};
useEffect(() => {
  const query = new URLSearchParams(location.search);
  const page = query.get("page") ? query.get("page") : 1;
  window.scrollTo(0, 0);
  setSearch("");
  setBoardList([]);
  setStop(false);
  setLoading(false);
  _page(0);
  result()
}, [location.pathname, slider]);

 useEffect(() => {
    if(!Auth.isLoggedIn()){
      navigate('/login');
    }
  },[])
  
  
  return (
    <div className="main-body-wrap-blind mn">
      <div className="nv-line"></div>
      <NavBar type={"investee"} backgroundColor={"#f9f9f9"} />
      <div className="main-body" style={{justifyContent:'unset'}}>
        <Banner differ={false} slider={slider} setSlider={setSlider} isCurrent={isCurrent} text='BLIND' isText={isText} navigate={navigate} topicType={type}/>
        {/* {arr.length>0 && arr} */}
        <InfiniteScroll
                pageStart={0}
                loadMore={() => getBoardList(page + 1)}
                hasMore={!loading && !stop}
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
              >
                <div className="discussion-content-wrap">
                  <div className="contents-padding-wrap">
                    <div className="discuss-main">
                      <div className="discuss-cards">
                        {boardList.map((data, i) => {
                          return (
                            <DiscussCard
                              item={data}
                              type={type}
                              getBoardList={getBoardList}
                              index={i}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </InfiniteScroll>
      </div>
      <NavBarBtm type={'Blind'}  />
    </div>
  );
};

export default Main;
