import React from 'react';
import { useDrag } from 'react-dnd'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
const WriteDragItem = ({text, type, tagName, img, endDrop, cancelDrop}) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'WriteCard',
        item: { type, tagName},
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        }),
        end: (item, monitor) => {
          const dropResult = monitor.getDropResult()
          const didDrop = monitor.didDrop()
          if (!didDrop) {
            cancelDrop()
          }
          else{
            endDrop()
          }
        },

      })
    return(
      <div className={'textbox '+text} ref={drag}>
        {text}
        {img &&
        <ImageOutlinedIcon/>
        }  
      </div>
    )
}
export default WriteDragItem