import React, { useState, useEffect } from 'react';
import './style.scss'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import NavBar from 'components/navbar/NavBar'
import ClearIcon from '@mui/icons-material/Clear';
async function digest(password) {
  let pw = new TextEncoder().encode(password);
  pw = await crypto.subtle.digest('SHA-256', pw);
  pw = Array.from(new Uint8Array(pw));
  pw = pw.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  // console.log(pw)
  return pw
}

const ProfileChange = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const [inputId, setInputId] = useState('')
  const [inputPw, setInputPw] = useState('')
  const [inputRm, setInputRm] = useState('')
  const onForgotHandler = () => {
    alert('문의하세여')
  }
  // const checkProfile = async() => {
  //   const res = await axios.get(url + '/user/company');
  //   if(res.data.company == null){
  //     navigate('/register')
  //   }
  //   else{
  //     navigate('/')
  //   }
  // }
  const _onKeyDown = (e) => {
    // console.log(e)
    if (e.keyCode == 13 ) onSubmitHandler()
    // if(onChange) onChange(e.target.value)
    // if(onChange) console.log(e.target.value + e.code)
  }
  const onSubmitHandler = async () => {
    // console.log('Email', inputId);
    // console.log('Password', inputPw);
    if (input == "") {
      if(type=='email'){
        alert('email을 입력해주세요.')
        document.location.href = '/'
      } 
      else if (type=='password') {
        alert('Password를 입력해주세요.')
        document.location.href = '/'
      }
  }
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    // const password = await digest(inputPw)
    // axios.post(url + '/user/login', {
    //   'email': inputId,
    //   'password': password
    // })
      // .then(response => {
      //   console.log('wadaw',response)
      //   if (response.status === 200) {
      //     const token = response.data.token;
      //     Auth.login(token)
      //     // var decoded = jwt_decode(token, jwt_secret);
      //     const userName = Auth.getName();
      //     // const userPermission = decoded.permission;
      //     // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      //     // sessionStorage.setItem('login_token', axios.defaults.headers.common['Authorization']);
      //     // sessionStorage.setItem('login_name', userName);
      //     // sessionStorage.setItem('permission', userPermission)
      //     alert(userName + "님 환영합니다.");
      //     // checkProfile()
      //   } else {
      //     alert('로그인에 실패하였습니다.')
      //   }
      // })
      // .catch(function (error) {
      //   console.log("실패", error);
      //   alert('로그인에 실패하였습니다.')
      // })
  }
  // useEffect(() => {
  //   console.log(Auth.isProfile())
  //   if(Auth.isProfile()){
  //     navigate('/')
  //   }
  // },[])
    const [wrong, setWrong] = useState(false)
    const [type, setType] = useState('')
    const [wrongPw, setWrongPw] = useState(false)
    const [input, setInput] = useState('')
    const[checkPw, setCheckPw] = useState('')
    const[checkPwState, setCheckPwState] = useState(false)
    const [btnState, setBtnState] = useState(true)
    const checkBtnState = (id) =>{
      if(id==''){
          setWrong(true);
          setBtnState(true)
        }
      else{
          setWrong(false)
          setBtnState(false)
      }
    }
    const checkBtnStatePw = (id) =>{
      if(id==''){
          setWrongPw(true);
          setBtnState(true)
        }
      else{
          setWrongPw(false)
          setBtnState(false)
      }
    }
    const handleInput = (e) => {
      setInput(e.currentTarget.value)
      checkBtnState(e.currentTarget.value)
    }
    const handleInputPw = (e)=>{
      setCheckPw(e.currentTarget.value)
      checkBtnStatePw(e.currentTarget.value)
    }
    useEffect(()=>{
        const getType = searchParams.get('type') 
        setType(getType)
    },[])
    return(
        <div className='profile-change-root mn'>
          {type!='' &&
          <main className='profile-change-main-wrap'>
            <button className='x-btn' type='button' onClick={()=>navigate('/register')}>
                <ClearIcon className='svg'/>
            </button>
            <div className='profile-change-main'>
                  <header className='header'>
                    <h1 className='h1 mn'>{type=='email'?'Change your email':'Change your password'}</h1>
                  </header>
                  <div className='form'>
                    <form style={{width:'100%'}}>
                      <div >
                        <div className='div mn'>
                          <label className='label mn-extended'>{type=='email'?'New Email':'Current Password'}</label>
                          <sup className='sup'>*</sup>
                        </div>
                        <div className='input-container mn'>
                          <input 
                            className={'input '+type} 
                            type={type}
                            autocomplete={type=='email' ?'username':'current-password'}
                            placeholder={type=='email' ?'New email':'Current password'}
                            aria-invalid={wrong}
                            onKeyDown={_onKeyDown}
                            name={type} 
                            value={input} 
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div >
                        <div className='div mn'>
                          <label className='label mn-extended'>{type=='email'?'Current Password':'New Password'}</label>
                          <sup className='sup'>*</sup>
                        </div>
                        <div className='input-container mn'>
                          <input 
                            className={'input password'} 
                            type='password'
                            autocomplete='current-password'
                            placeholder={type=='email'?'Current password':'New Password'}
                            aria-invalid={wrongPw}
                            onKeyDown={_onKeyDown}
                            name='password' 
                            value={checkPw} 
                            onChange={handleInputPw}
                          />
                        </div>
                      </div>
                      <div className='desc mn'>
                        Password must be between 6 and 20 characters
                      </div>
                      <div className='btn-box margin-btn-one'>
                        <button 
                        className='btn' 
                        type='submit' >
                          <span className='span-btn one'>
                            <span>Submit</span>
                          </span>
                        </button>
                      </div>
                      
                    </form>
                      <div className='btn-box margin-btn-two'>
                        <button 
                        className='btn' 
                        type='submit' >
                          <span className='span-btn two'>
                            <span>Cancel</span>
                          </span>
                        </button>
                    </div>
                  </div>
                  
                </div>
          </main>}
        </div>
    )
}

export default ProfileChange;