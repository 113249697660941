const DateFormat_Year_Month_Date_Hour_Minute = (date) => {
    if(!date) return ;
    let fullDate = new Date(date);
    const year = fullDate.getFullYear().toString();
    const month = fullDate.getMonth();
    const day = fullDate.getDate();
    const hour = fullDate.getHours();
    const minute = fullDate.getMinutes()
    return year.slice(0,4) + '. ' + ('0' + (month + 1)).slice(-2) + '. ' + ('0' + (day)).slice(-2) + ' ' +  ('0' + (hour)).slice(-2) + ':' + ('0' + (minute)).slice(-2);

}
export default DateFormat_Year_Month_Date_Hour_Minute;