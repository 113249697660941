import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


export const nowTabSlice = createSlice({
  name: 'combinationNowTab',
  initialState: 'combination',
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      console.log(state)
      return false
    }
  },

})

export const { update, reset } = nowTabSlice.actions

export default nowTabSlice.reducer