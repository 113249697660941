import React, { useEffect, useState, useRef } from "react";
const { kakao } = window;

const Map = ({address}) => {
  const [visible, setVisible] = useState(false);
  const initialMap = async() => {
    setVisible(false);
    const container = document.getElementById('map');
    let option = {
      center: '', // 지도의 중심좌표
      level: 6,
    }
    var geocoder = new kakao.maps.services.Geocoder();
    await geocoder.addressSearch(address, async(result, status) =>  {
        if (status === kakao.maps.services.Status.OK) {
          setVisible(true);
          var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
          option.center = coords;
          var map = new kakao.maps.Map(container, option);
          var marker = new kakao.maps.Marker({
            position: coords
          });
          marker.setMap(map);
      } 
    }); 

  }

  useEffect(() => {
    initialMap()
  }, [address])

  return (
    <div
      id="map"
      className="map"
      style={{ width: "100%", height: "200px", display: (visible) ? 'inherit' : 'none' }}
    ></div>            
  )
};

export default Map;