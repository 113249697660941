import React, { useState, useEffect } from 'react';
import Map from './Map.js'

const MapInfo = ({address}) => {
  return (
    (address) &&
      <div className='info-right-merge-box info_content_right_card'  style={{paddingBottom: 14}}>
        <div className='info_content_title' style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
          <div className='info_content_card_title'>지도</div>
          <div style={{textAlign: 'left', marginTop: '10px'}}>
            <p className='content_address'>{address}</p>  
          </div>
        </div>
        <Map address={address}></Map>
      </div>
  );
}

export default MapInfo;