import React,{useState, useEffect} from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {debounce} from 'lodash'
import Header from '../component/Header';
// import { useTable, useBlockLayout, useResizeColumns, useColumnOrder, useRowSelect, usePagination} from 'react-table'
import LeftSideBar from '../component/LeftSideBar'
import './news-list.scss'
import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/List';
import ImgTypeList from './component/ImgTypeList'
import TitleTypeList from './component/TitleTypeList'
import PageModule from '../component/PageModule'
import RightSideBar from '../component/RightSideBar'
import NavBarBtm from "components/navbar/NavBarBtm";
import { useDidMountEffect } from 'hooks/useDidMountEffect'
import Paging from 'components/Paging'
import axios from 'axios'
import url from 'utils/backend'
import ConvertComma from 'utils/ConvertComma'
import Loading from 'components/Loading'

const viewModeList = [
    {
        label: '제목형',
        value: 'title'
    },
    {
        label: '요약형',
        value: 'summary'
    },
]
const NewsList = () =>{
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [reactableSide, setReactableSide] = useState(true)
    const [reactableOpacity, setReactableOpacity] = useState(false)
    const navigate = useNavigate();
    const [pageState, setPageState] = useState(false)
    const [totalList, setTotalList] = useState([])
    const [list, setList] = useState([])
    const [viewMode, setViewMode] = useState(searchParams.get('mode') || viewModeList[0].value)
    const [loading, setLoading] = useState(true)
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const handleResize = debounce(()=>{
        setWindowSize({
            width:window.innerWidth,
            height:window.innerHeight
        })
    }, 300)
    
    const changeViewMode = (value) => {
        setViewMode(value)
        navigate('/member/newslist?page=1&mode=' + value)
    }

    const getMyList = async() => {
        try{
            const page = searchParams.get('page')
            if (!page) {
              navigate('/member/newslist?page=' + 1 + '&mode=' + viewMode, { replace: true });
              return;
            }
            setLoading(true)
            const res = await axios.get(url + '/news/my/write')
            setTotalList(res.data.list)
            setLoading(false)
        }
        catch(e){
            console.log(e)
        }
    }
 
    useEffect(()=>{
        window.addEventListener('resize', handleResize);
        return()=>{
            window.removeEventListener('resize',handleResize)
        }
    },[])
    useEffect(()=>{
        if(windowSize.width>1023){
            setReactableSide(true)
            setReactableOpacity(false)
            setPageState(false)
        }
        else{
            setReactableSide(false)
            setPageState(true)
        }
    },[windowSize])
    useEffect(() => {
        if(totalList.length == 0){
            getMyList()
        }
    }, [location])
    useEffect(() => {
        const page = searchParams.get('page') 
        if(page && totalList.length >= 1){
            setList(totalList.slice((page - 1) * 10, page * 10))
        }
    }, [totalList, location])

    return(
        <div className='mynews-main-wrap mg'>                
            <Loading loading={loading}/>
            <Header title={'기사목록'} reactableSide={reactableSide} setReactableSide={setReactableSide} reactableOpacity={reactableOpacity} setReactableOpacity={setReactableOpacity}/>
            <div className='mynews-main'>
                <section className='mynews-body mg' >
                    {/* <LeftSideBar reactableSide={reactableSide} setReactableSide={setReactableSide}/> */}
                    <div 
                        className='mynews-list' 
                        style={{opacity:(reactableOpacity?0.7:1)}}
                        onClick={()=>{
                            setReactableOpacity(false); 
                            if(pageState){
                                setReactableSide(false)
                            }
                        }}
                    >
                        <section className='member-admin-news-list'>
                            <header className='header'>
                                <div className='title'>
                                    <strong className='strong'>내 기사목록</strong>
                                    <small className='small'>(전체 {ConvertComma.comma(totalList?.length)}건)</small>
                                </div>
                            </header>
                            <article className='article' style={{width:'100%'}}>
                                <section className='part'>
                                    <header className='top'>
                                        <h2 className='h2'>
                                            <span className='span'>기사</span>
                                        </h2>
                                        <div className='type'>
                                            <div className='btn-group'>
                                                {viewModeList.map(data => {
                                                    return(
                                                        <a 
                                                            className={'btn'+ (data.value == viewMode ?' unclicked':' clicked')} 
                                                            onClick={()=>{
                                                                changeViewMode(data.value);
                                                            }}
                                                        >
                                                            <MenuIcon className='i'/>
                                                            <span className='btn-span'>&nbsp;{data.label}</span>
                                                        </a>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </header>
                                    {viewMode == 'title'?
                                        <section className='list'>
                                            {list.map(data => {
                                                return(
                                                    <TitleTypeList item={data}/>
                                                )
                                            })
                                            }
                                        </section>
                                        :
                                        <section className='list'>
                                            <ul className='ul'>
                                                {list.map(data => {
                                                    return(
                                                        <ImgTypeList item={data} />
                                                    )
                                                })
                                                }
                                            </ul>
                                        </section>
                                    }
                                </section>
                            </article>
                            <PageModule
                                pageCount={Math.ceil(totalList.length / 10)}
                                onPageChange={(v) => {
                                    let num = v + 1;
                                    navigate('/member/newslist?page=' + num + '&mode=' + viewMode);
                                
                                }}
                            />

                            {/* <div className="pagination" style={{ top: '680px', width: '100%' }}>
                                <Paging
                                    pageCount={1}
                                    page={instance.state.pageIndex}
                                    onPageChange={(e) => {
                                        let num = e.selected + 1;
                                        instance.gotoPage(e.selected)
                                        navigate('/meeting?page=' + num);
                                    }}
                                />
                            </div> */}
                        </section>
                    </div>
                    <RightSideBar
                        list={totalList}
                    />                  
                </section>
            </div>
            <NavBarBtm type={"Main"} />
        </div>
    )
}

export default NewsList