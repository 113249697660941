import React, {useState, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavBar from 'components/navbar/NavBar'
import './ProfileEdit.scss';
import 'styles/profile.scss';
import PasswordEdit from './component/tab/PasswordEdit';
import AccountWithdraw from './component/tab/AccountWithdraw'
import InformEdit from './component/tab/InformEdit'
import InvestmentEdit from './component/tab/InvestmentEdit'
import axios from 'axios';
import url from 'utils/backend';
import AlertConfirm2 from 'components/AlertConfirm2';
import { useNavigate } from 'react-router-dom';
import Auth from 'utils/auth';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader'
import getPureBusinessNumber from 'utils/getPureBusinessNumber'
import IsArrayEmpty from 'utils/IsArrayEmpty';
import Swal from 'sweetalert2'
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
const ProfileEdit = () => {
    const navigate = useNavigate();
    const [businessNumber, setBusinessNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [rank, setRank] = useState('');
    const [gender, setGender] = useState('');
    const [existList, setExistList] = useState([]);

    const [isPreview, setIsPreview] = useState(false);
    const [techs, setTechs] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [team, setTeam] = useState('');
    const [isCoor, setIsCoor] =useState(false);
    const [round, setRound] = useState([]);
    const [certification, setCertification] = useState('');
    const [price, setPrice] = useState({min: '', max: ''})
    const [amount, setAmount] = useState('');
    const [productList, setProductList] = useState([]);
    const [serviceVisible, setServiceVisible] = useState(false);
    const [investmentVisible, setInvestmentVisible] = useState(false);
    const [newInvestmentVisible, setNewInvestmentVisible] = useState(false);
    const [marketVisible, setMarketVisible] = useState(false);
    const [business, setBusiness] = useState([]);
    const [technology, setTechnology] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const menuList = [{label: '내 정보', value: 'profile'}, {label: '상세정보', value:'investment'}, {label: '비밀번호 변경', value: 'password'}, {label: '회원 탈퇴', value: 'withdraw'}]
    const [marketList, setMarketList] = useState({
        domestic: {
          opts: [{value: false, label: '개요'}, {value: false, label: '비전/성장성'}, {value: false, label: '동향/트렌드'}, {value: false, label: '경쟁흐름'}, {value: false, label: '진입장벽'}],
          content: '',
          value: '',
          title: '국내외 시장의',
          empty: false
        },
        business: {
          opts: [{value: false, label: '문제점/한계'}, {value: false, label: '요구사항'}, {value: false, label: '필요성'}, {value: false, label: '목적'}],
          content: '',
          title: '사업/제품/서비스/개발에 대한',
          empty: false,
          value: '',
        },
        company:{
          opts: [{value: false, label: '해결방안'}, {value: false, label: '대응방안'}, {value: false, label: '사업화방안'}],
          content: '',
          title: '동사에 대한',
          empty: false,
          value: '',
    
        },
        similarity:{
          opts: [{value: false, label: '제품/서비스'}, {value: false, label: '아이디어'}, {value: false, label: '매출액/규모'}, {value: false, label: '기술'}, {value: false, label: '개발'}],
          content: [],
          title: '업계 유사 회사를 기입해 주세요',
          empty: false,
          value: '',
    
        }
      })
    const [newInvestment, setNewInvestment] = useState({
        starting_date: null,
        pre_value: '',
        volume: '',
        round: ''
      })
    const handleTab = (event, newValue) => {
        setCurrentTab(newValue);
    };
    const headerList = { // Total Width: 24
        investment:[
          {label: 'Date', value: 'date', width: 3.5},
          {label: 'Amount', value: 'amount', width: 3.5},
          {label: 'Type', value: 'type', width: 3.5},
          {label: 'Round', value: 'round', width: 3.5},
          {label: 'Investor', value: 'investor', width: 12.8, textAlign: 'left'},
        ],
        newInvestment: [
          {label: 'Starting', value: 'starting_date', width: 8},
          {label: 'Pre - value', value: 'pre_value', width: 8},
          {label: 'Volume', value: 'volume', width: 6.4},
          {label: 'Round', value: 'round', width: 6.4},
        ] 
      }
    const getCompany = async() => {
        try{
            const res = await axios.get(url + '/user/company');
            const data = res.data.company;
              if(data.business_id){
                  setBusinessNumber(data.business_id);
              }
            if(data.description){
              setBusiness(data.description)
            }
            if(data.team){
              setTeam(data.team)
            }
            if(data.market.length >= 1){
                data.market.map((market, i) => {
                    if(market.group == '국내외 시장의'){
                      marketList.domestic.opts.some((opt,i2) => {
                          if(opt.label == market.key){
                              marketList.domestic.opts[i2].value = true;
                              return true;
                          }
                      })
                      marketList.domestic.content = market.value;
                      marketList.domestic.value = market.key;
      
                    }
                    else if(market.group == '사업/제품/서비스/개발에 대한'){
                      marketList.business.opts.some((opt,i2) => {
                          if(opt.label == market.key){
                              marketList.business.opts[i2].value = true;
                              return true;
                          }
                      })
                      marketList.business.content = market.value;
                      marketList.business.value = market.key;
      
                    }
                    else if(market.group == '동사에 대한'){
                      marketList.company.opts.some((opt,i2) => {
                          if(opt.label == market.key){
                              marketList.company.opts[i2].value = true;
                              return true;
                          }
                      })
                      marketList.company.content = market.value;
                      marketList.company.value = market.key;
      
                    }
                    else if(market.group == '업계 유사 회사를 기입해 주세요'){
                      marketList.similarity.opts.some((opt,i2) => {
                          if(opt.label == market.key){
                              marketList.similarity.opts[i2].value = true;
                              return true;
                          }
                      })
                      marketList.similarity.content = market.value;
                      marketList.similarity.value = market.key;
                    }
                })
                setMarketList(JSON.parse(JSON.stringify(marketList)))
            }
            if(!IsArrayEmpty(data.services)){
              setProductList(data.services);
            }
            if(!IsArrayEmpty(data.technology)){
              setTechs(data.technology);
            }
            if(data.investments.length >= 1){
              setExistList(data.investments);
            }
            let newTmp = {date: '', pv: '', volume: '', round: ''};
            if(data.new_investment.pre_value){
              newTmp.pre_value = data.new_investment.pre_value;
            }
            if(data.new_investment.volume){
              newTmp.volume = data.new_investment.volume;
            }
            if(data.new_investment.starting_date){
              newTmp.starting_date = data.new_investment.starting_date;
            }
            if(data.new_investment.round){
              newTmp.round = data.new_investment.round;
            }
            if(data.business.length  >= 1){
              setSectors(data.business)
            }
            if(data.technology.length >= 1){
              setTechs(data.technology);
            }
            setNewInvestment(newTmp)
            
        }
        catch(e){
            console.log(e)
        }
    }
    const getProfile = async() => {
        try{
            const res = await axios.get(url + '/user/profile');
      const data = res.data.profile;
      if(data.name){
        setName(data.name);
      }
      if(data.role){
        setRank(data.role);
      }
      if(data.is_founder){
        setIsCoor(data.is_founder);
      }
      
      if(data.email){
        setEmail(data.email);
      }
        }
        catch(e){
            console.log(e)
        }
    }
    const updateSuccess = () => {
        Swal.fire({
          title: '저장이 완료되었습니다.',
          confirmButtonText: '확인',
          showClass: {
            popup: 'animate__animated animate__fadeInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutDown'
          },
          customClass:{
            icon: 'swal-confirm-icon',
            popup: 'swal-confirm-container',
            title: 'swal-confirm-title ',
            confirmButton: 'swal-confirm-btn',
            cancelButton: 'swal-confirm-cancel'
          }
      })
        .then((result) => {
          if(result.isConfirmed) {
            setTimeout(() => {
              navigate('/')
            }, 500);
          }
        })
      }
    
    const updateProfile = async() => {
        try{
            if(!name){
                AlertConfirm2({
                    title: '이름을 입력해주세요.'
                })
            }
            else if(!rank){
                AlertConfirm2({
                    title: '직함을 입력해주세요.'
                })
            }
            else{
                await axios.post(url + '/user/profile', {
                    name: name,
                    role: rank,
                    is_founder: isCoor,
                  })
                  .then((res) => console.log(res.status))
                await axios.post(url + '/user/company',{
                    team: team
                  })
                updateSuccess()
            }
        }
        catch(e){
            console.log(e)
        }
    }
    const handleAdd = (label) => {
        if(label == '제품 및 서비스'){
          setServiceVisible(true)
        }
        else if(label == '투자현황'){
          setInvestmentVisible(true)
        }
        else if(label == '신규투자유치'){
          setNewInvestmentVisible(true)
        }
        else if(label == '시장'){
          setMarketVisible(true);
        }
      }
    const updateCompany = async() => {
        try{
            
            // if(!certification){
            //     AlertConfirm2({
            //         title: '투자사분류를 선택해주세요'
            //     })
            // }
            // else if(round.length < 1){
            //     AlertConfirm2({
            //         title: '선호 라운드을 입력해주세요'
            //     })
            // }
            // if(!price.min || !price.max ){
            //     AlertConfirm2({
            //         title: '주 투자 금액을 입력해주세요'
            //     })
            // }
            // else if(!amount){
            //     AlertConfirm2({
            //         title: '투자재원을 입력해주세요'
            //     })
            // }
            if(sectors.length < 1){
                AlertConfirm2({
                    title: '산업 분야를 입력해주세요'
                })
            }
            else if(techs.length < 1){
                AlertConfirm2({
                    title: '기술을 입력해주세요'
                })
            }
            else{
                const market = [];
      Object.keys(marketList).map((key, i) => {
        if(key != 'similarity'){
          if(marketList[key].value && marketList[key].content){
            market.push({group: marketList[key].title, key: marketList[key].value, value: marketList[key].content})
          }
        }
      })
      
      await axios.post(url + '/user/company',{
        team: team,
        business_id: getPureBusinessNumber(businessNumber),
        description: business,
        business: sectors,
        technology: techs,
        market: market,
        competitor: marketList.similarity.content,
        services: productList,
        investments: existList,
        new_investment: newInvestment,
        name:companyName
      })
                updateSuccess()
            }
        }
        catch(e){
            console.log(e)
        }
    }
    const confirmWithdraw = () => {
        AlertConfirm2({
            title: '정말 회원을 탈퇴하시겠습니까?',
            isDenied:  () => null,
            isConfirmed: () => deleteAccount()
        })
    }
    const deleteAccount = async() => {
        try{
            const res = await axios.post(url + '/user/withdraw', {
                user_id: Auth.getId()
            })
            Auth.logout();
            navigate('/Login')
        }
        catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        if(!Auth.isLoggedIn()){
          navigate('/profile/create');
        }
        Auth.isProfile()
        .then((res) => {
          if(res){
            // navigate('/')
          }
        })
       
      },[isPreview])
    useEffect(() => {
        if(Auth.isLoggedIn()){
        getCompany()
        getProfile()}
    },[])
    return(
        <div className='nv-wrap-bg'>
            <NavBar/>
            <BreadcrumbsHeader list={['my', 'profileEidt']} isPaddingLeft/>
            <div className='profile-edit-wrap'>
                <div className='profile-edit-container'>
                <div className='investee_register_root'>
                    <div className='container-title'>회원정보 수정</div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={currentTab} onChange={handleTab} aria-label="basic tabs example">
                            {menuList.map((data, i) => {
                                return(
                                    <Tab label={data.label} {...a11yProps(i)} />
                                )
                            })
                            }
                        </Tabs>
                    </Box>
                    <TabPanel value={currentTab} index={0}>
                        <InformEdit gender={gender} team={team} setEmail={setEmail} setTeam={setTeam} isCoor={isCoor} setIsCoor={setIsCoor} setGender={setGender} companyName={companyName} setCompanyName={setCompanyName} setBusinessNumber={setBusinessNumber} businessNumber={businessNumber} email={email} name={name} setName={setName} rank={rank} setRank={setRank} handleUpdate={updateProfile} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <InvestmentEdit setProductList={setProductList} headerList={headerList} setExistList={setExistList} newInvestmentVisible={newInvestmentVisible} setMarketList={setMarketList} marketVisible={marketVisible} setMarketVisible={setMarketVisible} setNewInvestmentVisible={setNewInvestmentVisible} investmentVisible={investmentVisible} setInvestmentVisible={setInvestmentVisible} marketList={marketList} serviceVisible={serviceVisible} setServiceVisible={setServiceVisible} productList={productList} handleAdd={handleAdd} setNewInvestment={setNewInvestment} newInvestment={newInvestment} existList={existList} certification={certification} business={business} setBusiness={setBusiness} setCertification={setCertification} round={round} setRound={setRound} price={price} setPrice={setPrice} amount={amount} setAmount={setAmount} sectors={sectors} setSectors={setSectors} techs={techs} setTechs={setTechs} handleUpdate={updateCompany}/>
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        <PasswordEdit/>
                    </TabPanel>
                    <TabPanel value={currentTab} index={3}>
                        <AccountWithdraw handleUpdate={confirmWithdraw}/>
                    </TabPanel>
                </div></div>
            </div>
        </div>
    )
}
export default ProfileEdit;
