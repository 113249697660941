import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import url from 'utils/backend'


export const getCustomType = () => {
  const type = window.localStorage.getItem('report_custom_type') || 'a';
  return type;
}

export const reportCustomTypeSlice = createSlice({
  name: 'reportCustomType',
  initialState: getCustomType(),
  reducers: {
    update: (state, action) => {
        const {payload} = action;
        window.localStorage.setItem("report_custom_type", payload);
        return payload
    },
    reset: (state) => {
      return 'a'
    }
  },

})

export const { update, reset } = reportCustomTypeSlice.actions

export default reportCustomTypeSlice.reducer