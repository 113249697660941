import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import axios from 'axios';
import url from 'utils/backend';
import Header from 'components/admin/Header'
import View from 'components/news/view/View'
import {getLabel} from 'utils/NewsStatus'
import AlertConfirm from 'components/AlertConfirm';
import LeftSideBar from 'components/admin/LeftSideBar'
import RightSideBar from 'components/admin/RightSideBar'
import BuildIcon from '@mui/icons-material/Build';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ListIcon from '@mui/icons-material/List';
import DateFormat_Year_Month_Date_Hour_Minute from 'utils/DateFormat_Year_Month_Date_Hour_Minute'
const NewsView = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {id} = useParams();
    const [news, setNews] = useState('');
    const getNews = async() => {
        try{
            const res = await axios.get(url + '/news?id=' + id);
            setNews(res.data)
        }
        catch(e){
            console.log(e)
        }
    }
    const removeNews = async() => {
        try{
            const res = await axios.post(url + '/news/remove', {
                news_id: id
            })
            navigateList()
        }
        catch(e){
            console.log(e)
        }
    }
    const confirmRemove = () => {
        AlertConfirm({
            title: '기사를 삭제하겠습니까?',
            isConfirmed: removeNews,
            isDenied: () => null
        })
    }

    const navigateList = () => {
        if(location.state?.prevPath == '/admin/newslist' || location.state?.prevPath == '/admin/newsSearch'){
            window.history.back()
        }
        else{
            navigate('/admin/newslist?page=1&status=default')
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getNews();
    }, [id])


    return(
        <div className='admin-main-wrap mg'>
            <Header title={''}/>
            <div className='admin-main'>
                <section className='admin-body mg' >
                    <LeftSideBar/>
                    <div className='admin-body-middle'>
                        <section className='panel-container'>
                            <header className='panel-header'>
                                <div className='panel-title'>
                                    <strong className='rc center'>
                                        기사뷰
                                        {news &&
                                            (news.reserved_at != null
                                            ?
                                                <div className='news-view-status admin'>
                                                    예약기사 {DateFormat_Year_Month_Date_Hour_Minute(news.reserved_at)}
                                                </div>
                                            :
                                                <div className='news-view-status admin'>
                                                    {getLabel(news.status, 'admin')}
                                                </div>                                            
                                            )
                                        }
                                    </strong>
                                </div>
                            </header>
                            <article className='panel-block'>
                                <div className='news-view-top-box admin'>
                                    <div className='top-btn-box'>

                                        <div>                                            
                                        </div>
                                        <div className='rc'>
                                            <>
                                            <div className='top-btn' onClick={navigateList}>
                                                <ListIcon/>
                                                목록
                                            </div>
                                            <div className='top-btn edit' onClick={() => navigate('/admin/newswrite/' + id)}>
                                                <BuildIcon style={{transform: 'rotateY(180deg)'}}/>
                                                수정
                                            </div>
                                            <div className='top-btn' onClick={confirmRemove}>
                                                <DeleteOutlineOutlinedIcon/>
                                                삭제
                                            </div>
                                            </>

                                        </div>
                                    </div>
                                </div>
                                {news &&
                                    <View
                                        title={news.title}
                                        subtitle={news.subtitle}
                                        mainImage={news.main_image}
                                        author={news.author}
                                        date={news.created_at}
                                        data={news.data}
                                        backgroundColor={'#fff'}
                                    />
                                }
                            </article>
                        </section>
                    </div>
                    <RightSideBar/>
                </section>
            </div>
        </div>
    )
}

export default NewsView