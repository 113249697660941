import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
    width: 650px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .item-title{
        font-weight: 600;
        color: #444;
        font-size: 16px;
        margin-bottom: 15px;
    }
    .item-content{
        width: 100%;
        color: #444;
        font-size: 14px;
    }
    .nv-textinput{
        color: #444;
        font-size: 14px;
        border-radius: 0 !important;
        fieldset{
            border: 0px;
        }
    }
`;
const ItemBox = ({title, content, children}) => {
    return(
        <Box style={{marginTop: 35}}>
            <div className='item-title'>
                {title}
            </div>
            <div className='item-content'>
                {content
                ?
                    content 
                :
                    children
                }
            </div>
        </Box>
    )
}
export default ItemBox;