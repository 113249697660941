import { configureStore } from '@reduxjs/toolkit'
import summaryFilterReducer from './filter/summaryFilter'
import keywordFilterReducer from './filter/keywordFilter'
import certificationFilterReducer from './filter/certificationFilter'
import memberFilterReducer from './filter/memberFilter'
import investmentFilterReducer from './filter/investmentFilter'
import secondaryFilterReducer from './filter/secondaryFilter'
import assignmentFilterReducer from './filter/assignmentFilter'
import customFilterReducer from './filter/customFilter'
import reportCustomListReducer from './report/customList'
import memoCustomReducer from './memo/custom'
import categoryReducer from './category'
import customHeaderList from './report/customHeaderList'
import reportCustomTypeReducer from './report/customType'
import registerSelectedList from './register/selectedList'
import registerList from './register/registerList'
import registerSearch from './register/search'
import registerStage from './register/stage'
import registerOpen from './register/open'
import registerIsCompanyOpen from './register/isCompanyOpen'
import searchList from './register/searchList'
import nowTabReducer from './combination/nowTab'
export const store = configureStore({
  reducer: {
    summaryFilter: summaryFilterReducer,
    keywordFilter: keywordFilterReducer,
    certificationFilter: certificationFilterReducer,
    memberFilter: memberFilterReducer,
    investmentFilter: investmentFilterReducer,
    secondaryFilter: secondaryFilterReducer,
    assignmentFilter: assignmentFilterReducer,
    customFilter: customFilterReducer,
    reportCustomList:reportCustomListReducer,
    reportCustomHeaderList: customHeaderList,
    reportCustomType: reportCustomTypeReducer,
    category: categoryReducer,
    memoCustom: memoCustomReducer,
    registerSelectedList: registerSelectedList,
    registerList: registerList,
    registerSearch: registerSearch,
    registerStage: registerStage,
    registerOpen:registerOpen,
    registerIsCompanyOpen:registerIsCompanyOpen,
    searchList: searchList,
    combinationNowTab: nowTabReducer
  },
})