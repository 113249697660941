import NvTextInput2 from 'components/NvTextInput2';
import InputBox3 from 'components/profile/InputBox3';
import React from 'react';
import ItemBox from '../ItemBox';
import 'styles/profile.scss';
import 'pages/profile-edit2/ProfileEdit.scss';

const InformEdit = ({companyName,businessNumber,team, setTeam, setEmail, isCoor, setIsCoor, gender, setGender, setBusinessNumber, setCompanyName, email, name, setName, rank, setRank, handleUpdate}) => {
    return(
        <div className='investee-content-wrap'>
            <div style={{width:"100%", backgroundColor:'#eceff5'}}>
        <div className='form_box register-preview-box'>
            <div style={{width:"100%", backgroundColor:'#eceff5'}}>
            <ItemBox title='회사명' content= {companyName}/>
            <ItemBox title='사업자번호'content={businessNumber}/>
            <ItemBox title='email' content={email} />
                
           {/*  <ItemBox title='이름' >
                <div className='text_input' content={name}>
                    <NvTextInput2 defaultValue={name} onChange={(v) => setName(v)} />
                </div>
            </ItemBox> */}
            <InputBox3 required option='name' state={name} setState={setName} />
            {/* <InputBox3 required option={'gender'} state={gender} setState={setGender}/>
            <InputBox3 required option='founder' state={isCoor} setState={setIsCoor} /> */}
            <InputBox3 required option='rank' state={rank} setState={setRank}/>
            <InputBox3 required option='team' state={team} setState={setTeam} />
            <div style={{ width: '100%',display: 'flex', justifyContent: 'center', paddingBottom: 30, marginTop: 50}}>
                <div className='profile-edit-btn' onClick={handleUpdate} >
                    저장
                </div>
            </div></div>
        </div></div></div>
    )
}

export default InformEdit;