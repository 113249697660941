import React, { useEffect, useState } from 'react';
import IsArrayEmpty from 'utils/IsArrayEmpty'
import Badges from './Badges'
const InputMarket = ({state}) => {
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    if(state){
      Object.keys(state).map((key, i) => {
        if(key == 'similarity'){
          if(!IsArrayEmpty(state[key].content)){
            setIsVisible(true)
          }
        }
        else if(state[key].content){
          setIsVisible(true)
        }
      })
    }
  },[state])
    return(
      isVisible 
      ?
        <div className='top-row-container' style={{border: '0px solid #bdbdc2'}}>
        {Object.keys(state).map((key, i) => {
          if(key == 'similarity'){
            return(
              !IsArrayEmpty(state['similarity'].content) &&
              <div>
                <div className='top-row-box'>
                <span className='name'>{state[key].title}({state[key].value})</span>
              </div>
              <div className='text_input' style={{border: 'none', height: '100%', paddingBottom: 10}}>
                <Badges state={state['similarity'].content} style={{minHeight: 20}} />
              </div>
              </div>
            )
          }
          else{
            return(
              state[key].content &&
              <div>
                <div className='top-row-box'>
                <span className='name'>{state[key].title}({state[key].value})</span>
              </div>
                <pre className='pre_text_input' style={{border: 'none'}}>{state[key].content}</pre>
              </div>
            )
          }
        })}
        </div>
        :
        <div className='top-row-container' style={{border: '0px solid #bdbdc2'}}>
          <div>
            <div className='top-row-box'>
            <span className='name'></span>
          </div>
            <pre className='pre_text_input' style={{border: 'none'}}></pre>
          </div>
        </div>
    )
}
export default InputMarket;