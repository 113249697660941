import React,{useState} from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
const PageModule = ({pageCount, onPageChange, isMember = false, isNewsList = false, handleApproveNews, handleRemoveNews}) =>{
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page')
    const pageIndexRender = () =>{
        let arr =[]
        for(let i=0; i < pageCount; i++){
            arr.push(
                <li className='li onebefore'>
                    <a className={'a'+(page==i + 1?' current':'')} onClick={()=>handlePage(i + 1)}>{i + 1}</a>
                </li>
            )
        }
        return arr
    }
    const handlePage = (v) => {
        searchParams.set('page', v)
        setSearchParams(searchParams)
    }
    return(
        <footer className='footer'>
            <div className='pagin-group'>
                <article className='pagin'>
                    <nav aria-label='Pagination'>
                        <ul className='ul'>
                            <li className='li'>
                                <a className='a' style={{letterSpacing:-3}} onClick={() => handlePage(1)}>
                                    &#60;&#60;
                                </a>
                            </li>
                            {page > 1 &&
                                <li className='li onenext'>
                                    <a className='a' style={{letterSpacing:-3}} onClick={() => handlePage(Number(page) - 1)}>
                                        &#60; &nbsp; 이전
                                    </a>
                                </li>
                            }
                            
                            {page >1 && 
                                <li className='li onebefore' onClick={() => handlePage(Number(page) - 1)}>
                                    <a className='a'>
                                        &#60; 
                                    </a>
                                </li>
                            }
                            
                            {pageIndexRender()}
                            {/* {page < pageCount &&
                                <li className='li onenext' onClick={() => setSearchParams({'page': Number(page) + 1})}>
                                    <a className='a'>
                                        &#62;
                                    </a>
                                </li>
                            } */}
                            {page < pageCount &&
                                <li className='li onebefore' onClick={() => handlePage(Number(page) + 1)}>
                                    <a className='a'>
                                    &#62;
                                    </a>
                                </li>
                            }
                            {page < pageCount &&
                                <li className='li onenext' onClick={() => handlePage(Number(page) + 1)}>
                                    <a className='a'>
                                   다음&nbsp;&#62;
                                    </a>
                                </li>
                            }
                            <li 
                                className='li' 
                                onClick={() => handlePage(pageCount)}
                            >
                                <a className='a' style={{letterSpacing:-3}}>
                                &#62;&#62;
                                </a>
                            </li>
                        </ul>
                    </nav>
                </article>
                {/* {isMember && 
                    <article className='pagin'>
                        <div className='btn-group'>
                            <button className='btn view'>
                                <CancelIcon style={{width:14, height:14}}/>
                                &nbsp; 탈퇴아이디보기</button>
                            <a className='btn a'>
                                <DoneIcon style={{width:14, height:14}}/>
                                &nbsp; 등록</a>
                        </div>    
                    </article>
                } */}
                {isNewsList &&
                    <article className='pagin'>
                        <div className='btn-group'>
                            <button className='btn approve-btn' onClick={() => handleApproveNews()}>
                                <DoneIcon/>
                                선택승인
                            </button>
                            <button className='btn remove-btn' onClick={() => handleRemoveNews()}>
                                <DeleteOutlinedIcon />
                                선택삭제
                            </button>
                        </div>    
                    </article>
                }
            </div>
        </footer>
    )
}

export default PageModule