import { flexbox } from '@mui/system';
import React, {useCallback, useState,useEffect} from 'react';
import { useDrop } from 'react-dnd'
import WriteItem from './WriteItem';
import GenerateId from 'utils/GenerateId'
import update from 'immutability-helper'
import axios from 'axios'
import url from 'utils/backend'
import _ from 'lodash'
import NewsWriteContent from './NewsWriteContent'
const WriteCard = ({handleDrop, writes, isWrite,setWrites, necessaryItems, setNecessaryItems}) => {
    const [{ canDrop, isOver }, drop] = useDrop(({
        accept:'WriteCard',
        drop: ((item) => {
            handleDrop(item)
            
        }),
        collect:monitor=>({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }))

    const moveWrites = useCallback((dragIndex, hoverIndex)=>{
        setWrites(prev => 
            update(prev, {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, prev[dragIndex]],
                ],
            })
        )
    }, [])


    const moveDrop = useCallback((item, hoverIndex)=>{
        let {type, tagName, index, listboxtype} =item;
        let newWrite = {
            id: GenerateId(),
            type: type,
            innerHtml:'',
            tagName: tagName,
            status: 'temp'
        }
        setWrites(prev =>{
            console.log(prev)
            let tmp = _.cloneDeep(prev)
            tmp.splice(hoverIndex, 0, newWrite)
            return tmp;
        })

    },[writes])

    const handleUpdateNecessary = useCallback((id, value, level) => {
        setNecessaryItems(prev => {
            let tmp = _.cloneDeep(prev);
            tmp.map(data => {
                if(data.id == id){
                    if(level){
                        data[level] = value
                    }
                    else{
                        if(data.type == 'img'){
                            data.file = value;
                            data.innerHtml = null;
                        }
                        else{
                            data.innerHtml = value;
                        }
                    }
                }
            })
            return tmp
        })
    }, [necessaryItems])

    const handleUpdate = useCallback((id, value, level) => {
        setWrites(prev => {
            let tmp = _.cloneDeep(prev);
            tmp.map(data => {
                if(data.id == id){
                    if(level){
                        data[level] = value
                    }
                    else{
                        if(data.type == 'img'){
                            data.file = value;
                            data.innerHtml = null;
                        }
                        else{
                            data.innerHtml = value;
                        }
                    }
                }
            })
            return tmp
        })
    }, [writes])
    const renderItem = useCallback((data, index) => {
        return (
            <WriteItem 
                key={data.id} 
                isWrite={isWrite} 
                moveDrop={moveDrop} 
                moveWrites={moveWrites} 
                id={data.id} 
                item={data} 
                type={data.type} 
                index={index} 
                handleDrop={handleDrop} 
                writes={writes} 
                setWrites={setWrites} 
                handleUpdate={handleUpdate}
            />
        )
      }, [])
    return(
        <div
            id='main-paragraph'
            style={{
                backgroundColor : (isWrite?'#fff':'#f9f9f9'),
                listStyle:'none',
                paddingTop:'1.25rem', 
            }}
        >
            {necessaryItems.map((data, i) => {
                return(
                    <NewsWriteContent 
                        item={data} 
                        isLock
                        isWrite={isWrite} 
                        handleUpdate={handleUpdateNecessary} 
                        isNecessary 
                        index={i} 
                    />
                )
            })
            }
            {!isWrite && 
            <div style={{backgroundColor:'#f9f9f9', margin:'0 auto', width:620}}>
                <div className='main-body-header'>
                    <div className='text-box mg'>
                        <div className='title'>
                            <div className='by mg'>
                                <span className='span mg'>
                                    <a style={{color:'inherit'}} href='/'>김현태 </a>
                                </span>
                                기자
                            </div>
                        </div>
                        <div className='date mn'>출간일
                            <time> August 3, 2022</time>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div>
                {writes.map((data, i) => renderItem(data, i))}
            </div>
            {(isWrite && writes.length == 0) &&
                <div
                    ref={drop}
                    style={{
                        backgroundColor : isOver ? 'rgba(0,0,0,.25)': '#fff',
                        height:120,
                        border: '2px dashed rgba(0,0,0,.25)',
                        color: '#888',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center'
                    }}
                >
                    Drag &#38; Drop
                </div>
                
            }

        </div>
    )
}
export default WriteCard