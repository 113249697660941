
import React, {useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'
import search_icon from 'assets/images/search_icon.png'
import styled from 'styled-components';
import Button from '@mui/material/Button';
import axios from 'axios';
import url from 'utils/backend';
import Swal from 'sweetalert2'
import moment from 'moment'
import Auth from 'utils/auth';
import {ViewIcon, LikeIcon, ChatIcon, ClockIcon, IconContainer} from 'components/discussion/Icon'

const ReplyBox = styled.div `
  width: 100%;
  margin-top: 50px;
`;
const ReplyHeader = styled.div `
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  p{
    font-size: 16px;
    font-weight: 900;
  }
  div{
    font-size:13px;
    font-weight: 400;
  }
  .title-box{
    display: flex;
    flex-direction: row;
    align-items: center
  }
  .delete-btn{
    color: black;
    margin-left: 10px;
    cursor: pointer;
  }
  .modify-btn{
    color: black;
    margin-left: 10px;
    font-size: 12px;
    cursor: pointer;
  }
`;
const ReplyContent = styled.pre`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  margin-bottom:15px;
`;

const ReplyWrite = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .input-box{
    width: 88%;
  }
  textarea{
    width: 85% !important;
    min-height: 80px !important;
    background-color: white;
    border-radius: 5px;
  }
  button{
    background-color: white;
    color: black;
    height: 50px;
  }

`;
const ReplyCard = ({item, getBoardInfo, getReplyList, writer_id, isReReply = false}) => {
    const [isLike, setIsLike] = useState(item.like);
    const [isEdit, setIsEdit] = useState(false);
    const [isReReplyWrite, setIsReReplyWrite] = useState(false);
    const [reReplies, setReReplies] = useState([]);
    const [rereplyContent, setReReplyContent] = useState('');
    const handleLike = async() => {
        if(isLike){
            item.likes -= 1;
            await axios.delete(url + '/forum/reply/' + item._id + '/unlike');
            setIsLike(false);
        }
        else{
          item.likes += 1;
            await axios.post(url + '/forum/reply/' + item._id + '/like');
            setIsLike(true);
        }
    }
    const updateReply = async() => {
        try{
            await axios.put(url + '/forum/reply/' + item._id,{
                content: item.content
            });
            setIsEdit(false)
        }
        catch(e){
            console.log(e)
        }
    }

    const createReReply = async() => {
        try{
            const res = await axios.post(url + '/forum/reply', {
                origin_id :item._id,
                content: rereplyContent
            });
            setIsReReplyWrite(false);
            getReplyList();
            
        }
        catch(e){
            console.log(e)
        }
    }
    const deleteReply = async(id) => {
        try{
          Swal.fire({
            title: '댓글을 삭제하시겠습니까?',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '닫기',
            customClass:{
                icon: 'swal-confirm-icon',
                popup: 'swal-confirm-container',
                title: 'swal-confirm-title animate__animated animate__bounceIn',
                confirmButton: 'swal-confirm-btn',
                cancelButton: 'swal-confirm-cancel'
            }
          }).then(async(result) => {
            if(result.isConfirmed) {
              const res = await axios.delete(url + '/forum/reply/' + id);
              getReplyList();
            } else if (result.isDenied) {
            }
        })
    
        }
        catch(e){
          console.log(e)
        }
    } 
    return(
        <>
            <ReplyHeader>
                <span className='title-box'>
                    <p>{item.category} <span style={{fontSize: 12, fontWeight:400}}>{item.author}</span>{(writer_id == item.user_id) && <span className='discussion-writer'>글쓴이</span>}</p>
                    {(!isEdit) &&
                        ((Auth.isMy(item.user_id))
                        ?
                        <>
                        <span className='modify-btn' onClick={() => setIsEdit(true)}>수정</span>
                        <span className='delete-btn' onClick={() => deleteReply(item._id)}>X</span>
                        </>
                        :
                        (!isReReply) && <span className='modify-btn' onClick={() => setIsReReplyWrite(!isReReplyWrite)}>답글</span>
                        )
                    }
                </span>
                {/* <div>{moment(data.created_at).format('YYYY.MM.DD')}</div> */}
                {/* {(!isEdit) && <ClockIcon time={item.created_at}/>} */}
            </ReplyHeader>
            <ReplyContent>
                {(!isEdit)
                ?
                item.content
                :
                <ReplyWrite>
                    <NvTextArea isExpand minRows={4} className='input-box' defaultValue={item.content} onChange={(v) => item.content = v}/>
                    <Button variant="contained" onClick={updateReply}>
                        저 장
                    </Button>
                </ReplyWrite>
                }
            </ReplyContent>
            <IconContainer>
                {(!isEdit) && <LikeIcon cnt={item.likes} isLike={isLike} onClick={(!Auth.isMy(item.user_id)) ? handleLike : null}/>}
                {/* <ChatIcon cnt={item.replies}/> */}
            </IconContainer>
            {(isReReplyWrite) &&
              <ReplyWrite style={{marginTop: 30}}>
                  <NvTextArea isExpand minRows={2} className='input-box' defaultValue={rereplyContent} onChange={(v) => setReReplyContent(v)}/>
                  <Button variant="contained" onClick={createReReply}>
                      등 록
                  </Button>
              </ReplyWrite>
            }
           
        </>
    )
}
export default ReplyCard;