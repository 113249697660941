import React, {useState, useEffect} from 'react'
import _ from "lodash";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import moment from 'moment';
import usePortal from 'react-useportal'
import { DatePicker, Space , ConfigProvider} from 'antd';
import 'antd/lib/date-picker/style/index.css'
import koKR from 'antd/es/locale/ko_KR'
import {ReactComponent as PickerIcon} from 'assets/images/DatePicker_icon.svg';

const {RangePicker} = DatePicker;

const NvDateRangePicker = ({defaultValue = [], onChange, unit, placeholder, isChangeDefault = true, first = '', waveStyle={}, isSuffix = true}) => {
    const [value, setValue] = useState([])
    const [open, setOpen] = useState(false)
    const [firstMode, setFirstMode] = useState(first ? first :'year')
    const [secondMode, setSecondMode] = useState(first ? first :'year')
    const [firstOpen, setFirstOpen] = useState(false);
    const [secondOpen, setSecondOpen] = useState(false) 
    const [init, setInit] = useState(true)

    const getPicker = () => {
        if(unit == '년'){
            return 'year'
        }
        else if(unit == '년월'){
            return 'month'
        }
        else{
            return 'date'
        }
    }
    const handlePannel = (value, mode, setMode) => {
        if(unit == '년월'){
            if(mode == 'year') setMode('year')
            else{
                setMode('month')
            }
        }
        else if(unit == '년월일'){
            if(mode == 'year') setMode('year')
            else if(mode == 'month'){
                setMode('month')
            }
            else{
                setMode('date')
            }
        }
    }
    useEffect(() => {
        if(init){
            let tmp = []
            defaultValue.map(d => {
                if(d){
                    tmp.push(moment(d))
                }
                else{
                    tmp.push(null)
                }
            })
            setValue(_.cloneDeep(tmp))
            setInit(false)
        }
        else if(!init && isChangeDefault){
            let tmp = []
            defaultValue.map(d => {
                if(d){
                    tmp.push(moment(d))
                }
                else{
                    tmp.push(null)
                }
            })
            setValue(_.cloneDeep(tmp))
        }
    }, [defaultValue])

    return(
        <ConfigProvider
            locale={koKR}
        >
            <div className='nv-daterangepicker'>
                <DatePicker
                    inputReadOnly                
                    value={value[0]}
                    className={firstOpen && 'open'}
                    onOpenChange={(open) => setFirstOpen(open)}
                    style={{paddingRight: 0}}
                    placeholder={placeholder[0]}
                    picker={getPicker(unit)}
                    onChange={(v) => {
                        var tmp = _.cloneDeep(value);
                        tmp[0] = v
                        setValue(tmp)
                        if(onChange) onChange(tmp)
                    }}
                    mode={firstMode}
                    onPanelChange={(value, mode) => {
                        handlePannel(value, mode, setFirstMode)
                    }}
                    suffixIcon={<div style={{marginLeft: 10}}/>}
                />
                <span id='nv-endAndornment-hover' style={{marginRight: 20, ...waveStyle}}>~</span>
                <DatePicker
                    inputReadOnly
                    value={value[1]}
                    style={{paddingRight: 0}}
                    className={secondOpen && 'open'}
                    onOpenChange={(open) => setSecondOpen(open)}
                    placeholder={placeholder[1]}
                    suffixIcon={isSuffix ? <PickerIcon style={{marginLeft: 5}}/> : null}
                    onChange={(v) => {
                        var tmp = _.cloneDeep(value);
                        tmp[1] = v
                        setValue(tmp)
                        if(onChange) onChange(tmp)
                    }}
                    picker={getPicker(unit)}
                    mode={secondMode}
                    onPanelChange={(value, mode) => {
                        handlePannel(value, mode, setSecondMode)
                    }}
                />
            </div>
            {/* <RangePicker 
                value={value} 
                separator={<p style={{margin:'0 0 0 -5px',fontSize:14}}>~</p>}
                inputReadOnly={true}
                picker={getPicker(unit)}
                placeholder={placeholder}
                suffixIcon={<PickerIcon/>}
                onCalendarChange={(dates, dateStrings) => {
                    if(dates){
                        var tmp = []
                        if(dates[0]){
                            if(dates[1]){
                                tmp = [moment(dateStrings[0]),moment(dateStrings[1])]
                            }
                            else{
                                tmp = [moment(dateStrings[0]),]
                            }
                        }
                        else{
                            if(dates[1]){
                                tmp = [,moment(dateStrings[1])]
                            }
                            else{
                                tmp = []
                            }
                        }
                        setValue(tmp)
                        if(onChange) onChange(tmp)
                    }
                    else{
                        tmp = []
                        setValue(tmp)
                        if(onChange) onChange(tmp)
                    }

                }}
            /> */}
        </ConfigProvider>

    )
}
export default NvDateRangePicker