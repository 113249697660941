import React, { useState, useEffect, useRef, useCallback } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
// import 'styles/common.scss';
import TextareaAutosize from 'react-textarea-autosize';

function NvTextArea({ defaultValue, minRows = 1, placeholder,onChange, rows=1 , className, style}) {
    const [value, _value] = useState('');

    useEffect(() => {
        _value(defaultValue)
    }, [defaultValue])

    return (
        <TextareaAutosize 
            multiline
            style={{...style, borderColor:'rgba(0,0,0,.15)'}}
            minRows={minRows}
            className={className? className + ' nv-textarea mg' : 'nv-textarea mg'}
            value={value} 
            spellcheck="false"
            placeholder={placeholder}
            onChange={(e) => {
                // handleResizeHeight(e)
                _value(e.target.value)
                if(onChange != undefined) onChange(e.target.value)
            }} 
        />
    );
}

export default NvTextArea;