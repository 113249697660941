import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import Auth from "utils/auth";
import logo from "assets/images/logo_with_text.png";
import NavDetail from "./NavDetail";
import CheckAuth from "hooks/CheckAuth";
import MyAvatar from "./MyAvatar";
import Swal from "sweetalert2";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import NvToggle from "./NvToggle";
import { useRecoilState } from "recoil";
import { btmTypeState } from "utils/state";
const NavBar = ({ width = "1200px", backgroundColor, type, isList = true }) => {
  CheckAuth();
  const navigate = useNavigate();
  const backgroundColorStyle = backgroundColor
    ? { backgroundColor: backgroundColor }
    : null;
  const activePage = window.location.pathname.split("/")[1];
  const [searchState, setSearchState] = useState(true);
  const [btmType, setBtmType] = useRecoilState(btmTypeState);
  let wrapStyle = {};
  if (isList) {
    wrapStyle = {
      width: "100%",
      padding: "0px 87px",
    };
  } else {
    wrapStyle = {
      width: width,
    };
  }
  let navList = [
    // {label: '회사소개', path: '#', parent: 'company'},
    { label: "NEW INVEST", path: "/investor/find", parent: "investor" },
    // {label: '정부과제찾기', path: '#'},
    { label: "BLIND", path: "/discussion/review", parent: "discussion" },
    // {label: 'IR 일정', path: '#', parent: 'ir'},
  ];
  const handleactivePage = (data) => {
    if (data.parent == "investor" && window.location.href.includes("find")) {
      return "active";
    } else {
      if (data.parent == activePage && !window.location.href.includes("my")) {
        if (data.parent == "company") {
          if (window.location.href.includes("my")) {
            return "";
          } else {
            return " active ";
          }
        } else {
          return " active";
        }
      } else {
        return "";
      }
    }
  };
  const handleNavigate = async (path) => {
    if (path == "#") {
      Swal.fire({
        title: "서비스 준비 중 입니다.",
        confirmButtonText: "확인",
        showClass: {
          popup: "animate__animated animate__fadeInUp",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutDown",
        },
        customClass: {
          icon: "swal-confirm-icon",
          popup: "swal-confirm-container",
          title: "swal-confirm-title animate__animated animate__bounceIn",
          confirmButton: "swal-confirm-btn",
          cancelButton: "swal-confirm-cancel",
        },
      });
    } else {
      if (window.location.pathname == path) {
        window.location.reload();
      } else {
        navigate(path);
      }
    }
  };
  return (
    <div className="nv-header" style={{backgroundColor:'#f9f9f9'}}>
      <div
        className="nv-header-wrapper "
        style={{ overflow: searchState ? "hidden" : "hidden" }}
      >
        <ul
          style={{
            width: 400,
            display: "flex",
            flexDirection: "row",
            paddingLeft: 20,
            marginBottom:0
          }}
        >
          {/* <li className="nv-nav-item-brand"><a className="nv-brand-name" href="" onClick={(e) => { navigate('/main') }}>PitchDeck</a></li> */}
          <li
            key={1}
            className="nv-nav-item-menu"
            onClick={() => {
              if (btmType == "Menu") {
                setBtmType("");
              } else {
                setBtmType("Menu");
              }
            }}
          >
            <div className="nv-nav-btn">
              <div
                className={"bar1 " + (btmType != "Menu" ? "" : "bar1-change")}
              ></div>
              <div
                className={"bar2 " + (btmType != "Menu" ? "" : "bar2-change")}
              ></div>
              <div
                className={"bar3 " + (btmType != "Menu" ? "" : "bar3-change")}
              ></div>
            </div>
            <NvToggle btmType={btmType} />
          </li>
          {/* <li
            key={2}
            className='nv-nav-item'
          >
            <div className='nv-nav-link'>
              <SearchIcon style={{marginTop:16, '&hover':{opacity:0.7}}}/>
            </div>
          </li> */}
          {/* {navList.map((data, i) => {
            return(
              <li
              key={i+1}
              className="nv-nav-item"
              >
                <div className={"nv-nav-link mn-extended" + handleactivePage(data)}  onClick={(e) => { handleNavigate(data.path) }}>{data.label}</div>
                <NavDetail width={data.width} direction={data.direction} parent={data.parent} curPage={window.location.pathname}></NavDetail>
              </li>
            )
          })} */}
        </ul>

        <div className={"nav-item-pitchdeck " + (!searchState && "hidden")}>
          <div style={{position:'relative'}}>
            <a className="nv-brand-name mn">
            <img  className='nv-logo' src={logo}></img>
            {/* PITCHDECK */}
          </a>

          <IconButton
            className="searchicon"
            onClick={() => {
              setSearchState(!searchState);
            }}
          >
            <SearchIcon />
          </IconButton>
          </div>
          <div className="nv-nav-header-center">
            <Paper
              sx={{
                p: "4px",
                display: "flex",
                backgroundColor: "#f9f9f9",
                alignItems: "center",
                width: "100%",
                height: 40,
              }}
              classes={{
                root: "nv-nav-search",
              }}
            >
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search News"
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton onClick={() => setSearchState(!searchState)}>
                <ClearIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
        <ul
          style={{
            width: 400,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom:0
          }}
        >
          <MyAvatar />
        </ul>
      </div>
    </div>
  );
};
export default NavBar;
