
import RemoveHtml from 'utils/RemoveHtml'
const DisplayNewsTxt = (data) => {
 
    return data.innerHtml.split('</p>').map(content => {
        let tmp = content.replace('<p>', '')
        if(!RemoveHtml(tmp)) return;
        else if(tmp == '<br>') return <br/>
        return (
            <>
            {RemoveHtml(tmp)}
            <br/>
            </>
        )
    })
}

export default DisplayNewsTxt