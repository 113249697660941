import { divisionList, durationPeriodList, managementFeeList, performanceFeeList, settleMonthList, standardRateList, standardRateTypeList, statusList,  } from "./combinationDropDownList"
import {investmentTypeList, BriefsList, InvestmentMethodList, growthList, stockTypeList, certificationList} from 'utils/DropDownList'

export const overviewList = [
    {label:'조합명',value:'name',th:2,td:10, type: 'string'},
    {label:'조합구분',value:'division',th:2,td:2, type: 'string', combo: divisionList, onlyCombo: true},
    {label:'결성일',value:'formation_date',th:2,td:2, type: 'date', unit: '년월일'},
    {label:'만기일',value:'due_date',th:2,td:2, type: 'date', unit: '년월일'},
    {label:'존속기간',value:'duration_period',th:2,td:2, type: 'string', combo: durationPeriodList, unit: '년', onlyCombo: true},
    {label:'결산월',value:'settlement_month',th:2,td:2, type: 'string', combo: settleMonthList, unit: '월', onlyCombo: true},
    {label:'등록(설립)일',value:'establish_date',th:2,td:2, type: 'date', unit: '년월일'},
    {label:'결성금액',value:'formation_amount',th:2,td:2, type: 'number', unit: '원'},
    {label:'1좌당금액',value:'share_per_price',th:2,td:2, type: 'number', unit: '원'},
    {label:'고유번호',value:'unique_number',th:2,td:2 ,type: 'string'},
    {label:'기준수익률',value:'standard_rate_type',th:2,td:2, type: 'string', combo: standardRateTypeList,
    second:{
        label:'기준수익률',value:'standard_rate',type: 'number', unit: '%'
    }
    },
    {label:'관리보수',value:'management_fee',th:2,td:2, type: 'number', 
    // combo: managementFeeList,
     unit: '%',
    second:{
        label:'관리보수',value:'management_fee_etc',type: 'string'
    }
    },
    {label:'성과보수',value:'performance_fee',th:2,td:2, type: 'number',
    // combo: performanceFeeList, 
    unit: '%',
    second:{
        label:'성과보수',value:'performance_fee_etc',type: 'string'
    }
    },
    {label:'조합상태',value:'status',th:2,td:2, type: 'string', combo: statusList},
    {label:'투자금액',value:'investment_amount',th:2,td:2, type: 'number', unit: '원', readOnly: true},
    {label:'투자잔액',value:'investment_balance',th:2,td:2, type: 'number', unit: '원', readOnly: true},
    {label:'투자기업',value:'investment_company',th:2,td:2, type: 'string', readOnly: true},
    {label:'관할구청',value:'district_office',th:2,td:2, type: 'string'},
    {label:'세무관서',value:'customs_office',th:2,td:2, type: 'string'},
    {label:'은행명',value:'bank_name',th:2,td:2, type: 'string'},
    {label:'은행계좌',value:'bank_account',th:2,td:2, type: 'string'},
    {label:'회계감사',value:'auditing',th:2,td:2, type: 'string'},
    {label:'소재지',value:'address',th:2,td:10, type: 'string'},
    {label:'첨부파일',value:'file',th:2,td:10},
]
export const LPList =   [
    // {label:'번호',value:'no',td:1, type: 'number'},
    {label:'조합원명',value: 'user_id', readValue: 'name', td:2, type: 'string', combo: [], onlyCombo: true},
    {label:'유형',value:'type',td:1, type: 'string', readOnly: true},
    {label:'구분',value: 'division',td:1, type: 'string', readOnly: true},
    {label:'출자좌수',value: 'share',td:1, type: 'number',unit: '좌'},
    {label:'출자금액',value: 'amount',td:1, type: 'number', unit: '원', readOnly: true},
    {label:'출자비율',value:'rate',td:1, type: 'number', unit: '%', readOnly: true},
    {label:'납입일자',value:'payment_date',td:1, type: 'date', unit: '년월일'},
    {label:'은행명',value:'bank_name',td:1 ,type: 'string', readOnly: true},
    {label:'계좌번호',value:'bank_account',td:1, type: 'string', readOnly: true},
    {label:'납입계좌',value:'payment_account',td:1, type: 'string'},
]
export const MyInfoList=[

    {label:'이름',value:'name', th:1,td:2, type: 'string'},
    {label:'주민등록번호',value:'registeration_number',th:1,td:2,type:'string'},
    {label:'이메일',value: 'email',th:1,td:2, type: 'string'},
    {label:'비밀번호',value: 'password',th:1,td:2, type: 'string',modifyOnly:true,isPassword:true},
    {label:'권한',value:'type',th:1,td:2, type:'string', combo:[{label:'GP',value:'GP'},{label:'LP',value:'LP'}],onlyCombo:true},
    {label:'휴대폰',value:'phone',th:1,td:2, type: 'string'},
    {label:'구분',value:'division',th:1,td:2, type: 'string',combo:[{label:'법인',value:'법인'},{label:'개인',value:'개인'}],onlyCombo:true},
    {label:'주소',value:'address',th:1,td:2, type: 'string'},
    {label:'은행명',value:'bank_name',th:1,td:2, type: 'string'},
    {label:'계좌번호',value:'bank_account',th:1,td:2, type: 'string'},
]

export const DistributionDetailList= [
    {
        label: '출자금',
        th:1,
        columns: [
            {label:'결성금액',th:1,td:2, value: 'formation_amount', type: 'number', unit: '원',},
            {label:'기 분배 출자금',th:1,td:2, value: 'distribution_amount', type: 'number', unit: '원',},
            {label:'출자잔액',th:1,td:2, value: 'investment_balance', type: 'number', unit: '원',},
        ]
    },
    {
        label: '투자',
        th:1,
        columns: [
            {label:'투자금액',th:1,td:2, value: 'investment_amount', type: 'number', unit: '원',},
            {label:'투자잔액',th:1,td:2, value: 'investment_balance', type: 'number', unit: '원',}
        ]
    },
    {
        label: '회수',
        th:1,
        columns: [
            {label:'투자원금',th:1,td:2, value: 'investment_amount', type: 'number', unit: '원',},
            {label:'투자수익',th:1,td:2, value: 'collect_gains', type: 'number', unit: '원',}
        ]
    },
    {
        label: '조합운용수익',
        th:1,
        columns: [
            {label:'예금이자',th:1,td:2, value: 'deposit_interest', type: 'number', unit: '원',},
            {label:'잡이익',th:1,td:2, value: 'miscellaneous_profit', type: 'number', unit: '원',},
        ]
    },
    {
        label: '조합운용비용',
        th:1,
        columns: [
            {label:'관리보수',th:1,td:2, value: 'management_fee', type: 'number', unit: '원',},
            {label:'성과보수',th:1,td:2, value: 'performance_fee', type: 'number', unit: '원',},
            {label:'회계감사',th:1,td:2, value: 'auditing', type: 'number', unit: '원',},
            {label:'법률감사',th:1,td:2, value: 'legal_audit', type: 'number', unit: '원',},
            {label:'은행수수료',th:1,td:2, value: 'bank_fee', type: 'number', unit: '원',},
            {label:'증권사수수료',th:1,td:2, value: 'brokerage_fee', type: 'number', unit: '원',},
            {label:'지급수수료',th:1,td:2, value: 'payment_fee', type: 'number', unit: '원',},
            {label:'세금과공과',th:1,td:2, value: 'tax_and_due', type: 'number', unit: '원',},
        ]
    },
    {
        label: 'TOTAL(배분금액)',
        th: 2,
        td: 2,
        type: 'number',
        value:'distribution_total',
    },
]
export const distributionPerformanceHeader = [
    {
        label:'',
        value:'',
        td:1,
        type:'string'
    },
    {
        label:'%',
        value:'percent',
        td:1,
        type:'string',
    },
    {
        label:'적용금액',
        value:'apply_amount',
        td:1,
        type:'string'
    }
]
export const distributionPerformanceData = [
    {
        label: '총수익률',
        value:{percent:'3%',apply_amount:'1'},
    },
    {
        label: '기준수익률',
        value:{percent:'3%',apply_amount:'1'},
    },
    {
        label: '초과수익률',
        value:{percent:'3%',apply_amount:'1'},
    },
    {
        label: '성과보수율',
        value:{percent:'3%',apply_amount:'1'},
    },
    
]

export const DistributionGetInfo = [
    {
        label: '예금이자',
        td: 2,
        type: 'number',
        unit: '원',
        value:'deposit_interest', 
    },
    {
        label: '잡이익',
        td: 2,
        type: 'number',
        unit: '원',
        value:'miscellaneous_profit', 
    },
    {
        label: '은행수수료',
        td: 2,
        type: 'number',
        unit: '원',
        value:'bank_fee', 
    },
    {
        label: '증권사수수료',
        td: 2,
        type: 'number',
        unit: '원',
        value:'brokerage_fee', 
    },
    {
        label: '지급수수료',
        td: 2,
        type: 'number',
        unit: '원',
        value:'payment_fee', 
    },
    {
        label: '세금과공과',
        td: 2,
        type: 'number',
        unit: '원',
        value:'tax_and_due', 
    },
]
export const TradeList = [
    {
        label: '기업명',
        td: 2,
        type: 'string',
        value:'company_id', readValue: 'name', onlyCombo: true,
        detail: true
    },
    {
        label: '투자내역',
        columns: [
            {label:'투자일자',td:1, value: 'investment_date', type: 'date', unit: '년월일',},
            {label:'주식수',td:1, value: 'investment_share', type: 'number', unit: '주'},
            {label:'주당단가',td:1, value: 'investment_price', type: 'number', unit: '원'},
            {label:'투자금액',td:1, value: 'investment_amount', type: 'number', unit: '원', readOnly: true},
        ]
    },
    {
        label: '회수내역',
        columns: [
            {label:'회수일자',td:1, value: 'collect_date', type: 'date', unit: '년월일'},
            {label:'매도자',td:1, value: 'collect_name', type: 'string'},
            {label:'주식수',td:1, value: 'collect_share', type: 'number', unit: '주'},
            {label:'주당단가',td:1, value: 'collect_price', type: 'number', unit: '원'},
            {label:'양도가액',td:1, value: 'collect_amount', type: 'number', unit: '원', readOnly: true},
            {label:'양도차익',td:1, value: 'collect_gains', type: 'number', unit: '원', readOnly: true},
        ]
    },
    {
        label: '투자잔액내역',
        columns:[
            {label:'주식수', value: 'remain_share', td:1, type:'number', unit: '주' , readOnly: true},
            {label:'주당단가', value: 'remain_price',td:1, type: 'number', unit: '원', readOnly: true},
            {label:'투자금액', value: 'remain_amount',td:1, type: 'number', unit: '원', readOnly: true}
        ]
    }
]
export const TradeDetailList = [
    {
        label: '기업명',
        td: 2,
        type: 'string',
        value:'company_id', readValue: 'name', onlyCombo: true,
    },
    {
        label: '투자일자',
        td: 1,
        type: 'date',
        unit: '년월일',
        value:'investment_date', 
    },
    {
        label: '투자금액',
        td: 1,
        type: 'number',
        unit: '원',
        value:'investment_amount', 
    },
    {
        label: '회수일자',
        td: 1,
        type: 'date',
        unit: '년월일',
        value:'collect_date', 
        detail: true
    },
    {
        label: '회수금액',
        td: 1,
        type: 'string',
        value:'collect_amount',
        type: 'number',
        unit: '원',
        detail: true
    },
    {
        label: '회수수익',
        td: 1,
        type: 'string',
        value:'collect_gains', 
        type: 'number',
        unit: '원',
        detail: true
    },
    {
        label: '수익률',
        td: 1,
        type: 'string',
        value:'revenue_rate',
        type: 'number',
        unit: '%',
        detail: true
    },
]

export const WithdrawList = [
    {label:'일자',value:'date', type: 'date', unit: '년월일', td:2,align:'center'},
    {label:'거래대상자',value:'trading_partner', type: 'string',td:1,align:'center'},
    {label:'적요',value:'briefs', type: 'string',td:1,align:'center', combo: BriefsList, onlyCombo: true},
    {label:'은행',value:'bank_name', type: 'string', td:1,align:'center'},
    {label:'계좌번호',value:'bank_account', type: 'string',td:2,align:'center'},
    {label:'금액',value:'amount', type: 'number', unit: '원', td:2,align:'center'},
    {label:'비고',value:'remarks', type: 'string',td:3,align:'center'},
]

export const DistributionList = [
    {label:'조합원명',value: 'user_id', readValue: 'name', td:2, type: 'string', combo: [], onlyCombo: true},
    {label:'출자비율',value:'rate',td:1, type: 'number', unit: '%', readOnly: true},
    {label:'출자원금',value: 'amount',td:1, type: 'number', unit: '원', readOnly: true},
    {label:'수익금',value: 'revenue',td:2, type: 'number', unit: '원', readOnly: true},
    {label:'성과보수금액',value: 'performance_fee_amount',td:1, type: 'number', unit: '원'},
    {label:'원천징수세액',value: 'withholding_tax_amount',td:1, type: 'number', unit: '원'},
    {label:'수익률',value: 'revenue_rate',td:1, type: 'number', unit: '%', readOnly: true},
    {label:'비고',value: 'remarks',td:2, type: 'string'},

]

export const ManagementFeePaymentList = [
    {label:'년도',value: 'date', td:1, type: 'date', unit: '년'},
    {label:'회차',value:'times',td:1, type: 'number', unit: '차'},
    {label:'지급일',value: 'payment_date',td:1, type: 'date', unit: '년월일'},
    {label:'관리보수 기간',value: 'range_date',td:2, type: 'range_date', unit: '년월', placeholder: ['시작일', '종료일']},
    {label:'기간일수',value: 'days',td:2, type: 'number', unit: '일', readOnly: true},
    {label:'보수율(액)',value: 'pay_rate',td:1, type: 'number', unit: '%'},
    {label:'금액',value: 'amount',td:1, type: 'number', unit: '원'},
    {label:'출금계좌',value: 'withdraw_account',td:2, type: 'string'},
    {label:'비고',value: 'remarks',td:1, type: 'string'},
]

export const InvestmentList = [
    {label:'기업명',value:'company_id', readValue: 'name', onlyCombo: true, td:2, type: 'string'},
    {label:'투자일자',value:'investment_date',td:1,align:'center', type:'date', unit: '년월일'},
    {label:'투자방식',value: 'method',td:1,align:'center', type: 'string', combo: InvestmentMethodList, onlyCombo: true},
    {label:'투자형태',value: 'investment_type',td:2,align:'center', type: 'string'},
    {label:'주당단가',value:'price',td:1,align:'right', type:'number', unit: '원'},
    {label:'투자금액',value:'volume',td:1,align:'right', type: 'number', unit: '원'},
    {label:'투자밸류',value:'investment_value',td:1,align:'center', type: 'number', unit: '억'},
    {label:'비고',value:'remarks',td:3,align:'center', type: 'string'},
]
export const CompanyInvestmentList = [
    {label:'투자조합/재원', value: 'combination_id', readValue: 'name', td:10, th:2, type: 'string', combo: [], onlyCombo: true},
    {label:'투자금액',value:'volume',td:2, th:2,align:'right', type: 'number', unit: '원'},
    {label:'주당단가',value:'price',td:2, th:2,align:'right', type:'number', unit: '원'},
    {label:'주식수',value: 'share', td:2, th:2, type: 'number', unit: '주'},
    {label:'투자방식',value: 'method',td:2,th:2, align:'center', type: 'string', combo: InvestmentMethodList, onlyCombo: true},
    {label:'투자형태',value: 'investment_type', td:2, th:2, type: 'string', combo: investmentTypeList},
    {label:'지분율',value: 'rate', td:2, th:2, type: 'number', unit: '%'},
    {label:'투자밸류',value:'investment_value',td:2,th:2, align:'center', type: 'number', unit: '억'},
    {label:'투자단계',value: 'investment_stage', td:2, th:2, type: 'string', combo: growthList},
    {label:'주권형태',value: 'stock_type', td:2, th:2, type: 'string',combo:  stockTypeList, onlyCombo: true},
    {label:'세부투자조건',value: 'detail_investment', td:10, th:2, type: 'string'},
    {
        label:'동반투자', 
        isRender:true,
        td: 10,
        th: 2,
        columns: [
        {label:'투자기관/조합',value: 'co_name', td:2, th:2, type: 'string'},
        {label:'투자금액',value: 'co_amount', td:2, th:2, type: 'number', unit: '원'},
        {label:'투자여부',value: 'co_decision', td:2, th:2, type: 'yn'},
        {label:'납입일정',value: 'co_payment_date', td:2, th:2, type: 'date', unit: '년월일'},
        {label:'비고',value: 'co_remarks', td:2, th:2, type: 'string', },
    ]},
    {label:'자금사용처',value: 'fund_used', td:10, th:2, type: 'string'},
    {label:'비고',value: 'remarks', td:10, th:2, type: 'string'},
]
export const CompanyOverviewList = [
    {label:'기업명', value: 'name', td:10, th:2, type: 'string', isRender:true},
    {label:'대표이사',value:'ceo',td:2, th:2, type: 'string'},
    {label:'설립연도',value:'establish_date',td:2, th:2, type:'date', unit: '년'},
    {label:'종업원수',value: 'employee_total', td:2, th:2, type: 'number', unit: '명'},
    {label:'자본금',value: 'capital',td:2,th:2,  type: 'number', unit: '원'},
    {label:'기업성장단계',value: 'stage', td:2, th:2, type: 'string', combo: growthList},
    {label:'주권형태',value: 'stock_type', td:2, th:2, type: 'string', combo: stockTypeList, onlyCombo: true},
    {label:'홈페이지',value:'homepage',td:2,th:2, type: 'string'},
    {label:'법인등록번호',value: 'corporation_id', td:2, th:2, type: 'string'},
    {label:'사업자등록번호',value: 'business_id', td:2, th:2, type: 'string',},
    {label:'주요사업',value: 'main_business', td:10, th:2, type: 'string'},
    {label:'산업분류',value: 'industry_classification', td:10, th:2, type: 'string'},
    {label:'인증현황',value: 'certification_status', td:10, th:2, type: 'string', combo:certificationList, multipleCombo:true},
    {label:'주소',value: 'address', td:10, th:2, type: 'string'},
    {label:'첨부파일',value: 'file', td:10, th:2},
]
export const CompanyCreateInfoList = [
    {label:'기업명', value: 'name', td:5, th:1, type: 'string',},
    {label:'대표이사',value:'ceo',td:1, th:1, type: 'string'},
    {label:'설립연도',value:'establish_date',td:1, th:1, type:'number', unit: '년월일'},
    {label:'종업원수',value: 'employee_total', td:1, th:1, type: 'number', unit: '명'},
    {label:'자본금',value: 'capital',td:1,th:1,  type: 'number', unit: '원'},
    {label:'기업성장단계',value: 'stage', td:1, th:1, type: 'string'},
    {label:'주권형태',value: 'stock_certification', td:1, th:1, type: 'string'},
    {label:'홈페이지',value:'homepage',td:1,th:1, type: 'string'},
    {label:'법인등록번호',value: 'corporation_id', td:1, th:1, type: 'string'},
    {label:'사업자등록번호',value: 'business_id', td:1, th:1, type: 'string',},
]