import { DatePickerProps, TimePickerProps } from 'antd';
import { DatePicker, Select, Space, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment'
import 'antd/dist/antd.css';

const format = 'HH:mm';

const NvTimePicker = ({defaultValue, onChange}) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(defaultValue ? moment(defaultValue) : null)
  }, [defaultValue])

  return (
    <TimePicker 
      value={value}
      onChange={onChange} 
      format={format}
      minuteStep={5}
      placeholder="00:00"
    />
  );
};
export default NvTimePicker
