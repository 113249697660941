import React, { useEffect, useState } from 'react'
import { AspectRatio } from 'react-aspect-ratio';
import axios from 'axios'
import url from 'utils/backend'
import getImageSignedURL from 'utils/getImageSignedURL'

const ViewImage = ({item, src, containerStyle}) =>{
    const img = new Image();
    const [uri, setUri] = useState('')
    const [ratio, setRatio] = useState(1);

    const getImage = async() => {
        let tmp = ''
        if(item?.file?.preview){
            tmp = item?.file?.preview
        }
        else if(item.key){
           tmp = await getImageSignedURL(item.key, item.mimetype)
        }
        setUri(tmp)
        img.src = tmp
        img.onload = () =>{
            if((img.height/img.width)*100 > 56){
                setRatio(56)
            }
            else{
                setRatio((img.height/img.width)*100)
            }
        }
    }

    useEffect(() => {
        getImage()
    }, [])
    return(
        <figure className='news-view-img-container' style={containerStyle}>
            <div style={{position:'relative',}}>
                <div style={{position:'relative', paddingBottom:(ratio+'%')}}>
                    {uri &&
                        <img className='img' src={uri}/>
                    }
                </div>
                {item.desc &&
                <p className='desc'>▲ {item.desc}</p>

                }
                {/* <div className='img-comment'>Christian Marquardt/Getty Images</div> */}
            </div>
        </figure>
    )
}

export default ViewImage