import styled from 'styled-components';

export const Root = styled.div`
    background-color: white;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const ContainerWrap = styled.div`
    width: 700px;

    .top_description_box {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
    }
    .top_description_box p{
        color: white;
        font-size: 16px;
    }
    h2 {
        color: white;
        font-size: 26px;
        font-weight: 800;
        margin-bottom: 40px;
        margin-top: 40px;
    }
  
    .btn_box {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
      div {
          cursor: pointer;
          background-color: #695D45;
          width: 180px;
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          color: white;
          font-weight: 500;
          font-size: 13px;
          align-items: center;
          margin-right: 20px;
          border-radius:5px;
          
  
      }
    }
    .col_btn_box {
        display: flex;
        flex-direction: column;
        width: 100%;
  
        div {
            width: 100%;
            height: 50px;
            background-color: #7E7E7E;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-weight: 900;
            color: #000000;
        }
        input {
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
            padding-left: 10px;
            font-size: 16px !important;
            font-weight: 500;
        }
    }
`;