import React from 'react'
import {useNavigate} from 'react-router-dom'
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {ReactComponent as Kakaotalk} from 'assets/images/kakaotalk.svg'
import AppleIcon from '@mui/icons-material/Apple';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
const Security = () =>{
    const navigate = useNavigate()
    return(
        <div>
            <div className='security'>
                <h2 className='mn-extended'>Email</h2>
                <p className='mn'>Manage your email address</p>
                <ul>
                    <li>
                        <div className='div'>
                            <EmailIcon className='svg'/>
                            {/* 밑에 p태그에 저장된 이메일 표시 */}
                            <p style={{margin:0}}></p>
                        </div>
                        <button className='btn' type='button' onClick={()=>navigate('/profile/change?type=email')}>
                            <span className='span'>
                                <span>Change email</span>
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className='security'>
                <h2 className='mn-extended'>Password</h2>
                <p className='mn'>Manage your account password.</p>
                <ul>
                    <li>
                        <div className='div'>
                            <ArrowCircleUpIcon className='svg'/>
                            <p style={{margin:0}}></p>
                        </div>
                        <button className='btn' type='button' onClick={()=>navigate('/profile/change?type=password')}>
                            <span className='span'>
                                <span>Change password</span>
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className='security'>
                <h2 className='mn-extended'>Social accounts</h2>
                <p className='mn'>Connecting social accounts allows you to log in with a click of a button.</p>
                <ul>
                    <li>
                        <div className='div'>
                            <AppleIcon className='svg'/>
                            <p style={{margin:0}}>Apple</p>
                        </div>
                        <button className='btn' type='button'>
                            <span className='span'>
                                <span>Connect</span>
                            </span>
                        </button>
                    </li>
                    <li>
                        <div className='div'>
                            <Kakaotalk className='svg' />
                            <p style={{margin:0}}>KakaoTalk</p>
                        </div>
                        <button className='btn' type='button'>
                            <span className='span'>
                                <span>Connect</span>
                            </span>
                        </button>
                    </li>
                    <li>
                        <div className='div'>
                            <TwitterIcon className='svg'/>
                            <p style={{margin:0}}>Twitter</p>
                        </div>
                        <button className='btn' type='button'>
                            <span className='span'>
                                <span>Connect</span>
                            </span>
                        </button>
                    </li>
                    <li>
                        <div className='div'>
                            <LinkedInIcon className='svg'/>
                            <p style={{margin:0}}>LinkedIn</p>
                        </div>
                        <button className='btn' type='button'>
                            <span className='span'>
                                <span>Connect</span>
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
            <button className='delete-account' type='button'>
                <span className='span'>
                    <span>Delete account</span>
                </span>
            </button>
            <p className='delete-description mn'>If you delete your account, your data will be gone forever.</p>
        </div>
    )
}

export default Security