import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import HomeIcon from '@mui/icons-material/Home';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LockResetIcon from '@mui/icons-material/LockReset';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ClearIcon from '@mui/icons-material/Clear';
import '../style.scss'
import NvToggle from 'components/navbar/NvToggle'
import { useRecoilState} from 'recoil'
import {btmTypeState} from 'utils/state'
import CheckAuth from 'hooks/CheckAuth';
import Auth from 'utils/auth';
const Header = ({title, backgroundColor}) =>{
    CheckAuth()
    const [isHover, setIsHover] =useState(false)
    const [hidden, setHidden] = useState(true)
    const navigate = useNavigate()
    const [btmType, setBtmType] = useRecoilState(btmTypeState)
    const handleLogout = () => {
        Auth.logout()
        navigate('/login', {replace: true})
    }
    return(
        <header className={'member-admin-topbar'+(backgroundColor?' border':'')} style={{backgroundColor:backgroundColor}}>
            <div 
            className='nv-nav-item-menu news-nav'
            onClick={()=>{
              if(btmType=='Menu'){
                setBtmType('')
              }
              else{
              setBtmType('Menu');
              }
          }
          }>
                <div className='nv-nav-btn' >
                <div className={'bar1 ' +(btmType!='Menu'?'':'bar1-change')}></div>
                <div className={'bar2 ' +(btmType!='Menu'?'':'bar2-change')}></div>
                <div className={'bar3 ' +(btmType!='Menu'?'':'bar3-change')}></div>
                </div>

            <NvToggle className='' btmType={btmType}/>
            </div>
            <article className='newsroom'>
                {/* <button aria-selected={reactableSide} type='button' className='nav-btn' onClick={()=>{setReactableSide(!reactableSide);setReactableOpacity(!reactableOpacity)}}>
                    <i className='svg'/>
                </button> */}
                <SearchIcon className='nav-btn' style={{backgroundColor:backgroundColor,transition:'all .3s ease-in-out',transform:(hidden?'translateY(0)':'translateY(100%')}}onClick={()=>setHidden(!hidden)}/>
                <span style={{transition:'all .3s ease-in-out',color:(backgroundColor && 'black'),transform:(hidden?'translateY(0)':'translateY(100%)')}}>{title}</span>
                <div className='search-box-wrap' style={{transform:(hidden?'translateY(-100%)':'translateY(0)')}}>
                    
                        {/* {hidden?<SearchIcon className='btn' onClick={()=>setHidden(!hidden)}/>:<ClearIcon className='btn' onClick={()=>setHidden(!hidden)}/>} */}
                    
                    <Paper
                    component="form"
                    style={{ padding:'2px 0 0 0',display: 'flex', alignItems: 'center',borderBottom:(backgroundColor && 'rgba(0,0,0,.3'), backgroundColor:(backgroundColor && '#f9f9f9'),width: '100%', height:'100%', borderRadius:'0.2rem' }}
                    className={'form active'}
                    >
                        <IconButton type="submit" style={{marginRight:10}}sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            sx={{width:'90%' }}
                            placeholder="기사검색"
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        {/* {!hidden && <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>} */}
                        <ClearIcon style={{cursor:'pointer'}}onClick={()=>setHidden(!hidden)}/>
                    </Paper>
                </div>
            </article>
            <article className='user-section'>
                <div className='main-btn mg'>
                    <a className='a' href='/'>
                        <HomeIcon className='svg'/>
                        <span className='text'>메인</span>
                    </a>
                </div>
                <div className='main-btn mg'>
                    <div className='menu-btn' onMouseEnter={()=>{setIsHover(true)}} onMouseLeave={()=>{setIsHover(false)}}>
                        <button type='button' className='btn'>
                            <em className='img' style={{backgroundImage:'url(http://cms.weeklytoday.com/image/newsroom/default-user.png)'}}>
                            </em>
                            <span>{Auth.getName()}</span>
                            <MoreHorizIcon className='dotlist'/>
                        </button>
                        <div className='drop-menu' style={{visibility:(isHover?'visible':'hidden')}}>
                            {/* <a>
                                <AccountBoxIcon className='svg'/> 정보수정
                            </a>
                            <a>
                                <LockResetIcon className='svg'/> 비밀번호 수정
                            </a> */}
                            <a onClick={handleLogout}>
                                <PowerSettingsNewIcon className='svg'/> 로그아웃
                            </a>
                        </div>
                    </div>
                </div>
            </article>
        </header>
    )
}

export default Header