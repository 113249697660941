import React, { useState, useEffect } from 'react';
import { scroller, Element } from "react-scroll";
import './style.scss'
import { Route, Routes } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckAuth from 'hooks/CheckAuth';
import Auth from 'utils/auth'
import axios from 'axios';
import url from 'utils/backend';
import NavBar from 'components/navbar/NavBar'
import IdPw from './component/IdPw'
import Profile from './component/Profile'
async function digest(password) {
  let pw = new TextEncoder().encode(password);
  pw = await crypto.subtle.digest('SHA-256', pw);
  pw = Array.from(new Uint8Array(pw));
  pw = pw.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  // console.log(pw)
  return pw
}

const ProfileCreate = () => {
  const navigate = useNavigate();
  const [infoState, setInfoState] =useState(true)
    return(
        <div className='profile-create-root mn'>
          <div className="nv-line"></div>
          <NavBar type={"investee"} backgroundColor={"#f9f9f9"} />
          <main className='profile-create-main-wrap'>
            <div className='profile-create-main'>
              <div className='contents-wrap'>
                <div className='contents'>
                  <header className='header'>
                    <h1 className='h1 mn'>Let's get your account set up.</h1>
                  </header>
                  <div style={{marginBottom:40}}>
                    {infoState
                    ?
                    <IdPw setInfoState={setInfoState}/>
                    :
                    <Profile />}
                  </div>
                  <p className='help-p'>
                    Need help? Email
                    <a> join@Pitchdeck.com </a>
                    for support.
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
    )
}

export default ProfileCreate;

// <div className='intro_root'>
        //   <div className='top_description_box'>
        //     <h2>
        //     사이트를 이용하시기 전에 가입신청이 필요합니다
        //     </h2>
        //     <div>
        //       <p>피치덱은 스타트업 업계 내 창업자와 투자사간 정보의 비대칭을 해소하고 자 스타트업에게 필요한 투자자 정보</p>
        //       <p>서비스를 제공 또는 AI 매칭하고 창업자들간 자유롭게 네트워크 및 정보교류를 할 수 있는 공간을 제공합니다.</p>
        //       <br/>
        //       <p >피치덱은 비공개서비스이며 스타트업에게만  서비스를 제공합니다. </p>
        //     </div>
        //   </div>

        //   <div className='btn_box'>
        //     <div onClick={(e) => { navigate('/login') }}>
        //     투자유치 등록 신청
        //     </div>
        //     <div>
        //     스타트업 등록 신청
        //     </div>
        //   </div>
        //   <h2 style={{marginTop:80}}>
        //   이미 확인되셨나요?
        //   </h2>
        //   <div className='col_btn_box'>
        //     <input className="input_box" type="text" style={{borderRadius:0, textAlign: 'center', fontSize: 16}} placeholder="아이디" name='id' value={inputId} onKeyDown={_onKeyDown} onChange={handleInputId} required />
        //     <input className="input_box" type="password" onKeyDown={_onKeyDown} style={{borderRadius:0, textAlign: 'center', fontSize:16}} placeholder="비밀번호" name='passwd' value={inputPw} onChange={handleInputPw} required />
        //     <div  onClick={onSubmitHandler}>로그인</div>
        //   </div>
        // </div>