import React from 'react'
import {Link} from 'react-router-dom'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import fillZero from 'utils/fillZero'
import getDayKorean from 'utils/getDayKorean'
import DateFormat_Year_Month_Date_Hour_Minute from 'utils/DateFormat_Year_Month_Date_Hour_Minute'
import DisplayNewsTxt from 'utils/DisplayNewsTxt'
import AddIcon from '@mui/icons-material/Add';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
const today = new Date();
const RightSideBar  = ({list = []}) =>{
    return(
        <aside className='rightaside'>
            <article className='today-date'>
                <strong className='strong'>{fillZero(today.getMonth() + 1)}. {fillZero(today.getDate())}. </strong>
                {fillZero(today.getHours())}:{fillZero(today.getMinutes())}
                <small className='small'> ({getDayKorean(today)})</small>
            </article>
            <article className='current-num'>
                <a className='content'>
                    <div className='svg-wrap apply'>
                        <AddIcon className='svg'/>
                    </div>
                    <span className='span-title'>
                        <em className='cnt'>{list.reduce((a,b) => (b.status == 'apply' ? a += 1 : a),0)}</em>
                        <span className='txt'>구독신청</span>
                    </span>
                </a>
                <a className='content'>
                    <div className='svg-wrap re'>
                        <CreateOutlinedIcon className='svg'/>
                    </div>
                    <span className='span-title'>
                        <em className='cnt'>{list.reduce((a,b) => (b.status == 'reject' ? a+=1 : a),0)}</em>
                        <span className='txt'>기사제보</span>
                    </span>
                </a>
                <a className='content'>
                    <div className='svg-wrap ok'>
                        <FlagOutlinedIcon className='svg'/>
                    </div>
                    <span className='span-title'>
                        <em className='cnt'>{list.reduce((a,b) => (b.status == 'approve' ? a+=1 : a),0)}</em>
                        <span className='txt'>저작권문의</span>
                    </span>
                </a>
            </article>
            {/* <article className='dash-note'>
                <a className='dash-note-header'>
                    <span className='header-title'>작성중</span>
                    <em className='header-cnt'>{list.reduce((a,b) => (b.status == 'write' ? a+=1 : a),0)}</em>
                </a>
                {list.filter(e => e.status == 'write').slice(0, 1).map(data => {
                    return(
                        <section className='content-box'>
                            <div className='latest-note'>
                                <Link className='note-href' to={'/member/newsview/' + data._id}>
                                    <span className='note-txt'>{data.title}</span>
                                    <em className='note-btm'>{data.author.name} | {DateFormat_Year_Month_Date_Hour_Minute(data.updated_at)}</em>
                                </Link>
                            </div>
                        </section>
                    )
                })}
            </article>
            <article className='dash-note'>
                <a className='dash-note-header'>
                    <span className='header-title'>기사댓글</span>
                    <em className='header-cnt'>0</em>
                </a>
            </article> */}
        </aside>
    )
}

export default RightSideBar