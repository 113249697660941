import React from 'react'
import ContentsBox from './ContentsBox'
const ContentsContainer = ({list}) =>{
    return(
        <div className='contents-container'>
            
                
                            <ContentsBox list={list.info}/>
                        
            
        </div>
    )
}

export default ContentsContainer