import React, { useEffect } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const IconTopic = ({header}) =>{
    
    return(
        <a className='main-investee-section-topic' href={'/topic?title='+(header.title)}>
            <div className='topic-header'>
                {header.icon!='' &&
                 <div style={{marginRight:10}}>
                    <div className='topic-header-icon'>
                        <img src={header.icon}/>
                    </div>
                </div>
                }
                <div className='topic-header-text'>
                    <div className='font-style-topic-title'>
                        {header.title}
                    </div>
                    {header.desc!='' && 
                    <div className='font-style-topic-desc'>
                        <div style={{marginTop:4}}>{header.desc}</div>
                    </div>
                    }
                </div>
                <ArrowForwardIosIcon className='svg'/>
            </div>
        </a>
    )
}

export default IconTopic