import styled from 'styled-components';
export const Root = styled.div`
    background-color: #F5F5F5;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;
export const Container = styled.div`
    width: 900px;
`
export const HeaderWrap = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:30px;
    padding-bottom: 40px;
`;
export const NavTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: black;
`;
export const SubTitle = styled.div`
    color: black;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 18px;
    color: rgb(51,51,51);
`;
export const Title = styled.div`
    color: rgb(51,51,51);
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 40px;
    margin-top: 40px;
`;
export const Name = styled.div`
    color: black;
    font-size: 18px;
    font-weight: 600;
`;
export const MText = styled.div `
    color: rgb(51,51,51);
    font-size: ${(props) => props.fontSize};        
`;
export const Card = styled.div `
    // background-color: white;
    padding: 20px 0px 30px 0px;
    color: black;
    .discussion-stage-box{
        width: 300px;
        height: 50px;
        background-color: white;
        margin-bottom: 30px;
        fieldset{
            border: 0px !important;
        }

    }
`;
export const CardLabel = styled.div `
    font-weight: 900;
    font-size: ${(props) => props.fontSize};        
    color: #444;
`;
export const WriteBox = styled.div `
    background-color: #FFFFFF;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    textarea{
        width: 100%;
        height: 98% !important;
    }
`;
export const Bottom = styled.div`
    margin-top: 30px;
    width: 100%;
    text-align: right;
    button{
        width: 900px;
        padding: 6px 0px;
        background-color: white;
        color: black;
        font-weight: 800;
        box-shadow: 0 2px 2px #c7c7c7;
    }
    button:hover{
        background-color: gray;
    }
`;
export const CheckBox = styled.div `
    .css-i4bv87-MuiSvgIcon-root{
        fill: black !important;
    }
`;