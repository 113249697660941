import React, {useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import NvTextInput from 'components/NvTextInput'
import NvTextArea from 'components/NvTextArea'
import search_icon from 'assets/images/search_icon.png'
import styled from 'styled-components';
import Button from '@mui/material/Button';
import axios from 'axios';
import url from 'utils/backend';
import Swal from 'sweetalert2'
import moment from 'moment'
import Auth from 'utils/auth';

const ReplyBox = styled.div `
  width: 100%;
  margin-top: 50px;
`;
const ReplyHeader = styled.div `
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  p{
    font-size: 16px;
    font-weight: 900;
  }
  div{
    font-size:13px;
    font-weight: 400;
  }
  .title-box{
    display: flex;
    flex-direction: row;
    align-items: center
  }

`;
const ReplyContent = styled.div`
  width: 100%;
  height: 100px;
  font-size: 15px;
  font-weight: 400;
`;
const ReplyWrite = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .input-box{
    width: 88%;
  }
  textarea{
    width: 85% !important;
    min-height: 80px !important;
    background-color: white;
    border-radius: 5px;
  }
  button{
    background-color: white;
    color: black;
    height: 50px;
  }

`;
const Reply = ({_id, replys, getBoardList}) => {
  const [content, setContent] = useState('');
  const createReply = async() => {
    try{
      if(content){
        const res = await axios.post(url + '/forum/reply', {
          origin_id: _id,
          content: content
        })
        setContent('')
        getBoardList();
      }
      else{
        Swal.fire({
          text: '내용을 입력해주세요.',
          icon: 'error',
          showClass: {
            popup: 'animate__animated animate__fadeInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutDown'
          },
          customClass:{
            title: 'animate__animated animate__bounceIn'
          }
        })
      }
    }
    catch(e){
      console.log(e)
    }
  }
  const deleteReply = async(id) => {
    try{
      Swal.fire({
        title: '댓글을 삭제하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '닫기'
      }).then(async(result) => {
        if(result.isConfirmed) {
          const res = await axios.delete(url + '/forum/reply/' + id);
          getBoardList()
        } else if (result.isDenied) {
        }
    })

    }
    catch(e){
      console.log(e)
    }
  } 

  return(
    <ReplyBox>
      <ReplyWrite>
        <NvTextArea className='input-box' defaultValue={content} onChange={(v) => setContent(v)}/>
        <Button variant="contained" onClick={() => {createReply()}} >
            등 록
        </Button>
      </ReplyWrite>
      {replys.map((data, i) => {
        return(
          <>
          <ReplyHeader>
            <span className='title-box'>
              <p>{data.category} <span style={{fontSize: 12, fontWeight:400}}>{data.author}</span></p>
              {(Auth.isMy(data.user_id)) && <span className='delete-btn' onClick={() => deleteReply(data._id)}>X</span>}
            </span>
            <div>{moment(data.created_at).format('YYYY.MM.DD')}</div>
          </ReplyHeader>
          <ReplyContent>
            {data.content}
          </ReplyContent>
          </>
        )
      })
      }
    </ReplyBox>
  )
}
export default Reply;