import { createSlice } from '@reduxjs/toolkit'

const assignmentFilter = {
  totalPrice: '',
  perPrice: '',
}
export const assignmentFilterSlice = createSlice({
  name: 'assignmentFilter',
  initialState: assignmentFilter,
  reducers: {
    update: (state, action) => {
      const {payload} = action;
      return payload
    },
    reset: (state) => {
      return assignmentFilter
    }
  },
})

export const { update, reset } = assignmentFilterSlice.actions

export default assignmentFilterSlice.reducer