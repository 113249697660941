import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled as styled2 } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import _ from 'lodash'
const PrettoSlider = styled2(Slider)({
    color: '#808080',
    height: 3,
    '&.MuiSlider-root':{
      borderRadius: 0
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      border: '2px solid orange',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: 'orange',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });
const Container = styled.div`
  margin-top: 15px;
  .label-box{
    display:flex;
    justify-content: space-between;
    color: #444;
    font-weight: 400;
  }
  .slider-title{
    font-size: 12px;
    color: #444;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;
const SliderBox = ({title, marks, state, setState, obj, checkObj, customObj = '', step = 1, isValueLabel = false, isDot = false}) => {
  const [value, setValue] = useState('');
  const minDistance = parseInt((marks[marks.length - 1].value - marks[0].value) * 0.1)
  // const minDistance = 10;
  useEffect(() => {
    setValue(state[obj]?.length >= 1 ? state[obj] : [marks[0].value, marks[marks.length - 1].value])
  },[state])

  const handleChange1 = (
    event,
    newValue,
    activeThumb,
  ) => {
    let items = _.cloneDeep(state)
    if (!Array.isArray(newValue)) {
      return;
    }

    if(newValue[0] == marks[0].value && newValue[1] == marks[marks.length - 1].value){
      // state[obj] = [];
      // setState(_.cloneDeep(state)) 
      setValue(newValue)
      // setValue([])
    }
    else if (activeThumb === 0) {
      // state[obj] = [Math.min(newValue[0], value[1] - minDistance), value[1]]
      // setState(_.cloneDeep(state)) 
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]])
    } else {
      // state[obj] = [value[0], Math.max(newValue[1], value[0] + minDistance)]
      // setState(_.cloneDeep(state)) 
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)])
    }
  };

  const valueText = (value) => {
    if(!isValueLabel) return value;
    let result = ''
    marks.some((data, i) => {
      if(data.value == value){
        result = data.label;
      }
    })
    return result
  }


  // useEffect(() => {
  //   setValue(state[obj]?.length >= 1 ? state[obj] : [marks[0].value, marks[marks.length - 1].value])
  // }, [state])

  return(
    <Container>
      {title &&
        <div className='slider-title'>
          {title}
        </div>      
      }
      <div className='label-box'>
        <div>{marks[0].label}</div>
        <div>{marks[marks.length - 1].label}</div>
      </div>
      {value.length >= 1 &&
        <PrettoSlider
          value={value}
          getAriaLabel={() => 'Minimum distance'}
          min={marks[0].value}
          max={marks[marks.length - 1].value}
          valueLabelDisplay="auto"
          valueLabelFormat={valueText}
          // aria-label="pretto slider"
          disableSwap
          onChange={handleChange1}
          step={step}
          onChangeCommitted={(v) => {
            let items = _.cloneDeep(state)
            if(checkObj){
              items[checkObj] = '';
            }
            if(customObj){
              items[customObj] = {min: '', max: ''};
            }
            if(value[0] == marks[0].value && value[1] == marks[marks.length - 1].value){
              items[obj] = []
              setState(_.cloneDeep(items))
            }
            else{
              items[obj] = value
              setState(_.cloneDeep(items))
            }
          }}
        />

      }
    </Container>
  )
}
export default SliderBox
